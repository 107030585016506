import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getProductReviewsList, createProductReview, getRatingDetails, updateProductReview } from './productReviewApi';

const initialState = {
    productReviewList: [],
    newProductReview: [],
    ratingDegtails: [],
    updateReview: [],
    status: {
        productReviewList: "idle",
        newProductReview: "idle",
        ratingDegtails: "idle",
        updateReview: "idle"
    }
}

export const getProductReviewsListAsync = createAsyncThunk('productReview/getProductReviewsList', async (data) => {
    const response = await getProductReviewsList(data.postData, data.tokenData);
    return response
});
export const createProductReviewAsync = createAsyncThunk("productReview/createProductReview", async (data) => {
    const response = await createProductReview(data.postData, data.tokenData);
    return response
});
export const getRatingDetailsAsync = createAsyncThunk('productReview/getRatingDetails', async (data) => {
    const response = await getRatingDetails(data.postData, data.tokenData);
    return response
});
export const updateProductReviewAsync = createAsyncThunk('productReview/updateProductReview', async (data) => {
    const response = await updateProductReview(data.postData, data.tokenData);
    return response
});


const productReviewSlice = createSlice({
    name: 'productReview',
    initialState,
    extraReducers: (builder) => {
        builder
            .addCase(getProductReviewsListAsync.pending, (state) => {
                state.status = "loading";
            })
            .addCase(getProductReviewsListAsync.fulfilled, (state, { payload }) => {
                state.productReviewList = payload
                state.status = "idle";
            })
            .addCase(getProductReviewsListAsync.rejected, (state, { error }) => {
                state.status = "idle";
                state.productReviewList = null
            })
            .addCase(createProductReviewAsync.pending, (state) => {
                state.status = "loading";
            })
            .addCase(createProductReviewAsync.fulfilled, (state, { payload }) => {
                state.newProductReview = payload
                state.status = "idle";
            })
            .addCase(createProductReviewAsync.rejected, (state, { error }) => {
                state.status = "idle";
                state.newProductReview = null
            })
            .addCase(getRatingDetailsAsync.pending, (state) => {
                state.status = "loading";
            })
            .addCase(getRatingDetailsAsync.fulfilled, (state, { payload }) => {
                state.ratingDegtails = payload
                state.status = "idle";
            })
            .addCase(getRatingDetailsAsync.rejected, (state, { error }) => {
                state.status = "idle";
                state.ratingDegtails = null
            })
            .addCase(updateProductReviewAsync.pending, (state) => {
                state.status = "loading";
            })
            .addCase(updateProductReviewAsync.fulfilled, (state, { payload }) => {
                state.updateReview = payload
                state.status = "idle";
            })
            .addCase(updateProductReviewAsync.rejected, (state, { error }) => {
                state.status = "idle";
                state.updateReview = null
            })

    }
});

export const selectProductReviewList = (state) => state.review.productReviewList;
export const selectRatingDetails = (state) => state.review.ratingDegtails;

export default productReviewSlice.reducer;