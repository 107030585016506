import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { leftArrowImg, bxsCoinStackImg, Fedex, Ups, Dhl, LogoIcondl } from "../../../utilities/images";

const DeliveryTypes2 = () => {
    const [selectedValue, setSelectedValue] = useState("jobr")

    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
    return (
        <div className="main-wrapper">
            <div className="order-heading">
                <div className="header-left-item">
                    <h4><Link to='/cart'><img src={leftArrowImg} alt="" /></Link> Delivery</h4>
                </div>
                <div className="header-right-item">
                    <Link to="#">0 <img src={bxsCoinStackImg} alt="" /></Link>
                </div>
            </div>

            <div className="main-content">
                <div className="delivery-typearea">
                    <form action="#" method="POST">
                        <div className="delivery-type-part">
                            <img src={Fedex} alt="" />
                            <div className="delivery-type-cnt">
                                <h2>Fedex Express shippin</h2>
                                <h4>$8.00</h4>
                            </div>
                            <div className="delivery-type-radbtn">
                                <span className="radio-btn">
                                    <input type="radio" id="radio-btn1" name="radio" checked={selectedValue === "fedex"} onChange={() => setSelectedValue("fedex")} />
                                    <label htmlFor="radio-btn1"></label>
                                </span>
                            </div>
                        </div>
                        <div className="delivery-type-part">
                            <img src={Ups} alt="" />
                            <div className="delivery-type-cnt">
                                <h2>UPS Priority shipping</h2>
                                <h4>$8.00</h4>
                            </div>
                            <div className="delivery-type-radbtn">
                                <span className="radio-btn">
                                    <input type="radio" id="radio-btn2" name="radio" checked={selectedValue === "ups"} onChange={() => setSelectedValue("ups")} />
                                    <label htmlFor="radio-btn2"></label>
                                </span>
                            </div>
                        </div>
                        <div className="delivery-type-part">
                            <img src={Dhl} alt="" />
                            <div className="delivery-type-cnt">
                                <h2>DHL Standard shipping</h2>
                                <h4>$8.00</h4>
                            </div>
                            <div className="delivery-type-radbtn">
                                <span className="radio-btn">
                                    <input type="radio" id="radio-btn3" name="radio" checked={selectedValue === "dhl"} onChange={() => setSelectedValue("dhl")} />
                                    <label htmlFor="radio-btn3"></label>
                                </span>
                            </div>
                        </div>
                        <div className="delivery-type-part">
                            <img src={LogoIcondl} alt="" />
                            <div className="delivery-type-cnt">
                                <h2>JOBR Standard shipping</h2>
                                <h4>$8.00</h4>
                            </div>
                            <div className="delivery-type-radbtn">
                                <span className="radio-btn">
                                    <input type="radio" id="radio-btn4" name="radio" checked={selectedValue === "jobr"} onChange={() => setSelectedValue("jobr")} />
                                    <label htmlFor="radio-btn4"></label>
                                </span>
                            </div>
                        </div>
                    </form>
                    <div className="delivery-typebtn">
                        <Link to='/placing'>Done</Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DeliveryTypes2