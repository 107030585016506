import React, { useState, useEffect } from 'react';
import { unwrapResult } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom';
import { ArrowWhite, bagImg, bellImg, leftArrowImg } from '../../../utilities/images';
import { selectLoginAuth } from '../auth/authSlice';
import { getProductByIdApiAsync } from './dashboardSlice'
import CommonLoader from '../../components/shared/CommonLoader';
import { createServiceCartApiAsync, getServiceCartApiAsync, removeOneServiceCartProductApiAsync, selectServiceCartDataByUser } from '../cart/cartSlice';
import { toast } from 'react-toastify';

const SelectService1 = () => {
    const history = useHistory();
    const [productLoading, setProductLoading] = useState(false);
    const { id, sellerId } = useParams();
    const auth = useSelector(selectLoginAuth);
    const toastId = React.useRef(null);
    const dispatch = useDispatch();
    const [productData, setProductData] = useState({});
    const serviceCartData = useSelector(selectServiceCartDataByUser);
    const [checkedTrendingState, setCheckedTrendingState] = useState(false);

    const productDataHandle = () => {
        setProductLoading(true)
        let getProductByIdParams = {
            "postData": {
                id: id,
                seller_id: sellerId,
                app_name: 'b2c',
                delivery_options: 2
            },
            "tokenData": auth?.payload?.token
        }
        dispatch(getProductByIdApiAsync(getProductByIdParams))
            .then(unwrapResult)
            .then(async (obj) => {
                setProductData(obj)
                setProductLoading(false)
            }
            )
            .catch((obj) => {
                setProductLoading(false)
            })
    }

    const handleData = (id) => {
        if (checkedTrendingState === false) {
            setCheckedTrendingState(true)
            let objData = {
                "postData": {
                    "seller_id": sellerId,
                    "supply_id": productData?.data?.payload?.product_detail?.supplies[0]?.id,
                    "supply_price_id": productData?.data?.payload?.product_detail?.supplies[0]?.supply_prices[0]?.id,
                    "product_id": id,
                    "app_name": "b2c"
                },
                "tokenData": auth?.payload?.token
            }
            setProductLoading(true)
            dispatch(createServiceCartApiAsync(objData))
                .then(unwrapResult)
                .then(async (obj) => {
                    if (!toast.isActive(toastId.current)) {
                        toastId.current = toast.success(obj?.data?.msg)
                    }
                    dispatch(getServiceCartApiAsync(auth?.payload?.token))
                        .then(unwrapResult)
                        .then(async (obj) => {
                            setProductLoading(false)
                        }
                        )
                        .catch((obj) => {
                            setProductLoading(false)
                        })
                }
                )
                .catch((obj) => {
                    setProductLoading(false)

                })
        } else {
            setCheckedTrendingState(false)
            let removeData = {
                "postData": {
                    "cart_id": serviceCartData?.data?.payload?.appointment_cart_products[0]?.cart_id,
                    "product_id": serviceCartData?.data?.payload?.appointment_cart_products[0]?.id
                },
                "tokenData": auth?.payload?.token
            }
            setProductLoading(true)
            dispatch(removeOneServiceCartProductApiAsync(removeData))
                .then(unwrapResult)
                .then(async (obj) => {
                    if (!toast.isActive(toastId.current)) {
                        toastId.current = toast.success(obj?.data?.msg)
                    }
                    dispatch(getServiceCartApiAsync(auth?.payload?.token))
                        .then(unwrapResult)
                        .then(async (obj) => {
                            setProductLoading(false)
                        }
                        )
                        .catch((obj) => {
                            setProductLoading(false)

                        })
                }
                )
                .catch((obj) => {
                    setProductLoading(false)
                })
        }
    }

    useEffect(() => {
        window.scrollTo(0, 0)
        productDataHandle()
        dispatch(getServiceCartApiAsync(auth?.payload?.token))
            .then(unwrapResult)
            .then(async (obj) => {
            }
            )
            .catch((obj) => {
            })
    }, []);

    return (
        <>
            {
                productLoading === true ?
                    <CommonLoader />
                    :
                    <>
                        <div className="order-heading" >
                            <h4 style={{ cursor: "pointer" }} onClick={() => history.push(`/home-store-services/${sellerId}`)}><img src={leftArrowImg} alt="" /> {productData?.data?.payload?.product_detail?.name} </h4>
                            <div className="header-right-item">
                                <button type="button" onClick={() => history.push('/notifications')}><img src={bellImg} alt="" /></button>
                                <button type="button"><img src={bagImg} alt="" /></button>
                            </div>
                        </div>

                        <div className="main-content cart-mainpd">
                            <div className="body-massage-mainprt">
                                <div className="body-msgtitle">
                                    <h2>Select Service <span>Required</span></h2>
                                </div>
                                <div className="bodymsg-part">
                                    {
                                        <div className="bodymsg-item">
                                            <div className="bodymsg-left">
                                                <div className="form-group" >
                                                    <input
                                                        type="checkbox"
                                                        id={`custom-checkbox1-${id}`}
                                                        name={id}
                                                        value={id}
                                                        checked={checkedTrendingState === true}
                                                        onChange={() => handleData(id)}
                                                    />
                                                    <label htmlFor={`custom-checkbox1-${id}`}></label>
                                                </div>
                                                <div className="bdymsg-cnt">
                                                    <h4>{productData?.data?.payload?.product_detail?.name}</h4>
                                                    <p>USD ${productData?.data?.payload?.product_detail?.price}</p>
                                                </div>
                                            </div>
                                            <div className="bodymsg-right">
                                                <img src={productData?.data?.payload?.product_detail?.image} alt="" />
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                            {serviceCartData?.data?.payload?.appointment_cart_products?.length > 0 ?
                                <div className="selected-product checkout-box">
                                    <div className="selected-itembox appoint-box">
                                        <div className="selected-leftcnt">
                                            <p>Services: <strong>{serviceCartData?.data?.payload?.appointment_cart_products?.length}</strong></p>
                                            <p>$ <strong>{serviceCartData?.data?.payload?.appointment_cart_products[0]?.product_details?.price?.toFixed(2)}</strong></p>
                                        </div>
                                        <div style={{ cursor: "pointer" }} className="selected-rightbtn" onClick={() => history.push(`/cart-services/${id}/${sellerId}`)}>
                                            <img src={ArrowWhite} alt="" />
                                        </div>
                                    </div>
                                </div>
                                : ""}
                        </div>
                    </>
            }
        </>
    )
}

export default SelectService1
