import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
    getCartApi,
    createCartApi,
    removeOneCartProductApi,
    createOrderApi,
    handleCartResponseApi,
    handleCartSubmitResponseApi,
    emptyCartApi,
    createServiceCartApi,
    getServiceCartApi,
    removeOneServiceCartProductApi,
    createServiceOrderApi
} from "./cartApi";

const initialState = {
    cartData: [],
    serviceCartData: [],
    cartResponse: {},
    cartSubmitResponse: {},
    status: {
        cartData: 'idle',
        serviceCartData: 'idle',
        cartResponse: 'idle',
        cartSubmitResponse: 'idle'
    },
};
export const getCartApiAsync = createAsyncThunk("cart/getcart", async (data) => {
    const response = await getCartApi(data);
    return response;
});
export const createCartApiAsync = createAsyncThunk("cart/createcart", async (data) => {
    const response = await createCartApi(data.postData, data.tokenData);
    return response;
});
export const removeOneCartProductApiAsync = createAsyncThunk("cart/removeOneCartProductApi", async (data) => {
    const response = await removeOneCartProductApi(data.postData, data.tokenData);
    return response;
});
export const createOrderApiAsync = createAsyncThunk("cart/createOrderApi", async (data) => {
    const response = await createOrderApi(data.postData, data.tokenData);
    return response;
});
export const handleCartResponseApiAsync = createAsyncThunk("cart/handleCartResponseApi", async (data) => {
    const response = await handleCartResponseApi(data);
    return response;
});
export const handleCartSubmitResponseApiAsync = createAsyncThunk("cart/handleCartSubmitResponseApi", async (data) => {
    const response = await handleCartSubmitResponseApi(data);
    return response;
});

export const emptyCartApiAsync = createAsyncThunk("cart/emptyCartApi", async (data) => {
    const response = await emptyCartApi(data);
    return response;
});
export const createServiceCartApiAsync = createAsyncThunk("cart/createServiceCartApi", async (data) => {
    const response = await createServiceCartApi(data.postData, data.tokenData);
    return response;
});
export const getServiceCartApiAsync = createAsyncThunk("cart/getServiceCartApi", async (data) => {
    const response = await getServiceCartApi(data);
    return response;
});
export const removeOneServiceCartProductApiAsync = createAsyncThunk("cart/removeOneServiceCartProductApi", async (data) => {
    const response = await removeOneServiceCartProductApi(data.postData, data.tokenData);
    return response;
});
export const createServiceOrderApiAsync = createAsyncThunk("cart/createServiceOrderApi", async (data) => {
    const response = await createServiceOrderApi(data.postData, data.tokenData);
    return response;
});
export const cartSlice = createSlice({
    name: "cart",
    initialState,
    reducers: {
        cartEmpty: (state) => {
            state.cartData = null;
            state.serviceCartData = null;
            state.cartResponse = null;
            state.cartSubmitResponse = null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getCartApiAsync.pending, (state) => {
                state.status = "loading";
            })
            .addCase(getCartApiAsync.fulfilled, (state, { payload }) => {
                state.cartData = payload
                state.status = "idle";
            })
            .addCase(getCartApiAsync.rejected, (state, { error }) => {
                state.status = "idle";
                state.cartData = null
            })
            .addCase(handleCartResponseApiAsync.pending, (state) => {
                state.status = "loading";
            })
            .addCase(handleCartResponseApiAsync.fulfilled, (state, { payload }) => {
                state.cartResponse = payload
                state.status = "idle";
            })
            .addCase(handleCartResponseApiAsync.rejected, (state, { error }) => {
                state.status = "idle";
                state.cartResponse = null
            })
            .addCase(handleCartSubmitResponseApiAsync.pending, (state) => {
                state.status = "loading";
            })
            .addCase(handleCartSubmitResponseApiAsync.fulfilled, (state, { payload }) => {
                state.cartSubmitResponse = payload
                state.status = "idle";
            })
            .addCase(handleCartSubmitResponseApiAsync.rejected, (state, { error }) => {
                state.status = "idle";
                state.cartSubmitResponse = null
            })
            .addCase(getServiceCartApiAsync.pending, (state) => {
                state.status = "loading";
            })
            .addCase(getServiceCartApiAsync.fulfilled, (state, { payload }) => {
                state.serviceCartData = payload
                state.status = "idle";
            })
            .addCase(getServiceCartApiAsync.rejected, (state, { error }) => {
                state.status = "idle";
                state.serviceCartData = null
            })
    },
});
export const { cartEmpty } = cartSlice.actions
export const selectCartData = (state) => state.cart.cartData;
export const selectServiceCartDataByUser = (state) => state.cart.serviceCartData;
export const selectCartResponse = (state) => state.cart.cartResponse;
export const selectCartSubmitResponse = (state) => state.cart.cartSubmitResponse;

export default cartSlice.reducer;