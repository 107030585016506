import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getWalletData, addWalletBalance, getTransectionHistory } from "./walletApi";

const initialState = {
    walletBalance: [],
    accontBalance: [],
    transectionHistory: [],
    status: {
        walletBalance: "idle",
        accontBalance: "idle",
        transectionHistory: "idle",
    }
}
export const getWalletDataAsync = createAsyncThunk("wallet/getWalletData", async (data) => {
    const response = await getWalletData(data.token);
    return response;
});
export const addWalletBalanceAsync = createAsyncThunk("wallet/addWalletBalance", async (data) => {
    const response = await addWalletBalance(data.postData, data.tokenData);
    return response;
});
export const getTransectionHistoryAsync = createAsyncThunk("wallet/getTransectionHistory", async (data) => {
    const response = await getTransectionHistory(data.postData, data.tokenData);
    return response;
});

export const walletSlice = createSlice({
    name: "wallet",
    initialState,
    reducers: {
        walletDataEmpty: (state) => {
            state.walletBalance = null;
            state.accontBalance = null;
            state.transectionHistory = null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getWalletDataAsync.pending, (state) => {
                state.status = "loading";
            })
            .addCase(getWalletDataAsync.fulfilled, (state, { payload }) => {
                state.walletBalance = payload
                state.status = "idle";
            })
            .addCase(getWalletDataAsync.rejected, (state, { error }) => {
                state.status = "idle";
                state.walletBalance = null
            })
            .addCase(addWalletBalanceAsync.pending, (state) => {
                state.status = "loading";
            })
            .addCase(addWalletBalanceAsync.fulfilled, (state, { payload }) => {
                state.accontBalance = payload
                state.status = "idle";
            })
            .addCase(addWalletBalanceAsync.rejected, (state, { error }) => {
                state.status = "idle";
                state.accontBalance = null
            })
            .addCase(getTransectionHistoryAsync.pending, (state) => {
                state.status = "loading";
            })
            .addCase(getTransectionHistoryAsync.fulfilled, (state, { payload }) => {
                state.transectionHistory = payload
                state.status = "idle";
            })
            .addCase(getTransectionHistoryAsync.rejected, (state, { error }) => {
                state.status = "idle";
                state.transectionHistory = null
            })
    }
})

export const { walletDataEmpty } = walletSlice.actions;
export const selectWalletBalance = (state) => state.wallet.walletBalance
export const selectTransectionHistory = (state) => state.wallet.transectionHistory
export default walletSlice.reducer;