import React, { useEffect, useState } from "react";
import { Link, useHistory } from 'react-router-dom';
import { leftArrowImg, bxsCoinStackImg, applePayImg, creditCardImg, debitCardImg, bankFilledImg, coinStackImg, veirfy2Img } from "../../../utilities/images";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { unwrapResult } from "@reduxjs/toolkit";
import { generatePlaidTokenApiAsync, getBankAccountApiAsync, linkBankAccountAsync, selectLoginAuth } from "../auth/authSlice";
import { useDispatch, useSelector } from "react-redux";
import Card from 'react-bootstrap/Card';
import { usePlaidLink } from 'react-plaid-link';
import { addWalletBalanceAsync, getWalletDataAsync, selectWalletBalance } from "../wallet/walletSlice";
import { toast } from "react-toastify";
import CommonLoader from '../../components/shared/CommonLoader';
import CustomModal from "../../components/shared/CustomModal";
import MoneyOptionModal from "../promotion/MoneyOptionModal";
import { selectUserProfile } from "../profile/profileSlice";
import PaymentAddedModal from "../promotion/PaymentAddedModal";


const Payment = (props) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const location = useLocation();
    const [routeFlag, setRouteFlag] = useState("")
    const toastId = React.useRef(null);
    const [dataLoading, setDataLoading] = useState(false);
    const [loading, setLoading] = useState(false);
    const [linkToken, setLinkToken] = useState("")
    const [amount, setAmount] = useState("")
    const [checkedValue, setCheckedValue] = useState("")
    const auth = useSelector(selectLoginAuth);
    const selectProfile = useSelector(selectUserProfile);
    const walletData = useSelector(selectWalletBalance);
    const [bankDetails, setBankDetails] = useState([]);
    const [key, setKey] = useState(Math.random());
    const [modalDetail, setModalDetail] = useState({
        show: false,
        title: "",
        flag: ""
    });
    const handleOnCloseModal = () => {
        setModalDetail({
            show: false,
            title: "",
            flag: ""
        });
        setKey(Math.random());
    }
    const changeModalHandle = (flag) => {
        setModalDetail({
            show: true,
            flag: flag
        });
        setKey(Math.random());
    }
    const { open, ready } = usePlaidLink({
        token: linkToken,
        onSuccess: (public_token, metadata) => {
            // send public_token to server
            console.log(public_token);
            let params = {
                postData: {
                    "plaid_public_token": public_token
                },
                tokenData: auth?.payload?.token
            }
            dispatch(linkBankAccountAsync(params))
                .then(unwrapResult)
                .then((obj) => {
                    dispatch(getBankAccountApiAsync(auth?.payload?.token))
                        .then(unwrapResult)
                        .then((obj) => {
                            setBankDetails(obj?.payload)
                        }
                        )
                        .catch((obj) => {
                        })
                }
                )
                .catch((obj) => {
                })
        },
    });

    const handleCreateWallet = () => {
        if (selectProfile?.payload?.is_wallet === false) {
            history.push(`/create-wallet`)
        } else {

        }
    }

    const payNowHandle = () => {
        if (!location.state) {
            if (!checkedValue) {
                toast.error("Please Select Bank Account")
            } else {
                setModalDetail({ show: true, flag: "coin_plan" }); setKey(Math.random());
            }
        } else {
            if (!checkedValue) {
                toast.error("Please Select Bank Account")
            }
            else {
                let params = {
                    postData: {
                        "amount": location?.state,
                        "account_name": checkedValue
                    },
                    tokenData: auth?.payload?.token
                }
                setLoading(true)
                dispatch(addWalletBalanceAsync(params))
                    .then(unwrapResult)
                    .then((obj) => {
                        setAmount(obj?.data?.payload?.amount)
                        if (!toast.isActive(toastId.current)) {
                            toastId.current = toast.success(obj?.data?.msg)
                        }
                        setModalDetail({ show: true, flag: 'added' }); setKey(Math.random());
                        let data = {
                            token: auth?.payload?.token
                        }
                        dispatch(getWalletDataAsync(data))
                            .then(unwrapResult)
                            .then(async (obj) => {
                                setLoading(false)
                            }
                            )
                            .catch((obj) => {
                                setLoading(false)
                            })
                        // history.push('/placing-1')
                    }
                    )
                    .catch((obj) => {
                        setLoading(false)
                    })
            }
        }
    }

    useEffect(() => {
        dispatch(generatePlaidTokenApiAsync(auth?.payload?.token))
            .then(unwrapResult)
            .then((obj) => {
                setLinkToken(obj?.payload?.link_token)
            }
            )
            .catch((obj) => {
            })
        setDataLoading(true)
        dispatch(getBankAccountApiAsync(auth?.payload?.token))
            .then(unwrapResult)
            .then((obj) => {
                setBankDetails(obj?.payload)
                setDataLoading(false)
            }
            )
            .catch((obj) => {
                setDataLoading(false)
            })
    }, [])

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <>
            {dataLoading === true ?
                <CommonLoader />
                :
                <>
                    <div className="order-heading">
                        <div className="header-left-item">
                            <h4><Link to="#" onClick={() => { history.push('/more') }}><img src={leftArrowImg} alt="leftArrowImg" /></Link>Payment methods</h4>
                        </div>
                        <div className="header-right-item">
                            <Link to="/wallet" className="coinStack">{walletData ? walletData?.data?.payload?.sila_balance : 0}
                                <img src={coinStackImg} alt="coinStack" />
                            </Link>
                        </div>
                    </div>

                    <div className="main-content">
                        <div className="payment-part paymentholder mt-4 ? ">
                            <form action="#" method="POST">
                                <div className="bodymsg-part">
                                    {
                                        bankDetails?.length > 0 ? <>
                                            {
                                                bankDetails?.map((val, index) => {
                                                    return (
                                                        <Card body key={index} className="mb-2">

                                                            <div className="holders">
                                                                <img src={bankFilledImg} alt="bankFilledImg" className="me-2"/>
                                                                <div className="accdetails">
                                                                    <div className='d-flex'>
                                                                        <h6 className="greysubHead_bold">A/C No:- </h6>{"  "}
                                                                        <span className="greysubHead_"> {val?.account_number}</span>
                                                                    </div>
                                                                    <div className='d-flex'>
                                                                        <h6 className="greysubHead_bold">A/C Holder Name:- </h6>{"  "}
                                                                        <span className="greysubHead_"> {val?.account_owner_name}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="form-group" >
                                                                <input
                                                                    type="radio"
                                                                    id={`custom-checkbox1-${index}`}
                                                                    name="group"
                                                                    value={val?.id}
                                                                    onChange={() => setCheckedValue(val?.account_name)}
                                                                />
                                                                <label htmlFor={`custom-checkbox1-${index}`}></label>
                                                            </div>
                                                        </Card>
                                                    )
                                                })
                                            }
                                        </> : ""
                                    }
                                </div>
                                <ul className="mb-4 pt-2 pb-2">
                                    {/* <li>
                                <div className="pay-left">
                                    <Link to="#"><img src={applePayImg} alt="" /> Apple pay</Link>
                                </div>
                                <div className="pay-right">
                                    <span>Default</span>
                                    <div className="radio-btn">
                                        <input type="radio" id="radio-btn1" name="radio" checked />
                                        <label htmlFor="radio-btn1"></label>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <Link to="#" onClick={() => { history.push({ pathname: '/credit-card', state: `${props.location.state}` }) }}>
                                    <img src={creditCardImg} alt="creditCardImg" />
                                    Add credit card
                                    <span><i className="fas fa-chevron-right"></i></span>
                                </Link>
                            </li>
                            <li>
                                <Link to="#">
                                    <img src={debitCardImg} alt="debitCardImg" />
                                    Add debit card
                                    <span><i className="fas fa-chevron-right"></i></span>
                                </Link>
                            </li> */}
                                    <li onClick={selectProfile?.payload?.is_wallet === true ? () => open() : () => history.push(`/create-wallet/payment`)} disabled={!ready}>
                                        <Link to="#">
                                            <img src={bankFilledImg} alt="bankFilledImg" />
                                            Connect your bank account
                                            <span><i className="fas fa-chevron-right"></i></span>
                                        </Link>
                                    </li>
                                </ul>
                            </form>
                            <div className="paynowBox_" >
                                <Link to="#" className="linkouterBox">
                                    <button type="button" className="btn submitBtn__" disabled={loading} onClick={() => payNowHandle()}>
                                        {loading === true && (
                                            <span className="spinner-border spinner-border-sm"></span>
                                        )}
                                        &nbsp;&nbsp;
                                        <span>Pay Now</span>
                                    </button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </>}
            <CustomModal
                key={key}
                show={modalDetail.show}
                backdrop="static"
                showCloseBtn={true}
                isRightSideModal={false}
                mediumWidth={(modalDetail.flag === 'coin_plan') ? false : true}
                size="md"
                child={(modalDetail.flag === 'coin_plan') ?
                    <MoneyOptionModal checkedValue={checkedValue} closeModal={() => handleOnCloseModal()} details={() => setModalDetail()} changeModal={(e) => changeModalHandle(e)} setAmount={setAmount} setRouteFlag={setRouteFlag} />
                    : (modalDetail.flag === 'added') ?
                        <PaymentAddedModal closeModal={() => handleOnCloseModal()} amount={amount} flag={routeFlag} />
                        : <></>
                }
                onCloseModal={() => handleOnCloseModal()}
            />
        </>
    );
}

export default Payment;