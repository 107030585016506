import React, { useState, useEffect } from "react";
import { Link, useHistory } from 'react-router-dom';
import { leftArrowImg, Info, ArrowWhite } from "../../../utilities/images";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { CalendarClock } from "../../../utilities/images";
import { unwrapResult } from "@reduxjs/toolkit";
import moment from "moment-timezone";
import { createServiceOrderApiAsync, getServiceCartApiAsync, removeOneServiceCartProductApiAsync, selectServiceCartDataByUser } from "./cartSlice";
import { toast } from 'react-toastify';
import { selectLoginAuth } from "../auth/authSlice";
import CommonLoader from "../../components/shared/CommonLoader";


const CartServices = () => {
    const location = useLocation();
    const toastId = React.useRef(null);
    const auth = useSelector(selectLoginAuth);
    const { id, sellerId } = useParams();
    const serviceCartData = useSelector(selectServiceCartDataByUser);
    const currDate = new Date();
    const [loading, setLoading] = useState(false);
    const history = useHistory();
    const dispatch = useDispatch();
    let couponData = (localStorage.getItem("couponsdata")) ? JSON.parse(localStorage.getItem("couponsdata")) : ""
    let discountValue = couponData ? couponData : (0).toFixed(2)

    const handleApplyCoupon = (val) => {
        localStorage.setItem("cartprice", val)
        history.push(`/coupons/${id}/${sellerId}/service`)
    }

    const handleBookAppointment = () => {
        if (!location?.state?.slotStartTime || !location?.state?.slotEndTime) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error('Please select time slots')
            }
        } else {
            let orderData = {
                "postData": {
                    "cart_id": serviceCartData?.data?.payload?.appointment_cart_products[0]?.cart_id,
                    "date": location?.state?.dateValue ? moment(location?.state?.dateValue).format("YYYY-MM-DD") : moment(currDate).format("YYYY-MM-DD"),
                    "start_time": location?.state?.slotStartTime,
                    "end_time": location?.state?.slotEndTime,
                    "mode_of_payment": "cash"
                },
                "tokenData": auth?.payload?.token
            }
            dispatch(createServiceOrderApiAsync(orderData))
                .then(unwrapResult)
                .then(async (obj) => {
                    if (!toast.isActive(toastId.current)) {
                        toastId.current = toast.success(obj?.data?.msg)
                    }
                    history.push(`/booking-list`)
                }
                )
                .catch((obj) => {
                })
        }
    }

    const handleCartRemoveProduct = (data) => {
        let removeData = {
            "postData": {
                "cart_id": data?.cart_id,
                "product_id": data?.id
            },
            "tokenData": auth?.payload?.token
        }
        dispatch(removeOneServiceCartProductApiAsync(removeData))
            .then(unwrapResult)
            .then(async (obj) => {
                if (!toast.isActive(toastId.current)) {
                    toastId.current = toast.success(obj?.data?.msg)
                }
                dispatch(getServiceCartApiAsync(auth?.payload?.token))
                    .then(unwrapResult)
                    .then(async (obj) => {
                    }
                    )
                    .catch((obj) => {
                    })
            }
            )
            .catch((obj) => {
            })
    }

    useEffect(() => {
        setLoading(true)
        dispatch(getServiceCartApiAsync(auth?.payload?.token))
            .then(unwrapResult)
            .then(async (obj) => {
                setLoading(false)
            }
            )
            .catch((obj) => {
                setLoading(false)
            })
    }, [])
    return (
        <>
            <div className="order-heading">
                <h4><Link to="#" onClick={() => history.push(`/select-service-1/${id}/${sellerId}`)}><img src={leftArrowImg} alt="leftArrowImg" /></Link> Checkout </h4>
            </div>
            {loading === true ?
                <CommonLoader />
                :
                <>
                    {
                        serviceCartData?.data?.payload?.appointment_cart_products?.length > 0 ?
                            <div className="main-content cart-mainpd">
                                <div className="product-delivery-area">
                                    <div className="product-delvbox">
                                        <img src={CalendarClock} alt="riderImg" />
                                        <div className="rider-cnt">
                                            {/* Object.keys(myObj).length */}
                                            <h6>Appointment time</h6>
                                            <h2>{location?.state?.dateValue ? location?.state?.dateValue : "Today, " + moment(currDate).format("MMMM DD, YYYY")}</h2>
                                            <h2>{location?.state?.slotStartTime && location?.state?.slotEndTime ? location?.state?.slotStartTime + '-' + location?.state?.slotEndTime : ""}</h2>
                                            <p onClick={() => history.push(`/appointment-time/${id}/${sellerId}`)}>
                                                <Link to=''>Change</Link>
                                            </p>
                                        </div>
                                    </div>

                                    {
                                        serviceCartData?.data?.payload?.appointment_cart_products?.map((val, index) => {
                                            return (
                                                <div className="select-card" key={index}>
                                                    <img src={val?.product_details?.image} alt="selectCard1Img" />
                                                    <div className="select-cardcnt">
                                                        <h4>{val?.product_details?.name}</h4>
                                                        <p>{val?.product_details?.description}</p>
                                                    </div>
                                                    <div className="select-cardrit">
                                                        <span><i className="fas fa-times" onClick={() => handleCartRemoveProduct(val)}></i></span>
                                                        <h6>${val?.product_details?.price}</h6>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                    <div className="apply-couponbox">
                                        <Link to="#">
                                            {
                                                couponData ?
                                                    <>
                                                        <h4 className="savings_head">"{couponData?.code} applied" <small className="savings">{discountValue} coupon savings</small></h4>
                                                        <span className="coupon_remove" onClick={() => localStorage.removeItem("couponsdata")}><i className="fa fa-trash"></i></span>
                                                    </>
                                                    :
                                                    <>
                                                        <h4>Apply Coupon <small>Add your coupon here</small></h4>
                                                        <span onClick={() => handleApplyCoupon(serviceCartData?.data?.payload?.appointment_cart_products[0]?.product_details?.price)}><i className="fas fa-chevron-right"></i></span>
                                                    </>
                                            }
                                        </Link>
                                    </div>

                                    <div className="cart-totalbox">
                                        <ul>
                                            <li>Sub Total {<span>${serviceCartData?.data?.payload?.appointment_cart_products[0]?.product_details?.price?.toFixed(2)}</span>}</li>
                                            <li>Coupon <span>-${discountValue}</span></li>
                                            <li><small>Taxes & Other fees <img src={Info} alt="" /></small> <span>$0.00</span></li>
                                            <li><strong>Total</strong> <strong>${(((serviceCartData?.data?.payload?.appointment_cart_products[0]?.product_details?.price)) - (discountValue))?.toFixed(2)}</strong></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="selected-product checkout-box">
                                    <div className="selected-itembox appoint-box">
                                        <div className="selected-leftcnt">
                                            <p><strong>Book an appointment</strong></p>
                                        </div>
                                        <div onClick={() => handleBookAppointment()} style={{ cursor: "pointer" }} className="selected-rightbtn">
                                            <img src={ArrowWhite} alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            : <div className="delivery-parthm">No Cart Found</div>
                    }
                </>}
        </>
    )
}

export default CartServices
