import { unwrapResult } from "@reduxjs/toolkit";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from 'react-router-dom';
import { leftArrowImg, ratingDarkImg, smokeImg } from "../../../utilities/images";
import { selectLoginAuth } from "../auth/authSlice";
import { getProductReviewsListAsync, selectProductReviewList } from "./productReviewSlice";
import moment from "moment-timezone";
import { Box } from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import PaginationSetting from '../../constants/PaginationSetting';
import CommonLoader from '../../components/shared/CommonLoader';
import ReactStars from 'react-stars';


const ProductReview = (props) => {
    const history = useHistory();
    const [tabData, setTabData] = useState((props?.location?.state === "review") ? 'tab2' : 'tab1');
    const [pagination, setPagination] = useState(PaginationSetting);
    const [page, setPage] = useState(1);
    const [productLoading, setProductLoading] = useState(false);
    const reviewList = useSelector(selectProductReviewList);
    const auth = useSelector(selectLoginAuth);
    const dispatch = useDispatch();

    const reviewListHandle = async (flag) => {
        let params = {
            "postData": {
                "flag": flag
            },
            "tokenData": auth?.payload?.token
        }
        setProductLoading(true)
        await dispatch(getProductReviewsListAsync(params))
            .then(unwrapResult)
            .then(async (obj) => {
                setProductLoading(false)
                setPagination((prevState) => ({ ...prevState, totalPages: obj?.data?.payload?.total_pages, totalRecords: obj?.data?.payload?.total }));
            })
            .catch((err) => {
                setProductLoading(false)
            })
    }

    useEffect(() => {
        reviewListHandle('all')
    }, [])

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <>

            <div className="order-heading">
                <h4><Link to='/more'><img src={leftArrowImg} alt="leftArrowImg" /></Link> Product review</h4>
            </div>

            <div className="main-content">
                <div className="product-review-area">
                    <div className="review-uptbtnp">
                        <Link to="#">Earn money providing product review</Link>
                    </div>

                    <div className="product-review-tab">
                        <div className="tabs">
                            <div className="tab-upper tab-upperhm noshadow">
                                <ul className="tab-links text-left pt-4">
                                    <li className={tabData === 'tab1' ? "active" : ""} onClick={() => { setTabData('tab1'); reviewListHandle("all") }}><Link to="#tab1">Available for reviews</Link></li>
                                    <li className={tabData === 'tab2' ? "active" : ""} onClick={() => { setTabData('tab2'); reviewListHandle("past") }}><Link to="#tab2">Past reviews</Link></li>
                                </ul>
                            </div>
                            {productLoading === true ?
                                <CommonLoader />
                                :
                                <div>
                                    {tabData === 'tab1' ?
                                        <div id="tab1" className={tabData === 'tab1' ? "active" : ""}>
                                            <div className="review-tab1">
                                                {reviewList?.data?.payload?.length > 0 ? reviewList?.data?.payload?.map((val, i) => {
                                                    return (
                                                        <div className="review-itemp" key={i}>
                                                            <div className="review-leftp">
                                                                <img src={val?.image} alt="review1Img" />
                                                                <p>{moment(val?.order_detail?.created_at).format('DD MMM,h:mm')}</p>
                                                            </div>
                                                            <div className="review-cntp">
                                                                <h2>{val?.name}</h2>
                                                                <p><img src={smokeImg} alt="smokeImg" /> {val?.seller_detail?.user_profiles?.firstname}</p>
                                                                <img src={ratingDarkImg} alt="ratingDarkImg" />
                                                                <h6>Provide review</h6>
                                                            </div>
                                                            <div className="review-rightp" onClick={() => { history.push({ pathname: 'product-review-available-two', state: val }) }}>
                                                                <Link to='#' ><i className="fas fa-chevron-right"></i></Link>
                                                            </div>
                                                        </div>
                                                    )
                                                }) : "No Reviews"}
                                            </div>
                                        </div>
                                        :
                                        <div id="tab2" className={tabData === 'tab2' ? "active" : ""}>
                                            <div className="review-tab2">
                                                {reviewList?.data?.payload?.length > 0 ? reviewList?.data?.payload?.map((val, i) => {
                                                    return (
                                                        <div className="review-itemp" key={i}>
                                                            <div className="review-leftp">
                                                                <img src={val?.image} alt="review1Img" />                                                               
                                                                <p>{moment(val?.order_detail?.created_at).format('DD MMM,h:mm')}</p>
                                                            </div>
                                                            <div className="review-cntp">
                                                                <h2>{val?.name}</h2>
                                                                <p><img src={smokeImg} alt="smokeImg" /> {val?.seller_detail?.user_profiles?.firstname}</p>
                                                                <ReactStars
                                                                    count={5}
                                                                    value={Number(val?.order_detail?.rating)}
                                                                    edit={false}
                                                                    size={20}
                                                                    color2={'#FCBA30'}
                                                                />
                                                                <h6 onClick={() => { history.push({ pathname: 'product-review-edit', state: val }) }}><Link to='#'>Edit Review</Link></h6>
                                                            </div>
                                                            <div className="review-rightp" onClick={() => { history.push({ pathname: 'product-review-available-two', state: val }) }}>
                                                                <Link to='#'><i className="fas fa-chevron-right"></i></Link>
                                                            </div>
                                                        </div>
                                                    )
                                                }) : "No Reviews"}
                                            </div>
                                        </div>
                                    }
                                </div>}
                        </div>
                    </div>
                </div>
            </div>
            {pagination.totalRecords > pagination.recordsPerPage &&
                <Box py={3} display="flex" justifyContent="center">
                    <Pagination
                        key={page}
                        count={pagination.totalPages || 0}
                        color="secondary"
                        variant="outlined"
                        onChange={(e, value) => setPage(value)}
                        defaultPage={page}
                    />
                </Box>
            }
        </>
    );
}

export default ProductReview;
