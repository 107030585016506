import axios from "axios";

const queryString = require('query-string');

export function getProductReviewsList(data, token) {
    const query = queryString.stringify(data);
    return new Promise((resolve, reject) =>
        axios
            .get(`${process.env.REACT_APP_STAGING_PRODUCT_URL}api/v1/products/to-reviews` + '?' + query, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token,
                    'app-name': 'b2c'
                }
            })
            .then(function (response) {
                resolve(response);
            })
            .catch(function (error) {
                reject(error)
                console.error(error);
            })

    )
}

export function createProductReview(data, token) {
    return new Promise((resolve, reject) =>
        axios
            .post(`${process.env.REACT_APP_STAGING_PRODUCT_URL}api/v1/reviews`, data, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token,
                    'app-name': 'b2c'
                }
            })
            .then(function (response) {
                resolve(response);
            })
            .catch(function (error) {
                reject(error)
                console.error(error);
            })
    )
}

export function getRatingDetails(data, token) {
    const query = queryString.stringify(data);
    return new Promise((resolve, reject) =>
        axios
            .get(`${process.env.REACT_APP_STAGING_PRODUCT_URL}api/v1/reviews/rating-detail` + "?" + query, {
                headers: {
                    'Content-type': "aplliction/json",
                    'Authorization': token,
                    'app-name': 'b2c'
                }
            })
            .then(function (response) {
                resolve(response);
            })
            .catch(function (error) {
                reject(error);
                console.log(error);
            })
    )
}

export function updateProductReview(data, token) {
    return new Promise((resolve, reject) =>
        axios
            .put(`${process.env.REACT_APP_STAGING_PRODUCT_URL}api/v1/reviews/update`, data, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token,
                    'app-name': 'b2c'
                }
            })
            .then(function (response) {
                resolve(response);
            })
            .catch(function (error) {
                reject(error)
                console.error(error);
            })
    )
}

