import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { home2Img, invoiceImg } from '../../../utilities/images'
import { allAddressAsync, changeCurrentAddressApiAsync, selectAllAddress } from '../../containers/addresses/addressSlice'
import { selectLoginAuth } from '../../containers/auth/authSlice'
import { userProfileAsync, selectUserProfile } from '../../containers/profile/profileSlice'

const DashboardAddress = () => {
    const history = useHistory()
    const dispatch = useDispatch()
    const auth = useSelector(selectLoginAuth)
    const locationData = useSelector(selectUserProfile)
    const [selectedData, setSelectedData] = useState(locationData?.payload?.current_location?.id)
    const allAddresssData = useSelector(selectAllAddress);
    const handleChange = async (data) => {
        setSelectedData(data)
        let params = {
            "postData": {
                "id":data?.id,
                "address_type":data?.address_type
            },
            "tokenData": auth?.payload?.token
        }
        await dispatch(changeCurrentAddressApiAsync(params))
        let params2 = {
            loginId: auth?.payload?.id,
            token: auth?.payload?.token
        }
        await dispatch(userProfileAsync(params2))
        let params1 = {
            token: auth.payload.token,
        }
        await dispatch(allAddressAsync(params1))
    }
    useEffect(() => {
        let params = {
            loginId: auth?.payload?.id,
            token: auth?.payload?.token
        }
        dispatch(userProfileAsync(params))
        let params1 = {
            token: auth.payload.token,
        }
        dispatch(allAddressAsync(params1))
    }, [])

    useEffect(() => {
        window.scrollTo(0, 0)   
          }, [])
          
    return (
        <>
            <div>
                <div className='loc_header'>
                    <i className="fa fa-angle-down" onClick={() => history.push('/dashboard')}></i>
                    <h6>Select a location</h6>
                </div>

                <div className='current_location' onClick={() => history.push('/new-address')}>
                    <h6 style={{ color: "#d70a0a" }}>Use a current location</h6>
                </div>

                <div className='recent_address'>
                    <h6>Recent Address</h6>
                    <p>
                        {locationData?.payload?.current_location?.formatted_address}
                    </p>
                </div>
                <div className='saved_address'>
                    <h6>Saved Address</h6>
                    <div className="saved_address">
                        {allAddresssData?.payload?.data.map((address, index) => {
                            return (
                                <React.Fragment key={index}>
                                    <div className={"address-item"}>
                                        <div className="radio-btn">
                                            {
                                                address?.address_type === "Home" ?
                                                    <img src={home2Img} alt="home2Img" />
                                                    : address?.address_type === "Work" ?
                                                        <i className="fa fa-briefcase"></i> :
                                                        address?.address_type === "Billing" ?
                                                            <img src={invoiceImg} alt="invoiceImg" /> :
                                                            <i className="fas fa-plus"></i>
                                            }
                                        </div>
                                        <div className="address-cnt">
                                            <h4>{address.address_type}</h4>
                                            <p>{address.custom_address}<br /></p>
                                        </div>
                                        <div className="edit-btn">
                                            <input type="radio" name="address" checked={selectedData === address?.id}
                                                onChange={() => handleChange(address)} />
                                            {/* <Link to="#">
                                                        <img src={edit2Img} alt="edit2Img" />
                                                    </Link> */}
                                        </div>
                                    </div>
                                </React.Fragment>
                            )
                        })}
                    </div>
                </div>
            </div>
        </>
    )
}

export default DashboardAddress