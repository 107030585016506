import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { selectLoginAuth } from '../auth/authSlice'
import { getBookingSlotAsync } from './dashboardSlice'
import { toast } from "react-toastify";
import { unwrapResult } from '@reduxjs/toolkit'
import { setLocationType } from 'react-geocode'

const ReservationTimeModal = (props) => {
    const dispatch = useDispatch()
    const toastId = React.useRef(null)
    const [slotStartTime, setSlotStartTime] = useState("")
    const [slotEndTime, setSlotEndTime] = useState("")
    const [loading, setLoading] = useState(false)
    const [slotId, setSlotId] = useState("")
    const auth = useSelector(selectLoginAuth)
    const [slotData, setSlotData] = useState({});
    
    const handleClick = (val) => {
        setSlotStartTime(val?.start_time)
        setSlotEndTime(val?.end_time)
        setSlotId(val?.id)
    }
    const handleTime = () => {
        if (slotId === "") {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error("Please Select Slot time")
            }
        } else {
            props.close()
            let data = {
                slotId: slotId,
                startTime: slotStartTime,
                endTime: slotEndTime
            }
            props.handleSlot(data)
        }
    }
    useEffect(() => {
        setLoading(true)
        let data = {
            "postData": {
                "sellerId": props?.sellerId,
            },
            "tokenData": auth?.payload?.token
        }
        dispatch(getBookingSlotAsync(data))
            .then(unwrapResult)
            .then(async (obj) => {
                setSlotData(obj)
                setLoading(false)
            }
            )
            .catch((obj) => {
                setLoading(false)
            })
    }, [])
    return (
        <>

            {
                loading ?
                    <div className="pageLoader d-flex justify-content-center align-items-center">
                        <div className="spinner-border" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div> :
                    <div className="modal-content">
                        <div className="modal-header modal-headerbok">
                            <button type="button" className="close" onClick={props.close}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="time-mdlmain">
                                <h2>Please chose a Time below:</h2>
                                <div className="time-mdlprt">
                                    <div className="delivery-time">
                                        {
                                            slotData?.data?.payload?.map((val, index) => {
                                                return (
                                                    <button type='button' key={index} className={val?.availabilty === false ? 'time_button inactive' : (slotStartTime === val?.start_time && slotId === val?.id) ? 'time_button active' : "time_button"} disabled={val?.availabilty === false} onClick={() => handleClick(val)}>{val?.start_time}-{val?.end_time}</button>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                                <div className="reserve-btn mt-auto" onClick={handleTime}>
                                    <Link to="#">Set Time</Link>
                                </div>
                            </div>
                        </div>
                    </div>
            }
        </>

    )
}

export default ReservationTimeModal