import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAddressDetail, getAllAddress, changeCurrentAddressApi } from "./addressApi";

const initialState = {
  addressDetail: [],
  allAddress: [],
  changeAddress: null,
  status: "idle",
};

export const addressDetailAsync = createAsyncThunk("address/detail", async (data) => {
  const response = await getAddressDetail(data);
  return response.data;
});
export const allAddressAsync = createAsyncThunk("all/address", async (data) => {
  const response = await getAllAddress(data);
  return response.data;
});
export const changeCurrentAddressApiAsync = createAsyncThunk("all/changeCurrentAddressApi", async (data) => {
  const response = await changeCurrentAddressApi(data.postData, data.tokenData);
  return response.data;
});

export const addressDetailSlice = createSlice({
  name: "address",
  initialState,

  extraReducers: (builder) => {
    builder
      .addCase(addressDetailAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(addressDetailAsync.fulfilled, (state, { payload }) => {
        state.addressDetail = payload
        state.status = "idle";
      })
      .addCase(addressDetailAsync.rejected, (state, { error }) => {
        state.status = "idle";
        state.addressDetail = null
      })
      .addCase(allAddressAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(allAddressAsync.fulfilled, (state, { payload }) => {
        state.allAddress = payload
        state.status = "idle";
      })
      .addCase(allAddressAsync.rejected, (state, { error }) => {
        state.status = "idle";
        state.allAddress = null
      })
      .addCase(changeCurrentAddressApiAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(changeCurrentAddressApiAsync.fulfilled, (state, { payload }) => {
        state.changeAddress = payload
        state.status = "idle";
      }).addCase(changeCurrentAddressApiAsync.rejected, (state, { error }) => {
        state.status = "idle";
        state.changeAddress = null
      })
  }

});

export const selectAllAddress = (state) => state.address.allAddress;
export default addressDetailSlice.reducer;