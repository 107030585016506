import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
    getSupportApi,
    getSubjectApi,
    supportDocumentUrlApi,
    createSupportRequestApi,
    getSupportByIdApi
} from "./SupportRequestApi";

const initialState = {
    supportdetails: null,
    support: [],
    supportById: [],
    subject: [],
    supportImage: null,
    supportCreate: [],
    status: {
        supportdetails: 'idle',
        support: 'idle',
        supportById: 'idle',
        subject: 'idle',
        supportImage: 'idle',
        supportCreate: 'idle'
    },
};

export const getSupportApiAsync = createAsyncThunk("support/supportlist", async (data) => {
    const response = await getSupportApi(data.postData, data.tokenData);
    return response;
});
export const getSubjectApiAsync = createAsyncThunk("support/subject", async () => {
    const response = await getSubjectApi();
    return response;
});
export const supportApiUrlAsync = createAsyncThunk("support/spporturl", async (data) => {
    const response = await supportDocumentUrlApi(data);
    return response;
});
export const supportCreateApiAsync = createAsyncThunk("support/supportcreate", async (data) => {
    const response = await createSupportRequestApi(data.postData, data.tokenData);
    return response;
});
export const getSupportByIdApiAsync = createAsyncThunk("support/getSupportByIdApi", async (data) => {
    const response = await getSupportByIdApi(data.postData, data.tokenData);
    return response;
});

export const supportSlice = createSlice({
    name: "support",
    initialState,

    extraReducers: (builder) => {
        builder
            .addCase(getSupportApiAsync.pending, (state) => {
                state.status = "loading";
            })
            .addCase(getSupportApiAsync.fulfilled, (state, { payload }) => {
                state.support = payload
                state.status = "idle";
            })
            .addCase(getSupportApiAsync.rejected, (state, { error }) => {
                state.status = "idle";
                state.support = null
            })
            .addCase(getSubjectApiAsync.pending, (state) => {
                state.status = "loading";
            })
            .addCase(getSubjectApiAsync.fulfilled, (state, { payload }) => {
                state.subject = payload
                state.status = "idle";
            })
            .addCase(getSubjectApiAsync.rejected, (state, { error }) => {
                state.status = "idle";
                state.subject = null
            })
            .addCase(supportApiUrlAsync.pending, (state) => {
                state.status = "loading";
            })
            .addCase(supportApiUrlAsync.fulfilled, (state, { payload }) => {
                state.supportImage = payload
                state.status = "idle";
            })
            .addCase(supportApiUrlAsync.rejected, (state, { error }) => {
                state.status = "idle";
                state.supportImage = null
            })
            .addCase(supportCreateApiAsync.pending, (state) => {
                state.status = "loading";
            })
            .addCase(supportCreateApiAsync.fulfilled, (state, { payload }) => {
                state.supportCreate = payload
                state.status = "idle";
            })
            .addCase(supportCreateApiAsync.rejected, (state, { error }) => {
                state.status = "idle";
                state.supportCreate = null
            })
            .addCase(getSupportByIdApiAsync.pending, (state) => {
                state.status = "loading";
            })
            .addCase(getSupportByIdApiAsync.fulfilled, (state, { payload }) => {
                state.supportById = payload
                state.status = "idle";
            })
            .addCase(getSupportByIdApiAsync.rejected, (state, { error }) => {
                state.status = "idle";
                state.supportById = null
            })
    },
});

export const selectSupportData = (state) => state.support.support;
export const selectSupportDataById = (state) => state.support.supportById;
export const selectSubjectData = (state) => state.support.subject;
export const selectUploadedUrlData = (state) => state.support.supportImage;

export default supportSlice.reducer;