import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useSelector, useDispatch } from "react-redux";
import identityImg from "../../public/images/identity.png";
import idCardImg from "../../public/images/id-card.png";
import lefArrowImg from "../../public/images/left-arrow.svg";
import { uploadIdCardAsync, uploadIdCardAsync1, selectLoginAuth, getDocumentTypeAsync, uploadKycDocumentAsync } from "../../containers/auth/authSlice"
import { unwrapResult } from '@reduxjs/toolkit';
import CommonLoader from '../../components/shared/CommonLoader';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { userProfileAsync } from '../profile/profileSlice';

const IdVerification = (props) => {
  const dispatch = useDispatch();
  const { flag } = useParams();
  const toastId = React.useRef(null)
  const history = useHistory();
  const [loading, setLoading] = useState(false)
  const [document, setDocument] = useState("");
  const [document1, setDocument1] = useState("");
  const [selectedData, setSelectedData] = useState("")
  const [documentType, setDocumentType] = useState([])
  const loginAuth = useSelector(selectLoginAuth);

  const handleChange = (e, flag) => {
    const imageFile = e.target.files[0];
    if (flag === 'document_1') {
      let params = {
        document: imageFile
      }
      setLoading(true)
      dispatch(uploadIdCardAsync(params))
        .then(unwrapResult)
        .then((obj) => {
          setDocument(obj.payload.profile_photo)
          setLoading(false)
          if (!toast.isActive(toastId.current)) {
            toastId.current = toast.success(obj?.msg)
          }
        }
        )
        .catch((obj) => {
          setLoading(false)
          if (!toast.isActive(toastId.current)) {
            toastId.current = toast.error(obj?.message)
          }
        })
    }
    if (flag === 'document_2') {
      // setDocument1(URL.createObjectURL(imageFile));
      let params = {
        document: imageFile
      }
      setLoading(true)
      dispatch(uploadIdCardAsync1(params))
        .then(unwrapResult)
        .then((obj) => {
          setDocument1(obj.payload.profile_photo)
          setLoading(false)
          if (!toast.isActive(toastId.current)) {
            toastId.current = toast.success(obj?.msg)
          }
        }
        )
        .catch((obj) => {
          setLoading(false)
          if (!toast.isActive(toastId.current)) {
            toastId.current = toast.error(obj?.message)
          }
        })
    }
  }

  let data = {
    postData: {
      "document_type": selectedData,
      "document_1": document,
      "document_2": document1
    },
    tokenData: loginAuth?.payload?.token
  }
  const handleOnSubmit = async (e) => {
    e.preventDefault();
    if (!selectedData) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Please select the identity type");
      }
      return;
    }
    if (!document || !document1) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Please select the documents");
      }
      return;
    }
    setLoading(true)
    await dispatch(uploadKycDocumentAsync(data))
      .then(unwrapResult)
      .then((obj) => {
        if (!toast.isActive(toastId.current)) {
          toastId.current = toast.success(obj?.msg)
        }
        let params = {
          loginId: loginAuth?.payload?.id,
          token: loginAuth?.payload?.token
        }
        dispatch(userProfileAsync(params))
        setLoading(false)
        history.push(`/bank-account/${flag}`)
      }
      )
      .catch((obj) => {
        setLoading(false)
      })
  }

  const handleIdentityType = (e) => {
    setSelectedData(e.target.value)
  }

  useEffect(() => {
    setLoading(true)
    dispatch(getDocumentTypeAsync(loginAuth?.payload?.token))
      .then(unwrapResult)
      .then((obj) => {
        setLoading(false)
        setSelectedData(obj.payload.document_types[0]?.name)
        setDocumentType(obj)
      }
      )
      .catch((obj) => {
        setLoading(false)
      })
  }, [])

  return (
    <>
      <div className="age-upper">
        <Link to="#" onClick={() => { history.goBack() }}><img src={lefArrowImg} alt="lefArrowImg" /> Id Verification</Link>
      </div>

      <div className="age-main">
        <div className="age-cnt">
          <h2>Id Verification</h2>
          <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution</p>
        </div>
        {
          loading ?
            <CommonLoader />
            :
            <div className="age-item">
              <div className='row'>
                <div className='col-md-12'>
                  <h4>Choose your identity type</h4>
                  <select className="country-name Customform-control selectControl" style={{ width: "100%" }} defaultValue={"Select Identity Type"} name="identity_type" id="identity_type" onChange={(e) => handleIdentityType(e)}>
                    <option value="Select Identity Type" disabled>Select Identity Type</option>
                    {documentType?.payload?.document_types
                      ?.map((value, index) => {
                        return (
                          <option value={value?.name} key={index}>{value?.label}</option>
                        )
                      })}
                  </select>
                </div>
              </div>

              <div className="upload-btn-wrapper upload-btn-wrapper2">
                <button className="filebtn">
                  <span className="upload-cnt">
                    <h4>Upload your ID card</h4>
                  </span>
                  <span className="upload-item">
                    <img src={document ? document : idCardImg} alt="idCardImg" />
                  </span>
                </button>
                <input type="file" name="myfile" onChange={(e) => { handleChange(e, 'document_1') }} />
              </div>
              <div className="upload-btn-wrapper upload-btn-wrapper2">
                <button className="filebtn">
                  <span className="upload-cnt">
                    <h4>Confirm your identiry</h4>
                  </span>
                  <span className="upload-item" >
                    <img src={document1 ? document1 : identityImg} alt="identityImg" />
                  </span>
                </button>
                <input type="file" name="myfile" onChange={(e) => { handleChange(e, 'document_2') }} />
              </div>
            </div>
        }

      </div>

      {
        (document && document1) ?
          <div className='bottomBtnbox'>
            <button type="submit" className="document_button blueprimeBtn" disabled={loading} onClick={(e) => { handleOnSubmit(e) }}>
              {loading === true && (
                <span className="spinner-border spinner-border-sm"></span>
              )}
              &nbsp;&nbsp;
              Continue
            </button>
          </div>
          : ""
      }
    </>
  )
}

export default IdVerification;