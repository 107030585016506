import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getProfilePhoto, getUpdateProfile, getUserProfile } from "./editProfileApi";
import { toast } from "react-toastify";

const initialState = {
  profilePhoto: null,
  updateprofile: null,
  userProfile: null,
  status: "idle",
  status1: {
    profilePhotoStatus: "idle"
  }
};

export const profilePhotoAsync = createAsyncThunk("profile/photo", async (data) => {
  const response = await getProfilePhoto(data);
  return response.data;
});

export const updateProfileAsync = createAsyncThunk("profile/update", async (data) => {
  const response = await getUpdateProfile(data);
  return response.data;
});

export const userProfileAsync = createAsyncThunk("profile/user", async (data) => {
  const response = await getUserProfile(data);
  return response.data;
});

export const profilePhotoSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    emptyProfile: (state) => {
      state.profilePhoto = null;
      state.userProfile = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(profilePhotoAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(profilePhotoAsync.fulfilled, (state, { payload }) => {
        state.profilePhoto = payload
        state.status = "idle";
      })
      .addCase(profilePhotoAsync.rejected, (state, { error }) => {
        state.status = "idle";
        state.profilePhoto = null
      })
      .addCase(updateProfileAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateProfileAsync.fulfilled, (state, { payload }) => {
        state.updateprofile = payload
        state.status = "idle";
      })
      .addCase(updateProfileAsync.rejected, (state, { error }) => {
        state.status = "idle";
        state.updateprofile = null
      })
      .addCase(userProfileAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(userProfileAsync.fulfilled, (state, { payload }) => {
        state.userProfile = payload
        state.status = "idle";
      })
      .addCase(userProfileAsync.rejected, (state, { error }) => {
        state.status = "idle";
        state.userProfile = null
      })

  },
});

export const { emptyProfile } = profilePhotoSlice.actions;
export const selectProfilePhoto = (state) => state.editProfilePhoto.profilePhoto;
// export const selectUpdateProfileData = (state) => state;
export const selectUserProfile = (state) => state.userProfile.userProfile;
export default profilePhotoSlice.reducer;