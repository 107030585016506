import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useHistory, useParams } from 'react-router-dom';
import { leftArrowImg, home2Img, edit2Img, invoiceImg, coinStackImg } from "../../../utilities/images";
import { selectLoginProfile } from "../auth/authSlice";
import { allAddressAsync, selectAllAddress } from "./addressSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import { selectWalletBalance } from "../wallet/walletSlice";
import CommonLoader from "../../components/shared/CommonLoader";

const Address = (props) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const { flag } = useParams();
    const [addressLoading, setAddressLoading] = useState(false);
    const walletData = useSelector(selectWalletBalance);
    const selectLoginUser = useSelector(selectLoginProfile);
    const allAddresssData = useSelector(selectAllAddress);

    useEffect(() => {
        let params = {
            token: selectLoginUser.payload.token,
        }
        setAddressLoading(true)
        dispatch(allAddressAsync(params))
            .then(unwrapResult)
            .then(async (obj) => {
                setAddressLoading(false)
            }
            )
            .catch((obj) => {
                setAddressLoading(false)
            })
    }, [dispatch])

    useEffect(() => {
        window.scrollTo(0, 0)

    }, [])
    return (
        <>
            <div className="order-heading">
                <div className="header-left-item">
                    <h4><Link to="/more"><img src={leftArrowImg} alt="leftArrowImg" /></Link> Addresses</h4>
                </div>
                <div className="header-right-item">
                    <Link to="/wallet" className="coinStack">{walletData ? walletData?.data?.payload?.sila_balance : 0}
                        <img src={coinStackImg} alt="coinStack" />
                    </Link>
                </div>
            </div>
            {addressLoading === true ?
                <CommonLoader />
                :
                <div className="main-content">
                    <div className="address-part">
                        <form action="#" method="POST">
                            {allAddresssData?.payload?.data?.length > 0
                                ?
                                <div className="row">
                                    {allAddresssData?.payload?.data.map((address, index) => {
                                        return (
                                            <React.Fragment key={index}>
                                                <div onClick={flag === "placing" ? () => history.push(`/placing-1/${address?.id}`) : void (0)}
                                                    className={flag ? "address-item add_pointer" : "address-item"}>
                                                    <div className="radio-btn">
                                                        {
                                                            address?.address_type === "Home" ?
                                                                <img src={home2Img} alt="home2Img" />
                                                                : address?.address_type === "Work" ?
                                                                    <i className="fa fa-briefcase"></i> :
                                                                    address?.address_type === "Billing" ?
                                                                        <img src={invoiceImg} alt="invoiceImg" /> :
                                                                        <i className="fas fa-plus"></i>
                                                        }
                                                    </div>
                                                    <div className="address-cnt">
                                                        <h4>{address.address_type}</h4>
                                                        <p>{address.formatted_address}<br /></p>
                                                    </div>
                                                    <div className="edit-btn">
                                                        <Link to={`/change-address/${address.id}`}>
                                                            <img src={edit2Img} alt="" />
                                                        </Link>
                                                    </div>
                                                </div>
                                            </React.Fragment>
                                        )
                                    })}
                                </div>
                                :
                                <>
                                    <div>
                                        <p style={{ textAlign: 'center' }}>No Data Found</p>
                                    </div>
                                </>
                            }
                        </form>
                        <div className="address-btn" onClick={() => { flag === "placing" ? history.push(`/new-address/${flag}`) : history.push(`/new-address`) }}>
                            <Link to="#">Add new address</Link>
                        </div>
                    </div>
                </div>}
        </>
    );
}

export default Address;