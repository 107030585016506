import React, { useEffect, useState } from "react";
import { Link, useHistory } from 'react-router-dom';
import { usePaymentInputs } from 'react-payment-inputs';
import { leftArrowImg, bxsCoinStackImg } from "../../../utilities/images";

const AddCreditCard = (props) => {
    const history = useHistory();
    const { getExpiryDateProps } = usePaymentInputs();
    const [cardNumber, setCardNumber] = useState("");
    const [expiryDate, setExpiryDate] = useState("");
    const [cardHolderName, setCardHolderName] = useState("")
    const [cardCVV, setCardCvv] = useState("")
    const [address, setAddress] = useState("yes");


    const handleChangeExpiryDate = (e) => {
        const value = e.target.value
        setExpiryDate(value);
    }
    const setName = e => {
        const cardHolderName = e.target.value.toUpperCase();
        setCardHolderName(cardHolderName);
    };

    const setCVV = e => {
        let { value } = e.target;
        value = value.replace(/ /gi, "");
        if (isNaN(value)) {
            return;
        } else {
            setCardCvv(e.target.value);
        }
    };

    const handleOnSubmit = () => {
        history.push({ pathname: '/payment-added', state: props.location.state });
    }
    const setNumber = e => {
        let { value } = e.target;
        value = value.replace(/ /gi, "");
        if (isNaN(value)) {
            return;
        } else {
            setCardNumber(e.target.value)
        }
    };
    const formatCardNumber = value => {
        let v = value.replace(/\s+/g, "").replace(/[^0-9]/gi, "");
        let matches = v.match(/\d{4,16}/g);
        let match = (matches && matches[0]) || "";
        let parts = [];
        for (let i = 0, len = match.length; i < len; i += 4) {
            parts.push(match.substring(i, i + 4));
        }
        if (parts.length) {
            return parts.join(" ");
        } else {
            return value;
        }
    };

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <>
            <div className="order-heading">
                <div className="header-left-item">
                    <h4><Link to='/payment'><img src={leftArrowImg} alt="leftArrowImg" /></Link> Add a credit card </h4>
                </div>
                <div className="header-right-item">
                    <Link to="#">0 <img src={bxsCoinStackImg} alt="bxsCoinStackImg" /></Link>
                </div>
            </div>

            <div className="main-content">
                <div className="card-maininfo">
                    <form action="#" method="POST">
                        <div className="form-row">
                            <div className="col-12">
                                <div className="cardinfo-item">
                                    <label htmlFor="c1">Card Number</label>
                                    <input
                                        id="c1"
                                        maxLength={19}
                                        placeholder="Card number"
                                        value={formatCardNumber(cardNumber)}
                                        options={{ creditCard: true }}
                                        onChange={setNumber} />
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="cardinfo-item">
                                    <label htmlFor="c2">Expiry</label>
                                    <input type="date" id="c2" placeholder="mm/yy"
                                        {...getExpiryDateProps({ onChange: handleChangeExpiryDate })} value={expiryDate} />
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="cardinfo-item">
                                    <label htmlFor="c3">CVV</label>
                                    <input
                                        id="c3"
                                        maxLength={3}
                                        placeholder="Enter CVC"
                                        value={cardCVV}
                                        options={{
                                            numeral: true
                                        }}
                                        onChange={setCVV}
                                    />
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="cardinfo-item">
                                    <label htmlFor="c1">Card Holder Name</label>
                                    <input
                                        type="text"
                                        id="c4"
                                        placeholder="Card Holder name"
                                        maxLength="30"
                                        value={cardHolderName}
                                        onChange={e => setName(e)} />
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="card-addro">
                                    <h4>Shipping address as same billing address</h4>
                                    <span className="radio-btn">
                                        <input type="radio" id="radio-btn1" name="radio" checked={address === 'yes'} value="yes" onChange={() => setAddress("yes")} />
                                        <label htmlFor="radio-btn1">Yes</label>
                                    </span>
                                    <span className="radio-btn">
                                        <input type="radio" id="radio-btn2" name="radio" checked={address === 'no'} value="no" onChange={() => setAddress("no")} />
                                        <label htmlFor="radio-btn2">No</label>
                                    </span>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="cardinfo-checkbox">
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                        <label className="form-check-label" htmlFor="defaultCheck1">
                                            Save this card htmlFor a faster checkout <br /> <span>By saving your card you grant us your consent to store your payment method htmlFor future order. You can withdraw anytime.</span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                    <div className="pay-btn">
                        <Link to="#" onClick={(e) => { handleOnSubmit(e) }}>Add Card</Link>
                    </div>
                </div>
            </div>
        </>
    );
}

export default AddCreditCard;