import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from 'react-router-dom';
import { leftArrowImg } from "../../../utilities/images";
import { selectLoginAuth } from "../auth/authSlice";
import { getTermsPrivacyAsync, selectTermsPrivacy } from "./settingSlice";
import CommonLoader from '../../components/shared/CommonLoader';
import { unwrapResult } from "@reduxjs/toolkit";


const PrivacyPolicy = (props) => {
    const history = useHistory();
    const privacyData = useSelector(selectTermsPrivacy);
    const dispatch = useDispatch();
    const [privacyLoading, setPrivacyLoading] = useState(false);
    const auth = useSelector(selectLoginAuth);

    const privacyDataHandle = () => {
        setPrivacyLoading(true)
        dispatch(getTermsPrivacyAsync(auth?.payload?.token))
            .then(unwrapResult)
            .then((obj) => {
                setPrivacyLoading(false)
            })
            .catch((obj) => {
                setPrivacyLoading(false)
            })
    }
    useEffect(() => {
        privacyDataHandle()
    }, [])

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <>
            {privacyLoading === true ?
                <CommonLoader />
                :
                <>
                    <div className="order-heading">
                        <h4><Link to='/more'><img src={leftArrowImg} alt="leftArrowImg" /></Link> Privacy Policy</h4>
                    </div>

                    <div className="main-content">
                        <div className="update-accinfo term-andcond">
                            <h4>Privacy Policy</h4>
                            <h2>Welcome to JOBR!</h2>
                            <p><strong>You will need to fill out a form in the next step. Please read the instructions below to complete the form correctly</strong></p>
                            <p>{privacyData?.data?.payload?.privacy_policy}</p>
                            <ul>
                                <li><Link to='/dashboard'>Accepted</Link></li>
                            </ul>
                        </div>
                    </div>
                </>}
        </>
    );
}

export default PrivacyPolicy;