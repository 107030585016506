import React, { useEffect, useState } from "react";
import { Link, useHistory } from 'react-router-dom';
import { coinStackImg, leftArrowImg, ncigar } from "../../../utilities/images";
import { getNotificationAsync, readNotificationApiAsync } from './notificationSlice'
import { useSelector, useDispatch } from "react-redux";
import { selectWalletBalance } from "../wallet/walletSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import { selectLoginAuth } from "../auth/authSlice";
import PaginationSetting from '../../constants/PaginationSetting';
import { Box } from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import CommonLoader from '../../components/shared/CommonLoader';
import moment from "moment-timezone";

const Notifications = (props) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const [pagination, setPagination] = useState(PaginationSetting);
    const [page, setPage] = useState(1);
    const auth = useSelector(selectLoginAuth);
    const [loading, setLoading] = useState(false);
    const walletData = useSelector(selectWalletBalance);
    const [notifications, setNotifications] = useState({});

    const handleReadNotification = (data) => {
        let params = {
            postData: {
                "notification_id": data
            },
            tokenData: auth?.payload?.token
        }
        setLoading(true)
        dispatch(readNotificationApiAsync(params))
            .then(unwrapResult)
            .then(async (obj) => {
                let notificationData = {
                    "postData": {
                        "page": page,
                        "limit": pagination.recordsPerPage
                    },
                    "tokenData": auth?.payload?.token
                }
                dispatch(getNotificationAsync(notificationData))
                    .then(unwrapResult)
                    .then(async (obj) => {
                        setNotifications(obj)
                        setPagination((prevState) => ({ ...prevState, totalPages: obj?.payload?.total_pages, totalRecords: obj?.payload?.total }));
                        setLoading(false)
                    }
                    )
                    .catch((obj) => {
                        setLoading(false)
                    })
            }
            )
            .catch((obj) => {
                setLoading(false)
            })
    }

    const notificationDataHandle = () => {
        let notificationData = {
            "postData": {
                "page": page,
                "limit": pagination.recordsPerPage
            },
            "tokenData": auth?.payload?.token
        }
        setLoading(true)
        dispatch(getNotificationAsync(notificationData))
            .then(unwrapResult)
            .then(async (obj) => {
                setNotifications(obj)
                setPagination((prevState) => ({ ...prevState, totalPages: obj?.payload?.total_pages, totalRecords: obj?.payload?.total }));
                setLoading(false)
            }
            )
            .catch((obj) => {
                setLoading(false)
            })
    }

    useEffect(() => {
        window.scrollTo(0, 0)
        notificationDataHandle()
    }, [page])

    return (
        <>
            <div className="order-heading">
                <div className="header-left-item">
                    <h4><Link to="#" onClick={() => { history.goBack() }}><img src={leftArrowImg} alt="" /></Link> Notifications</h4>
                </div>
                <div className="header-right-item">
                    <Link to="/wallet" className="coinStack">{walletData ? walletData?.data?.payload?.sila_balance : 0}
                        <img src={coinStackImg} alt="coinStack" />
                    </Link>
                </div>
            </div>
            {loading === true ?
                <CommonLoader />
                :
                <>
                    <div className="main-content">
                        <div className="notification-part">
                            <div className="notification-sec">
                                {/* <h2>Today</h2> */}
                                {notifications?.data?.payload?.data?.length > 0 ?
                                    notifications?.data?.payload?.data?.map((data, index) => {
                                        return (
                                            <div style={{ cursor: "pointer" }} className="notification-item active-notifybox" onClick={() => handleReadNotification(data?.notification_id)} key={index}>
                                                {/* <img src={data?.notification?.user_image ? data?.notification?.user_image : ncigar} alt="" /> */}
                                                <div className="notification-cnt">
                                                    <h4>{data?.notification?.title} <span>{moment(new Date(data?.notification?.created_at)).fromNow()}</span></h4>
                                                    <p>{data?.notification?.description}</p>
                                                </div>
                                            </div>
                                        )
                                    }) : "No Notifications"
                                }
                            </div>
                        </div>
                    </div>
                </>}
            {pagination.totalRecords > pagination.recordsPerPage &&
                <Box py={3} display="flex" justifyContent="center" className="pagination_content">
                    <Pagination
                        key={page}
                        count={pagination.totalPages || 0}
                        color="secondary"
                        variant="outlined"
                        onChange={(e, value) => setPage(value)}
                        defaultPage={page}
                    />
                </Box>
            }
        </>
    );
}

export default Notifications;
