import axios from "axios";
import { toast } from "react-toastify";
const queryString = require('query-string');
// A mock function to mimic making an async request for data

export function getBrandOfCategory(data, token) {
  let query = queryString.stringify(data);
  return new Promise((resolve, reject) =>
    axios
      .get(`${process.env.REACT_APP_STAGING_PRODUCT_URL}api/v1/brands`+ '?' + query, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
          'app-name': 'b2c'
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        console.error(error);
      })
  )
}
export function getHomeData(token) {
  return new Promise((resolve, reject) =>
    axios
      .get(`${process.env.REACT_APP_STAGING_PRODUCT_URL}api/v1/products/dashboard?coupons_limit=100`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
          'app-name': 'b2c'
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        console.error(error);
      })
  )
}
export function getCategories(data, token) {
  let query = queryString.stringify(data);
  return new Promise((resolve, reject) =>
    axios
      .get(`${process.env.REACT_APP_STAGING_PRODUCT_URL}api/v1/categories` + '?' + query, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
          'app-name': 'b2c'
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        console.error(error);
        reject(error)
      })
  )
}
export function getTrendingNearBySeller(data, token) {
  let query = queryString.stringify(data);
  return new Promise((resolve, reject) =>
    axios
      .get(`${process.env.REACT_APP_STAGING_USER_SERVICES}api/v1/users/sellers` + '?' + query, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
          'app-name': 'b2c'
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        console.error(error);
        reject(error)
      })
  )
}
export function getProductListBySupplier(data, token) {
  let sellerId = data.sellerId
  delete data.sellerId
  let query = queryString.stringify(data);
  return new Promise((resolve, reject) =>
    axios
      .get(`${process.env.REACT_APP_STAGING_PRODUCT_URL}api/v1/supplies/seller-info/${sellerId}` + '?' + query, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
          'app-name': 'b2c'
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        console.error(error);
      })
  )
}
export function createBookingApi(data, token) {
  return new Promise((resolve, reject) =>
    axios
      .post(`${process.env.REACT_APP_STAGING_ORDER_URL}api/v1/bookings/`, data, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
          'app-name': 'b2c'
        }
      })
      .then(function (response) {
        resolve(response);
        localStorage.removeItem("invaliddata")
        // toast.success(response?.data?.msg)
      })
      .catch(function (error) {
        reject(error)
        toast.error(error?.response?.data?.msg)
        localStorage.setItem("invaliddata", error?.response?.data?.msg)
        console.error(error);
      })
  )
}
export function getBookingSlot(data, token) {
  let query=queryString.stringify(data)
  return new Promise((resolve, reject) =>
    axios
      .get(`${process.env.REACT_APP_STAGING_ORDER_URL}api/v1/slots/service-appointment-slots`+'?'+query, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
          'app-name': 'b2c'
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        console.error(error);
      })
  )
}
export function getProductByIdApi(data, token) {
  let id = data.id
  delete data.id
  let query = queryString.stringify(data)
  return new Promise((resolve, reject) =>
    axios
      .get(`${process.env.REACT_APP_STAGING_PRODUCT_URL}api/v1/products/${id}` + '?' + query, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
          'app-name': 'b2c'
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        console.error(error);
        reject(error)
      })
  )
}
export function getBannerByServiceType(data, token) {
  let query = queryString.stringify(data);
  return new Promise((resolve, reject) =>
    axios
      .get(`${process.env.REACT_APP_STAGING_PRODUCT_URL}api/v1/banners` + '?' + query, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
          'app-name': 'b2c'
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        console.error(error);
        reject(error)
      })
  )
}
export function getAllOrderByUser(data,token) {
  let query=queryString.stringify(data)
  return new Promise((resolve, reject) =>
    axios
      .get(`${process.env.REACT_APP_STAGING_ORDER_URL}api/v1/orders`+"?"+query, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
          'app-name': 'b2c'
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        console.error(error);
      })
  )
}
export function getReorderByUser(data, token) {
  let id = data.id
  delete data.id
  return new Promise((resolve, reject) =>
    axios
      .get(`${process.env.REACT_APP_STAGING_ORDER_URL}api/v1/orders/${id}`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
          'app-name': 'b2c'
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        console.error(error);
      })
  )
}

export function cancelOrderByUser(data, token) {
  let id = data.orderId
  delete data.orderId
  return new Promise((resolve, reject) =>
    axios
      .put(`${process.env.REACT_APP_STAGING_ORDER_URL}api/v1/orders/status/${id}`, data, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
          'app-name': 'b2c'
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        console.error(error);
      })
  )
}

export function ratingFeedbackByUser(data, token) {
  let id = data.order_id
  delete data.order_id
  return new Promise((resolve, reject) =>
    axios
      .put(`${process.env.REACT_APP_STAGING_ORDER_URL}api/v1/orders/${id}`, data, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
          'app-name': 'b2c'
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        console.error(error);
      })
  )
}
export function getExtraProductsByCategoryId(data, token) {
  let query = queryString.stringify(data);
  return new Promise((resolve, reject) =>
    axios
      .get(`${process.env.REACT_APP_STAGING_PRODUCT_URL}api/v1/products` + '?' + query, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
          'app-name': 'b2c'
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        console.error(error);
        reject(error)
      })
  )
}
export function getProductServiceCount(token) {
  return new Promise((resolve, reject) =>
    axios
      .get(`${process.env.REACT_APP_STAGING_PRODUCT_URL}api/v1/categories/count`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
          'app-name': 'b2c'
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        console.error(error);
        reject(error)
      })
  )
}

export function getCompanyReviewsDetails(data,token) {
  let id = data.sellerId
  delete data.sellerId
  let query = queryString.stringify(data);
  return new Promise((resolve, reject) =>
    axios
      .get(`${process.env.REACT_APP_STAGING_PRODUCT_URL}api/v1/reviews/review-rating/${id}`+'?'+query, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
          'app-name': 'b2c'
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        console.error(error);
        reject(error)
      })
  )
}
export function getVariantId(data,token) {
  let query = queryString.stringify(data);
  return new Promise((resolve, reject) =>
    axios
      .get(`${process.env.REACT_APP_STAGING_PRODUCT_URL}api/v1/supply_variants/by-attribute-value-ids`+'?'+query, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
          'app-name': 'b2c'
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        console.error(error);
        reject(error)
      })
  )
}
export function getAttributesForFilter(data,token) {
  let query = queryString.stringify(data);
  return new Promise((resolve, reject) =>
    axios
      .get(`${process.env.REACT_APP_STAGING_PRODUCT_URL}api/v1/attributes`+'?'+query, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
          'app-name': 'b2c'
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        console.error(error);
        reject(error)
      })
  )
}