import axios from "axios";
import { toast } from "react-toastify";

export function getProfilePhoto(data) {
    var formData = new FormData();
    formData.append('profile', data.profile);
    return new Promise((resolve, reject) =>
        axios.post(`${process.env.REACT_APP_STAGING_USER_SERVICES}api/v1/user_profiles/profile`, formData, {
            headers: {
                'Content-Type': 'application/json',
                'app-name': 'b2c'
            }
        })
            .then(function (response) {
                // toast.success(response?.data?.msg)
                resolve(response);
            })
            .catch(function (error) {
                toast.error(error?.response?.data?.msg)
                console.error(error);
                reject(error)
            })
    )
}

export function getUpdateProfile(data) {
    const id = data.loginId;
    delete data.loginId;
    const token = data.token;
    delete data.token;
    return new Promise((resolve, reject) =>
        axios.put(`${process.env.REACT_APP_STAGING_USER_SERVICES}api/v1/user_profiles/${id}`, data, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token,
                'app-name': 'b2c'
            }
        })
            .then(function (response) {
                resolve(response);
            })
            .catch(function (error) {
                console.error(error);
                reject(error)
            })
    )
}

export function getUserProfile(data) {
    const id = data.loginId;
    delete data.loginId;
    const token = data.token;
    delete data.token;
    return new Promise((resolve, reject) =>
        axios.get(`${process.env.REACT_APP_STAGING_USER_SERVICES}api/v1/users/${id}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token,
                'app-name': 'b2c'
            }
        })
            .then(function (response) {
                resolve(response);
            })
            .catch(function (error) {
                console.error(error);
                reject(error)
            })
    )
}