import React, { useEffect, useState } from "react";
import { Link, useHistory, useLocation } from 'react-router-dom';
import { req1Img, tellImg, emailImg, locationImg, inf1Img, inf2Img, profileImg } from "../../../utilities/images";
import { useDispatch, useSelector } from "react-redux";
import { selectLoginAuth } from "../auth/authSlice";
import moment from 'moment-timezone'
import {  getSupportByIdApiAsync } from "./SupportRequestSlice";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { unwrapResult } from "@reduxjs/toolkit";

const SupportRequestDetails = (props) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const [supportData , setSupportData] = useState([])
    const [supportDataLoading, setSupportDataLoading] = useState(false);
    const location = useLocation();
    const { id } = useParams();
    const auth = useSelector(selectLoginAuth)
    // const supportData = useSelector(selectSupportDataById);
    console.log(supportData,'support data');
    const result = location?.state;

    useEffect(() => {
        window.scrollTo(0, 0)
        const data = {
            "postData": {
                id : id
            },
            "tokenData": auth?.payload?.token
        }
        setSupportDataLoading(true)
        dispatch(getSupportByIdApiAsync(data))
        .then(unwrapResult)
            .then(async (obj) => {
                setSupportData(obj?.data?.payload?.data)
                setSupportDataLoading(false)
            }
            )
            .catch((obj) => {
                setSupportDataLoading(false)
            })
    }, []);

    return (
        <>
            <div className="order-heading">
                <h4><Link to="#" onClick={() => { history.push('/my-support-request') }}><i className="fas fa-chevron-left"></i> #{result?.track_number}</Link></h4>
                <div className="info-reqall">
                    <Link to="#"><i className="fas fa-info-circle"></i></Link>
                    <div className="reqdetails-info">
                        <div className="reqdet-infobox">
                            <div className="reqdetails-upper">
                                <img src={auth?.payload?.user_profiles?.profile_photo ? auth?.payload?.user_profiles?.profile_photo : req1Img} alt="req1Img" />
                                <div className="reqdetails-uprcnt">
                                    <h4>{auth?.payload?.user_profiles?.firstname}</h4>
                                    <p>Me</p>
                                </div>
                            </div>

                            <ul>
                                <li><img src={tellImg} alt="tellImg" /> {auth?.payload?.user_profiles?.phone_no}</li>
                                <li><Link to="#"><img src={emailImg} alt="emailImg" /> {auth?.payload?.email ? auth?.payload?.email : "xyz@gmail.com"}</Link></li>
                                <li><img src={locationImg} alt="locationImg" />{auth?.payload?.user_profiles?.current_address}</li>
                                {/* <li><img src={locationImg} alt="locationImg" />4318 Daffodil Lane, Savage, <br /> Virginia(VA), 20763</li> */}
                            </ul>

                            <div className="reqdet-infobtm">
                                <h4>Total Tickets: <strong>16</strong></h4>
                                <ul>
                                    <li>Solved: <strong>9</strong></li>
                                    <li>Cancelled: <strong>1</strong></li>
                                    <li>Open: <strong>1</strong></li>
                                    <li>Unrespond: <strong>1</strong></li>
                                </ul>
                            </div>
                        </div>

                        {result?.support_comments?.length > 0 ?
                            <div className="reqdet-infobox2">
                                <h4>Assigned agent:</h4>
                                <div className="reqinfo-item">
                                    <img src={result?.support_comments[0]?.user_id_details?.profile_photo ? result?.support_comments[0]?.user_id_details?.profile_photo : profileImg} alt="supportProfImg" />
                                    <div className="reqinfo-cnt2">
                                        <h6>{result?.support_comments[0]?.user_id_details?.firstname + " " + result?.support_comments[0]?.user_id_details?.lastname}</h6>
                                        <p>{moment(result?.support_comments[0]?.created_at).format('MMM DD, YYYY')} {" "}|{" "} {moment(result?.support_comments[0]?.created_at).format('hh:mm A')}</p>
                                    </div>
                                </div>
                                <h4>Also on this ticket:</h4>
                                <ul>
                                    <li><img src={inf1Img} alt="inf1Img" />Satomi D.</li>
                                    <li><img src={inf2Img} alt="inf2Img" />Satomi D.</li>
                                </ul>
                            </div> : <></>}
                    </div>
                </div>
            </div>
            <div className="main-content">
                <div className="reqdetails-area">
                    <div className="reqdetails-part">
                        <div className="reqdetails-upper">
                            <img src={auth?.payload?.user_profiles?.profile_photo ? auth?.payload?.user_profiles?.profile_photo : req1Img} alt="req1Img" />
                            <div className="reqdetails-uprcnt">
                                <h4>{auth?.payload?.user_profiles?.firstname}</h4>
                                <p>Me</p>
                            </div>
                        </div>
                        <div className="reqdetails-content">
                            <p>{result?.notes}</p>
                            <h6>
                                {moment(result?.created_at).format('MMM DD, YYYY')} {" "}|{" "} {moment(result?.created_at).format('hh:mm A')}
                            </h6>
                        </div>
                    </div>

                    {result?.support_comments?.length > 0 ?
                        result?.support_comments?.map((val, index) => {
                            return (
                                <div className="reqdetails-part border-0" key={index}>
                                    <div className="reqdetails-upper">
                                        <img src={val?.user_id_details?.profile_photo ? val?.user_id_details?.profile_photo : profileImg} alt="" />
                                        <div className="reqdetails-uprcnt">
                                            <h4>{val?.user_id_details?.firstname + " " + val?.user_id_details?.lastname}</h4>
                                            <p>JOBR Agent</p>
                                        </div>
                                    </div>
                                    <div className="reqdetails-content">
                                        <p>{val?.comment}</p>
                                        <p>--------------</p>
                                        <p>
                                            Regards, <br />
                                            {val?.user_id_details?.firstname}{" "}{val?.user_id_details?.lastname} <br />
                                            Jobr.com Support Team
                                        </p>
                                        <h6>
                                            {moment(val?.created_at).format('MMM DD, YYYY')} {" "}|{" "} {moment(val?.created_at).format('hh:mm A')}
                                        </h6>
                                    </div>
                                </div>
                            )
                        }) : <></>
                    }

                </div>
            </div>
        </>
    );
}

export default SupportRequestDetails;