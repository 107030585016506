import axios from "axios";
// A mock function to mimic making an async request for data
export function getDeliveryTypesApi(data, token) {
  return new Promise((resolve, reject) =>
    axios
      .get(`${process.env.REACT_APP_STAGING_ORDER_URL}api/v1/delivery_types/seller/${data}`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
          'app-name': 'b2c'
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        console.error(error);
      })
  )
}

export function getAllDeliveryTypesApi(token) {
  return new Promise((resolve, reject) =>
    axios
      .get(`${process.env.REACT_APP_STAGING_ORDER_URL}api/v1/delivery_types`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
          'app-name': 'b2c'
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        console.error(error);
      })
  )
}