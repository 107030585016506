import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import MultiRangeSlider, { ChangeResult } from "multi-range-slider-react";
import { getExtraProductsByCategoryIdAsync, selectProductList } from './dashboardSlice';
import { unwrapResult } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';


const ProducttypeModal = (props) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const [minValue, setMinValue] = useState(25);
    const [maxValue, setMaxValue] = useState(175);
    const [productDataLoading, setProductDataLoading] = useState(false);
    const [clearDataLoading, setClearDataLoading] = useState(false);
    const productsBySubcategoryId = useSelector(selectProductList);
    const [checkedState, setCheckedState] = useState(
        new Array(props?.attributeValueData?.length).fill(false)
    );

    const handleClearAll = () => {
        let subCategoryParams = {
            "postData": {
                page: props?.newParams?.page,
                limit: props?.newParams?.limit,
                app_name: props?.newParams?.app_name,
                delivery_options: props?.newParams?.delivery_options,
                seller_id: props?.newParams?.seller_id,
                sub_category_ids: props?.newParams?.sub_category_ids
            },
            "tokenData": props?.newParams?.tokenData
        }
        setClearDataLoading(true)
        dispatch(getExtraProductsByCategoryIdAsync(subCategoryParams))
            .then(unwrapResult)
            .then(async (obj) => {
                props.paginationHandle(obj)
                setClearDataLoading(false)
            }
            )
            .catch((obj) => {
                setClearDataLoading(false)

            })
    }

    const handleChecklistChange = (position, id,type) => {
        const updatedCheckedState = checkedState.map((item, index) =>
            index === position ? !item : item
        );
        setCheckedState(updatedCheckedState);
        let data = checkedState.map((val, index) => {
            return (
                val === true ? props?.attributeValueData[index]?.id : false
            )
        })
        let result = data?.filter(val => val !== false);
        let exists = result.includes(id)
        let newResults = []
        if (exists) {
            newResults = result.filter((c) => { return c !== id })
        } else {
            newResults = [...result, id]
        }
        let subCategoryParams={}
        if(type === 'attribute'){
            subCategoryParams = {
                "postData": {
                    page: props?.newParams?.page,
                    limit: props?.newParams?.limit,
                    app_name: props?.newParams?.app_name,
                    delivery_options: props?.newParams?.delivery_options,
                    seller_id: props?.newParams?.seller_id,
                    sub_category_ids: props?.newParams?.sub_category_ids,
                    attribute_value_ids: newResults?.toString()
                },
                "tokenData": props?.newParams?.tokenData
            }
        }else if(type === "brand"){
            subCategoryParams = {
                "postData": {
                    page: props?.newParams?.page,
                    limit: props?.newParams?.limit,
                    app_name: props?.newParams?.app_name,
                    delivery_options: props?.newParams?.delivery_options,
                    seller_id: props?.newParams?.seller_id,
                    sub_category_ids: props?.newParams?.sub_category_ids,
                    brand_id: newResults?.toString()
                },
                "tokenData": props?.newParams?.tokenData
            }
        }
        setProductDataLoading(true)
        dispatch(getExtraProductsByCategoryIdAsync(subCategoryParams))
            .then(unwrapResult)
            .then(async (obj) => {
                props.paginationHandle(obj)
                setProductDataLoading(false)
            }
            )
            .catch((obj) => {
                setProductDataLoading(false)

            })

    }

    useEffect(() => {
        if (props.flag === 'price') {
            let subCategoryParams = {
                "postData": {
                    page: props?.newParams?.page,
                    limit: props?.newParams?.limit,
                    app_name: props?.newParams?.app_name,
                    delivery_options: props?.newParams?.delivery_options,
                    seller_id: props?.newParams?.seller_id,
                    sub_category_ids: props?.newParams?.sub_category_ids,
                    min_price: minValue,
                    max_price: maxValue
                },
                "tokenData": props?.newParams?.tokenData
            }
            setProductDataLoading(true)
            dispatch(getExtraProductsByCategoryIdAsync(subCategoryParams))
                .then(unwrapResult)
                .then(async (obj) => {
                    props.paginationHandle(obj)
                    setProductDataLoading(false)
                }
                )
                .catch((obj) => {
                    setProductDataLoading(false)

                })
        }
    }, [minValue, maxValue])

    return (
        <>
            <div className="productModal filterMOdal">
                <div className="productInnerDetails pt-4 pb-4">
                    <div className="productScroll">
                        <div className='modalHeaderproduct_ d-flex justify-content-center mb-4'>
                            <i onClick={() => props.closeModal()} className="fas fa-chevron-left leftbackBtn" aria-hidden="true"></i>
                            <h5 className='subHeading'>Product Type</h5>
                        </div>
                        {props.flag === 'price' ?
                            <>
                                <MultiRangeSlider
                                    min={0}
                                    max={200}
                                    step={10}
                                    minValue={minValue}
                                    maxValue={maxValue}
                                    onInput={(e) => {
                                        setMinValue(e.minValue);
                                        setMaxValue(e.maxValue);
                                    }}
                                ></MultiRangeSlider>
                                <div>
                                    <div style={{ margin: '10px' }}><b>Min Price:</b> {minValue}</div>
                                    <div style={{ margin: '10px' }}><b>Max Price:</b> {maxValue}</div>
                                </div>
                            </>
                            :
                            props.flag === 'attribute' || props.flag === 'brand' ?
                                <>
                                    <div className='filterBody_'>
                                        <ul className='filterList'>
                                            {
                                                props?.attributeValueData?.map((val, i) => {
                                                    return (
                                                        <li key={i} className='listItemms_'>
                                                            <div className="form-group" >
                                                                <input
                                                                    type="checkbox"
                                                                    id={`checktype1-${i}`}
                                                                    name={val?.id}
                                                                    checked={checkedState[i]}
                                                                    value={val?.id}
                                                                    onChange={() => handleChecklistChange(i, val?.id,props.flag)}
                                                                />
                                                                <label htmlFor={`checktype1-${i}`} className='typeLabel_'>{val?.name}</label>
                                                            </div>
                                                        </li>
                                                    )
                                                })
                                            }
                                        </ul>
                                    </div>
                                </> : <></>}
                        {/* <div className='bottomfilterBtn d-flex mt-5'>
                            <Link to="#" onClick={() => handleClearAll()} className="clearAll_">Clear All</Link>
                            <Link onClick={() => { props.closeModal(); props.closeFilterModal() }} to="#" className="primaryBtn">View Results ({productsBySubcategoryId?.data?.payload?.data?.length ? productsBySubcategoryId?.data?.payload?.data?.length : 0})</Link>
                        </div> */}
                        <div className='bottomfilterBtn d-flex mt-5'>
                            <Link to="#" >
                            <button type="button" className="clearAll_" disabled={clearDataLoading === true} onClick={() => handleClearAll()}>
                                {clearDataLoading === true && (
                                    <span className="spinner-border spinner-border-sm"></span>
                                )}
                                &nbsp;&nbsp;
                                <span>Clear All</span>
                            </button>
                            </Link>
                            <Link to="#" >
                                <button type="button" className="btn btn-primary continue border-0" disabled={productDataLoading === true} onClick={() => { props.closeModal(); props.closeFilterModal() }}>
                                    {productDataLoading === true && (
                                        <span className="spinner-border spinner-border-sm"></span>
                                    )}
                                    &nbsp;&nbsp;
                                    <span>View Results ({productsBySubcategoryId?.data?.payload?.data?.length ? productsBySubcategoryId?.data?.payload?.data?.length : 0})</span>
                                </button>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ProducttypeModal
