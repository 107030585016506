import React, { useEffect, useState } from 'react';
import { ArrowWhite, deliveryParcelImg, discountOutlineImg, dotCircleImg, heart2, leftArrowImg, starCircleImg } from '../../../utilities/images';
import { useHistory, Link, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import BusinessDetails from './BusinessDetails';
import CompanyReviews from './CompanyReviews';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { selectLoginAuth } from '../auth/authSlice';
import { getProductListAsync, getCompanyReviewsDetailsAsync } from './dashboardSlice';
import { createFavoriteListApiAsync } from '../favoriteList/favoriteListSlice';
import { unwrapResult } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import Slider from 'react-slick';
import Geocode from "react-geocode";
import SliderWrapper from './offerStyle';
import CustomModal from "../../components/shared/CustomModal";
import CommonLoader from "../../components/shared/CommonLoader";
import { getServiceCartApiAsync, selectServiceCartDataByUser } from '../cart/cartSlice';



const HomeStoreServices = () => {
    const history = useHistory();
    const { id } = useParams();
    const auth = useSelector(selectLoginAuth);
    const [productDataLoading, setProductDataLoading] = useState(false);
    const dispatch = useDispatch();
    const [city, setCity] = useState("");
    const [companyReviewData, setCompanyReviewData] = useState({});
    const [productList, setProductList] = useState({});
    const serviceCartData = useSelector(selectServiceCartDataByUser);
    const [address, setAddress] = useState("");
    const [key, setKey] = useState(Math.random());
    const [modalDetail, setModalDetail] = useState({
        show: false,
        title: "",
        flag: ""
    });
    const handleOnCloseModal = () => {
        setModalDetail({
            show: false,
            title: "",
            flag: ""
        });
        setKey(Math.random());
    }

    const offersSettings = {
        dots: true,
        autoplay: true,
        autoplaySpeed: 2000,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
        speed: 500,
        arrows: false,
        adaptiveHeight: true
    };

    const geocodeHandler = () => {
        navigator.geolocation.getCurrentPosition(function (position) {
            Geocode.setApiKey("AIzaSyBbytCk92gm3MK3Mrs_374RDKf4bz0X1ck");
            Geocode.setLanguage("en");
            Geocode.setLocationType("ROOFTOP");
            Geocode.enableDebug();
            Geocode.fromLatLng(position.coords.latitude, position.coords.longitude).then(
                (response) => {
                    const address = response.results[0].formatted_address;
                    let city, state, country;
                    for (let i = 0; i < response.results[0].address_components.length; i++) {
                        for (let j = 0; j < response.results[0].address_components[i].types.length; j++) {
                            switch (response.results[0].address_components[i].types[j]) {
                                case "locality":
                                    city = response.results[0].address_components[i].long_name;
                                    break;
                                case "administrative_area_level_1":
                                    state = response.results[0].address_components[i].long_name;
                                    break;
                                case "country":
                                    country = response.results[0].address_components[i].long_name;
                                    break;
                            }
                        }
                    }
                    setAddress(address)
                    setCity(city)
                },
                (error) => {
                    console.error(error);
                }
            )
        })
    }

    const favoriteHandle = (favId) => {
        let favData = {
            "postData": {
                "seller_id": favId
            },
            "tokenData": auth?.payload?.token
        }
        setProductDataLoading(true)
        dispatch(createFavoriteListApiAsync(favData))
            .then(unwrapResult)
            .then(async (obj) => {                
                toast.success(obj?.data?.msg)
                let sellerProductParams = {
                    "postData": {
                        sellerId: id,
                        lat: 32.5534,
                        long: 76.1258,
                        app_name: 'b2c',
                        delivery_options: 2
                    },
                    "tokenData": auth?.payload?.token
                }
                dispatch(getProductListAsync(sellerProductParams))
                    .then(unwrapResult)
                    .then(async (obj) => {
                        setProductList(obj)
                        setProductDataLoading(false)
                    }
                    )
                    .catch((obj) => {
                        setProductDataLoading(false)
                    })
            }
            )
            .catch((obj) => {
                setProductDataLoading(false)
            })
    }

    const companyReviewsDataHandle = () => {
        setProductDataLoading(true)
        let sellerProductParams = {
            "postData": {
                sellerId: id
            },
            "tokenData": auth?.payload?.token
        }
        dispatch(getCompanyReviewsDetailsAsync(sellerProductParams))
            .then(unwrapResult)
            .then(async (obj) => {
                setCompanyReviewData(obj)
                setProductDataLoading(false)
            }
            )
            .catch((obj) => {
                setProductDataLoading(false)
            })
    }

    const productDataHandle = () => {
        setProductDataLoading(true)
        let sellerProductParams = {
            "postData": {
                sellerId: id,
                lat: 32.5534,
                long: 76.1258,
                app_name: 'b2c',
                delivery_options: 2
            },
            "tokenData": auth?.payload?.token
        }
        dispatch(getProductListAsync(sellerProductParams))
            .then(unwrapResult)
            .then(async (obj) => {
                setProductList(obj)
                setProductDataLoading(false)
            }
            )
            .catch((obj) => {
                setProductDataLoading(false)
            })
    }

    useEffect(() => {
        window.scrollTo(0, 0)
        productDataHandle()
        geocodeHandler()
        companyReviewsDataHandle()
        dispatch(getServiceCartApiAsync(auth?.payload?.token))
    }, [])

    return (
        <>
            {productDataLoading === true ?
                <CommonLoader />
                :
                <>
                    <div className='header-part browse-addprdcard-header'>
                        <div className='row align-items-center'>
                            <div className='header-left-item col-6 col-md-6'>
                                <img style={{ cursor: "pointer" }} onClick={() => history.goBack()} src={leftArrowImg} alt="" className='me-1' />
                                <h6 className='subhead_'>Back</h6>
                            </div>
                            <div className="header-right-item col-6 col-md-6 text-end">
                                <button type="button" onClick={() => favoriteHandle(productList?.data?.payload?.sellerDetails?.id)}><img src={heart2} alt="" /></button>
                                <DropdownButton className='d-inline-block p-0' id="dropdown-basic-button" title={<img src={dotCircleImg} alt="" />}>
                                    <Dropdown.Item as={Link} to="#" onClick={() => {
                                        setModalDetail({
                                            show: true,
                                            flag: "company_reviews"
                                        });
                                        setKey(Math.random());
                                    }}>Company Review</Dropdown.Item>
                                    <Dropdown.Item as={Link} to="#" onClick={() => {
                                        setModalDetail({
                                            show: true,
                                            flag: "business_details"
                                        });
                                        setKey(Math.random());
                                    }}>Business Details</Dropdown.Item>
                                    <Dropdown.Item as={Link} to="#" onClick={() => history.push(`/store-directions/${id}`)} >Get Store directions</Dropdown.Item>
                                </DropdownButton>
                            </div>
                        </div>
                    </div>
                    <div className="store-banner-part p-0">
                        <div>
                            <img src={productList?.data?.payload?.sellerDetails?.user_profiles?.banner_image} alt='' className='storyBannerBg' />
                            <div className="store-box">
                                <div className="store-item">
                                    <img src={productList?.data?.payload?.sellerDetails?.user_profiles?.profile_photo} alt="" />
                                </div>
                                <div className="store-cnt">
                                    {/* <h4>{productList?.data?.payload?.sellerDetails?.user_profiles?.username} <Link to="#" > <i onClick={() => favoriteHandle(productList?.data?.payload?.sellerDetails?.id)} className={productList?.data?.payload?.isFavourite === true ? `far fa-heart` : `far fa-heart`}></i></Link></h4> */}
                                    <h4>{productList?.data?.payload?.sellerDetails?.user_profiles?.username}</h4>
                                    <p><img src={starCircleImg} alt="" /> {productList?.data?.payload?.sellerRating?.rating} ({productList?.data?.payload?.sellerRating?.review_count}+ ratings)</p>
                                    <h6>{productList?.data?.payload?.sellerDetails?.user_profiles?.firstname}</h6>
                                    <ul>
                                        <li>Outlate <span>{productList?.data?.payload?.sellerCurrentLocation?.address_line_1}</span></li>
                                        <li>30 mins <Link to="#">{city} <i className="fas fa-chevron-down"></i></Link></li>
                                    </ul>
                                    <h3><Link to="#"><img src={deliveryParcelImg} alt="" /> ${productList?.data?.payload?.deliveryFee} Delivery fee</Link></h3>
                                </div>
                            </div>
                        </div>
                        {/* ******offer slider***** */}
                        <div className="banner-slider trslider-part" id="offerSlider">
                            <SliderWrapper>
                                <Slider {...offersSettings}>
                                    {
                                        productList?.data?.payload?.sellerOffersList?.length > 0 && productList?.data?.payload?.sellerOffersList?.map((val, index) => {
                                            return (
                                                <React.Fragment key={index}>
                                                    <div className="store-btn">
                                                        <Link to="#"><img src={discountOutlineImg} alt="" />
                                                            {val?.discount_percentage}% off upto ${val?.max_discount}
                                                        </Link>
                                                        <div className="owl-dots dotstab d-block">
                                                            <span>{(index + 1)}/{productList?.data?.payload?.sellerOffersList?.length}</span>
                                                        </div>
                                                    </div>
                                                </React.Fragment>
                                            )
                                        })
                                    }
                                </Slider>
                            </SliderWrapper>
                        </div>
                    </div>
                    <div className="hmserv-mainprt">
                        {
                            productList?.data?.payload?.products?.map((val, i) => {
                                return (
                                    <div key={i} className="hmserv-item" onClick={() => history.push(`/select-service-1/${val?.id}/${id}`)} style={{ cursor: 'pointer' }}>
                                        <div className="hmserv-left">
                                            <img src={val?.image} alt="" />
                                        </div>
                                        <div className="hmserv-right">
                                            <h4>{val?.name}</h4>
                                            <h6>Starts from <strong>USD${val?.price}</strong></h6>
                                            <p>{val?.description}</p>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                    {serviceCartData?.data?.payload?.appointment_cart_products?.length > 0 ?
                        <div className="selected-product checkout-box">
                            <div className="selected-itembox appoint-box">
                                <div className="selected-leftcnt">
                                    <p>Services: <strong>{serviceCartData?.data?.payload?.appointment_cart_products?.length}</strong></p>
                                    <p>$ <strong>{serviceCartData?.data?.payload?.appointment_cart_products[0]?.product_details?.price?.toFixed(2)}</strong></p>
                                </div>
                                <div style={{ cursor: "pointer" }} className="selected-rightbtn" onClick={() => history.push(`/cart-services/${serviceCartData?.data?.payload?.appointment_cart_products[0]?.product_details?.id}/${id}`)}>
                                    <img src={ArrowWhite} alt="" />
                                </div>
                            </div>
                        </div>
                        : ""}
                </>}
            <CustomModal
                key={key}
                show={modalDetail.show}
                // backdrop="static"
                showCloseBtn={false}
                ids="productInner"
                isRightSideModal={false}
                mediumWidth={true}
                child={modalDetail.flag === 'business_details'
                    ? <BusinessDetails productList={productList} categoryLoading={productDataLoading} closeModal={() => handleOnCloseModal()} details={(e) => handleOnCloseModal(e)} /> :
                    modalDetail.flag === 'company_reviews'
                        ? <CompanyReviews productList={companyReviewData} categoryLoading={productDataLoading} closeModal={() => handleOnCloseModal()} details={(e) => handleOnCloseModal(e)} />
                        : ""
                }
                onCloseModal={() => handleOnCloseModal()}
            />
        </>
    )
}

export default HomeStoreServices
