import { unwrapResult } from "@reduxjs/toolkit";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from 'react-router-dom';
import { starCircleImg, clockImg, deliveryParcelImg, flexButton, frame, shopLight, productActive } from "../../../utilities/images";
import { selectLoginAuth } from "../auth/authSlice";
import { getFavoriteListAsync, getProductFavoriteListAsync } from "./favoriteListSlice";
import PaginationSetting from '../../constants/PaginationSetting';
import Pagination from "@material-ui/lab/Pagination";
import { Box } from "@material-ui/core";
import CommonLoader from '../../components/shared/CommonLoader';
import MobileHeader from "../../components/shared/MobileHeader";
import SliderWrapper from "../dashboard/SliderStyle";
import Slider from "react-slick";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const FavoriteList = (props) => {
    const history = useHistory();
    const PathName = window.location.pathname;
    const [productFavoriteList, setProductFavoriteList] = useState(false);
    const [favoriteList, setFavoriteList] = useState(false);
    const [favoriteLoading, setfavoriteLoading] = useState(false);
    const dispatch = useDispatch();
    const [tabData, setTabData] = useState(1);
    const auth = useSelector(selectLoginAuth);
    const [pagination, setPagination] = useState(PaginationSetting);
    const [page, setPage] = useState(1);

    const productSettings = {
        dots: false,
        autoplay: true,
        autoplaySpeed: 2000,
        infinite: true,
        slidesToShow: 2,
        slidesToScroll: 1,
        centerMode: true,
        centerPadding: '20px',
        initialSlide: 0,
        speed: 500,
        arrows: false,
        adaptiveHeight: true,
        appendDots: (dots) => <ul>{dots}</ul>,
        customPaging: (i) => <div className="slideContent" >{`${i + 1}/${(productFavoriteList?.data?.payload?.data?.length)}`}</div>
    };

    const productFavoriteListHandle = () => {
        let favParams = {
            "postData": {                
                app_name: 'b2c',
                delivery_options: 1,
                page: page,
                limit: pagination.recordsPerPage
            },
            "tokenData": auth?.payload?.token
        }
        setfavoriteLoading(true)
        dispatch(getProductFavoriteListAsync(favParams))
            .then(unwrapResult)
            .then(async (obj) => {
                setProductFavoriteList(obj)
                setPagination((prevState) => ({ ...prevState, totalPages: obj?.data?.payload?.total_pages, totalRecords: obj?.data?.payload?.total }));
                setfavoriteLoading(false)
            }
            )
            .catch((obj) => {
                setfavoriteLoading(false)

            })
    }
    const favoriteListHandle = () => {
        let favParams = {
            "postData": {
                page: page,
                limit: pagination.recordsPerPage
            },
            "tokenData": auth?.payload?.token
        }
        setfavoriteLoading(true)
        dispatch(getFavoriteListAsync(favParams))
            .then(unwrapResult)
            .then(async (obj) => {
                setFavoriteList(obj)
                setPagination((prevState) => ({ ...prevState, totalPages: obj?.data?.payload?.total_pages, totalRecords: obj?.data?.payload?.total }));
                setfavoriteLoading(false)
            }
            )
            .catch((obj) => {
                setfavoriteLoading(false)

            })
    }

    useEffect(() => {
        favoriteListHandle()
        productFavoriteListHandle()
    }, [tabData])

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <>
            <MobileHeader />
            {favoriteLoading === true ?
                <CommonLoader />
                :
                <>
                    <div className="main-content">
                        <div className="search-tab">
                            <div className="delivery-parthm">
                                <div className="tabs">
                                    <div className="tab-upperhm noshadow">
                                        <ul className="tab-links text-left p-0 favListMenus">
                                            <li className={(tabData === 1) ? 'favListItems active' : 'favListItems'} onClick={() => setTabData(1)}>
                                                <Link to="#">
                                                    <img src={flexButton} alt='flexButton' className="favViewImg" />
                                                    <img src={shopLight} alt='ShopLight' className="favViewActive" />
                                                </Link>
                                            </li>
                                            <li className={(tabData === 2) ? 'favListItems active' : 'favListItems'} onClick={() => setTabData(2)}>
                                                <Link to="#">
                                                    <img src={frame} alt='frame' className="favViewImg" />
                                                    <img src={productActive} alt='ProductActive' className="favViewActive" />
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                                {(tabData === 1) && favoriteList && favoriteList?.data?.payload?.data?.map((item, index) => {
                                    return (
                                        <React.Fragment key={index}>
                                            <div className="resultSec  mt-3" style={{ cursor: "pointer" }} onClick={() => history.push({ pathname: `/home-store/${item?.seller?.unique_uuid}`, state: PathName })}>
                                                <div className="trslider-main mb-2" >
                                                    <img src={item?.seller?.user_profiles?.profile_photo} alt="" className="overlapImg" />
                                                    <div className="trslider-cnt">
                                                        <h5 className="productNameHead">{item?.seller?.user_profiles?.firstname}</h5>
                                                        <ul className="productOverlapMenus mt-1">
                                                            <li className="productOverlapItems">
                                                                <img src={starCircleImg} alt="" className="me-2" />
                                                                <span className="overlaptSubtext">{item?.sellerRating?.rating}</span>
                                                            </li>
                                                            <li className="productOverlapItems">
                                                                <img src={clockImg} alt="" className="me-2" />
                                                                <span className="overlaptSubtext">{item?.distance?.timeString}</span>
                                                            </li>
                                                            <li className="productOverlapItems">
                                                                <img src={deliveryParcelImg} alt="" className="me-2" />
                                                                <span className="overlaptSubtext">${item?.deliveryFee} Delivery fee</span>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </React.Fragment>
                                    )
                                })}
                                {(tabData === 2) && productFavoriteList && productFavoriteList?.data?.payload?.map((item, index) => {
                                    return (
                                        <React.Fragment key={index}>
                                            <div className="resultSec">
                                                <div className="row">
                                                    <div className="col-md-12 mb-2">
                                                        <h4 className="subheadLight_">{item?.sellerName}</h4>
                                                    </div>
                                                </div>
                                                <SliderWrapper>
                                                    <Slider {...productSettings} >
                                                        {
                                                            item?.favourite_product?.map((val, i) => {
                                                                return (
                                                                    <div style={{cursor:"pointer"}} className="row" key={i} onClick={()=>history.push(`/home-store-product/${item?.sellerId}/${val?.id}/favorite/${item?.sellerUserId}`)}>
                                                                        <div className="col">
                                                                            <div className="customCardContainer">
                                                                                <div className="customCardArea">
                                                                                    <div className="cardProductImg">
                                                                                        <img src={val?.image} />
                                                                                    </div>
                                                                                    <div className="cardContentArea">
                                                                                        <h3 className="descrTxt">
                                                                                            <b>{val?.name}</b> {val?.description}
                                                                                        </h3>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>)
                                                            })
                                                        }
                                                    </Slider>
                                                </SliderWrapper>
                                            </div>
                                        </React.Fragment>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </>}
            {pagination.totalRecords > pagination.recordsPerPage &&
                <Box py={3} display="flex" justifyContent="center">
                    <Pagination
                        key={page}
                        count={pagination.totalPages || 0}
                        color="secondary"
                        variant="outlined"
                        onChange={(e, value) => setPage(value)}
                        defaultPage={page}
                    />
                </Box>
            }
        </>
    );
}


export default FavoriteList;