import React from 'react';
import Footer from './Footer';

const DashboardLayout = (props) => {
    return (
        <>
            <main className="main-wrapper">
                {props.children}
                <Footer {...props}/>
            </main>
            
        </>
    )

}

export default DashboardLayout;