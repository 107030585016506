import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from 'react-router-dom';
import { coinStackImg, leftArrowImg, orderWhiteImg } from "../../../utilities/images";
import { selectLoginAuth } from "../auth/authSlice";
import { getReorderByUserAsync } from '../dashboard/dashboardSlice';
import moment from "moment-timezone";
import { selectWalletBalance } from "../wallet/walletSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import CommonLoader from '../../components/shared/CommonLoader';


const Tracking = (props) => {
    const history = useHistory();
    const [reorderProducts, setReorderProducts] = useState({});
    const walletData = useSelector(selectWalletBalance);
    const [loading, setLoading] = useState(false);
    const auth = useSelector(selectLoginAuth);
    const dispatch = useDispatch();
    const [status, setStatus] = useState("");

    let id = localStorage.getItem('orderId');
    let data = {
        'postData': {
            id: id
        },
        "tokenData": auth?.payload?.token
    }
    useEffect(() => {
        window.scrollTo(0, 0)
        setLoading(true)
        dispatch(getReorderByUserAsync(data))
            .then(unwrapResult)
            .then(async (obj) => {
                setReorderProducts(obj)
                setStatus(obj?.data?.payload?.status)
                setLoading(false)
            }
            )
            .catch((obj) => {
                setLoading(false)
            })
    }, [])

    return (
        <>
            <div className="order-heading">
                <div className="header-left-item">
                    <h4><Link to="#" onClick={() => { history.push('/orders-placed') }}><img src={leftArrowImg} alt="leftArrowImg" /></Link> Track your order </h4>
                </div>
                <div className="header-right-item">
                    <Link to="/wallet" className="coinStack">{walletData ? walletData?.data?.payload?.sila_balance : 0}
                        <img src={coinStackImg} alt="coinStack" />
                    </Link>
                </div>
            </div>
            {loading === true ?
                <CommonLoader />
                :            
            <div className="main-content">
                <div className="assinged-main assinged-mainbg2 track-bg">
                    <div className="assinged-overlay">
                        <div className="assinged-delivery">
                            <div className="assinged-box">
                                <div className="assinged-boxupper">
                                    <div className="assinged-boxuper-left">
                                        <h4>Order status</h4>
                                        <p>Preparing your order</p>
                                    </div>
                                    <div className="assinged-boxuper-right">
                                        <Link to="#" onClick={() => { history.push('/tracking-two') }}><img src={orderWhiteImg} alt="orderWhiteImg" /> Order</Link>
                                    </div>
                                </div>

                                <div className="track-timeline">
                                    <ul>
                                        <li className={status >= 0 ? "active-timeline pt-4" : ""}>
                                            <h4>Order accepted</h4>
                                            <p>{moment(reorderProducts?.data?.payload?.updated_at).format('DD MMM, YYYY | HH:mm a')}</p>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
}
        </>
    );
}

export default Tracking;