import axios from "axios";
const queryString = require('query-string');

export function getBookingList(data, token) {
  let query = queryString.stringify(data)
  return new Promise((resolve, reject) =>
    axios
      .get(`${process.env.REACT_APP_STAGING_ORDER_URL}api/v1/appointments` + "?" + query, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
          'app-name': 'b2c'
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        console.error(error);
        reject(error)
      })
  )
}
export function cancelBookingByUser(data, token) {
  return new Promise((resolve, reject) =>
    axios
      .put(`${process.env.REACT_APP_STAGING_ORDER_URL}api/v1/bookings/booking-cancel/${data}`, {}, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
          'app-name': 'b2c'
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        console.error(error);
      })
  )
}
export function updateBookingByUser(id, data, token) {
  return new Promise((resolve, reject) =>
    axios
      .put(`${process.env.REACT_APP_STAGING_ORDER_URL}api/v1/bookings/${id}`, data, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
          'app-name': 'b2c'
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        console.error(error);
      })
  )
}