import { unwrapResult } from "@reduxjs/toolkit";
import moment from "moment-timezone";
import React, { useEffect, useState } from "react";
import { withGoogleMap, GoogleMap, withScriptjs, Marker } from "react-google-maps";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from 'react-router-dom';
import { toast } from "react-toastify";
import { arrowRightSideImg, coinStackImg, edit2Img, Info, leftArrowImg, LocationDark, locationImg, LogoIcon, OrderBlue, plusImg, veirfy2Img, Waiting, WalletBlue } from "../../../utilities/images";
import CustomModal from "../../components/shared/CustomModal";
import { allAddressAsync, selectAllAddress } from "../addresses/addressSlice";
import { selectLoginAuth } from "../auth/authSlice";
import { createOrderApiAsync, getCartApiAsync, selectCartSubmitResponse } from "../cart/cartSlice";
import { useParams } from "react-router-dom/cjs/react-router-dom";
import CommonLoader from '../../components/shared/CommonLoader';
import { getWalletDataAsync, selectWalletBalance } from "../wallet/walletSlice";
import { selectUserProfile } from "../profile/profileSlice";
import MoneyOptionModal from "./MoneyOptionModal";

const Promotion = (props) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const { id } = useParams();
    const auth = useSelector(selectLoginAuth);
    const [dataLoading, setDataLoading] = useState(false);
    const [loading, setLoading] = useState(false);
    const addressData = useSelector(selectAllAddress);
    const walletData = useSelector(selectWalletBalance);
    const [selectedAddress, setSelectedAddress] = useState({});
    const selectProfile = useSelector(selectUserProfile);
    const cartFullResponse = useSelector(selectCartSubmitResponse)
    // const [zoom, setZoom] = useState(15)
    // const [latitude, setLatitude] = useState(30.900965)
    // const [longitude, setLongitude] = useState(75.8572758)
    // const cartData = useSelector(selectCartData)
    const toastId = React.useRef(null)
    const [key, setKey] = useState(Math.random());
    const [modalDetail, setModalDetail] = useState({
        show: false,
        title: "",
        flag: ""
    });
    const handleOnCloseModal = () => {
        setModalDetail({
            show: false,
            title: "",
            flag: ""
        });
        setKey(Math.random());
    }


    console.log(cartFullResponse,"response")

    let discountValue = cartFullResponse?.cartOtherData?.couponData ? Number((cartFullResponse?.amout?.total_amount * cartFullResponse?.cartOtherData?.couponData?.discount_percentage) / 100).toFixed(2) : (0).toFixed(2)

    function amount(item) {
        return item.price;
    }
    function sum(prev, next) {
        return prev + next;
    }
    const date = new Date();
    let deliveryData
    let couponData
    if (cartFullResponse?.cartOtherData?.delivery_type_id === 1) {
        deliveryData = {
            "delivery_type_id": cartFullResponse?.cartOtherData?.delivery_type_id
        }
    }
    else {
        deliveryData = {
            "delivery_type_id": cartFullResponse?.cartOtherData?.delivery_type_id,
            "preffered_delivery_end_time": cartFullResponse?.cartOtherData?.preffered_delivery_end_time,
            "preffered_delivery_start_time": cartFullResponse?.cartOtherData?.preffered_delivery_start_time
        }
    }

    if (cartFullResponse?.cartOtherData?.couponData === "") {
        couponData = {
            "is_favourite": false
        }
    }
    else {
        couponData = {
            "coupon_code": cartFullResponse?.cartOtherData?.couponData && cartFullResponse?.cartOtherData?.couponData?.code,
            "is_favourite": false
        }
    }

    let data = {
        "postData":
        {
            "cart_id": cartFullResponse?.cart_products[0]?.cart_id,
            "address_id": selectedAddress?.id,
            "address_type": selectedAddress?.address_type,
            "address": selectedAddress?.formatted_address,
            "address_2": selectedAddress?.formatted_address,
            "city": selectedAddress?.city,
            "delivery_option": "1",
            "state": selectedAddress?.district,
            "zip": selectedAddress?.postal_code,
            "country": selectedAddress?.country,
            "fax": "465",
            "coordinates": [
                selectedAddress?.longitude,
                selectedAddress?.latitude
            ],
            "date": moment(date).format('YYYY-MM-DD'),
            ...deliveryData,
            ...couponData,
            "mode_of_payment": "jbr"
        },
        "tokenData": auth?.payload?.token
    }
    const handleOrder = async () => {
        setLoading(true)
        await dispatch(createOrderApiAsync(data))
            .then(unwrapResult)
            .then(async (obj) => {
                localStorage.setItem('orderId', obj?.data?.payload?.id)
                if (!toast.isActive(toastId.current)) {
                    toastId.current = toast.success(obj?.msg)
                }
                let params = {
                    token: auth?.payload?.token
                }
                dispatch(getWalletDataAsync(params))
                    .then(unwrapResult)
                    .then(async (obj) => {
                        setLoading(false)
                    }
                    )
                    .catch((obj) => {
                        setLoading(false)
                    })
                setModalDetail({ show: true, flag: "OrderPlaced" });
                setKey(Math.random());
            }
            )
            .catch((obj) => {
                setLoading(false)
                handleOnCloseModal()
                // if (!toast.isActive(toastId.current)) {
                //     toastId.current = toast.error(obj?.message)
                // }
            })
    }

    const AsyncMap = withScriptjs(
        withGoogleMap(
            props => (
                <GoogleMap
                    defaultZoom={15}
                    defaultCenter={{ lat: selectedAddress?.latitude, lng: selectedAddress?.longitude }}
                >
                    <Marker
                        name={''}
                        draggable={true}
                        onDragEnd={onMarkerDragEnd}
                        position={{ lat: selectedAddress?.latitude, lng: selectedAddress?.longitude }}
                    />
                </GoogleMap>
            )
        )
    );
    const onMarkerDragEnd = (event) => {
        //dragger event
    }

    const amountDataHandle = () => {
        // history.push({ pathname: '/payment', state: amountData })
    }

    const renderOrderPlacedHtml = () => {
        return (
            <div className="modal-content">
                <div className="modal-body">
                    <div className="modal-mainprt">
                        <div className="mdlverify-cnt cardmdl-verify">
                            <h4>Order Placed</h4>
                            <img src={veirfy2Img} alt="veirfy2Img" />
                            <p>Your order placed successfully</p>
                            <h6>Order is proccessing</h6>
                        </div>
                        <div className="mdlverify-btn mdllogin-btn" onClick={() => history.push('/tracking')}>
                            <Link to='#' className="active">Track order</Link>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    const renderWarningHtml = () => {
        return (
            <div className="modal-body">
                <div className="modal-mainprt warning-mdlbox">
                    <div className="mdlverify-cnt cardmdl-verify">
                        <h4>Warning</h4>
                        <img src={Waiting} alt="" />
                        <p>No showing up</p>
                        <h6>No Refund!</h6>
                    </div>
                    <div className="mdlverify-btn mdllogin-btn">
                        {/* <Link to="#" onClick={() => handleOrder()} className="active">Confirm & Place the order</Link> */}
                        <button type="button" className="active btn btn-primary continue border-0" disabled={loading === true} onClick={() => handleOrder()}>
                            {loading === true && (
                                <span className="spinner-border spinner-border-sm"></span>
                            )}
                            &nbsp;&nbsp;
                            <span>Confirm & Place the order</span>
                        </button>

                    </div>
                </div>
            </div>
        )
    }
    const renderServiceFeesHtml = () => {
        return (
            <div className="modal-content">
                <div className="modal-header address-mdlheader">
                    <h5><strong>Whats included?</strong></h5>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => { handleOnCloseModal() }}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body address-mdlbody pt-0">
                    <div className="taxes-mdlbox-cnt">
                        <h4>Service fees <span>$1.50</span></h4>
                        <p>Cras lobortis ligula sed metus tempor, nec aliquet dolor suscipit. Nullam eleifend condimentum sollicitudin.</p>
                    </div>
                    <div className="taxes-mdlbox-cnt">
                        <h4>Taxes <span>$1.50</span></h4>
                    </div>
                </div>
            </div>
        )
    }

    const walletDataHandle = () => {
        setDataLoading(true)
        let params = {
            token: auth?.payload?.token
        }
        dispatch(getWalletDataAsync(params))
            .then(unwrapResult)
            .then(async (obj) => {
                setDataLoading(false)
            }
            )
            .catch((obj) => {
                setDataLoading(false)
            })
    }

    const handleJbrAdd = () => {
        if (selectProfile?.payload?.is_wallet === true) {
            setModalDetail({ show: true, flag: "coin_plan" }); setKey(Math.random());
        } else {
            history.push(`/create-wallet/order`)
        }
    }

    useEffect(() => {
        if (selectProfile?.payload?.is_wallet === true) {
            walletDataHandle()
        }
        setDataLoading(true)
        dispatch(getCartApiAsync(auth?.payload?.token))
        let params = {
            token: auth?.payload?.token
        }
        dispatch(allAddressAsync(params))
            .then(unwrapResult)
            .then(async (obj) => {
                id ? setSelectedAddress(addressData?.payload?.data?.find(item => item.id == id)) : setSelectedAddress(addressData?.payload?.data[0])
                setDataLoading(false)
            }
            )
            .catch((obj) => {
                setDataLoading(false)
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
        window.scrollTo(0, 0)
    }, [])

    return (
        <>
            {dataLoading === true ?
                <CommonLoader />
                :
                <>
                    <div className="order-heading">
                        <div className="header-left-item">
                            <h4><Link to='/cart'><img src={leftArrowImg} alt="leftArrowImg" /></Link> Review & Payment </h4>
                        </div>
                        <div className="header-right-item">
                            <Link to="/wallet" className="coinStack">{walletData ? walletData?.data?.payload?.sila_balance : 0}
                                <img src={coinStackImg} alt="coinStack" />
                            </Link>
                        </div>
                    </div>

                    <div className="main-content cart-mainpd">
                        <div className="placeord-main">

                            {
                                addressData?.payload?.data?.length > 0
                                    ?
                                    <div className="delvaddres-boxr">
                                        <h2><img src={LocationDark} alt="LocationDark" />Delivery Address </h2>
                                        <div className="delv-map">
                                            <AsyncMap
                                                googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_MAP_KEY}&libraries=places`}
                                                loadingElement={
                                                    <div style={{ height: `100%` }} />
                                                }
                                                containerElement={
                                                    <div style={{ height: '300px' }} />
                                                }
                                                mapElement={
                                                    <div style={{ height: `100%` }} />
                                                }
                                            />
                                        </div>
                                        <h4>{selectedAddress?.address_type}</h4>
                                        <p>{selectedAddress?.formatted_address}</p>
                                        <div onClick={() => history.push(`/addresses/placing`)} className="aprt-btn">{selectedAddress?.formatted_address} <i className="fas fa-chevron-right"></i></div>
                                    </div>
                                    :
                                    <div className="delvaddres-boxr">
                                        <h2><img src={locationImg} alt="locationImg" />Add Delivery Address <Link to="#" onClick={() => history.push('/new-address-add/placing')}><img src={edit2Img} alt="edit2Img" /></Link></h2>
                                    </div>
                            }

                            <div className="delvpaymnt-box">
                                <h2><img src={WalletBlue} alt="" /> Balance <Link to="#" ><img src={plusImg} alt="plusImg" onClick={handleJbrAdd} /></Link></h2>
                                <Link className="paymnt-dlbtn" to=''><img src={LogoIcon} alt="LogoIcon" /> JBR<span>JBR {walletData?.data?.payload?.sila_balance}</span></Link>
                            </div>

                            <div className="orderdlv-detailsbox">
                                <h2><img src={OrderBlue} alt="OrderBlue" /> Order details</h2>
                                <div className="details-ordmdl border-top-0 pt-0">
                                    <ul>
                                        {
                                            cartFullResponse?.cart_products?.map((val, index) => {
                                                return (
                                                    <li key={index}>
                                                        <div className="details-mdlleft">
                                                            <strong>{val?.qty}</strong> <small>x</small> {val?.product_details?.name} <span></span>
                                                        </div>
                                                        <p>${val?.product_details?.price + (val?.product_details?.selected_attribute_values ? val?.product_details?.selected_attribute_values?.map(amount).reduce(sum) : 0)}</p>
                                                    </li>
                                                )
                                            })
                                        }
                                    </ul>
                                </div>

                                <div className="total-part">
                                    <ul>
                                        <li>Sub Total {cartFullResponse?.amout?.total_amount ? <span>${(cartFullResponse?.amout?.total_amount).toFixed(2)}</span> : ""}</li>
                                        <li>Coupon <span>-${discountValue}</span></li>
                                        <li><small>Taxes & Other fees <img src={Info} alt="" /></small> <span>$0.00</span></li>
                                        <li>Delivery fees <span>${Number(cartFullResponse?.cartOtherData?.delivery_fees).toFixed(2)}</span></li>
                                        <li><strong>Total</strong> <strong>${(((cartFullResponse?.amout?.total_amount) + Number(cartFullResponse?.cartOtherData?.delivery_fees)) - (discountValue)).toFixed(2)}</strong></li>
                                    </ul>
                                </div>
                            </div>

                            <div className="compl-orcnt">
                                <h6>By completing this order, I agree to all <Link to="/terms-&-conditions">terms & conditions</Link></h6>
                            </div>

                        </div>

                        <div className="selected-product checkout-box">
                            <div className={(addressData?.payload?.data?.length > 0) && (selectProfile?.payload?.is_wallet === true) && ((walletData?.data?.payload?.sila_balance * 106) > ((((cartFullResponse?.amout?.total_amount) + Number(cartFullResponse?.cartOtherData?.delivery_fees)) - (discountValue)).toFixed(2))) ? "selected-itembox place-ordbtn" : "selected-itembox place-ordbtn place-misaddr"}>
                                <div className="selected-leftcnt">
                                    <p><strong>Place Order</strong>{(addressData?.payload?.data?.length > 0 && (selectProfile?.payload?.is_wallet === true)) ? "" : (!addressData) ? <small>(Missing address)</small> : ((selectProfile?.payload?.is_wallet === false) || (walletData?.data?.payload?.sila_balance * 106) < ((((cartFullResponse?.amout?.total_amount) + Number(cartFullResponse?.cartOtherData?.delivery_fees)) - (discountValue)).toFixed(2))) ? <small>(Insufficient balance)</small> : ""} </p>
                                </div>
                                <div className="selected-rightbtn" onClick={(addressData?.payload?.data?.length > 0) && (selectProfile?.payload?.is_wallet === true) && (walletData?.data?.payload?.sila_balance > ((((cartFullResponse?.amout?.total_amount) + Number(cartFullResponse?.cartOtherData?.delivery_fees)) - (discountValue)).toFixed(2))) ? () => { setModalDetail({ show: true, flag: "refund" }); setKey(Math.random()); } : void (0)}>
                                    <Link to="#">
                                        <img src={arrowRightSideImg} alt="arrowRightSideImg" />
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </>}

            <CustomModal
                key={key}
                show={modalDetail.show}
                backdrop="static"
                showCloseBtn={true}
                isRightSideModal={false}
                mediumWidth={(modalDetail.flag === 'OrderPlaced' || modalDetail.flag === 'coin_plan') ? false : true}
                darkBackground={modalDetail.flag === 'OrderPlaced' ? true : false}
                size="md"
                child={modalDetail.flag === 'OrderPlaced' ?
                    renderOrderPlacedHtml()
                    : (modalDetail.flag === "refund") ?
                        renderWarningHtml()
                        : (modalDetail.flag === 'coin_plan') ?
                            <MoneyOptionModal closeModal={() => handleOnCloseModal()} />
                            :
                            renderServiceFeesHtml()
                }
                onCloseModal={() => handleOnCloseModal()}
            />
        </>
    );
}

export default Promotion;