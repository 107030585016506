import { combineReducers } from "redux";
import authReducer from "../../src/app/containers/auth/authSlice"
import dashboardReducer from "../../src/app/containers/dashboard/dashboardSlice"
import browseReducer from "../../src/app/containers/browse/browserSlice"
import notificationReducer from "../../src/app/containers/notifications/notificationSlice"
import couponsReducer from '../../src/app/containers/coupons/couponSlice'
// import editProfilePhotoReducer from '../../src/app/containers/profile/profileSlice'
import idCardPhotoReducer  from '../../src/app/containers/auth/ageVerificationSlice'
import userProfileReducer from '../../src/app/containers/profile/profileSlice'
import faqReducer from '../../src/app/containers/faq/faqSlice'
import supportReducer from '../../src/app/containers/supportRequest/SupportRequestSlice'
import addressReducer from '../../src/app/containers/addresses/addressSlice';
import productSearchReducer from '../../src/app/containers/search/SearchSlice';
import cartReducer from "../../src/app/containers/cart/cartSlice";
import deliveryReducer from "../../src/app/containers/delivery/deliverySlice"
import bookingReducer from "../../src/app/containers/booking/bookingSlice"
import favoriteReducer from "../../src/app/containers/favoriteList/favoriteListSlice"
import reviewReducer from "../../src/app/containers/productReview/productReviewSlice"
import settingReducer from "../../src/app/containers/pages/settingSlice"
import walletReducer from "../../src/app/containers/wallet/walletSlice"

const rootReducer = combineReducers({
  // Define a top-level state field named `todos`, handled by `todosReducer`
  auth: authReducer,
  dashboard: dashboardReducer,
  browse: browseReducer,
  coupons: couponsReducer,
  cart : cartReducer,
  address: addressReducer,
  editProfilePhoto: userProfileReducer, 
  idCardPhoto: idCardPhotoReducer,
  productSearch: productSearchReducer, 
  faq: faqReducer,
  userProfile: userProfileReducer,
  notification: notificationReducer,
  support: supportReducer,
  delivery: deliveryReducer,
  booking:bookingReducer,
  favorites:favoriteReducer,
  review:reviewReducer,
  setting:settingReducer,
  wallet: walletReducer

});

export default rootReducer;