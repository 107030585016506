import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
    getNotificationsList,
    readNotificationApi
} from "./notificationApi";

const initialState = {
};

export const getNotificationAsync = createAsyncThunk("notification/data", async (data) => {
    const response = await getNotificationsList(data.postData,data.tokenData);
    return response;  
});

export const readNotificationApiAsync = createAsyncThunk("notification/data", async (data) => {
    const response = await readNotificationApi(data.postData,data.tokenData);
    return response;  
});

export const notificationSlice = createSlice({
    name: "notification",
    initialState,

});

export default notificationSlice.reducer;