import axios from "axios";
const queryString = require('query-string');

export function getNotificationsList(data, token) {
    let query = queryString.stringify(data)
    return new Promise((resolve, reject) =>
      axios
        .get(`${process.env.REACT_APP_STAGING_USER_SERVICES}api/v1/notifications` + "?" + query, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': token,
            'app-name': 'b2c'
          }
        })
        .then(function (response) {
          resolve(response);
        })
        .catch(function (error) {
          reject(error)
          console.error(error);
        })
    )
  }
  export function readNotificationApi(data, token) {
    return new Promise((resolve, reject) =>
      axios
        .put(`${process.env.REACT_APP_STAGING_USER_SERVICES}api/v1/notifications/read`,data, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': token,
            'app-name': 'b2c'
          }
        })
        .then(function (response) {
          resolve(response);
        })
        .catch(function (error) {
          reject(error)
          console.error(error);
        })
    )
  }