import React, { useEffect, useState } from "react";
import { Link, useHistory, useLocation } from 'react-router-dom';
import { leftArrowImg, cameraImg, logoImg } from "../../../utilities/images";
import ReactStars from 'react-stars';
import CustomModal from "../../components/shared/CustomModal";
import { selectLoginAuth, uploadIdCardAsync } from "../auth/authSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import { toast } from 'react-toastify';
import CommonLoader from "../../components/shared/CommonLoader";
import { createProductReviewAsync, getRatingDetailsAsync, selectRatingDetails } from "./productReviewSlice";

const ProductReviewTwo = (props) => {
    const history = useHistory();
    const location = useLocation();
    const results = location?.state;
    const toastId = React.useRef(null);
    const [loading, setLoading] = useState(false);
    const [ratingDetailsLoading, setRatingDetailsLoading] = useState(false);
    const dispatch = useDispatch();
    const ratingDegtails = useSelector(selectRatingDetails);
    const auth = useSelector(selectLoginAuth);
    const [productRating, setProductRating] = useState("");
    const [qualityRating, setQualityRating] = useState("");
    const [moneyRating, setMoneyRating] = useState("");
    const [note, setNote] = useState("");
    const [document, setDocument] = useState("");
    // const [imageData, setImageData] = useState("");
    const [fileData, setFileData] = useState([]);
    const [key, setKey] = useState(Math.random());
    const [modalDetail, setModalDetail] = useState({
        show: false,
        title: "",
        flag: ""
    });

    const handleOnCloseModal = () => {
        setModalDetail({
            show: false,
            title: "",
            flag: ""
        });
        setKey(Math.random());
    };

    const handleSubmit = () => {
        const submitData = {
            "postData": {
                "order_id": results?.order_detail?.order_id?.toString(),
                "product_id": results?.id?.toString(),
                "seller_id": results?.seller_detail?.unique_uuid,
                "rating": productRating,
                "comment": note,
                "product_quality_rating": qualityRating,
                "value_of_money_rating": moneyRating,
                "images": [{
                    "document": document
                }]
            },
            "tokenData": auth?.payload?.token
        }
        if (!note) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error('Please writes notes')
            }
        }
        else if (!productRating) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error('Please give Product Rating')
            }
        }
        else if (!qualityRating) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error('Please give Product Quality Rating')
            }
        }
        else if (!moneyRating) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error('Please give rating for value of Money')
            }
        }
        else if (!document) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error('Please Attach images')
            }
        }
        else {
            setLoading(true)
            dispatch(createProductReviewAsync(submitData))
                .then(unwrapResult)
                .then(async (obj) => {
                    setLoading(false)
                    if (!toast.isActive(toastId.current)) {
                        toastId.current = toast.success(obj?.msg)
                    }
                    setModalDetail({
                        show: true,
                        flag: "congratulations"
                    })
                    setKey(Math.random());
                })
                .catch((obj) => {
                    setLoading(false)
                })
        }

    }

    const onChangeInput = (e, fieldName) => {
        const value = e.target.value;
        if (fieldName === "note") {
            setNote(value.trimStart());
        }
    };

    let imageFile;
    let fileObj = [];
    let fileArray = [];
    let fileDetails = []

    const handleChange = (e) => {
        imageFile = e.target.files[0];
        let params = {
            document: imageFile
        }
        dispatch(uploadIdCardAsync(params))
            .then(unwrapResult)
            .then((obj) => {
                setDocument(obj.payload.profile_photo)
                if (!toast.isActive(toastId.current)) {
                    toastId.current = toast.success(obj?.msg)
                }
            }
            )
            .catch((obj) => {
                if (!toast.isActive(toastId.current)) {
                    toastId.current = toast.error(obj?.message)
                }
            })
        // setImageData(imageFile)
        // setDocument(URL.createObjectURL(imageFile));
        fileObj.push(e.target.files)
        for (let i = 0; i < fileObj[0].length; i++) {
            fileArray.push({ url: URL.createObjectURL(fileObj[0][i]), name: fileObj[0][i]?.name, size: fileObj[0][i]?.size })
        }
        setFileData(fileArray)
    }
    const ratingDetailsHandle = () => {
        setRatingDetailsLoading(true)
        const ratingParams = {
            "postData": {
                user_id: results?.order_detail?.user_id,
                order_id: results?.order_detail?.order_id,
                flag_id: results?.id
            },
            "tokenData": auth?.payload?.token
        }
        setRatingDetailsLoading(true)
        dispatch(getRatingDetailsAsync(ratingParams))
            .then(unwrapResult)
            .then((obj) => {
                setProductRating(obj?.data?.payload?.rating)
                setQualityRating(obj?.data?.payload?.product_quality_rating)
                setMoneyRating(obj?.data?.payload?.value_of_money_rating)
                setNote(obj?.data?.payload?.comment)
                setNote(obj?.data?.payload?.comment)
                setFileData(obj?.data?.payload?.images)
                setRatingDetailsLoading(false)
            }
            )
            .catch((obj) => {
                setRatingDetailsLoading(false)
            })
    }

    useEffect(() => {
        ratingDetailsHandle()
    }, [])

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <>
        {ratingDetailsLoading === true ?
                <CommonLoader/>
                :
                <>
            <div className="order-heading">
                <h4><Link to='/product-review-available'><img src={leftArrowImg} alt="leftArrowImg" /></Link> Product review</h4>
            </div>

            <div className="main-content">
                <div className="product-review-area">
                    <div className="rate-upper">
                        <h2>Rate your experience</h2>
                        <p>Are you satisfy with product?</p>
                    </div>

                    <div className="rating-partex">
                        <div className="rating-leftex">
                            <img className="reviewImg_" src={results?.image} alt="review1Img" />
                        </div>
                        <div className="rating-rightex">
                            <h4>{results?.name}</h4>
                            <div className="rateYo">
                                <ReactStars
                                    count={5}
                                    half={false}
                                    value={Number(productRating)}
                                    onChange={(e) => setProductRating(e)}
                                    size={30}
                                    color2={'#FCBA30'}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="rating-innerex">
                        <div className="rating-inrleftex">
                            <p>Product Quality</p>
                        </div>
                        <div className="rating-inrrightex">
                            <div className="rateYo">
                                <ReactStars
                                    count={5}
                                    half={false}
                                    value={Number(qualityRating)}
                                    onChange={(e) => setQualityRating(e)}
                                    size={30}
                                    color2={'#FCBA30'}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="rating-innerex">
                        <div className="rating-inrleftex">
                            <p>Value of Money</p>
                        </div>
                        <div className="rating-inrrightex">
                            <div className="rateYo">
                                <ReactStars
                                    count={5}
                                    half={false}
                                    value={Number(moneyRating)}
                                    onChange={(e) => setMoneyRating(e)}
                                    size={30}
                                    color2={'#FCBA30'}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="additional-box">
                        <h4>Write your additional feedback</h4>
                        <form action="#" method="POST" >
                            <textarea name="" id="" cols="30" rows="5" value={note} onChange={(e) => { onChangeInput(e, 'note') }}></textarea>
                        </form>
                    </div>
                    <div className="attach-part">
                        <h4>Attach images</h4>
                        {ratingDegtails?.status === 200 ? "" :
                            <div className="upload-btn-wrapper">
                                <button className="filebtn"><img src={cameraImg} alt="cameraImg" /></button>
                                <input type="file" name="myfile" onChange={(e) => handleChange(e)} multiple />
                            </div>}
                    </div>

                    {(fileData) ?
                        <>
                            {
                                (fileData || [])?.map((data, index) => {
                                    return (
                                        <div className="attach-item" key={index}>
                                            <img src={data?.url || data?.document} alt="attachImg" />
                                            {/* <div className="attach-cnt">
                                            <h4>{data?.name}</h4>
                                            <p>{data?.size} kb</p>
                                            <span></span>
                                        </div> */}
                                        </div>
                                    )
                                })
                            }
                        </>
                        : ""
                    }

                    <div className="submit-btnrating_ mt-4">
                        <Link className="linkouterBox" to='#'>
                            <button type="button" className="btn submitBtn__" disabled={loading === true || ratingDegtails?.status === 200} onClick={() => handleSubmit()}>
                                {loading === true && (
                                    <span className="spinner-border spinner-border-sm"></span>
                                )}
                                &nbsp;&nbsp;
                                <span>{ratingDegtails?.status === 200 ? 'Submitted' : 'Submit'}</span>
                            </button>
                        </Link>
                    </div>
                </div>

                <CustomModal
                    key={key}
                    show={modalDetail.show}
                    backdrop="static"
                    showCloseBtn={false}
                    isRightSideModal={false}
                    darkModal={true}
                    child={
                        <div className="modal-body">
                            {/* <div className="modal-header">
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() =>{props.closeModal()}}>
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div> */}
                            <div className="modal-body">
                                <div className="modal-mainprt review-mdlprt">
                                    <div className="mdlverify-cnt">
                                        <h4>Congratulation</h4>
                                        <img src={logoImg} alt="logoImg" />
                                        <p>Review submitted successfully</p>
                                        <h6>You have earn JBR 1.00</h6>
                                    </div>
                                    <div className="mdlverify-btn mdllogin-btn">
                                        <Link to="#" onClick={() => { history.push({ pathname: '/product-review-available', state: "review" }) }} className="active" data-dismiss="modal">Back</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    // size="lg"
                    //title={modalDetail.title}
                    // footerContent = {}
                    // footerClasses = 'justify-content-start' // justify-content-start, justify-content-end, justify-content-center
                    onCloseModal={() => handleOnCloseModal()}
                />

            </div>
            </>}
        </>

    );
}

export default ProductReviewTwo;