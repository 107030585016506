import axios from "axios";
const queryString = require('query-string');

export function getUserFaq(data) {
let query =  queryString.stringify(data);
    return new Promise((resolve, reject) =>
        axios.get(`${process.env.REACT_APP_STAGING_SUPPORT}api/v1/faqs`+"?"+query, {
            headers: {
                'app-name': 'b2c',
                'Content-Type': 'application/json'
            }
        })
            .then(function (response) {
                resolve(response);
            })
            .catch(function (error) {
                console.error(error);
                reject(error)
            })
    )
}