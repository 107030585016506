import React, { useEffect, useState } from 'react'
import { Link, useHistory, useParams } from 'react-router-dom'
import { favorableLinebarImg, heart2, leftArrowImg, SearchImg, starCircleImg } from '../../../utilities/images'
import { useDispatch, useSelector } from 'react-redux';
import { getExtraProductsByCategoryIdAsync, getProductByIdApiAsync, getVariantIdAsync } from './dashboardSlice';
import { selectLoginAuth } from '../auth/authSlice';
import SliderWrapperFashion from "./fashionStyle";
import Slider from 'react-slick';
import { unwrapResult } from '@reduxjs/toolkit';
import SliderWrapper from "./SliderStyle";
import namedColors from 'color-name-list';
import { createCartApiAsync, emptyCartApiAsync, getCartApiAsync, selectCartData } from '../cart/cartSlice';
import { toast } from 'react-toastify';
import CustomModal from "../../components/shared/CustomModal";
import CommonLoader from '../../components/shared/CommonLoader';
import { createProductFavoriteApiAsync } from '../favoriteList/favoriteListSlice';

const HomeStoreProduct = () => {
    const history = useHistory();
    const toastId = React.useRef(null);
    const [productData, setProductData] = useState({});
    const [extraProducts, setExtraProducts] = useState({});
    const [loading, setLoading] = useState(false);
    const { sellerId, id, flag, subCatId, sellerUserId } = useParams();
    const [newPrice, setNewPrice] = useState('');
    const [productDataLoading, setProductDataLoading] = useState(false);
    const [size, setSize] = useState('');
    const [material, setMaterial] = useState('');
    const [sizeId, setSizeId] = useState('');
    const [materialId, setMaterialId] = useState('');
    const [color, setColor] = useState('');
    const [colorId, setColorId] = useState('');
    const [variantId, setVariantId] = useState('');
    const [productId, setProductId] = useState("");
    const [extraProductId, setExtraProductId] = useState(id);
    const auth = useSelector(selectLoginAuth);
    const dispatch = useDispatch();
    const [key, setKey] = useState(Math.random());
    const [modalDetail, setModalDetail] = useState({
        show: false,
        title: "",
        flag: "",
        id: ""
    });
    const [selectedItems, setSelectedItems] = useState([]);
    const cartData = useSelector(selectCartData);

    const offersSettings = {
        dots: true,
        autoplay: true,
        autoplaySpeed: 2000,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
        speed: 500,
        arrows: false,
        adaptiveHeight: true
    };
    const recommendedSettings = {
        dots: false,
        autoplay: true,
        autoplaySpeed: 2000,
        infinite: true,
        slidesToShow: 2,
        slidesToScroll: 1,
        centerMode: true,
        centerPadding: '20px',
        initialSlide: 0,
        speed: 500,
        arrows: false,
        adaptiveHeight: true,
        appendDots: (dots) => <ul>{dots}</ul>,
    };
    let newSelectedItems = [...selectedItems]

    const attributeHandle = (ind, flag, name, id) => {
        if (flag === 'size') {
            setSize(name)
            setSizeId(id)
            newSelectedItems[ind] = id;
            setSelectedItems(newSelectedItems);
        }
        if (flag === 'color') {
            setColor(name)
            setColorId(id)
            newSelectedItems[ind] = id;
            setSelectedItems(newSelectedItems);
        }
        if (flag === 'material') {
            setMaterial(name)
            setMaterialId(id)
            newSelectedItems[ind] = id;
            setSelectedItems(newSelectedItems);
        }
        if (newSelectedItems?.filter((val) => val !== undefined).length === 3) {
            let params = {
                "postData": {
                    "attribute_value_ids": newSelectedItems?.join(','),
                    "supply_id": productData?.data?.payload?.product_detail?.supplies[0]?.id
                },
                "tokenData": auth?.payload?.token
            }
            dispatch(getVariantIdAsync(params))
                .then(unwrapResult)
                .then(async (obj) => {
                    setNewPrice(obj?.data?.payload?.price)
                    setVariantId(obj?.data?.payload?.attribute_variant_id)
                }
                )
                .catch((obj) => {
                })
        }
    }

    const removeCart = () => {
        dispatch(emptyCartApiAsync(auth?.payload?.token))
            .then(unwrapResult)
            .then(async (obj) => {
                toast.success(obj?.data?.msg)
                dispatch(getCartApiAsync(auth?.payload?.token))
                    .then(unwrapResult)
                    .then(async (obj) => {

                    }
                    )
                    .catch((obj) => {
                    })
                handleOnCloseModal()
            }
            )
            .catch((obj) => {
            })
    }
    const renderRemoveCartHtml = () => {
        return (
            <div className="modal-content">
                <div className="modal-body">
                    <div className="modal-mainprt">
                        <div className="mdlverify-cnt cardmdl-verify">
                            <h6>Items already in cart</h6>
                            <p>Your cart contains item from other services.
                                would you like to reset your cart for adding
                                items from the restaurent.
                            </p>
                        </div>
                        <div className="modal-footer" >
                            <button className="btn cancleBtn " onClick={() => handleOnCloseModal()}  >No</button>
                            <button className="btn btn-primary submitBtn" onClick={() => removeCart()}>Yes, start a fresh</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    const handleOnCloseModal = () => {
        setModalDetail({
            show: false,
            title: "",
            flag: "",
            id: ""
        });
        setKey(Math.random());
    }

    const favoriteHandle = (favId) => {
        let favData = {
            "postData": {
                "product_id": favId,
                "seller_id": sellerUserId
            },
            "tokenData": auth?.payload?.token
        }
        setProductDataLoading(true)
        dispatch(createProductFavoriteApiAsync(favData))
            .then(unwrapResult)
            .then(async (obj) => {
                toast.success(obj?.data?.msg)
                let productParams = {
                    "postData": {
                        seller_id: sellerId,
                        app_name: 'b2c',
                        delivery_options: 1,
                        id: extraProductId
                    },
                    "tokenData": auth?.payload?.token
                }
                dispatch(getProductByIdApiAsync(productParams))
                    .then(unwrapResult)
                    .then(async (obj) => {
                        setProductData(obj)
                        let extraData = {
                            "postData": {
                                category_ids: obj?.data?.payload?.product_detail?.category_id,
                                seller_id: sellerId,
                                app_name: 'b2c',
                                delivery_options: 1
                            },
                            "tokenData": auth?.payload?.token
                        }
                        dispatch(getExtraProductsByCategoryIdAsync(extraData))
                            .then(unwrapResult)
                            .then(async (obj) => {
                                setExtraProducts(obj)
                                setProductDataLoading(false)
                            }
                            )
                            .catch((obj) => {
                                setProductDataLoading(false)
                            })
                    }
                    )
                    .catch((obj) => {
                    })
            }
            )
            .catch((obj) => {
                setProductDataLoading(false)
            })
    }

    // const addToCartUlternative = (productId, colorUlternativeAttributeId, sizeUlternativeAttributeId) => {
    //     let objProduct = {
    //         "postData": {
    //             "seller_id": sellerId,
    //             "product_id": productId,
    //             "qty": 1,
    //             "attribute_ids": [colorUlternativeAttributeId, sizeUlternativeAttributeId],
    //             "attribute_value_ids": [colorId, sizeId],
    //             "service_type": "Delivery"
    //         },
    //         "tokenData": auth?.payload?.token
    //     }
    //     if ((cartData?.data?.payload?.cart_products?.length > 0) && (cartData?.data?.payload?.seller_id !== sellerId) && (cartData?.data?.payload?.service_id !== 3)) {
    //         setModalDetail({
    //             show: true,
    //             flag: "cart_exist"
    //         })
    //         setKey(Math.random());
    //     } else {
    //         if (!sizeId || !colorId || !materialId) {
    //             if (!toast.isActive(toastId.current)) {
    //                 toastId.current = toast.error("Please select all size, color and material");
    //             }
    //         } else {
    //             dispatch(createCartApiAsync(objProduct))
    //                 .then(unwrapResult)
    //                 .then(async (obj) => {
    //                     if (!toast.isActive(toastId.current)) {
    //                         toastId.current = toast.success(obj?.data?.msg);
    //                     }
    //                     dispatch(getCartApiAsync(auth?.payload?.token))
    //                 }
    //                 )
    //                 .catch((obj) => {
    //                 })
    //         }
    //     }
    // }

    const handleBag = (serviceId, supplyId, priceId, appName) => {
        if ((cartData?.data?.payload?.cart_products?.length > 0) && (cartData?.data?.payload?.seller_id !== sellerId) && (cartData?.data?.payload?.service_id !== 3)) {
            setModalDetail({
                show: true,
                flag: "cart_exist"
            })
            setKey(Math.random());
        } else {
            let objProduct
            if (productData?.data?.payload?.product_detail?.supplies[0]?.attributes?.length > 0) {
                if (!sizeId || !colorId || !materialId) {
                    if (!toast.isActive(toastId.current)) {
                        toastId.current = toast.error("Please select all size, color and material");
                    }
                    return false
                }
                objProduct = {
                    "postData": {
                        "delivery_option": "1",
                        "supply_id": supplyId,
                        "supply_price_id": priceId,
                        "seller_id": sellerId,
                        "product_id": extraProductId,
                        "qty": 1,
                        "supply_variant_id": variantId,
                        "service_id": serviceId,
                        "app_name": appName
                    },
                    "tokenData": auth?.payload?.token
                }
            } else {
                objProduct = {
                    "postData": {
                        "seller_id": sellerId,
                        "product_id": extraProductId,
                        "qty": 1,
                        "service_id": serviceId,
                        "app_name": appName,
                        "delivery_option": "1",
                        "supply_id": supplyId,
                        "supply_price_id": priceId,
                    },
                    "tokenData": auth?.payload?.token
                }
            }
            setLoading(true)
            dispatch(createCartApiAsync(objProduct))
                .then(unwrapResult)
                .then(async (obj) => {
                    dispatch(getCartApiAsync(auth?.payload?.token))
                        .then(unwrapResult)
                        .then(async (obj) => {
                            setLoading(false)
                        }
                        )
                        .catch((obj) => {
                            setLoading(false)
                        })
                    flag === "store" || flag === "favorite" ? history.push(`/home-store/${sellerId}`) : history.push(`/subcategory-products/${sellerId}/${subCatId}/${sellerUserId}`)
                }
                )
                .catch((obj) => {
                    setLoading(false)
                })
        }
    }
    const productDataHandle = (id) => {
        setProductDataLoading(true)
        let productParams = {
            "postData": {
                seller_id: sellerId,
                id: extraProductId,
                app_name: 'b2c',
                delivery_options: 1
            },
            "tokenData": auth?.payload?.token
        }
        dispatch(getProductByIdApiAsync(productParams))
            .then(unwrapResult)
            .then(async (obj) => {
                setProductData(obj)
                let extraData = {
                    "postData": {
                        category_ids: obj?.data?.payload?.product_detail?.category_id,
                        seller_id: sellerId,
                        app_name: 'b2c',
                        delivery_options: 1
                    },
                    "tokenData": auth?.payload?.token
                }
                dispatch(getExtraProductsByCategoryIdAsync(extraData))
                    .then(unwrapResult)
                    .then(async (obj) => {
                        setExtraProducts(obj)
                        setProductDataLoading(false)
                    }
                    )
                    .catch((obj) => {
                        setProductDataLoading(false)
                    })
            }
            )
            .catch((obj) => {
                setProductDataLoading(false)
            })
    }
    useEffect(() => {
        productDataHandle()
    }, [extraProductId])

    useEffect(() => {
        window.scrollTo(0, 0)
        dispatch(getCartApiAsync(auth?.payload?.token))
            .then(unwrapResult)
            .then(async (obj) => {
            }
            )
            .catch((obj) => {
            })
    }, [])

    return (
        <>
            {productDataLoading === true ?
                <CommonLoader />
                :
                <>
                    <div className="order-heading">
                        <h4><Link to="#" onClick={() => { flag === 'favorite' ? history.push(`/favoriteList`) : history.push(`/home-store/${sellerId}`) }}><img src={leftArrowImg} alt="" /></Link>Back</h4>
                        <div className="header-right-item">
                            <button type="button" onClick={() => favoriteHandle(productData?.data?.payload?.product_detail?.id)}><img src={heart2} alt="" /></button>
                            <button type="button"><img src={SearchImg} alt="" /></button>
                        </div>
                    </div>
                    <div className="main-content cart-mainpd fasmain-content">
                        <div className="fasproduct-detailsmain">
                            <div className="fasproduct-dtslider">
                                <div className="main-content1" id="fashionSlider">
                                    <SliderWrapperFashion>
                                        <Slider {...offersSettings}>
                                            {
                                                productData && productData?.data?.payload?.product_detail?.product_images?.map((val, index) => {
                                                    return (
                                                        <div key={index}>
                                                            <img src={val?.url} alt="" className='product_one' />
                                                        </div>
                                                    )
                                                })
                                            }
                                        </Slider>
                                    </SliderWrapperFashion>
                                </div>
                                <p><img src={starCircleImg} alt="" /> <strong>{productData?.data?.payload?.product_rating?.rating}</strong> ({productData?.data?.payload?.product_rating?.review_count}+ ratings)</p>
                            </div>
                            <div className="fasproduct-dtprt">
                                <div className="fasprd-dtupper">
                                    <h2>{productData?.data?.payload?.product_detail?.name}</h2>
                                    <p>{productData?.data?.payload?.product_detail?.product_type?.name}</p>
                                    <h4>Price: ${newPrice ? newPrice : productData?.data?.payload?.product_detail?.price}</h4>
                                </div>
                                {productData?.data?.payload?.product_detail?.supplies[0]?.attributes?.length > 0 ?
                                    productData?.data?.payload?.product_detail?.supplies[0]?.attributes?.map((val, ind) => {
                                        return (
                                            <React.Fragment key={ind}>
                                                {(val?.name === "Color") ?
                                                    <div className="fasprd-dtcolor">
                                                        <p>Select Color: <strong>{namedColors.find(x => x.hex === color.toLocaleLowerCase())?.name}</strong></p>
                                                        <div className="fashion-color">
                                                            {val?.values?.map((v, i) => {
                                                                return (
                                                                    <span style={{ backgroundColor: `${v?.name}` }} className={(colorId === v?.id) ? "active" : ""}
                                                                        onClick={() => attributeHandle(ind, 'color', v?.name, v?.id)}
                                                                        key={i}>
                                                                    </span>
                                                                )
                                                            })}
                                                        </div>
                                                    </div> : ""}
                                                {(val?.name === "Size") ?
                                                    <div className="fasprd-size">
                                                        <p>Select Size: <strong>{size}</strong> </p>
                                                        <ul>
                                                            {val?.values?.map((v, i) => {
                                                                return (
                                                                    <li className={(sizeId === v?.id) ? "active" : ""}
                                                                        onClick={() => attributeHandle(ind, 'size', v?.name, v?.id)}
                                                                        key={i} >
                                                                        <strong>{v?.name}</strong>
                                                                    </li>
                                                                )
                                                            })}
                                                        </ul>
                                                    </div> : ""}
                                                {(val?.name === "Material") ?
                                                    <div className="fasprd-size">
                                                        <p>Select Material: <strong>{material}</strong> </p>
                                                        <ul>
                                                            {val?.values?.map((v, i) => {
                                                                return (
                                                                    <li className={(materialId === v?.id) ? "active" : ""}
                                                                        onClick={() => attributeHandle(ind, 'material', v?.name, v?.id)}
                                                                        key={i} >
                                                                        <strong>{v?.name}</strong>
                                                                    </li>
                                                                )
                                                            })}
                                                        </ul>
                                                    </div> : ""}
                                            </React.Fragment>
                                        )
                                    })
                                    : ""}

                                <div className="fasprd-cnt">
                                    <h4>Shipping charge: <strong>$$$</strong></h4>
                                    <p>Estimated arrival within 7 business days with JOBR shipping.</p>
                                </div>

                                <div className="fasprd-btn">
                                    <button type="button" className='fevButton_' onClick={() => favoriteHandle(productData?.data?.payload?.product_detail?.id)}><i className="far fa-heart"></i> Add to Favorite</button>
                                    <button type="button" className="btn btn-primary continue border-0" disabled={loading === true} onClick={() => handleBag(productData?.data?.payload?.product_detail?.service_id, productData?.data?.payload?.product_detail?.supplies[0]?.id, productData?.data?.payload?.product_detail?.supplies[0]?.supply_prices[0]?.id, productData?.data?.payload?.product_detail?.supplies[0]?.supply_prices[0]?.app_name)}>
                                        {loading === true && (
                                            <span className="spinner-border spinner-border-sm"></span>
                                        )}
                                        &nbsp;&nbsp;
                                        <span><i className="fas fa-shopping-bag"></i> Add to Bag</span>
                                    </button>
                                </div>
                            </div>
                            <div className="fasprd-dtcontent">
                                <h2>Product Details</h2>
                                <p>{productData?.data?.payload?.product_detail?.description}</p>
                                <ul>

                                    {productData?.data?.payload?.product_detail?.product_attribute?.length > 0 ?
                                        productData?.data?.payload?.product_detail?.product_attribute?.map((val, ind) => {
                                            return (
                                                <React.Fragment key={ind}>
                                                    <div> {(val?.attributes?.name === "Sleeve Length") ? <li>Sleeve Length: <strong>{val?.attributes?.attribute_values[0].name}</strong></li> : <></>}</div>
                                                    <div> {(val?.attributes?.name === "Collar") ? <li>Collar: <strong>{val?.attributes?.attribute_values[0].name}</strong></li> : <></>}</div>
                                                    <div> {(val?.attributes?.name === "Occasion") ? <li>Occasion: <strong>{val?.attributes?.attribute_values[0].name}</strong></li> : <></>}</div>
                                                    <div> {(val?.attributes?.name === 'Print or Pattern Type') ? <li>Print or Pattern Type: <strong>{val?.attributes?.attribute_values[0].name}</strong></li> : <></>}</div>
                                                    <div> {(val?.attributes?.name === 'Lining Fabric') ? <li>Lining Fabric: <strong>{val?.attributes?.attribute_values[0].name}</strong></li> : <></>}</div>
                                                    <div> {(val?.attributes?.name === 'Type') ? <li>Lining Fabric: <strong>{val?.attributes?.attribute_values[0].name}</strong></li> : <></>}</div>
                                                </React.Fragment>
                                            )
                                        }) : ""}
                                </ul>
                            </div>
                            {productData?.data?.payload?.product_detail?.product_ingredient.length > 0 &&
                                <div className="scan-accordian-area">
                                    <div className="scan-accordian-upper">
                                        {productData?.data?.payload?.product_detail?.health_rating && <h2
                                            style={{
                                                backgroundColor: productData?.data?.payload?.product_detail?.health_rating === 1 ?
                                                    "#1AF8FF" :
                                                    productData?.data?.payload?.product_detail?.health_rating === 2 ?
                                                        "#1BE5FF" :
                                                        productData?.data?.payload?.product_detail?.health_rating === 3 ?
                                                            "#1CD3FF" :
                                                            productData?.data?.payload?.product_detail?.health_rating === 4 ?
                                                                "#1CC3FF" :
                                                                productData?.data?.payload?.product_detail?.health_rating === 5 ?
                                                                    "#1FB3FF" :
                                                                    productData?.data?.payload?.product_detail?.health_rating === 6 ?
                                                                        "#1FB3FF" :
                                                                        productData?.data?.payload?.product_detail?.health_rating === 7 ?
                                                                            "#21A0FF" :
                                                                            productData?.data?.payload?.product_detail?.health_rating === 8 ?
                                                                                "#228FFF" :
                                                                                productData?.data?.payload?.product_detail?.health_rating === 9 ?
                                                                                    "#247DFF" :
                                                                                    "#256CFE"
                                            }}
                                        >{productData?.data?.payload?.product_detail?.health_rating} <small>score</small></h2>}
                                        {productData?.data?.payload?.product_detail?.name && <h4>{productData?.data?.payload?.product_detail?.name}</h4>}
                                    </div>
                                    <div className="data-avlfr">
                                        <p>Data availability</p>
                                        <h4>Fair</h4>
                                    </div>

                                    {productData?.data?.payload?.data?.product_ingredient?.length > 0 ?
                                        <div className="scan-accortitl">
                                            <h6>Ingredient information</h6>
                                        </div>
                                        : <></>
                                    }

                                    <div className="scan-accordian-part">
                                        <div className="ziehharmonika">
                                            {productData?.data?.payload?.product_detail?.product_ingredient?.map((val, i) => {
                                                return (
                                                    <React.Fragment key={i}>
                                                        <h3 onClick={() => setProductId(productId === val?.id ? "" : val?.id)}><span
                                                            style={{
                                                                backgroundColor: val?.ingredient?.health_rating === 1 ?
                                                                    "#1AF8FF" :
                                                                    val?.ingredient?.health_rating === 2 ?
                                                                        "#1BE5FF" :
                                                                        val?.ingredient?.health_rating === 3 ?
                                                                            "#1CD3FF" :
                                                                            val?.ingredient?.health_rating === 4 ?
                                                                                "#1CC3FF" :
                                                                                val?.ingredient?.health_rating === 5 ?
                                                                                    "#1FB3FF" :
                                                                                    val?.ingredient?.health_rating === 6 ?
                                                                                        "#1FB3FF" :
                                                                                        val?.ingredient?.health_rating === 7 ?
                                                                                            "#21A0FF" :
                                                                                            val?.ingredient?.health_rating === 8 ?
                                                                                                "#228FFF" :
                                                                                                val?.ingredient?.health_rating === 9 ?
                                                                                                    "#247DFF" :
                                                                                                    "#256CFE"
                                                            }}
                                                        >{val?.ingredient?.health_rating}</span>{val?.ingredient?.name}</h3>
                                                        {
                                                            (val?.id === productId) ?
                                                                <div>
                                                                    <p>{val?.ingredient?.description}</p>
                                                                </div>
                                                                : <></>
                                                        }
                                                    </React.Fragment>
                                                )
                                            })}
                                        </div>
                                    </div>

                                    <div className="scan-accorbtm">
                                        <div className="scan-number">
                                            <span>1</span>
                                            <span className="numb-sbg2">2</span>
                                            <span className="numb-sbg3">3</span>
                                            <span className="numb-sbg4">4</span>
                                            <span className="numb-sbg5">5</span>
                                            <span className="numb-sbg6">6</span>
                                            <span className="numb-sbg7">7</span>
                                            <span className="numb-sbg8">8</span>
                                            <span className="numb-sbg9">9</span>
                                            <span className="numb-sbg10">10</span>
                                        </div>

                                        <div className="favroble-bar">
                                            <span>Favorable</span>
                                            <img src={favorableLinebarImg} alt="favorableLinebarImg" />
                                            <span>Unfavorable</span>
                                        </div>
                                    </div>
                                </div>}
                            <div className="store-slider">
                                {(extraProducts?.data?.payload?.data?.filter(item => item?.id != extraProductId)?.length > 0) ?
                                    <>
                                        <div className="store-sldhead">
                                            <h2>You may also like</h2>
                                        </div>
                                        <SliderWrapper>
                                            <Slider {...recommendedSettings} >
                                                {extraProducts?.data?.payload?.data?.filter(item => ((item?.id != extraProductId)))?.map((val, index) => {
                                                    console.log(extraProducts?.data?.payload?.data?.filter(item => ((item?.id != extraProductId))), "response of slider")
                                                    return (
                                                        <React.Fragment key={index}>
                                                            {
                                                                val?.product_images?.length > 0 ?
                                                                    <div className="carousel-cell bestSellBox_" >
                                                                        <div className="paddcart-item">
                                                                            <div className="paddcart-inner">

                                                                                <SliderWrapperFashion>
                                                                                    <Slider {...offersSettings}>
                                                                                        {
                                                                                            val?.product_images?.map((value, i) => {
                                                                                                return (
                                                                                                    <div key={i}>
                                                                                                        <img src={value?.url} alt="" className="fashion-m" />
                                                                                                    </div>
                                                                                                )
                                                                                            })
                                                                                        }
                                                                                    </Slider>
                                                                                </SliderWrapperFashion>
                                                                                <div className='sellBody_'>
                                                                                    {val?.supplies[0]?.attributes ?
                                                                                        val?.supplies[0]?.attributes?.map((item, ind) => {
                                                                                            return (
                                                                                                <React.Fragment key={ind}>
                                                                                                    {(item?.name === "Color") ?
                                                                                                        <ul className='colorBox mb-2'>
                                                                                                            {item?.values?.map((v, i) => {
                                                                                                                return (
                                                                                                                    <li style={{ backgroundColor: `${v?.name}` }} className={(colorId === v?.id) ? "active" : ""}
                                                                                                                        onClick={() => attributeHandle('color', v?.name, v?.id)}
                                                                                                                        key={i}>
                                                                                                                    </li>
                                                                                                                )
                                                                                                            })}
                                                                                                        </ul> : ""}
                                                                                                    {(item?.name === "Size") ?
                                                                                                        <ul className='fasprd-size'>
                                                                                                            {item?.values?.map((v, i) => {
                                                                                                                return (
                                                                                                                    <li className={(sizeId === v?.id) ? "active" : ""}
                                                                                                                        onClick={() => attributeHandle('size', v?.name, v?.id)}
                                                                                                                        key={i} >
                                                                                                                        <strong>{v?.name}</strong>
                                                                                                                    </li>
                                                                                                                )
                                                                                                            })}
                                                                                                        </ul> : ""}
                                                                                                    {(item?.name === "Material") ?
                                                                                                        <div className='materialBox'>
                                                                                                            {item?.values?.map((v, i) => {
                                                                                                                return (
                                                                                                                    <p className={(materialId === v?.id) ? "active" : ""}
                                                                                                                        onClick={() => attributeHandle('material', v?.name, v?.id)}
                                                                                                                        key={i} >
                                                                                                                        <strong>{v?.name}</strong>
                                                                                                                    </p>
                                                                                                                )
                                                                                                            })}
                                                                                                        </div> : ""}
                                                                                                </React.Fragment>
                                                                                            )
                                                                                        })
                                                                                        : ""}
                                                                                    <h4 className='smallHeading'>{val?.name}</h4>
                                                                                    <p className='smallHeading mb-2'>{val?.brand?.name}</p>
                                                                                    <p className='boldText_'>${val?.price}</p>
                                                                                    <div className='addBox_' >
                                                                                        <i className="fas fa-plus"></i>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    :
                                                                    <></>
                                                            }

                                                        </React.Fragment>
                                                    )
                                                })}
                                            </Slider>
                                        </SliderWrapper>
                                    </>
                                    : <></>}
                            </div>

                        </div>
                    </div></>}
            <CustomModal
                key={key}
                show={modalDetail.show}
                backdrop="static"
                showCloseBtn={false}
                isRightSideModal={false}
                mediumWidth={modalDetail.flag === "cart_exist" ? false : true}
                darkBackground={modalDetail.flag === "cart_exist" ? true : false}
                size="lg"
                child={modalDetail.flag === "cart_exist" ?
                    renderRemoveCartHtml() : <></>
                }
                onCloseModal={() => handleOnCloseModal()}
            />
        </>
    )
}

export default HomeStoreProduct
