import React, { useState, useEffect } from 'react'
import { useHistory, Link, useParams } from 'react-router-dom'
import { leftLightImg } from '../../../utilities/images'
import { withGoogleMap, GoogleMap, withScriptjs, Marker, InfoWindow ,DirectionsRenderer } from "react-google-maps";
import { getProductListAsync } from './dashboardSlice';
import { useSelector, useDispatch } from 'react-redux';
import { selectLoginAuth } from '../auth/authSlice';
import { unwrapResult } from '@reduxjs/toolkit';
import { getAddressFromCoordinates } from '../addresses/AddressMethods';
import CommonLoader from "../../components/shared/CommonLoader";



const StoreDirections = () => {
    const history = useHistory();
    // const [directions, setDirections] = useState();
    const [latitude, setLatitude] = useState(null);
    const [longitude, setLongitude] = useState(null);
    const [zoom, setZoom] = useState(15);
    const { id } = useParams();
    const [productDataLoading, setProductDataLoading] = useState(false);
    const [productList, setProductList] = useState({});
    const auth = useSelector(selectLoginAuth);
    const dispatch = useDispatch();

    const geocodeHandler = () => {
        navigator.geolocation.getCurrentPosition(function (position) {
            getAddressFromCoordinates(position.coords.latitude, position.coords.longitude)
                .then(data => {
                    setLongitude(position.coords.longitude)
                    setLatitude(position.coords.latitude)
                })
                .catch(err => console.log('error getting address', err));
        });
    }

    const productDataHandle = () => {
        setProductDataLoading(true)
        let sellerProductParams = {
            "postData": {
                sellerId: id,
                lat: 32.5534,
                long: 76.1258
            },
            "tokenData": auth?.payload?.token
        }
        dispatch(getProductListAsync(sellerProductParams))
            .then(unwrapResult)
            .then(async (obj) => {
                setProductList(obj)
                setProductDataLoading(false)
            }
            )
            .catch((obj) => {
                setProductDataLoading(false)
            })
    }
    const AsyncMap = withScriptjs(
        withGoogleMap(
            props => (
                <GoogleMap
                    defaultZoom={15}
                    defaultCenter={{ lat: productList?.data?.payload?.sellerCurrentLocation?.latitude, lng: productList?.data?.payload?.sellerCurrentLocation?.longitude }}
                >
                    <DirectionsRenderer
                        // directions={directions}
                    />
                </GoogleMap>
            )
        )
    );
    useEffect(() => {
        productDataHandle()
        geocodeHandler()
    }, [])

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

   
    return (
        <>
            {productDataLoading === true ?
                <CommonLoader />
                :
                <>
                    <div className="order-heading">
                        <div className="header-left-item">
                            <h4><Link to="#" onClick={() => { history.push(`/home-store/${id}`) }}><img src={leftLightImg} alt="leftArrowImg" /></Link>Back</h4>
                        </div>
                    </div>
                    <div className="map_box">
                        {/* <img src={addressMapImg} alt="addressMapImg" /> */}
                        <AsyncMap
                            googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_MAP_KEY}&libraries=places`}
                            loadingElement={
                                <div style={{ height: `100%` }} />
                            }
                            containerElement={
                                <div style={{ height: '700px' }} />
                            }
                            mapElement={
                                <div style={{ height: `100%` }} />
                            }
                        />
                    </div>
                    <div className="result-sec directstoreMap" style={{ marginTop: "70px" }}>
                        <div className="result-secleft">
                            <img src={productList?.data?.payload?.sellerDetails?.user_profiles?.profile_photo} alt="shopImg" />
                        </div>
                        <div className="result-secright">
                            <h4>{productList?.data?.payload?.sellerDetails?.user_profiles?.username}</h4>
                            <p><i className="fal fa-map-marker-alt"></i> {productList?.data?.payload?.distance?.timeString}({productList?.data?.payload?.distance?.inKM}Km)</p>
                            <button className='directBtn'> Direction <i className="fas fa-paper-plane"></i></button>
                        </div>
                    </div>
                </>}

        </>
    )
}

export default StoreDirections
