import React, { useState, useEffect } from "react";
import { Link, useHistory } from 'react-router-dom';
import { leftArrowImg, galleryImg, uploadImg, coinStackImg } from "../../../utilities/images";
import { selectLoginAuth } from "../auth/authSlice";
import { getSubjectApiAsync, getSupportApiAsync, selectSubjectData, selectSupportData, selectUploadedUrlData, supportApiUrlAsync, supportCreateApiAsync } from "../../containers/supportRequest/SupportRequestSlice"
import { useDispatch, useSelector } from "react-redux";
import moment from 'moment-timezone'
import PaginationSetting from '../../constants/PaginationSetting';
import { Box } from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import { unwrapResult } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import CommonLoader from '../../components/shared/CommonLoader'
import { selectWalletBalance } from "../wallet/walletSlice";

const SupportRequest = (props) => {
    const history = useHistory();
    const [tabData, setTabData] = useState(1);
    const auth = useSelector(selectLoginAuth);
    const [document, setDocument] = useState("");
    const [subjectValue, setSubjectValue] = useState("");
    const [description, setDescription] = useState("");
    const [supportImage, setSupportImage] = useState("");
    const supportData = useSelector(selectSupportData);
    const subjectData = useSelector(selectSubjectData);
    const [loading, setLoading] = useState(false);
    const [supportDataLoading, setSupportDataLoading] = useState(false);
    const dispatch = useDispatch();
    const [pagination, setPagination] = useState(PaginationSetting);
    const toastId = React.useRef(null);
    const walletData = useSelector(selectWalletBalance);
    const [page, setPage] = useState(1);
    const uploadedUrlData = useSelector(selectUploadedUrlData);
    let fullname = `${auth?.payload?.user_profiles?.firstname}`+" "+`${auth?.payload?.user_profiles?.lastname}`
    // let fullname = `${auth?.payload?.name}`

    const handleChange = (e) => {
        const imageFile = e.target.files[0];
        let params = {
            document: imageFile
        }
        setDocument(URL.createObjectURL(imageFile));
        dispatch(supportApiUrlAsync(params))
            .then(unwrapResult)
            .then(async (obj) => {
                setSupportImage(obj?.data?.payload?.document)
            }
            )
            .catch((obj) => {
            })
    }

    let data = {
        "postData": {
            "subject_id": Number(subjectValue),
            "email": auth?.payload?.email ? auth?.payload?.email : "",
            "name": fullname,
            "document_url": [
                {
                    "url": uploadedUrlData?.data?.payload?.document
                }
            ],
            "notes": description,
            "type": "support"
        },
        "tokenData": auth?.payload?.token
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        if (!subjectValue) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error("Please select subject type");
            }
            return;
        }
        if (!description) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error("Please enter a note");
            }
            return;
        }
        if (!supportImage) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error("Please select image");
            }
            return;
        }
        setLoading(true)
        dispatch(supportCreateApiAsync(data))
            .then(unwrapResult)
            .then(async (obj) => {
                setTabData(1)
                setDocument("")
                setDescription("")
                const data = {
                    "postData": {
                        page: page,
                        limit: pagination.recordsPerPage
                    },
                    "tokenData": auth?.payload?.token
                }
                setSupportDataLoading(true)
                dispatch(getSupportApiAsync(data))
                    .then(unwrapResult)
                    .then(async (obj) => {
                        setSupportDataLoading(false)
                        setPagination((prevState) => ({ ...prevState, totalPages: obj?.data?.payload?.total_pages, totalRecords: obj?.data?.payload?.total }));
                    }
                    )
                    .catch((obj) => {
                        setSupportDataLoading(false)
                    })
                setLoading(false)
            }
            )
            .catch((obj) => {
                setLoading(false)
            })
        // history.push("/my-support-request")
    }

    useEffect(() => {
        dispatch(getSubjectApiAsync())
    }, []);

    useEffect(() => {
        const data = {
            "postData": {
                page: page,
                limit: pagination.recordsPerPage
            },
            "tokenData": auth?.payload?.token
        }
        setSupportDataLoading(true)
        dispatch(getSupportApiAsync(data))
            .then(unwrapResult)
            .then(async (obj) => {
                setSupportDataLoading(false)
                setPagination((prevState) => ({ ...prevState, totalPages: obj?.data?.payload?.total_pages, totalRecords: obj?.data?.payload?.total }));
            }
            )
            .catch((obj) => {
                setSupportDataLoading(false)
            })
        window.scrollTo(0, 0)
    }, [page])

    return (
        <>
            {supportDataLoading === true ?
                <CommonLoader />
                :
                <>
                    <div className="order-heading">
                        <div className="header-left-item">
                            <h4><Link to='/help-center'><img src={leftArrowImg} alt="leftArrowImg" /></Link> My support request</h4>
                        </div>
                        <div className="header-right-item">
                            <Link to="/wallet" className="coinStack">{walletData ? walletData?.data?.payload?.sila_balance : 0}
                                <img src={coinStackImg} alt="coinStack" />
                            </Link>
                        </div>
                    </div>

                    <div className="main-content">
                        <div className="support-reqtab">
                            <div className="tabs">
                                <div className="tab-upper">
                                    <ul className="tab-links">
                                        <li className={(tabData === 1) ? 'active' : ''} onClick={() => setTabData(1)}><Link to="#">My support ({supportData?.data?.payload?.total > 0 ? supportData?.data?.payload?.total : 0})</Link></li>
                                        <li className={(tabData === 2) ? 'active' : ''} onClick={() => setTabData(2)}><Link to="#">Support</Link></li>
                                    </ul>
                                </div>

                                <div className="tab-content">
                                    {(tabData === 1) && (
                                        <>
                                            <div id="tab1" className="tab active">
                                                <>
                                                    {supportData?.data?.payload?.data?.length ?
                                                        <>
                                                            {supportData?.data?.payload?.data?.map(
                                                                (value, index) => {
                                                                    return (
                                                                        <React.Fragment key={index}>
                                                                            <div>
                                                                                <div className="support-rewtab1">
                                                                                    <h2 style={{ cursor: "pointer" }} onClick={() => { history.push({ pathname: `my-support-request-details/${value?.id}`, state: value }) }}>
                                                                                        #{value?.track_number} <span>{value?.status?.name}</span>
                                                                                    </h2>
                                                                                    <div className="support-reqinner">
                                                                                        {/* <span>{value?.subject?.name}</span> */}
                                                                                        <h4>
                                                                                            <strong>{value?.subject?.name}</strong>
                                                                                        </h4>
                                                                                        <p>{value?.notes}</p>
                                                                                    </div>
                                                                                    {
                                                                                        value?.support_comments?.length > 0 ?
                                                                                            value?.support_comments?.map((value, ind) => {
                                                                                                return (
                                                                                                    <div className="support-reqbtm" key={ind}>
                                                                                                        <span>Last Respond</span>
                                                                                                        <div className="support-reqbtmitem">
                                                                                                            <img src={value?.user_id_details?.profile_photo ? value?.user_id_details?.profile_photo : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png"} alt="" />
                                                                                                            <div className="support-reqbtmcnt">
                                                                                                                <h4>{value?.user_id_details?.firstname}{" "}{value?.user_id_details?.lastname}</h4>
                                                                                                                <p>
                                                                                                                    {moment(value?.created_at).format('MMM DD, YYYY')} {" "}|{" "} {moment(value?.created_at).format('hh:mm A')}
                                                                                                                    {value?.lastResponseData?.time}
                                                                                                                </p>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                )
                                                                                            })
                                                                                            : ""
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        </React.Fragment>
                                                                    );
                                                                }
                                                            )}
                                                        </> : ""}
                                                </>
                                            </div>
                                        </>
                                    )}

                                    {(tabData === 2) && (
                                        <>
                                            <div>
                                                <div className="support-rewtab2">
                                                    <div className="more-help-part">
                                                        <div className="more-help-upper">
                                                            <h4>Open new ticket</h4>
                                                            <p>Our gateway is routed directly to Telstra, with Optus and Vodafone networks service.</p>
                                                        </div>

                                                        <div className="more-help-form">
                                                            <form action="#" method="POST" >
                                                                <input
                                                                    className="user"
                                                                    type="text"
                                                                    value={fullname}
                                                                    disabled
                                                                />
                                                                <input
                                                                    className="email"
                                                                    type="text"
                                                                    value={auth?.payload?.email ? auth?.payload?.email : ""}
                                                                    disabled
                                                                />
                                                                <select name="subject" defaultValue={"Select Subject"} onChange={(e) => setSubjectValue(e.target.value)}>
                                                                    <option value="Select Subject" disabled>Select Subject</option>
                                                                    {subjectData?.data?.payload?.data?.map((value, index) => {
                                                                        return (
                                                                            <option value={value?.id} key={index}>{value?.name}</option>
                                                                        )
                                                                    })}
                                                                </select>

                                                                <textarea
                                                                    name="description"
                                                                    cols="30"
                                                                    rows="6"
                                                                    placeholder="Write here"
                                                                    value={description}
                                                                    onChange={e => setDescription(e.target.value)}
                                                                ></textarea>
                                                                <div className="upload-btn-wrapper upload-btn2 upload-btn-wrapper2">
                                                                    <button className="filebtn">
                                                                        <span className="upload-cnt">
                                                                        </span>
                                                                        <span className="upload-item">
                                                                            <img src={document ? document : galleryImg} alt="" className={document ? "support_img" : ""} />
                                                                            <img src={document ? "" : uploadImg} alt="" className={document ? "support_img" : ""} />
                                                                            <span>{document ? "" : "Upload your files here"}</span>
                                                                        </span>
                                                                    </button>
                                                                    <input type="file" name="myfile" onChange={e => handleChange(e)} />
                                                                </div>
                                                                <button type="button" className="submit-btn" disabled={loading} onClick={e => handleSubmit(e)}>
                                                                    {loading === true && (
                                                                        <span className="spinner-border spinner-border-sm"></span>
                                                                    )}
                                                                    &nbsp;&nbsp;
                                                                    <span>Submit</span>
                                                                </button>
                                                            </form>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </>)}
                                </div>
                            </div>
                        </div>
                    </div>
                </>}
            {pagination.totalRecords > pagination.recordsPerPage &&
                <Box py={3} display="flex" justifyContent="center" className="pagination_content">
                    <Pagination
                        key={page}
                        count={pagination.totalPages || 0}
                        color="secondary"
                        variant="outlined"
                        onChange={(e, value) => setPage(value)}
                        defaultPage={page}
                    />
                </Box>
            }
        </>
    );
}

export default SupportRequest;