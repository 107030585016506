import React, { useEffect, useState } from 'react';
import { bagImg, heart2, leftArrowImg, categoryImg, SearchImg } from '../../../utilities/images';
import Badge from "@material-ui/core/Badge";
import { Link, useHistory, useParams } from 'react-router-dom';
import { getBrandListAsync, getCategoryApiAsync } from './dashboardSlice';
import { useDispatch, useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { selectLoginAuth } from '../auth/authSlice';
import CommonLoader from "../../components/shared/CommonLoader";
import CustomModal from "../../components/shared/CustomModal";
import ProductModal from "./ProductModal";
import PaginationSetting from '../../constants/PaginationSetting';
import { Box } from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";

const SubcategoryListing = () => {
    const history = useHistory()
    const [pagination, setPagination] = useState(PaginationSetting);
    const [page, setPage] = useState(1);
    const { id, flag, categoryName, subCatId } = useParams();
    const [subCategoryData, setSubCategoryData] = useState({});
    const [brandData, setBrandData] = useState({});
    const [categoryLoading, setCategoryLoading] = useState(false);
    const [count, setCount] = useState("");
    const [categorySelected, setCategorySelected] = useState('');
    const [subcategoryId, setSubcategoryId] = useState(subCatId);
    const auth = useSelector(selectLoginAuth);
    const dispatch = useDispatch();
    const [key, setKey] = useState(Math.random());
    const [modalDetail, setModalDetail] = useState({
        show: false,
        title: "",
        flag: ""
    });
    const handleOnCloseModal = () => {
        setModalDetail({
            show: false,
            title: "",
            flag: ""
        });
        setKey(Math.random());
    }

    const subCategoryDataHandle = () => {
        let categoryParam = {
            "postData": {
                category_id: id,
                main_category: true,
                b2b_service_type: flag
            },
            "tokenData": auth?.payload?.token
        }
        setCategoryLoading(true)
        dispatch(getCategoryApiAsync(categoryParam))
            .then(unwrapResult)
            .then((obj) => {
                setSubCategoryData(obj)
                let subCategoryParams = {
                    "postData": {
                        page: page,
                        limit: pagination.recordsPerPage,
                        main_category: true,
                        b2b_service_type: flag,
                        category_id: subcategoryId ? subcategoryId : obj?.data?.payload?.data[0]?.id
                    },
                    "tokenData": auth?.payload?.token
                }
                setCategorySelected(subcategoryId ? obj?.data?.payload?.data?.find(item => item?.id == subcategoryId)?.name : obj?.data?.payload?.data[0]?.name)
                setSubcategoryId(subcategoryId ? obj?.data?.payload?.data?.find(item => item?.id == subcategoryId)?.id : obj?.data?.payload?.data[0]?.name)
                dispatch(getBrandListAsync(subCategoryParams))
                    .then(unwrapResult)
                    .then(async (obj) => {
                        setBrandData(obj)
                        setPagination((prevState) => ({ ...prevState, totalPages: obj?.data?.payload?.total_pages, totalRecords: obj?.data?.payload?.total }));
                        setCategoryLoading(false)
                    }
                    )
                    .catch((obj) => {
                        setCategoryLoading(false)
                    })
            }
            )
            .catch((obj) => {
                setCategoryLoading(false)
            })
    }

    const handleBrandsBySubcategoryId = async (data) => {
        setSubcategoryId(data?.id)
        setCategorySelected(data?.name)
        setCategoryLoading(true)
        let subCategoryParams = {
            "postData": {
                page: page,
                limit: pagination.recordsPerPage,
                main_category: true,
                b2b_service_type: flag,
                category_id: data.id
            },
            "tokenData": auth?.payload?.token
        }
        await dispatch(getBrandListAsync(subCategoryParams))
            .then(unwrapResult)
            .then(async (obj) => {
                setBrandData(obj)
                setPagination((prevState) => ({ ...prevState, totalPages: obj?.data?.payload?.total_pages, totalRecords: obj?.data?.payload?.total }));
                setCategoryLoading(false)
            }
            )
            .catch((obj) => {
                setCategoryLoading(false)

            })
    }

    useEffect(() => {
        subCategoryDataHandle()
        window.scrollTo(0, 0)
    }, [])
    return (
        <>
            {
                categoryLoading === true ?
                    <CommonLoader />
                    :
                    <>
                        <div className="header-part browse-addprdcard-header">
                            <div className='row align-items-center'>
                                <div className="header-left-item col-6 col-md-6">
                                    <div className="header-left">
                                        <img style={{ cursor: "pointer" }} onClick={() => history.push(`/home-category/${id}/${flag}/${categoryName}`)} src={leftArrowImg} alt="leftArrow" className='me-1' />
                                        <h6 className='subhead_'>{categoryName}</h6>
                                    </div>
                                </div>
                                <div className="header-right-item col-6 col-md-6 text-end">
                                    <button type="button" >
                                        <img src={heart2} alt="bellImg" />
                                    </button>
                                    {
                                        count !== "" ?
                                            <Badge overlap="rectangular" color="secondary" badgeContent={count}>
                                                <button type="button" onClick={() => history.push('/cart')}><img src={bagImg} alt="bagImg" /></button>
                                            </Badge>
                                            :
                                            <button type="button" onClick={() => history.push('/browse')} ><img src={SearchImg} alt="SearchImg" /></button>
                                    }
                                </div>
                            </div>
                        </div>

                        {<div className="maphm-btmprt">
                            <div className="maphm-btmlinks">
                                <ul className="card_category">
                                    <li className='card_categoryItems'>
                                        <img onClick={() => {
                                            setModalDetail({
                                                show: true,
                                                flag: "products"
                                            });
                                            setKey(Math.random())
                                        }} src={categoryImg} alt='' className='allIcon' /></li>
                                    {
                                        subCategoryData?.data?.payload?.data?.map((val, index) => {
                                            return (
                                                <li key={index} onClick={() => { handleBrandsBySubcategoryId(val) }} className='card_categoryItems' style={{ zIndex: subCategoryData?.data?.payload?.data?.length - index }}>
                                                    <Link to="#" className={(categorySelected === val?.name) ? `active` : ''}>
                                                        <img src={val?.image} alt="catImg" />
                                                        {val?.name}
                                                    </Link></li>
                                            )
                                        })
                                    }
                                </ul>
                            </div>
                        </div>}
                        <div className="main-content">
                            <div className="tobacco-mainitem">
                                <ul>
                                    {
                                        brandData?.data?.payload?.data ?
                                            brandData?.data?.payload?.data?.map((val, index) => {
                                                return (
                                                    <div style={{ cursor: "pointer" }} key={index} onClick={() => history.push(`/seller-list/${id}/${subcategoryId}/${val?.id}/${flag}/${categoryName}/${categorySelected}/${subCategoryData?.data?.payload?.data[0]?.service?.name}`)} >
                                                        <div className='innerProductContainer'>
                                                            <div className='innerProductArea'>
                                                                <img src={val?.image} alt='tob1Img' />
                                                            </div>
                                                            <ul>
                                                                <li>
                                                                    <h5 className='subHeading'>{val?.name} </h5>
                                                                </li>
                                                                <li className='mt-1 mb-1'>
                                                                    <p className='subText'>
                                                                        21 Brands
                                                                    </p>
                                                                </li>
                                                                <li>
                                                                    <p className='innerText'>
                                                                        55 Products
                                                                    </p>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>

                                                )
                                            })
                                            : <span>No Data Found</span>
                                    }
                                </ul>
                            </div>
                        </div>
                    </>}
            <CustomModal
                key={key}
                show={modalDetail.show}
                // backdrop="static"
                showCloseBtn={false}
                ids="productInner"
                isRightSideModal={false}
                mediumWidth={true}
                child={modalDetail.flag === 'products'
                    ? <ProductModal flag="others" categoryData={subCategoryData?.data?.payload?.data} setSubcategoryId={(e) => handleBrandsBySubcategoryId(e)} categoryLoading={categoryLoading} closeModal={() => handleOnCloseModal()} details={(e) => handleOnCloseModal(e)} />
                    : ""

                }
                onCloseModal={() => handleOnCloseModal()}
            />
            {pagination.totalRecords > pagination.recordsPerPage &&
                <Box py={3} display="flex" justifyContent="center">
                    <Pagination
                        key={page}
                        count={pagination.totalPages || 0}
                        color="secondary"
                        variant="outlined"
                        onChange={(e, value) => setPage(value)}
                        defaultPage={page}
                    />
                </Box>
            }
        </>
    )
}

export default SubcategoryListing
