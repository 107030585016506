import axios from "axios";
import { toast } from "react-toastify";
const queryString = require('query-string');

export function getWalletData(token) {
    return new Promise((resolve, reject) =>
        axios
            .get(`${process.env.REACT_APP_STAGING_WALLET_URL}api/v1/wallets/balance`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token,
                    'app-name': 'b2c'
                }
            })
            .then(function (response) {
                resolve(response);
            })
            .catch(function (error) {
                reject(error)
                console.error(error);
            })
    )
}
export function addWalletBalance(data,token) {
    return new Promise((resolve, reject) =>
        axios
            .post(`${process.env.REACT_APP_STAGING_WALLET_URL}api/v1/transactions/issue-sila`, data,{
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token,
                    'app-name': 'b2c'
                }
            })
            .then(function (response) {
                resolve(response);
            })
            .catch(function (error) {
                reject(error)
                console.error(error);
            })
    )
}
export function getTransectionHistory(data,token) {
    let query = queryString.stringify(data)
    return new Promise((resolve, reject) =>
        axios
            .get(`${process.env.REACT_APP_STAGING_WALLET_URL}api/v1/transactions/user`+'?'+query, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token,
                    'app-name': 'b2c'
                }
            })
            .then(function (response) {
                resolve(response);
            })
            .catch(function (error) {
                reject(error)
                console.error(error);
            })
    )
}
