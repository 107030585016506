import React from 'react'
import { veirfy2Img } from '../../../utilities/images';
import { Link, useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const PaymentAddedModal = (props) => {
    const history = useHistory();
    return (
        <>
            <div className="modal-body">
                {/* <div className="modal-header">
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() =>{props.closeModal()}}>
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div> */}
                <div className="modal-body">
                    <div className="modal-mainprt">
                        <div className="mdlverify-cnt cardmdl-verify">
                            <h4>Done!</h4>
                            <img src={veirfy2Img} alt="veirfy2Img" />
                            <p>USD {props.amount}.00 paid</p>
                            <h6>Successfully {props?.amount}.00 JBR added</h6>
                        </div>
                        <div className="mdlverify-btn mdllogin-btn" onClick={props?.flag === "payment" ? () => history.push('/wallet') : () => history.push('/placing-1')}>
                            <Link to="#" className="active" data-dismiss="modal" aria-label="Close">Back to cart</Link>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PaymentAddedModal;
