import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { bagImg, bellImg, CalenderDark2, ClockDark2, leftArrowImg } from '../../../utilities/images';
import CustomModal from '../../components/shared/CustomModal';
import ReserveTimeModal from './ReservationTimeModal';
import ReserveDateModal from './ReservationDateModal';
import moment from 'moment-timezone';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { toast } from "react-toastify";

const AppointmentTime = () => {
    const history = useHistory()
    const { id, sellerId, flag } = useParams();
    const [key, setKey] = useState(Math.random());
    const currDate = new Date();
    const toastId = React.useRef(null)
    const [dateValue, setDateValue] = useState(moment(currDate).format("MMMM DD, YYYY"))
    const [slotStartTime, setSlotStartTime] = useState("")
    const [slotEndTime, setSlotEndTime] = useState("")
    const [modalDetail, setModalDetail] = useState({
        show: false,
        title: "",
        flag: ""
    });

    let bookingData = {
        "dateValue": dateValue,
        "slotStartTime": slotStartTime,
        "slotEndTime": slotEndTime,
    }
    const handleOnCloseModal = () => {
        setModalDetail({
            show: false,
            title: "",
            flag: ""
        });
        setKey(Math.random());
    }
    const handleSlotDetails = (data) => {
        setSlotStartTime(data.startTime)
        setSlotEndTime(data.endTime)
    }
    const handleDateAsync = (data) => {
        // setDateValue(moment(data).format('DD-MM-YYYY'))
        setDateValue(moment(data).format('MMMM DD, YYYY'))
    }
    const handleModalDetails = (data) => {
        if (data === 'time' || data === "date") {
            setModalDetail({ show: true, flag: data }); setKey(Math.random());
        }
    }

    const handleResrve = () => {
        if (!slotStartTime && !slotEndTime) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error("Please Select Slot time")
                return false
            }
        }
        if (flag === 'edit') {
            
        } else {
            history.push({ pathname: `/cart-services/${id}/${sellerId}`, state: bookingData })
        }
    }

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <>
            <div className="order-heading">
                <h4><Link to="#" onClick={() => history.push(`/cart-services/${id}/${sellerId}`)}><img src={leftArrowImg} alt="" /></Link></h4>
                <div className="header-right-item">
                    <button type="button" onClick={() => history.push('/notifications')}><img src={bellImg} alt="" /></button>
                    <button type="button"><img src={bagImg} alt="" /></button>
                </div>
            </div>

            <div className="main-content cart-mainpd">
                <div className="bodymsg-appoint-time">
                    <div className="appoint-timemain">
                        <h2>Appointment time</h2>
                        <p>When do you want to book time?</p>
                        <ul>
                            <li onClick={() => {
                                setModalDetail({
                                    show: true,
                                    flag: "date"
                                });
                                setKey(Math.random());
                            }}>
                                <Link to="#">
                                    <div className="appont-tmleft">
                                        <h4><img src={CalenderDark2} alt="" />Appointment</h4>
                                    </div>
                                    <div className="appont-tmright">
                                        <span>Today,{dateValue}<i className="fas fa-chevron-right"></i></span>
                                    </div>
                                </Link>
                            </li>
                            <li
                                onClick={() => {
                                    setModalDetail({
                                        show: true,
                                        flag: "time"
                                    });
                                    setKey(Math.random());
                                }}>
                                <Link to="#">
                                    <div className="appont-tmleft">
                                        <h4><img src={ClockDark2} alt="" /> Time</h4>
                                    </div>
                                    <div className="appont-tmright" >
                                        <span>{slotStartTime && slotEndTime ? slotStartTime + "-" + slotEndTime : "please select time slot"}<i className="fas fa-chevron-right"></i></span>
                                    </div>
                                </Link>
                            </li>
                        </ul>
                    </div>

                    <div className="reserve-btn mt-auto" onClick={() => handleResrve()}>
                        <Link to="#">Reserve</Link>
                    </div>
                </div>
            </div>
            <CustomModal
                key={key}
                show={modalDetail.show}
                backdrop="static"
                showCloseBtn={true}
                isRightSideModal={true}
                size="md"
                child={(modalDetail.flag === 'time') ?
                    <ReserveTimeModal close={() => handleOnCloseModal()} details={(e) => handleModalDetails(e)} sellerId={sellerId} handleSlot={(e) => handleSlotDetails(e)} date={dateValue} />
                    : (modalDetail.flag === 'date') ? <ReserveDateModal close={() => handleOnCloseModal()} details={(e) => handleModalDetails(e)} getDate={(e) => handleDateAsync(e)} />
                        : ""
                }
                onCloseModal={() => handleOnCloseModal()}
            />
        </>
    )
}

export default AppointmentTime