import axios from "axios";
import moment from "moment-timezone";
import { toast } from "react-toastify";

export function getCartApi(data) {
    return new Promise((resolve, reject) =>
        axios
            .get(`${process.env.REACT_APP_STAGING_ORDER_URL}api/v1/carts/user`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': data,
                    'app-name': 'b2c'
                }
            })
            .then(function (response) {
                resolve(response);
            })
            .catch(function (error) {
                reject(error)
                console.error(error);
            })
    )
}
export function createCartApi(data, token) {
    return new Promise((resolve, reject) =>
        axios
            .post(`${process.env.REACT_APP_STAGING_ORDER_URL}api/v1/carts`, data,{
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token,
                    'app-name': 'b2c'
                }
            })
            .then(function (response) {
                resolve(response);
                toast.success(response?.data?.msg)
            })
            .catch(function (error) {
                reject(error)
                toast.error(error?.response?.data?.msg)
                console.error(error);
            })
    )
}
export function removeOneCartProductApi(data, token) {
    return new Promise((resolve, reject) =>
        axios
            .delete(`${process.env.REACT_APP_STAGING_ORDER_URL}api/v1/carts/${data.cart_id}/${data.product_id}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token,
                    'app-name': 'b2c'
                }
            })
            .then(function (response) {
                resolve(response);
                toast.success(response?.data?.msg)
            })
            .catch(function (error) {
                reject(error)
                toast.error(error?.response?.data?.msg)
                console.error(error);
            })
    )
}
export function createOrderApi(data, token) {
    return new Promise((resolve, reject) =>
        axios
            .post(`${process.env.REACT_APP_STAGING_ORDER_URL}api/v1/orders`, data,{
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token,
                    'app-name': 'b2c'
                }
            })
            .then(function (response) {
                resolve(response);
                // toast.success(response?.data?.msg)
            })
            .catch(function (error) {
                reject(error)
                toast.error(error?.response?.data?.msg)
                console.error(error);
            })
    )
}

export function handleCartResponseApi(data) {
    return data
}

export function handleCartSubmitResponseApi(data) {
    return data
}

export function emptyCartApi(token) {
    return new Promise((resolve, reject) =>
        axios
            .delete(`${process.env.REACT_APP_STAGING_ORDER_URL}api/v1/carts`,{
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token,
                    'app-name': 'b2c'
                }
            })
            .then(function (response) {
                resolve(response);
                // toast.success(response?.data?.msg)
            })
            .catch(function (error) {
                reject(error)
                toast.error(error?.response?.data?.msg)
                console.error(error);
            })
    )
}
export function createServiceCartApi(data, token) {
    return new Promise((resolve, reject) =>
        axios
            .post(`${process.env.REACT_APP_STAGING_ORDER_URL}api/v1/appointment_carts`, data,{
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token,
                    'app-name': 'b2c'
                }
            })
            .then(function (response) {
                resolve(response);
                // toast.success(response?.data?.msg)
            })
            .catch(function (error) {
                reject(error)
                toast.error(error?.response?.data?.msg)
                console.error(error);
            })
    )
}
export function getServiceCartApi(token) {
    return new Promise((resolve, reject) =>
        axios
            .get(`${process.env.REACT_APP_STAGING_ORDER_URL}api/v1/appointment_carts/user`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token,
                    'app-name': 'b2c'
                }
            })
            .then(function (response) {
                resolve(response);
            })
            .catch(function (error) {
                reject(error)
                console.error(error);
            })
    )
}
export function removeOneServiceCartProductApi(data, token) {
    return new Promise((resolve, reject) =>
        axios
            .delete(`${process.env.REACT_APP_STAGING_ORDER_URL}api/v1/appointment_carts/${data.cart_id}/${data.product_id}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token,
                    'app-name': 'b2c'
                }
            })
            .then(function (response) {
                resolve(response);
                // toast.success(response?.data?.msg)
            })
            .catch(function (error) {
                reject(error)
                toast.error(error?.response?.data?.msg)
                console.error(error);
            })
    )
}
export function createServiceOrderApi(data, token) {
    return new Promise((resolve, reject) =>
        axios
            .post(`${process.env.REACT_APP_STAGING_ORDER_URL}api/v1/appointments`, data,{
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token,
                    'app-name': 'b2c',
                    'timezone' : (moment.tz.guess() == 'Asia/Calcutta') ? 'Asia/Kolkata' : moment.tz.guess()
                }
            })
            .then(function (response) {
                resolve(response);
                // toast.success(response?.data?.msg)
            })
            .catch(function (error) {
                reject(error)
                toast.error(error?.response?.data?.msg)
                console.error(error);
            })
    )
}