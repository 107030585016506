import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
    getHomeData,
    getCategories,
    getTrendingNearBySeller,
    getProductListBySupplier,
    createBookingApi,
    getBookingSlot,
    getProductByIdApi,
    getBannerByServiceType,
    getAllOrderByUser,
    getReorderByUser,
    cancelOrderByUser,
    ratingFeedbackByUser,
    getExtraProductsByCategoryId,
    getBrandOfCategory,
    getProductServiceCount,
    getCompanyReviewsDetails,
    getVariantId,
    getAttributesForFilter
} from "./dashboardApi";

const initialState = {
    products:[],
     status: {
        products:"idle"
    },
};

export const getBrandListAsync = createAsyncThunk("dashboard/brandList", async (data) => {
    const response = await getBrandOfCategory(data.postData, data.tokenData);
    return response;
});
export const getHomeDataApiAsync = createAsyncThunk("dashboard/homedata1", async (data) => {
    const response = await getHomeData(data);
    return response;
});
export const getCategoryApiAsync = createAsyncThunk("dashboard/categorydata", async (data) => {
    const response = await getCategories(data.postData, data.tokenData);
    return response;
});
export const getTrendingNearBySellerAsync = createAsyncThunk("dashboard/trendingnearby", async (data) => {
    const response = await getTrendingNearBySeller(data.postData, data.tokenData);
    return response;
});
export const getProductListAsync = createAsyncThunk("dashboard/productasync", async (data) => {
    const response = await getProductListBySupplier(data.postData, data.tokenData);
    return response;
});
export const getBookingSlotAsync = createAsyncThunk("dashboard/slotlist", async (data) => {
    const response = await getBookingSlot(data.postData, data.tokenData);
    return response;
});
export const createBookingApiAsync = createAsyncThunk("dashboard/createbooking", async (data) => {
    const response = await createBookingApi(data.postData, data.tokenData);
    return response;
});
export const getProductByIdApiAsync = createAsyncThunk("dashboard/getProductByIdApi", async (data) => {
    const response = await getProductByIdApi(data.postData, data.tokenData);
    return response;
});
export const getBannerByServiceTypeAsync = createAsyncThunk("dashboard/getBannerByServiceType", async (data) => {
    const response = await getBannerByServiceType(data.postData, data.tokenData);
    return response;
});
export const getAllOrderByUserAsync=createAsyncThunk("dashboard/getAllOrderByUser", async(data)=>{
    const response=await getAllOrderByUser(data.postData,data.tokenData);
    return response;
});
export const getReorderByUserAsync=createAsyncThunk("dashboard/getReorderByUser", async(data)=>{
    const response=await getReorderByUser(data.postData,data.tokenData);
    return response;
});
export const cancelOrderByUserAsync=createAsyncThunk("dashboard/cancelOrderByUser", async(data)=>{
    const response=await cancelOrderByUser(data.postData,data.tokenData);
    return response;
});
export const ratingFeedbackByUserAsync=createAsyncThunk("dashboard/ratingFeedbackByUser", async(data)=>{
    const response=await ratingFeedbackByUser(data.postData,data.tokenData);
    return response;
});
export const getExtraProductsByCategoryIdAsync=createAsyncThunk("dashboard/getExtraProductsByCategoryId", async(data)=>{
    const response=await getExtraProductsByCategoryId(data.postData,data.tokenData);
    return response;
});
export const getProductServiceCountAsync=createAsyncThunk("dashboard/getProductServiceCount", async(data)=>{
    const response=await getProductServiceCount(data);
    return response;
});
export const getCompanyReviewsDetailsAsync=createAsyncThunk("dashboard/getCompanyReviewsDetails", async(data)=>{
    const response=await getCompanyReviewsDetails(data.postData,data.tokenData);
    return response;
});
export const getVariantIdAsync=createAsyncThunk("dashboard/getVariantId", async(data)=>{
    const response=await getVariantId(data.postData,data.tokenData);
    return response;
});
export const getAttributesForFilterAsync=createAsyncThunk("dashboard/getAttributesForFilter", async(data)=>{
    const response=await getAttributesForFilter(data.postData,data.tokenData);
    return response;
});
export const dashboardSlice = createSlice({
    name: "dashboard",
    initialState,
    reducers:{
        emptyDashboard: (state) => {
            state.products = null;
          },
    },
    extraReducers: (builder) => {
        builder
          .addCase(getExtraProductsByCategoryIdAsync.pending, (state) => {
            state.status = "loading";
          })
          .addCase(getExtraProductsByCategoryIdAsync.fulfilled, (state, { payload }) => {
            state.products = payload
            state.status = "idle";
          })
          .addCase(getExtraProductsByCategoryIdAsync.rejected, (state, { error }) => {
            state.status = "idle";
            state.products = null
          })
      },
});
export const {emptyDashboard} = dashboardSlice.actions;
export const selectProductList = (state)=>state.dashboard.products;
export default dashboardSlice.reducer;