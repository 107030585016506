import axios from "axios";
import { toast } from "react-toastify";
const queryString = require('query-string');

export function getAllCoupons(data, token) {
  let query = queryString.stringify(data);
  return new Promise((resolve, reject) =>
    axios
      .get(`${process.env.REACT_APP_STAGING_PRODUCT_URL}api/v1/coupons`+ '?' + query, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
          'app-name': 'b2c'
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        // console.error(error);
      })
  )
}
export function verifiedCouponsApi(data, token) {
  return new Promise((resolve, reject) =>
    axios
      .post(`${process.env.REACT_APP_STAGING_PRODUCT_URL}api/v1/coupons/verify`,data, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
          'app-name': 'b2c'
        }
      })
      .then(function (response) {
        resolve(response);
        // toast.success(response?.data?.msg)
      })
      .catch(function (error) {
        reject(error)
        toast.error(error?.response?.data?.msg)
        // console.error(error);
      })
  )
}
export function getAllCouponsByCategoryId(data, token) {
  let query = queryString.stringify(data);
  return new Promise((resolve, reject) =>
    axios
      .get(`${process.env.REACT_APP_STAGING_PRODUCT_URL}api/v1/coupons/get-coupon-by-categoryId`+ '?' + query, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
          'app-name': 'b2c'
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        // console.error(error);
      })
  )
}