import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import circleImg from "../../public/images/circle-btn.png";
import Webcam from "react-webcam";
import { useDispatch } from 'react-redux';
import { uploadIdCardAsync1 } from './authSlice';

//const WebcamComponent = () => <Webcam />;
const videoConstraints = {
    width: 400,
    height: 400,
    facingMode: "user"
};

const AgeVerificationTwo = (props) => {
    var atob = require('atob');
    let stateData = JSON.parse(props?.history?.location?.state)
    const history = useHistory();
    const dispatch = useDispatch()
    const [image, setImage] = useState('');
    const webcamRef = React.useRef(null);
    const capture = React.useCallback(
        () => {
            const imageSrc = webcamRef.current.getScreenshot();
            function dataURLtoFile(dataurl, filename) {
                var arr = dataurl.split(','),
                  mime = arr[0].match(/:(.*?);/)[1],
                  bstr = atob(arr[1]),
                  n = bstr.length,
                  u8arr = new Uint8Array(n);
            
                while (n--) {
                  u8arr[n] = bstr.charCodeAt(n);
                }
                return new File([u8arr], filename, { type: mime });
              }
              function uuidv4() {
                return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
                  (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(20)
                );
              }
            let file1 = dataURLtoFile(imageSrc, `${uuidv4()}.jpeg`);
            let data ={
                "document": file1
            }
                dispatch(uploadIdCardAsync1(data))
            setImage(file1)
            localStorage.setItem(stateData?.imageData, JSON.stringify(imageSrc))
            history.push({ pathname: `${stateData?.loc}`});
        },
        [webcamRef]
    );
    useEffect(() => {
        window.scrollTo(0, 0)   
          }, [])
    return (
        <>
            <main className="main-wrapper age-verification-wraper2">
                <div className="scan-part">
                    {/* <button type="button"></button> */}
                    <Webcam
                        audio={false}
                        height={"200px"}
                        ref={webcamRef}
                        screenshotFormat="image/jpeg"
                        width={"100%"}
                        videoConstraints={videoConstraints}
                    />
                </div>

                <div className="circle-btn">
                    <Link to="#" onClick={(e) => { capture() }}>
                        <img src={circleImg} alt="circleImg" />
                    </Link>
                </div>
            </main>
        </>
    )
}

export default AgeVerificationTwo;