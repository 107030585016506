import React, { useEffect } from "react";
import { Link } from 'react-router-dom';
import { coinStackImg, leftArrowImg } from "../../../utilities/images";
import { selectWalletBalance } from "../wallet/walletSlice";
import { useDispatch, useSelector } from "react-redux";
import { getSettingsApiAsync, updateNotificationSettingApiAsync } from "./settingSlice";
import { selectLoginAuth } from "../auth/authSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import CommonLoader from "../../components/shared/CommonLoader"
import { useState } from "react";

const Setting = (props) => {
    const [loader, setLoader] = useState(false)
    const [notificationSetting, setNotificationSetting] = useState(false)
    const [emailNotificationSetting, setEmailNotificationSetting] = useState(false)
    const [pushNotificationSetting, setPushNotificationSetting] = useState(false)
    const [chatNotificationSetting, setChatNotificationSetting] = useState(false)
    const walletData = useSelector(selectWalletBalance);
    const auth = useSelector(selectLoginAuth)
    const dispatch = useDispatch()

    const updateSetting = (flag, state) => {
        let params = {
            tokenData: auth?.payload?.token,
            postData: {
                "app_name": "driver",
                [flag]: state
            }
        }
        setLoader(true)
        dispatch(updateNotificationSettingApiAsync(params))
            .then(unwrapResult)
            .then(async (obj) => {
                let params = {
                    tokenData: auth?.payload?.token,
                    postData: {
                        "app_name": "b2c"
                    }
                }
                dispatch(getSettingsApiAsync(params))
                    .then(unwrapResult)
                    .then(async (obj) => {
                        setNotificationSetting(obj?.data?.payload?.notification_status)
                        setEmailNotificationSetting(obj?.data?.payload?.email_notification_status)
                        setChatNotificationSetting(obj?.data?.payload?.chat_notification_status)
                        setPushNotificationSetting(obj?.data?.payload?.push_notification_status)
                        setLoader(false)
                    }
                    )
                    .catch((obj) => {
                        setLoader(false)
                    })
            }
            )
            .catch((obj) => {
                setLoader(false)
            })
    }

    const handleCheckboxChange = (flag) => {
        if (flag === "notification") {
            setNotificationSetting(prev => !prev)
            updateSetting("notification_status", !notificationSetting)
        }
        if (flag === "email") {
            setEmailNotificationSetting(prev => !prev)
            updateSetting("email_notification_status", !emailNotificationSetting)
        }
        if (flag === "push") {
            setPushNotificationSetting(prev => !prev)
            updateSetting("push_notification_status", !pushNotificationSetting)
        }
        if (flag === "chat") {
            setChatNotificationSetting(prev => !prev)
            updateSetting("chat_notification_status", !chatNotificationSetting)
        }
    }

    let params = {
        tokenData: auth?.payload?.token,
        postData: {
            "app_name": "b2c"
        }
    }
    useEffect(() => {
        window.scrollTo(0, 0)
        setLoader(true)
        dispatch(getSettingsApiAsync(params))
            .then(unwrapResult)
            .then(async (obj) => {
                setNotificationSetting(obj?.data?.payload?.notification_status)
                setEmailNotificationSetting(obj?.data?.payload?.email_notification_status)
                setChatNotificationSetting(obj?.data?.payload?.chat_notification_status)
                setPushNotificationSetting(obj?.data?.payload?.push_notification_status)
                setLoader(false)
            }
            )
            .catch((obj) => {
                setLoader(false)
            })
    }, [])

    return (
        <>
            <div className="order-heading">
                <div className="header-left-item">
                    <h4><Link to='/more'><img src={leftArrowImg} alt="leftArrowImg" /></Link> Settings</h4>
                </div>
                <div className="header-right-item">
                    <Link to="/wallet" className="coinStack">{walletData ? walletData?.data?.payload?.sila_balance : 0}
                        <img src={coinStackImg} alt="coinStack" />
                    </Link>
                </div>
            </div>

            {
                loader ?
                    <CommonLoader />
                    : <div className="main-content">
                        <div className="settings-area">
                            <form action="#" method="POST">
                                <div className="faceid-box settings-part">
                                    <h4>Notifications</h4>
                                    <ul>
                                        <li>
                                            <span>Notification</span>
                                            <div className="custom-control custom-switch custom-switch-sm">
                                                <input type="checkbox" className="custom-control-input" id="customSwitch1" checked={notificationSetting} onChange={() => handleCheckboxChange("notification")} />
                                                <label className="custom-control-label" htmlFor="customSwitch1"></label>
                                            </div>
                                        </li>
                                        <li>
                                            <span>Email Notification</span>
                                            <div className="custom-control custom-switch custom-switch-sm">
                                                <input type="checkbox" className="custom-control-input" id="customSwitch2" checked={emailNotificationSetting} onChange={() => handleCheckboxChange("email")} />
                                                <label className="custom-control-label" htmlFor="customSwitch2"></label>
                                            </div>
                                        </li>
                                        <li>
                                            <span>Push Notification</span>
                                            <div className="custom-control custom-switch custom-switch-sm">
                                                <input type="checkbox" className="custom-control-input" id="customSwitch3" checked={pushNotificationSetting} onChange={() => handleCheckboxChange("push")} />
                                                <label className="custom-control-label" htmlFor="customSwitch3"></label>
                                            </div>
                                        </li>
                                        <li>
                                            <span>Chat Notification</span>
                                            <div className="custom-control custom-switch custom-switch-sm">
                                                <input type="checkbox" className="custom-control-input" id="customSwitch4" checked={chatNotificationSetting} onChange={() => handleCheckboxChange("chat")} />
                                                <label className="custom-control-label" htmlFor="customSwitch4"></label>
                                            </div>
                                        </li>
                                    </ul>
                                </div>

                                {/* <div className="faceid-box settings-part">
                                    <h4>Payments</h4>
                                    <ul>
                                        <li>
                                            <span>Payment popup</span>
                                            <div className="custom-control custom-switch custom-switch-sm">
                                                <input type="checkbox" className="custom-control-input" id="customSwitch5" />
                                                <label className="custom-control-label" htmlFor="customSwitch5"></label>
                                            </div>
                                        </li>
                                        <li>
                                            <span>Payment popup</span>
                                            <div className="custom-control custom-switch custom-switch-sm">
                                                <input type="checkbox" className="custom-control-input" id="customSwitch6" />
                                                <label className="custom-control-label" htmlFor="customSwitch6"></label>
                                            </div>
                                        </li>
                                        <li>
                                            <span>Payment popup</span>
                                            <div className="custom-control custom-switch custom-switch-sm">
                                                <input type="checkbox" className="custom-control-input" id="customSwitch7" />
                                                <label className="custom-control-label" htmlFor="customSwitch7"></label>
                                            </div>
                                        </li>
                                        <li>
                                            <span>Payment popup</span>
                                            <div className="custom-control custom-switch custom-switch-sm">
                                                <input type="checkbox" className="custom-control-input" id="customSwitch8" />
                                                <label className="custom-control-label" htmlFor="customSwitch8"></label>
                                            </div>
                                        </li>
                                    </ul>
                                </div>

                                <div className="faceid-box settings-part">
                                    <h4>Accounts</h4>
                                    <ul>
                                        <li>
                                            <span>Payment popup</span>
                                            <div className="custom-control custom-switch custom-switch-sm">
                                                <input type="checkbox" className="custom-control-input" id="customSwitch9" />
                                                <label className="custom-control-label" htmlFor="customSwitch9"></label>
                                            </div>
                                        </li>
                                        <li>
                                            <span>Payment popup</span>
                                            <div className="custom-control custom-switch custom-switch-sm">
                                                <input type="checkbox" className="custom-control-input" id="customSwitch10" />
                                                <label className="custom-control-label" htmlFor="customSwitch10"></label>
                                            </div>
                                        </li>
                                        <li>
                                            <span>Payment popup</span>
                                            <div className="custom-control custom-switch custom-switch-sm">
                                                <input type="checkbox" className="custom-control-input" id="customSwitch11" />
                                                <label className="custom-control-label" htmlFor="customSwitch11"></label>
                                            </div>
                                        </li>
                                        <li>
                                            <span>Payment popup</span>
                                            <div className="custom-control custom-switch custom-switch-sm">
                                                <input type="checkbox" className="custom-control-input" id="customSwitch12" />
                                                <label className="custom-control-label" htmlFor="customSwitch12"></label>
                                            </div>
                                        </li>
                                    </ul>
                                </div> */}

                            </form>
                        </div>
                    </div>
            }

        </>
    );
}

export default Setting;