import { createSlice ,createAsyncThunk } from "@reduxjs/toolkit";
import {getTermsPrivacy, getSettingsApi, updateNotificationSettingApi} from './settingApi'

const initialState={
    termsPrivacy:[],
    status:{
        termsPrivacy:"idle"
    }
};

export const getTermsPrivacyAsync=createAsyncThunk('setting/getTermsPrivacy', async(data)=>{
    const response=await getTermsPrivacy(data);
    return response
});
export const getSettingsApiAsync=createAsyncThunk('setting/getSettingsApi', async(data)=>{
    const response=await getSettingsApi(data.postData, data.tokenData);
    return response
});
export const updateNotificationSettingApiAsync=createAsyncThunk('setting/updateNotificationSettingApi', async(data)=>{
    const response=await updateNotificationSettingApi(data.postData, data.tokenData);
    return response
});

const settingSlice = createSlice({
name:"setting",
initialState,
extraReducers: (builder) => {
    builder
    .addCase(getTermsPrivacyAsync.pending, (state) => {
        state.status = "loading";
    })
    .addCase(getTermsPrivacyAsync.fulfilled, (state, { payload }) => {
        state.termsPrivacy = payload
        state.status = "idle";
    })
    .addCase(getTermsPrivacyAsync.rejected, (state, { error }) => {
        state.status = "idle";
        state.termsPrivacy = null
})
}

})
export const selectTermsPrivacy=(state)=>state.setting.termsPrivacy;

export default settingSlice.reducer