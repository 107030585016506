import React, { useEffect } from "react";
import { Link } from 'react-router-dom';
import { leftArrowImg, walletP1Img, walletP2Img, walletP3Img, walletP4Img, walletP5Img, walletP6Img, walletP7Img, } from "../../../utilities/images";

const WalletProducts = (props) => {

   useEffect(() => {
      window.scrollTo(0, 0)
  }, [])
   return (
      <>
         <div className="order-heading">
            <div className="header-left-item wallet-header-left">
               <h4><Link to='/wallet'><img src={leftArrowImg} alt="leftArrowImg" /></Link> H&M <span>7 Products purchases</span></h4>
            </div>
         </div>

         <div className="main-content">
            <div className="wallet-product-area">
               <div className="product-addcart-main">
                  <div className="paddcart-item">
                     <div className="paddcart-inner wallet-prditm">
                        <img src={walletP1Img} alt="" />
                        <h4>Slim Fit Pima cotton T-shirt</h4>
                        <p>H&M</p>
                        <h6><span>1</span> 3 times</h6>
                     </div>
                  </div>
                  <div className="paddcart-item">
                     <div className="paddcart-inner wallet-prditm">
                        <img src={walletP2Img} alt="" />
                        <h4>Fit Short-sleeved rugby shirt</h4>
                        <p>H&M</p>
                        <h6><span>1</span> 3 times</h6>
                     </div>
                  </div>
                  <div className="paddcart-item">
                     <div className="paddcart-inner wallet-prditm">
                        <img src={walletP3Img} alt=""/>
                        <h4>Conscious choice Trainers</h4>
                        <p>H&M</p>
                        <h6><span>1</span> 3 times</h6>
                     </div>
                  </div>
                  <div className="paddcart-item">
                     <div className="paddcart-inner wallet-prditm">
                        <img src={walletP4Img} alt="" />
                        <h4>Slim Fit Pima cotton T-shirt</h4>
                        <p>H&M</p>
                        <h6><span>1</span> 3 times</h6>
                     </div>
                  </div>
                  <div className="paddcart-item">
                     <div className="paddcart-inner wallet-prditm">
                        <img src={walletP5Img} alt=""/>
                        <h4>Fit Short-sleeved rugby shirt</h4>
                        <p>H&M</p>
                        <h6><span>1</span> 3 times</h6>
                     </div>
                  </div>
                  <div className="paddcart-item">
                     <div className="paddcart-inner wallet-prditm">
                        <img src={walletP6Img} alt=""/>
                        <h4>Conscious choice Trainers</h4>
                        <p>H&M</p>
                        <h6><span>1</span> 3 times</h6>
                     </div>
                  </div>
                  <div className="paddcart-item">
                     <div className="paddcart-inner wallet-prditm">
                        <img src={walletP7Img} alt="" />
                        <h4>Large ceramic vase</h4>
                        <p>H&M</p>
                        <h6><span>1</span> 3 times</h6>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </>
   );
}


export default WalletProducts;
