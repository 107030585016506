import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { leftArrowImg, rider2, LocationDark, Info, OrderBlue, coinStackImg } from "../../../utilities/images";
import CustomModal from '../../components/shared/CustomModal';
import { selectLoginAuth } from '../auth/authSlice';
import { getReorderByUserAsync } from '../dashboard/dashboardSlice';
import { withGoogleMap, GoogleMap, withScriptjs, Marker } from "react-google-maps";
import { selectWalletBalance } from '../wallet/walletSlice';
import { unwrapResult } from '@reduxjs/toolkit';
import CommonLoader from '../../components/shared/CommonLoader';

const OrdersPlaced = () => {
    const [key, setKey] = useState(Math.random());
    const auth = useSelector(selectLoginAuth);
    const [loading, setLoading] = useState(false);
    const [zoom, setZoom] = useState(15)
    const walletData = useSelector(selectWalletBalance);
    const [modalDetail, setModalDetail] = useState({
        show: false,
        title: "",
        flag: ""
    });
    const [reorderProducts, setReorderProducts] = useState({});
    const dispatch = useDispatch();
    const handleOnCloseModal = () => {
        setModalDetail({
            show: false
        });
        setKey(Math.random());
    }
    function amount(item) {
        return item.price;
    }
    function sum(prev, next) {
        return prev + next;
    }

    const AsyncMap = withScriptjs(
        withGoogleMap(
            props => (
                <GoogleMap
                    defaultZoom={zoom}
                    defaultCenter={{ lat: reorderProducts?.data?.payload?.coordinates[1], lng: reorderProducts?.data?.payload?.coordinates[0] }}
                >
                    <Marker
                        name={''}
                        draggable={false}
                        // onDragEnd={onMarkerDragEnd}
                        position={{ lat: reorderProducts?.data?.payload?.coordinates[1], lng: reorderProducts?.data?.payload?.coordinates[0] }}
                    />
                </GoogleMap>
            )
        )
    );
    let id = localStorage.getItem('orderId');
    let data = {
        'postData': {
            id: id
        },
        "tokenData": auth?.payload?.token
    }
    useEffect(() => {
        window.scrollTo(0, 0)
        setLoading(true)
        dispatch(getReorderByUserAsync(data))
            .then(unwrapResult)
            .then(async (obj) => {
                setReorderProducts(obj)
                setLoading(false)
            }
            )
            .catch((obj) => {
                setLoading(false)
            })
    }, [])

    return (
        <>
            <div className="order-heading">
                <div className="header-left-item">
                    <h4><Link to='/dashboard'><img src={leftArrowImg} alt="" /></Link> Home </h4>
                </div>
                <div className="header-right-item">
                    <Link to="/wallet" className="coinStack">{walletData ? walletData?.data?.payload?.sila_balance : 0}
                        <img src={coinStackImg} alt="coinStack" />
                    </Link>
                </div>
            </div>
            {loading === true ?
                <CommonLoader />
                :
                <div className="main-content cart-mainpd pb-5">
                    <div className="placeord-main">
                        <div className="product-delvbox">
                            <img src={rider2} alt="" />
                            <div className="rider-cnt">
                                <h6>Delivery time</h6>
                                <h2>{reorderProducts?.data?.payload?.delivery_type_id === 1 ?
                                    "Express Delivery" :
                                    reorderProducts?.data?.payload?.delivery_type_id === 2 ? "1 hour delivery" :
                                        "2 hour delivery"
                                }</h2>
                                <p>
                                    {reorderProducts?.data?.payload?.delivery_type_id !== 1 ?
                                        `${reorderProducts?.data?.payload?.preffered_delivery_start_time}-${reorderProducts?.data?.payload?.preffered_delivery_end_time}` : <></>
                                    }
                                    <Link to='/delivery-types'>Change time window</Link>
                                </p>
                            </div>
                        </div>

                        <div className="delvaddres-boxr mb-2">
                            <h2><img src={LocationDark} alt="LocationDark" />Delivery Address </h2>
                            <div className="delv-map">
                                <AsyncMap
                                    googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_MAP_KEY}&libraries=places`}
                                    loadingElement={
                                        <div style={{ height: `100%` }} />
                                    }
                                    containerElement={
                                        <div style={{ height: '300px' }} />
                                    }
                                    mapElement={
                                        <div style={{ height: `100%` }} />
                                    }
                                />
                            </div>
                            <h4>{reorderProducts?.data?.payload?.address_type}</h4>
                            <p>{reorderProducts?.data?.payload?.address}</p>
                            <p>{reorderProducts?.data?.payload?.city}</p>
                        </div>

                        <div className="orderdlv-detailsbox">
                            <h2><img src={OrderBlue} alt="OrderBlue" /> Order details</h2>
                            <div className="details-ordmdl border-top-0 pt-0">
                                <ul>
                                    {
                                        reorderProducts?.data?.payload?.order_details?.map((val, index) => {
                                            return (
                                                <li key={index}>
                                                    <div className="details-mdlleft">
                                                        <strong>{val?.qty}</strong> <small>x</small> {val?.product_name} <span></span>
                                                    </div>
                                                    <p>${val?.qty * val?.price}</p>
                                                </li>
                                            )
                                        })
                                    }
                                </ul>
                            </div>

                            <div className="total-part">
                                <ul>
                                    <li>Sub Total <span>${reorderProducts?.data?.payload?.actual_amount}</span></li>
                                    <li>Coupon <span>-${reorderProducts?.data?.payload?.discount}</span></li>
                                    <li><small>Taxes & Other fees <img src={Info} alt="" /></small> <span>${reorderProducts?.data?.payload?.tax}</span></li>
                                    <li>Delivery fees <span>${Number(reorderProducts?.data?.payload?.delivery_charge).toFixed(2)}</span></li>
                                    <li><strong>Total</strong> <strong>${Number(reorderProducts?.data?.payload?.payable_amount).toFixed(2)}</strong></li>
                                </ul>
                            </div>
                        </div>

                        <div className="compl-orcnt">
                            <h6>By completing this order, I agree to all <Link to='/terms-and-conditions'>terms & conditions</Link></h6>
                        </div>
                    </div>
                </div>}
            <CustomModal
                key={key}
                show={modalDetail.show}
                backdrop="static"
                showCloseBtn={true}
                isRightSideModal={true}
                child={<div className="modal-content">
                    <div className="modal-header address-mdlheader">
                        <h5><strong>Whats included?</strong></h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={handleOnCloseModal}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body address-mdlbody pt-0">
                        <div className="taxes-mdlbox-cnt">
                            <h4>Service fees <span>$1.50</span></h4>
                            <p>Cras lobortis ligula sed metus tempor, nec aliquet dolor suscipit. Nullam eleifend condimentum sollicitudin.</p>
                        </div>
                        <div className="taxes-mdlbox-cnt">
                            <h4>Taxes <span>$1.50</span></h4>
                        </div>
                    </div>
                </div>
                }
                onCloseModal={() => handleOnCloseModal()}
            />
        </>
    )
}

export default OrdersPlaced