import React, { useEffect } from "react";
import { Link, useHistory } from 'react-router-dom';
import { leftArrowImg, faceIdImg } from "../../../utilities/images";

const FaceIdAndPin = (props) => {
    const history = useHistory();

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <>
            <div className="order-heading">
                <h4><Link to='/more'><img src={leftArrowImg} alt="leftArrowImg" /></Link> Face ID & PIN</h4>
            </div>

            <div className="main-content">
                <div className="faceid-upper">
                    <Link to="#">
                        <img src={faceIdImg} alt="faceIdImg" />
                    </Link>
                </div>

                <div className="container">
                    <div className="faceid-box">
                        <h4>Enable</h4>
                        <ul>
                            <li>
                                <span>Face ID</span>
                                <div className="custom-control custom-switch custom-switch-sm">
                                    <input type="checkbox" className="custom-control-input" id="customSwitch1" />
                                    <label className="custom-control-label" for="customSwitch1"></label>
                                </div>
                            </li>
                            <li>
                                <span>PIN</span>
                                <div className="custom-control custom-switch custom-switch-sm">
                                    <input type="checkbox" className="custom-control-input" id="customSwitch2" />
                                    <label className="custom-control-label" for="customSwitch2"></label>
                                </div>
                            </li>
                        </ul>
                        <p>It is a long established fact that a reader will be distracted by the readable content.</p>
                    </div>

                    <div className="faceid-btn">
                        <Link to='/face-id-and-Pin'>Set up Face ID <i className="fas fa-chevron-right"></i></Link>
                        <Link to="#" onClick={() => { history.push({pathname:'/reset-new-pin', state:"setuppin"}) }}>Set up PIN <i className="fas fa-chevron-right"></i></Link>
                    </div>
                </div>
            </div>
        </>
    );
}


export default FaceIdAndPin;
