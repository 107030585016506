import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAllCoupons, verifiedCouponsApi, getAllCouponsByCategoryId } from "./couponApi";

const initialState = {
  verifiedCoupon: [],
  status: {
    verifiedCoupon: "idle"
  }
};

export const couponsAsync = createAsyncThunk("all/coupons", async (data) => {
  const response = await getAllCoupons(data.postData, data.tokenData);
  return response;
});
export const verifiedCouponsApiAsync = createAsyncThunk("all/verifiedCouponsApi", async (data) => {
  const response = await verifiedCouponsApi(data.postData, data.tokenData);
  return response;
});
export const getAllCouponsByCategoryIdAsync = createAsyncThunk("all/getAllCouponsByCategoryId", async (data) => {
  const response = await getAllCouponsByCategoryId(data.postData, data.tokenData);
  return response;
});

export const couponsSlice = createSlice({
  name: "coupons",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(verifiedCouponsApiAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(verifiedCouponsApiAsync.fulfilled, (state, { payload }) => {
        state.verifiedCoupon = payload
        state.status = "idle";
      })
      .addCase(verifiedCouponsApiAsync.rejected, (state, { error }) => {
        state.status = "idle";
        state.verifiedCoupon = null
      })
  },
});

export const selectVerifiedCoupon = (state) => state.coupons.verifiedCoupon;
export default couponsSlice.reducer;