import React, { useEffect, useState } from "react";
import { Link, useHistory } from 'react-router-dom';
import { leftArrowImg, bxsCoinStackImg, applePayImg, creditCardImg, debitCardImg, bankFilledImg } from "../../../utilities/images";

const PaymentMethods = (props) => {
    const history = useHistory();

    const [selectedData, setSelectedData] = useState("default")

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <>
            <div className="order-heading">
                <div className="header-left-item">
                    <h4><Link to="#" onClick={() => { history.goBack() }}><img src={leftArrowImg} alt="leftArrowImg" /></Link> Payment methods</h4>
                </div>
                <div className="header-right-item">
                    <Link to="#">0 <img src={bxsCoinStackImg} alt="bxsCoinStackImg" /></Link>
                </div>
            </div>

            <div className="main-content">
                <div className="payment-part">
                    <form action="#" method="POST">
                        <ul>
                            <li>
                                <div className="pay-left">
                                    <Link to="#"><img src={applePayImg} alt="applePayImg" /> Apple pay</Link>
                                </div>
                                <div className="pay-right">
                                    <span>Default</span>
                                    <div className="radio-btn">
                                        <input type="radio" id="radio-btn1" name="radio" value="default" checked={selectedData === 'default'} onChange={e => { setSelectedData(e.target.value) }} />
                                        <label htlmFor="radio-btn1"></label>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <Link to='/credit-card'>
                                    <img src={creditCardImg} alt="creditCardImg" />
                                    Add credit card
                                    <span><i className="fas fa-chevron-right"></i></span>
                                </Link>
                            </li>
                            <li>
                                <Link to="#">
                                    <img src={debitCardImg} alt="debitCardImg" />
                                    Add debit card
                                    <span><i className="fas fa-chevron-right"></i></span>
                                </Link>
                            </li>
                            <li>
                                <Link to="#">
                                    <img src={bankFilledImg} alt="bankFilledImg" />
                                    Connect your bank account
                                    <span><i className="fas fa-chevron-right"></i></span>
                                </Link>
                            </li>
                        </ul>
                    </form>
                    <div className="pay-btn">
                        <Link to="#">Pay Now</Link>
                    </div>
                </div>
            </div>
        </>
    );
}


export default PaymentMethods;
