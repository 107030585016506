import React, { useEffect, useState } from 'react';
import Badge from "@material-ui/core/Badge";
import { bagImg, categoryImg, clockImg, deliveryParcelImg, heart2, leftArrowImg, starCircleImg } from '../../../utilities/images';
import { useHistory, Link } from 'react-router-dom/cjs/react-router-dom';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { useDispatch, useSelector } from 'react-redux';
import { getCategoryApiAsync, getTrendingNearBySellerAsync } from './dashboardSlice';
import { selectLoginAuth } from '../auth/authSlice';
import { unwrapResult } from '@reduxjs/toolkit';
import CommonLoader from "../../components/shared/CommonLoader";
import CustomModal from "../../components/shared/CustomModal";
import ProductModal from "./ProductModal";
import PaginationSetting from '../../constants/PaginationSetting';
import { Box } from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";

const HomeServices = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const [pagination, setPagination] = useState(PaginationSetting);
    const [page, setPage] = useState(1);
    const [count, setCount] = useState("");
    const { id, flag, categoryName } = useParams();
    const [name, setName] = useState(categoryName);
    const [key, setKey] = useState(Math.random());
    const auth = useSelector(selectLoginAuth);
    const [sellerData, setSellerData] = useState({});
    const [categoryLoading, setCategoryLoading] = useState(false);
    const [categorySelected, setCategorySelected] = useState('');
    const [categoryData, setCategoryData] = useState({})
    const [modalDetail, setModalDetail] = useState({
        show: false,
        title: "",
        flag: ""
    });
    const handleOnCloseModal = () => {
        setModalDetail({
            show: false,
            title: "",
            flag: ""
        });
        setKey(Math.random());
    }

    const sellersDataHandle = (data) =>{
        setName(data?.name)
        setCategorySelected(data?.name)
        let params = {
            postData: {
                page: page,
                limit: pagination.recordsPerPage,
                category_id: data?.id,
                b2b_service_type: flag,
                lat: 30.7355,
                long: 76.7121
            },
            tokenData: auth?.payload?.token
        }
        setCategoryLoading(true)
        dispatch(getTrendingNearBySellerAsync(params))
            .then(unwrapResult)
            .then(async (obj) => {
                setSellerData(obj)
                setPagination((prevState) => ({ ...prevState, totalPages: obj?.data?.payload?.sellers?.total_pages, totalRecords: obj?.data?.payload?.sellers?.total }));
                setCategoryLoading(false)
            }
            )
            .catch((obj) => {
                setCategoryLoading(false)
            })
    }

    const categoryDataHandle = () => {
        let categoryParams = {
            'postData': {
                service_type: flag
            },
            'tokenData': auth?.payload?.token
        }
        setCategoryLoading(true)
        dispatch(getCategoryApiAsync(categoryParams))
            .then(unwrapResult)
            .then(async (obj) => {
                setCategoryData(obj)
                setCategorySelected(obj?.data?.payload?.data?.find(item => item?.id == id)?.name)
                setCategoryLoading(false)
            }
            )
            .catch((obj) => {
                setCategoryLoading(false)
            })
    }
    const sellersHandle = async () => {
        let params = {
            postData: {
                page: page,
                limit: pagination.recordsPerPage,
                category_id: id,
                b2b_service_type: flag,
                lat: 30.7355,
                long: 76.7121
            },
            tokenData: auth?.payload?.token
        }
        setCategoryLoading(true)
        dispatch(getTrendingNearBySellerAsync(params))
            .then(unwrapResult)
            .then(async (obj) => {
                setSellerData(obj)
                setPagination((prevState) => ({ ...prevState, totalPages: obj?.data?.payload?.sellers?.total_pages, totalRecords: obj?.data?.payload?.sellers?.total }));
                setCategoryLoading(false)
            }
            )
            .catch((obj) => {
                setCategoryLoading(false)
            })
    }

    useEffect(() => {
        categoryDataHandle()
        sellersHandle()
        window.scrollTo(0, 0)
    }, [])

    return (
        <>
            {
                categoryLoading === true ?
                    <CommonLoader />
                    :
                    <>
                        <div className="header-part browse-addprdcard-header">
                            <div className="header-left-item">
                                <div className="header-left">
                                    <img onClick={() => history.push(``)} style={{ cursor: "pointer" }} src={leftArrowImg} alt="leftArrow" className='me-1' />
                                    <h6 className='subhead_'>{name}</h6>
                                </div>
                            </div>
                            <div className="header-right-item">
                                <button type="button" >
                                    <img src={heart2} alt="bellImg" />
                                </button>
                                {
                                    count !== "" ?
                                        <Badge overlap="rectangular" color="secondary" badgeContent={count}>
                                            <button type="button" onClick={() => history.push('/cart')}><img src={bagImg} alt="bagImg" /></button>
                                        </Badge>
                                        :
                                        <button type="button" onClick={() => history.push('/cart')}><img src={bagImg} alt="bagImg" /></button>
                                }
                            </div>
                        </div>
                        {<div className="maphm-btmprt">
                            <div className="maphm-btmlinks">
                                <ul className="card_category">
                                    <li className='card_categoryItems'><img onClick={() => {
                                        setModalDetail({
                                            show: true,
                                            flag: "products"
                                        });
                                        setKey(Math.random())
                                    }} src={categoryImg} alt='' className='allIcon' /></li>
                                    {
                                        categoryData?.data?.payload?.data?.filter(item => item?.image !== null)?.map((val, index) => {
                                            return (
                                                <li onClick={() => sellersDataHandle(val)} key={index} className='card_categoryItems' style={{ zIndex: categoryData?.data?.payload?.data?.filter(item => item?.image !== null).length - index}}><Link to="#" className={(categorySelected === val?.name) ? `active` : ''}><img src={val?.image} alt="catImg" /> {val?.name}</Link></li>
                                            )
                                        })
                                    }
                                </ul>
                            </div>
                        </div>}
                        <div className="main-content">
                            <div className="tobacco-mainitem">
                                <ul>
                                    {
                                        sellerData?.data?.payload?.sellers?.data ?
                                            sellerData?.data?.payload?.sellers?.data?.map((val, index) => {
                                                return (
                                                    <div key={index} style={{cursor:"pointer"}} onClick={() => history.push(`/home-store-services/${val?.unique_uuid}`)} >
                                                        <div className='innerProductContainer'>
                                                            <div className='innerProductArea'>
                                                                <img style={{ width: "100px" }} src={val?.user_profiles?.profile_photo} alt='tob1Img' />
                                                            </div>
                                                            <ul>
                                                                <li><strong>{val?.user_profiles?.firstname}</strong>  </li>
                                                                <li><img src={starCircleImg} alt="starCircleImg" /> {val?.sellerRating?.rating} </li>
                                                                <li><img src={clockImg} alt="clockImg" />{val?.distance?.timeString} </li>
                                                                <li><img src={deliveryParcelImg} alt="deliveryParcelImg" /> ${val?.deliveryFee} Delivery fee</li>
                                                            </ul>
                                                        </div>
                                                    </div>

                                                )
                                            })
                                            : <span>No Seller Found</span>
                                    }
                                </ul>
                            </div>
                        </div>
                    </>}
                    {pagination.totalRecords > pagination.recordsPerPage &&
                <Box py={3} display="flex" justifyContent="center">
                    <Pagination
                        key={page}
                        count={pagination.totalPages || 0}
                        color="secondary"
                        variant="outlined"
                        onChange={(e, value) => setPage(value)}
                        defaultPage={page}
                    />
                </Box>
            }
            <CustomModal
                key={key}
                show={modalDetail.show}
                // backdrop="static"
                showCloseBtn={false}
                ids="productInner"
                isRightSideModal={false}
                mediumWidth={true}
                child={modalDetail.flag === 'products'
                    ? <ProductModal flag="others" setName={setName} categoryData={categoryData?.data?.payload?.data} setSubcategoryId={(e)=>sellersDataHandle(e)} setCategorySelected={setCategorySelected} categoryLoading={categoryLoading} closeModal={() => handleOnCloseModal()} details={(e) => handleOnCloseModal(e)} />
                    : ""

                }
                onCloseModal={() => handleOnCloseModal()}
            />
        </>
    )
}

export default HomeServices
