import React, { useEffect, useState } from 'react'
import { addWalletBalanceAsync, getWalletDataAsync, selectWalletBalance } from '../wallet/walletSlice';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { Gift, LogoIcon3, coinStackImg } from '../../../utilities/images';
import { selectLoginAuth } from '../auth/authSlice';
import { unwrapResult } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';


const MoneyOptionModal = (props) => {
    const walletData = useSelector(selectWalletBalance);
    const auth = useSelector(selectLoginAuth);
    const toastId = React.useRef(null);
    const history = useHistory();
    const dispatch = useDispatch();
    const [amountData, setAmountData] = useState('');

    const amountDataHandle = () => {
        // history.push({ pathname: '/payment', state: amountData })
    }

    const handleSubmit = (data) => {
        if (!props.checkedValue) {
            history.push({ pathname: '/payment', state: data })
        } else {
            let params = {
                postData: {
                    "amount": data,
                    "account_name": props.checkedValue
                },
                tokenData: auth?.payload?.token
            }
            dispatch(addWalletBalanceAsync(params))
                .then(unwrapResult)
                .then((obj) => {
                    props.setAmount(obj?.data?.payload?.amount)
                    props.changeModal('added')
                    props.setRouteFlag('payment')
                    if (!toast.isActive(toastId.current)) {
                        toastId.current = toast.success(obj?.data?.msg)
                    }
                    let data = {
                        token: auth?.payload?.token
                    }
                    dispatch(getWalletDataAsync(data))
                        .then(unwrapResult)
                        .then(async (obj) => {
                           
                            // history.push('/wallet')
                        }
                        )
                        .catch((obj) => {
                        })
                }
                )
                .catch((obj) => {
                })
        }
    }

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <>
            <div className="modal-content modal-full">
                <div className="modal-header">
                    <button type="button" className="close" onClick={() => props.closeModal()}>
                        <span aria-hidden="true">Close</span>
                    </button>
                </div>
                <div className="modal-body">
                    <div className="coinplan-modal">
                        <div className="coinp-mdlupr">
                            <h2>Wallet</h2>
                            <Link to="/wallet" className="coinStack">{walletData ? walletData?.data?.payload?.sila_balance : 0}
                                <img src={coinStackImg} alt="coinStack" />
                            </Link>
                        </div>
                        <div className="coinp-uprcnt">
                            <p>Use coins to buy products from any listed stores and support/ tip to your rider</p>
                        </div>

                        <div className="coinp-refferbox">
                            <img src={Gift} alt="" />
                            <h4>Get 15.00 JBR</h4>
                            <p>Get 15.00 JBR for every refaral </p>
                            <Link to="#">Share</Link>
                        </div>

                        <div className="coinp-jbrbtn">
                            <ul>
                                <li onClick={() => handleSubmit(10)}>
                                    <Link to="#" >
                                        <span className="jbrbtn-left">
                                            <span><img src={LogoIcon3} alt="" /> JBR 10</span>
                                        </span>
                                        <span className="jbrbtn-right">
                                            <span>USD $10 <i className="fas fa-chevron-right"></i></span>
                                        </span>
                                    </Link>
                                </li>
                                <li onClick={() => handleSubmit(25)}>
                                    <Link to="#" >
                                        <span className="jbrbtn-left">
                                            <span><img src={LogoIcon3} alt="" /> JBR 25</span>
                                        </span>
                                        <span className="jbrbtn-right">
                                            <span>USD $25 <i className="fas fa-chevron-right"></i></span>
                                        </span>
                                    </Link>
                                </li>
                                <li onClick={() => handleSubmit(50)}>
                                    <Link to="#" >
                                        <span className="jbrbtn-left">
                                            <span><img src={LogoIcon3} alt="" /> JBR 50</span>
                                        </span>
                                        <span className="jbrbtn-right">
                                            <span>USD $50 <i className="fas fa-chevron-right"></i></span>
                                        </span>
                                    </Link>
                                </li>
                                <li onClick={() => handleSubmit(100)}>
                                    <Link to="#" >
                                        <span className="jbrbtn-left">
                                            <span><img src={LogoIcon3} alt="" /> JBR 100</span>
                                        </span>
                                        <span className="jbrbtn-right">
                                            <span>USD $100 <i className="fas fa-chevron-right"></i></span>
                                        </span>
                                    </Link>
                                </li>
                                <li onClick={()=>amountDataHandle()}>
                                    <Link to="#" >
                                        <div className="register-item">
                                            <input type="number" placeholder="type any amount to add JBR" value={amountData} onChange={(e) => setAmountData(e.target.value)} />
                                        </div>
                                    </Link>
                                </li>
                            </ul>
                        </div>

                        <div className="coinp-mdlbtmcnt">
                            <p>If you wish to purchase any product or service made available through Service (“Purchase”), you may be asked to supply certain information relevant to your Purchase including but not limited to, your credit or debit card number, the expiration date of your card, your billing address, and your shipping information.</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default MoneyOptionModal
