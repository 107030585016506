import { unwrapResult } from "@reduxjs/toolkit";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from 'react-router-dom';
import { leftArrowImg, galleryImg, uploadImg, coinStackImg } from "../../../utilities/images";
import { selectLoginAuth } from "../auth/authSlice";
import { selectSubjectData, selectUploadedUrlData, supportApiUrlAsync, supportCreateApiAsync } from "../supportRequest/SupportRequestSlice";
import { selectWalletBalance } from "../wallet/walletSlice";

const ReportAndIssue = (props) => {
    const history = useHistory();
    const auth = useSelector(selectLoginAuth)
    // let fullname = `${auth?.payload?.name}`
    let fullname = `${auth?.payload?.user_profiles?.firstname}`+" "+`${auth?.payload?.user_profiles?.lastname}`
    const [subjectValue, setSubjectValue] = useState("")
    const subjectData = useSelector(selectSubjectData);
    const walletData = useSelector(selectWalletBalance);
    const [description, setDescription] = useState("")
    const [document, setDocument] = useState("")
    const uploadedUrlData = useSelector(selectUploadedUrlData);
    const dispatch = useDispatch()
    const [userName, setUserName] = useState("");
    const [email, setEmail] = useState("");
    const [subject, setSubject] = useState("");
    const [selectedFiles, setSelectedFiles] = useState();
    const [note, setNote] = useState("");

    const onChangeInput = (e, fieldName) => {
        const value = e.target.value;
        if (fieldName === "userName") {
            setUserName(value);
        }
        else if (fieldName === "email") {
            setEmail(value.trim());
        }
        else if (fieldName === "subject") {
            setSubject(value);
        }
        else if (fieldName === "note") {
            setNote(value.trimStart());
        }
    };

    const onSelectFiles = (e) => {
        const files = e.target.files[0];
        let params = {
            document: files
        }
        setDocument(URL.createObjectURL(files));
        dispatch(supportApiUrlAsync(params))
    }

    const onSubmit = (e) => {
        e.preventDefault()
        let data = {
            "postData": {
                "subject_id": Number(subjectValue),
                "email": auth?.payload?.email ? auth?.payload?.email : "abc@gmail.com",
                "name": fullname,
                "document_url": [
                    {
                        "url": uploadedUrlData?.data?.payload?.document
                    }
                ],
                "notes": description,
                "type": "support_other_issue"
            },
            "tokenData": auth?.payload?.token
        }
        dispatch(supportCreateApiAsync(data))
            .then(unwrapResult)
            .then(async (obj) => {
                history.push('/report-an-issue-two')
            }
            )
            .catch((obj) => {
            })
    }

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <>
            <div className="order-heading">
                <div className="header-left-item">
                    <h4><Link to='/help-center'><img src={leftArrowImg} alt="leftArrowImg" /></Link> Report other issue</h4>
                </div>
                <div className="header-right-item">
                    <Link to="/wallet" className="coinStack">{walletData ? walletData?.data?.payload?.sila_balance : 0}
                        <img src={coinStackImg} alt="coinStack" />
                    </Link>
                </div>
            </div>

            <div className="main-content">
                <div className="more-help-part">
                    <div className="more-help-upper">
                        <h4>Report other issue</h4>
                        <p>Our gateway is routed directly to Telstra, with Optus and Vodafone networks service.</p>
                    </div>

                    <div className="more-help-form">
                        <form action="#" method="POST">
                            <input
                                className="user"
                                type="text"
                                value={fullname}
                                disabled
                            />
                            <input
                                className="email"
                                type="text"
                                value={auth?.payload?.email ? auth?.payload?.email : "abc@gmail.com"}
                                disabled
                            />
                            <select name="subject" defaultValue={"Select Subject"} onChange={(e) => setSubjectValue(e.target.value)}>
                                <option value="Select Subject" disabled>Select Subject</option>
                                {subjectData?.data?.payload?.data?.map((value, index) => {
                                    return (
                                        <option value={value?.id} key={index}>{value?.name}</option>
                                    )
                                })}
                            </select>
                            <textarea
                                name="description"
                                cols="30"
                                rows="6"
                                placeholder="Write here"
                                value={description}
                                onChange={e => setDescription(e.target.value)}
                            ></textarea>
                            <div className="upload-btn-wrapper upload-btn2">
                                <button className="filebtn">
                                    <span className="upload-item">
                                        <img src={document ? document : galleryImg} alt="" className={document ? "support_img" : ""} />
                                        <img src={document ? "" : uploadImg} alt="" className={document ? "support_img" : ""} />
                                        <span>{document ? "" : "Upload your files here"}</span>
                                    </span>
                                </button>
                                <input type="file" name="myfile" onChange={(e) => { onSelectFiles(e) }} />
                            </div>
                            <button type="submit" className="submit-btn" onClick={(e) => { onSubmit(e) }}>Submit</button>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ReportAndIssue;