import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getBookingList, cancelBookingByUser,updateBookingByUser } from './bookingApi'

const initialState = {
    status: {
    }
}
export const getBookingListAsync = createAsyncThunk("booking/getBookingList", async (data) => {
    const response = await getBookingList(data.postData, data.tokenData);
    return response;
});
export const cancelBookingByUserAsync = createAsyncThunk("booking/cancelBookingByUser", async (data) => {
    const response = await cancelBookingByUser(data.postData, data.tokenData);
    return response;
});
export const updateBookingByUserAsync = createAsyncThunk("booking/updateBookingByUser", async (data) => {
    const response = await updateBookingByUser(data.idData, data.postData, data.tokenData);
    return response;
});


export const bookingSlice = createSlice({
    name: "booking",
    initialState
});

export default bookingSlice.reducer;

