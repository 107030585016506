import React, { useEffect, useState } from "react";
import { Link, useHistory } from 'react-router-dom';
import { leftArrowImg, logoImg, biArrowDownLeftImg, biArrowUpImg, biArrowDownImg } from "../../../utilities/images";
import { getTransectionHistoryAsync, selectTransectionHistory, selectWalletBalance } from "./walletSlice";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { selectLoginAuth } from "../auth/authSlice";
import moment from "moment-timezone";
import PaginationSetting from '../../constants/PaginationSetting';
import Pagination from "@material-ui/lab/Pagination";
import { Box } from "@material-ui/core";
import CommonLoader from '../../components/shared/CommonLoader';

const Wallet = (props) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const [walletLoading, setWalletLoading] = useState(false);
    const auth = useSelector(selectLoginAuth);
    const walletData = useSelector(selectWalletBalance);
    const [pagination, setPagination] = useState(PaginationSetting);
    const [page, setPage] = useState(1);
    const transectionHistoryData = useSelector(selectTransectionHistory);

    const transactionsHistoryHandle = () => {
        let params = {
            postData: {
                page: page,
                limit: pagination.recordsPerPage,
                transaction_type: 'all'
            },
            tokenData: auth?.payload?.token
        }
        setWalletLoading(true)
        dispatch(getTransectionHistoryAsync(params))
            .then(unwrapResult)
            .then(async (obj) => {
                setWalletLoading(false)
                setPagination((prevState) => ({ ...prevState, totalPages: obj?.data?.payload?.total_pages, totalRecords: obj?.data?.payload?.total }));
            }
            )
            .catch((obj) => {
                setWalletLoading(false)
            })
    }

    useEffect(() => {
        transactionsHistoryHandle()
    }, [page])

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <>
            {walletLoading === true ?
                <CommonLoader />
                :
                <>
                    <div className="order-heading">
                        <div className="header-left-item">
                            <h4><Link to="#" onClick={() => { history.push('/more') }}><img src={leftArrowImg} alt="leftArrowImg" /></Link> JBR Wallet</h4>
                        </div>
                        <div className="header-right-item">
                            <Link to="/payment" className="coinStack">Buy</Link>
                        </div>
                    </div>

                    <div className="main-content walletjbr_">
                        <div className="wallet-mainprt">
                            <div className="wallet-balance-box">
                                <div className="wallet-balance-upper">
                                    <img src={logoImg} alt="logoImg" />
                                    <div className="wallet-balance-uprcnt">
                                        <p>Avaiable Balance</p>
                                        <h4>JBR {walletData?.data?.payload?.sila_balance}</h4>
                                    </div>
                                </div>
                                <ul>
                                    <li><Link to="#" onClick={() => history.push('/wallet-2/top_ups')}><img src={biArrowDownLeftImg} alt="biArrowDownLeftImg" /> Added</Link></li>
                                    <li><Link to="#" onClick={() => history.push('/wallet-2/payments')}><img src={biArrowUpImg} alt="biArrowUpImg" /> Purchases</Link></li>
                                    <li><Link to="#" onClick={() => history.push('/wallet-2/transfers')}><img src={biArrowDownImg} alt="biArrowDownImg" /> Earn</Link></li>
                                </ul>
                            </div>

                            <div className="wallet-balance-links">
                                <h2>Buying capacity </h2>
                                <ul>
                                    <li><Link to="#" onClick={() => { history.push('/wallet-brands') }}><strong>5</strong> Brands</Link></li>
                                    <li><Link to="#" onClick={() => { history.push('/wallet-products') }}><strong>19</strong> Products </Link></li>
                                    <li><Link to="#" onClick={() => { history.push('/wallet-shops') }}><strong>3</strong> Shops</Link></li>
                                </ul>
                            </div>

                            <div className="transection-history">
                                <h2>Transection history</h2>
                                <ul>
                                    {
                                        transectionHistoryData?.data?.payload?.data?.length > 0 ?
                                            transectionHistoryData?.data?.payload?.data?.map((val, ind) => {
                                                return (
                                                    <li key={ind}>
                                                        <div className="transhist-left">
                                                            <img src={val?.payment_type === "issue_sila" ? biArrowDownLeftImg : val?.payment_type === "transfer" ? biArrowUpImg : biArrowDownImg} alt="biArrowDownLeftImg" />
                                                            <div className="transhist-cnt">
                                                                <h4>{val?.payment_type}</h4>
                                                                <p>{moment(val?.createdAt).format('DD MMM,YYYY')}</p>
                                                            </div>
                                                        </div>
                                                        <div className="transhist-right">
                                                            <p>JBR {val?.amount}</p>
                                                        </div>
                                                    </li>
                                                )
                                            })
                                            : "No Transection History"
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>
                </>}
            {pagination.totalRecords > pagination.recordsPerPage &&
                <Box py={3} display="flex" justifyContent="center">
                    <Pagination
                        key={page}
                        count={pagination.totalPages || 0}
                        color="secondary"
                        variant="outlined"
                        onChange={(e, value) => setPage(value)}
                        defaultPage={page}
                    />
                </Box>
            }
        </>
    );
}

export default Wallet;
