import axios from "axios";

const queryString = require('query-string');

export function getTermsPrivacy(data, token) {
    return new Promise((resolve, reject) =>
        axios
            .get(`${process.env.REACT_APP_STAGING_USER_SERVICES}api/v1/settings`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token,
                    'app-name': 'b2c'
                }
            })
            .then(function (response) {
                resolve(response);
            })
            .catch(function (error) {
                reject(error)
                console.error(error);
            })

    )
}
export function getSettingsApi(data, token) {
    let query = queryString.stringify(data);
    return new Promise((resolve, reject) =>
        axios
            .get(`${process.env.REACT_APP_STAGING_USER_SERVICES}api/v1/user_settings` + '?' + query, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token,
                    'app-name': 'b2c'
                }
            })
            .then(function (response) {
                resolve(response);
            })
            .catch(function (error) {
                reject(error)
                console.error(error);
            })

    )
}
export function updateNotificationSettingApi(data, token) {
    return new Promise((resolve, reject) =>
        axios
            .patch(`${process.env.REACT_APP_STAGING_USER_SERVICES}api/v1/user_settings`, data, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token,
                    'app-name': 'b2c'
                }
            })
            .then(function (response) {
                resolve(response);
                // toast.success(response?.data?.msg)
            })
            .catch(function (error) {
                reject(error)
                console.error(error);
            })
    )
}