import axios from "axios";
const queryString = require('query-string');

export function getSupportApi(data,token) {
  let query=queryString.stringify(data)
    return new Promise((resolve, reject) =>
      axios
        .get(`${process.env.REACT_APP_STAGING_SUPPORT}api/v1/supports/user`+"?"+query, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': token,
            'app-name': 'b2c'
          }
        })
        .then(function (response) {
          resolve(response);
        })
        .catch(function (error) {
          console.error(error);
          reject(error)
        })
    )
  }
  
  export function getSubjectApi() {
    return new Promise((resolve, reject) =>
      axios
        .get(`${process.env.REACT_APP_STAGING_SUPPORT}api/v1/subjects`, {
          headers: {
            'Content-Type': 'application/json',
            'app-name': 'b2c'
          }
        })
        .then(function (response) {
          resolve(response);
        })
        .catch(function (error) {
          console.error(error);
          reject(error)
        })
    )
  }
  
  export function supportDocumentUrlApi(data) {
    var formData = new FormData();
    formData.append('document', data.document);
    return new Promise((resolve, reject) =>
      axios
        .post(`${process.env.REACT_APP_STAGING_SUPPORT}api/v1/supports/document`, formData, {
          headers: {
            'Content-Type': 'application/json',
            'app-name': 'b2c'
          }
        })
        .then(function (response) {
          resolve(response);
        })
        .catch(function (error) {
          console.error(error);
          reject(error)
        })
    )
  }
  
  export function createSupportRequestApi(data, token) {
    return new Promise((resolve, reject) =>
      axios
        .post(`${process.env.REACT_APP_STAGING_SUPPORT}api/v1/supports`, data, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': token,
            'app-name': 'b2c'
          }
        })
        .then(function (response) {
          localStorage.removeItem("invaliddata")
          resolve(response);
        })
        .catch(function (error) {
          localStorage.setItem("invaliddata", error?.response?.data?.msg)
          console.error(error);
          reject(error)
        })
    )
  }
  export function getSupportByIdApi(data,token) {
      return new Promise((resolve, reject) =>
        axios
          .get(`${process.env.REACT_APP_STAGING_SUPPORT}api/v1/support_comments/support/${data.id}`, {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': token,
              'app-name': 'b2c'
            }
          })
          .then(function (response) {
            resolve(response);
          })
          .catch(function (error) {
            console.error(error);
            reject(error)
          })
      )
    }