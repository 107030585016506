import { unwrapResult } from "@reduxjs/toolkit";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from 'react-router-dom';
import { leftArrowImg, galleryImg, uploadImg, coinStackImg } from "../../../utilities/images";
import { selectLoginAuth } from "../auth/authSlice";
import { getSubjectApiAsync, selectSubjectData, selectUploadedUrlData, supportApiUrlAsync, supportCreateApiAsync } from "../supportRequest/SupportRequestSlice";
import { toast } from "react-toastify";
import { selectWalletBalance } from "../wallet/walletSlice";

const HomeSupport = (props) => {
    const history = useHistory();
    const subjectData = useSelector(selectSubjectData);
    const [document, setDocument] = useState("");
    const [subject, setSubject] = useState("");
    const auth = useSelector(selectLoginAuth);
    const walletData = useSelector(selectWalletBalance);
    const toastId = React.useRef(null);
    const dispatch = useDispatch();
    const uploadedUrlData = useSelector(selectUploadedUrlData);
    const [supportImage, setSupportImage] = useState("")
    const [note, setNote] = useState("");
    let fullname = `${auth?.payload?.user_profiles?.firstname}` + " " + `${auth?.payload?.user_profiles?.lastname}`

    const onChangeInput = (e, fieldName) => {
        const value = e.target.value;
        if (fieldName === "subject") {
            setSubject(value);
        }
        else if (fieldName === "note") {
            setNote(value.trimStart());
        }
    };

    const onSelectFiles = (e) => {
        const imageFile = e.target.files[0];
        let params = {
            document: imageFile
        }
        setDocument(URL.createObjectURL(imageFile));
        dispatch(supportApiUrlAsync(params))
            .then(unwrapResult)
            .then(async (obj) => {
                setSupportImage(obj?.data?.payload?.document)
            }
            )
            .catch((obj) => {
            })
    }

    const onSubmit = async (e) => {
        e.preventDefault()
        if (!subject) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error("Please select subject type");
            }
            return;
        }
        if (!note) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error("Please enter a note");
            }
            return;
        }
        if (!supportImage) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error("Please select image");
            }
            return;
        }
        let data = {
            "postData": {
                "subject_id": Number(subject),
                "email": auth?.payload?.email ? auth?.payload?.email : "abc@gmail.com",
                "name": fullname,
                "document_url": [
                    {
                        "url": uploadedUrlData?.data?.payload?.document
                    }
                ],
                "notes": note,
                "type": "support_other_issue"
            },
            "tokenData": auth?.payload?.token
        }

        dispatch(supportCreateApiAsync(data))
            .then(unwrapResult)
            .then(async (obj) => {
                setDocument("")
                setNote("")
                if (!toast.isActive(toastId.current)) {
                    toastId.current = toast.success(obj?.data?.msg);
                }
                history.push('/help-center-three')
            }
            )
            .catch((obj) => {
            })
    }
    useEffect(() => {
        dispatch(getSubjectApiAsync())
    }, []);
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <>
            <div className="order-heading">
                <div className="header-left-item">
                    <h4><Link to="#" onClick={() => { history.goBack() }}><img src={leftArrowImg} alt="leftArrowImg" /></Link> Get help with my orders</h4>
                </div>
                <Link to="/wallet" className="coinStack">{walletData ? walletData?.data?.payload?.sila_balance : 0}
                    <img src={coinStackImg} alt="coinStack" />
                </Link>
            </div>

            <div className="main-content">
                <div className="more-help-part">
                    <div className="more-help-upper">
                        <h4>Need more help</h4>
                        <p>Our gateway is routed directly to Telstra, with Optus and Vodafone networks service.</p>
                    </div>

                    <div className="more-help-form">
                        <form action="#" method="POST">
                            <input
                                className="user"
                                type="text"
                                value={fullname}
                                disabled
                            />
                            <input
                                className="email"
                                type="text"
                                value={auth?.payload?.email ? auth?.payload?.email : ""}
                                disabled
                            />
                            <select name="subject" defaultValue={"Select Subject"} onChange={(e) => { onChangeInput(e, 'subject') }}>
                                <option value="Select Subject" disabled>Select Subject</option>
                                {subjectData?.data?.payload?.data?.map((value, index) => {
                                    return (
                                        <option value={value?.id} key={index}>{value?.name}</option>
                                    )
                                })}
                            </select>
                            <textarea name="" id="" cols="30" rows="6" placeholder="Write here" value={note} onChange={(e) => { onChangeInput(e, 'note') }}></textarea>
                            <div className="upload-btn-wrapper upload-btn2">
                                <button className="filebtn" >
                                    <span className="upload-cnt">
                                    </span>
                                    <span className="upload-item">
                                        <img src={document ? document : galleryImg} alt="" className={document ? "support_img" : ""} />
                                        <img src={document ? "" : uploadImg} alt="" className={document ? "support_img" : ""} />
                                        <span>{document ? "" : "Upload your files here"}</span>
                                    </span>
                                </button>
                                <input type="file" name="myfile" onChange={(e) => { onSelectFiles(e) }} />
                            </div>
                            <button type="button" className="submit-btn" onClick={(e) => { onSubmit(e) }}>Submit</button>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
}

export default HomeSupport;