import React, { useEffect, useState } from 'react'
import ProductView from './ProductView';
import MapView from './MapView';
import { Link, useHistory, useParams } from 'react-router-dom';
import { bagImg, heart2, leftArrowImg, mapViews, mapViewsActive, productView, productViewActive } from '../../../utilities/images';
import Badge from "@material-ui/core/Badge"



const HomeCategory = () => {
    const [tabData, setTabData] = useState("tab1");
    const { categoryName } = useParams();
    const history = useHistory()
    const pathName = window.location.pathname;
    const [count, setCount] = useState("")

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <>
            <div className="header-part browse-addprdcard-header">
                <div className='row align-items-center'>
                    <div className="header-left-item col-6 col-md-6">
                        <div className="header-left">
                            {(pathName === '/dashboard') ? "" :
                                <Link to="/dashboard">
                                    <img src={leftArrowImg} alt="leftArrow" />
                                </Link>
                            }
                            <h6 className='subhead_'>{categoryName}</h6>
                        
                            {/* <Link to="#">{selectProfile?.payload?.user_profiles?.firstname}</Link> */}
                            {/* <p className="location_select" onClick={() => history.push('location-select')}>{selectProfile?.payload?.current_location?.formatted_address ? `${(selectProfile?.payload?.current_location?.formatted_address).substring(0, 40)}...` : "Add Address"}</p> */}
                        </div>
                    </div>
                    <div className="header-right-item col-6 col-md-6 text-end">
                        <button type="button" >
                            <img src={heart2} alt="bellImg" />
                        </button>
                        {
                            count !== "" ?
                            <Badge overlap="rectangular" color="secondary" badgeContent={count}>
                            <button type="button" onClick={() => history.push('/cart')}><img src={bagImg} alt="bagImg" /></button>
                            </Badge>
                            :
                            <button type="button" onClick={() => history.push('/cart')}><img src={bagImg} alt="bagImg" /></button>
                        }
                    </div>
                </div>
            </div>
            <div className="main-content">
                <div className="home-tab">
                    <div className="tabs">
                        <div className="tab-upper tab-upperhm">
                            <ul className="tab-links">
                                <li className={(tabData === "tab1") ? "tabItems active" : "tabItems"} onClick={() => setTabData("tab1")}>
                                    <Link to="#product-view" className='productTabLink'>
                                        <img src={productView} alt='' className='me-2 productViewImg'/> 
                                        <img src={productViewActive} alt='' className='me-2 productViewActive'/> 
                                        <span>Product View</span>
                                    </Link>
                                </li>
                                <li className={(tabData === "tab2") ? "tabItems active" : "tabItems"} onClick={() => setTabData("tab2")}>
                                    <Link to="#map-view" className='productTabLink'>
                                        <img src={mapViews} alt='' className='me-2 productViewImg' /> 
                                        <img src={mapViewsActive} alt='' className='me-2 productViewActive' /> 
                                        <span>Map View</span>
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="tab-content">
                        {
                            (tabData === "tab1") ?
                                <ProductView />
                                : (tabData === "tab2") ?
                                    <MapView value="food" />
                                    : <></>
                        }
                    </div>
                </div>
            </div>

        </>
    )
}

export default HomeCategory
