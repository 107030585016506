import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { clockImg, deliveryParcelImg, ProductSt8, starCircleImg } from '../../../utilities/images';
import { Link } from 'react-router-dom';
import { getBannerByServiceTypeAsync, getCategoryApiAsync, getTrendingNearBySellerAsync } from './dashboardSlice';
import { useDispatch, useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { selectLoginAuth } from '../auth/authSlice';
import Slider from "react-slick";
import SliderWrapper from "./SliderStyle";
import CommonLoader from '../../components/shared/CommonLoader';
import PaginationSetting from '../../constants/PaginationSetting';
import { Box } from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";


const ProductView = () => {
    const history = useHistory();
    const { id, flag, categoryName } = useParams();
    const PathName = window.location.pathname;
    const auth = useSelector(selectLoginAuth);
    const [pagination, setPagination] = useState(PaginationSetting);
    const [page, setPage] = useState(1);
    const [categoryLoading, setCategoryLoading] = useState(false);
    const dispatch = useDispatch();
    const [bannerData, setBannerData] = useState({});
    const [subCategoryData, setSubCategoryData] = useState({});
    const [trendingSellerData, setTrendingSellerData] = useState({});

    const bannerSettings = {
        dots: true,
        autoplay: true,
        autoplaySpeed: 2000,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
        speed: 500,
        arrows: false,
        adaptiveHeight: true,
        appendDots: (dots) => <ul>{dots}</ul>,
        customPaging: (i) => <div className="slideContent" >{`${i + 1}/${(bannerData?.data?.payload?.data?.length)}`}</div>
    };

    const subCategoryDataHandle = () => {
        setCategoryLoading(true)
        let categoryParam = {
            "postData": {
                category_id: id,
                main_category: true,
                b2b_service_type: flag
            },
            "tokenData": auth?.payload?.token
        }
        dispatch(getCategoryApiAsync(categoryParam))
            .then(unwrapResult)
            .then(async (obj) => {
                setSubCategoryData(obj)
                setCategoryLoading(false)
            }
            )
            .catch((obj) => {
                setCategoryLoading(false)
            })
    }

    const bannerDataHandle = () => {
        setCategoryLoading(true)
        let bannerData = {
            "postData": {
                b2b_service_type: flag,
                category_id:id
            },
            "tokenData": auth?.payload?.token
        }
        dispatch(getBannerByServiceTypeAsync(bannerData))
            .then(unwrapResult)
            .then(async (obj) => {
                setBannerData(obj)
                setCategoryLoading(false)
            }
            )
            .catch((obj) => {
                setCategoryLoading(false)
            })
    }

    const trendingSellerDataHandle = () => {
        setCategoryLoading(true)
        let params = {
            postData: {
                page: page,
                limit: pagination.recordsPerPage,
                b2b_service_type: flag,
                category_id:id,
                lat: 30.7355,
                long: 76.7121,
                need_trending: true,
                need_nearby: true
            },
            tokenData: auth?.payload?.token
        }
        dispatch(getTrendingNearBySellerAsync(params))
            .then(unwrapResult)
            .then(async (obj) => {
                setTrendingSellerData(obj)
                setPagination((prevState) => ({ ...prevState, totalPages: obj?.data?.payload?.sellers?.total_pages, totalRecords: obj?.data?.payload?.sellers?.total }));
                setCategoryLoading(false)
            }
            )
            .catch((obj) => {
                setCategoryLoading(false)
            })
    }

    useEffect(() => {
        subCategoryDataHandle()
        bannerDataHandle()
    }, [])

    useEffect(() => {
        trendingSellerDataHandle()
    }, [page])

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <>
            <div className="search-barhm">
                <form action="#" method="POST">
                    <div className='searchGroup'>
                        <input type="search" placeholder="Search here" onFocus={() => history.push('/browse')} />
                    </div>
                </form>
            </div>
            {
                categoryLoading === true ?
                    <CommonLoader />
                    :
                    <>
                        <div className="retail-part retail-part2">
                            {
                                subCategoryData?.data?.payload?.data?.slice(0, 3)?.map((val, index) => {
                                    return (
                                        <div className="retail-item" key={index} onClick={() => history.push(`/subcategory-list/${id}/${flag}/${categoryName}/${val?.id}`)}>
                                            <span>
                                                <img src={val?.image} alt="" />
                                            </span>
                                            <p>{val?.name}</p>
                                        </div>
                                    )
                                })
                            }

                            {subCategoryData?.data?.payload?.data?.length > 3 ?
                                <div className="retail-item" onClick={() => history.push(`/subcategory-list/${id}/${flag}/${categoryName}`)}>
                                    <Link to="#">
                                        <img src={ProductSt8} alt="" />
                                    </Link>
                                    <p>All</p>
                                </div> : null
                            }

                        </div>
                        {/* mega slider */}

                        <div id='bannerSlider' className="banner-slider trslider-part">
                            <SliderWrapper>
                                <Slider {...bannerSettings}>
                                    {
                                        bannerData?.data?.payload?.data && bannerData?.data?.payload?.data?.map((val, index) => {
                                            return (
                                                <div className="carousel-cell" key={index}>
                                                    <img src={val?.path} alt="" />
                                                </div>
                                            )
                                        })
                                    }
                                </Slider>
                            </SliderWrapper>
                        </div>
                        {
                            trendingSellerData?.data?.payload?.sellers?.data?.length > 0 &&
                            <div className="delivery-parthm p-bottom">
                                <div className="trending-title pt-5">
                                    <h2>Stores Nearby</h2>
                                </div>
                                {trendingSellerData?.data?.payload?.sellers?.data?.map((value, index) => {
                                    return (
                                        <React.Fragment key={index}>
                                            <div className="delivery-sechm" style={{ cursor: 'pointer' }} onClick={flag === "product" ? () => history.push({ pathname: `/home-store/${value?.unique_uuid}`, state: PathName }) : () => history.push({ pathname: `/home-store-services/${value?.unique_uuid}`, state: PathName })}>
                                                <img className="delv-prd" src={value?.user_profiles?.profile_photo} alt="" />
                                                <div className="storeText_">
                                                <h4>{value?.user_profiles?.firstname}</h4>
                                                <ul>
                                                    <li><img src={starCircleImg} alt="starCircleImg" />{value?.sellerRating?.rating}</li>
                                                    <li><img src={clockImg} alt="clockImg" />{value?.distance?.timeString}</li>
                                                    <li><img src={deliveryParcelImg} alt="deliveryParcelImg" />{value?.deliveryFee} Delivery fee</li>
                                                </ul>
                                            </div>
                                            <div className="storeOverlay_"></div>
                                            </div>

                                        </React.Fragment>
                                    )
                                })
                                }
                            </div>

                        }
                    </>}
            {pagination.totalRecords > pagination.recordsPerPage &&
                <Box py={3} display="flex" justifyContent="center">
                    <Pagination
                        key={page}
                        count={pagination.totalPages || 0}
                        color="secondary"
                        variant="outlined"
                        onChange={(e, value) => setPage(value)}
                        defaultPage={page}
                    />
                </Box>
            }
        </>
    )
}

export default ProductView
