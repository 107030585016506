import React, { useEffect } from 'react'
import { Link } from 'react-router-dom';
import { Cross } from "../../../utilities/images";

const ReorderView = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div className="main-wrapper">
            <div className="order-heading reorder-header">
                <Link to="#">
                    <img src={Cross} alt="" />
                </Link>
                <h4>Previous order <span>Friday, July 15 2022</span></h4>
            </div>
                                                                                                                                                                       
            <div className="main-content">
                <div className="container reord-mainprt">
                    <div className="slitem-part">
                        <h4>Select Items</h4>
                        <form action="#" method="POST">
                            <ul>
                                <li>
                                    <div className="form-group">
                                        <input type="checkbox" id="check" />
                                        <label htmlFor="check"><strong>1</strong> <small>x</small> Marlboro Red Gold <span>(Packet)</span></label>
                                    </div>
                                    <p>$6.56</p>
                                </li>
                                <li>
                                    <div className="form-group">
                                        <input type="checkbox" id="check2" />
                                        <label htmlFor="check2"><strong>1</strong> <small>x</small> Marlboro Red Gold <span>(Packet)</span></label>
                                    </div>
                                    <p>$6.56</p>
                                </li>
                                <li>
                                    <div className="form-group">
                                        <input type="checkbox" id="check3" />
                                        <label htmlFor="check3"><strong>1</strong> <small>x</small> Marlboro Red Gold <span>(Packet)</span></label>
                                    </div>
                                    <p>$6.56</p>
                                </li>
                            </ul>
                        </form>
                        <div className="addmore-item">
                            <Link to="#">Add more items</Link>
                        </div>
                    </div>

                    <div className="reorder-btn mt-auto">
                        <Link to='/cart'>Add to cart</Link>
                    </div>
                </div>
            </div>


        </div>
    )
}

export default ReorderView