import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
    getDeliveryTypesApi,
    getAllDeliveryTypesApi
} from "./deliveryApi";

const initialState = {
    deliveryTypes: [],
    status: {
        deliveryTypes: 'idle',
    },
};

export const getDeliveryTypesApiAsync = createAsyncThunk("delivery/getDeliveryTypesApi", async (data) => {
    const response = await getDeliveryTypesApi(data.postData, data.tokenData);
    return response;
});
export const getAllDeliveryTypesApiAsync = createAsyncThunk("delivery/getAllDeliveryTypesApi", async (data) => {
    const response = await getAllDeliveryTypesApi(data);
    return response;
});

export const deliverySlice = createSlice({
    name: "delivery",
    initialState,
    extraReducers: (builder) => {
        builder
        .addCase(getDeliveryTypesApiAsync.pending, (state) => {
            state.status = "loading";
        })
        .addCase(getDeliveryTypesApiAsync.fulfilled, (state, { payload }) => {
            state.deliveryTypes = payload
            state.status = "idle";
        })
        .addCase(getDeliveryTypesApiAsync.rejected, (state, { error }) => {
            state.status = "idle";
            state.deliveryTypes = null
    })
    .addCase(getAllDeliveryTypesApiAsync.pending, (state) => {
        state.status = "loading";
    })
    .addCase(getAllDeliveryTypesApiAsync.fulfilled, (state, { payload }) => {
        state.deliveryTypes = payload
        state.status = "idle";
    })
    .addCase(getAllDeliveryTypesApiAsync.rejected, (state, { error }) => {
        state.status = "idle";
        state.deliveryTypes = null
})
    },
});

export const selectDeliveryTypeData = (state) => state.delivery.deliveryTypes;

export default deliverySlice.reducer;