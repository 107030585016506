import React, { useEffect, useState } from "react";
import { Link, useHistory, useLocation } from 'react-router-dom';
// import CustomModal from "../../components/shared/CustomModal";
import { cameraImg, leftArrowImg } from "../../../utilities/images";
import ReactStars from 'react-stars'
import { unwrapResult } from "@reduxjs/toolkit";
import { selectLoginAuth, uploadIdCardAsync } from "../auth/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { getRatingDetailsAsync, updateProductReviewAsync } from "./productReviewSlice";
import { toast } from 'react-toastify';



const ProductReviewEdit = (props) => {
    const location = useLocation();
    const history = useHistory();
    const results = location?.state;
    const [document, setDocument] = useState("");
    const [loading, setLoading] = useState(false);
    const toastId = React.useRef(null);
    const dispatch = useDispatch();
    const auth = useSelector(selectLoginAuth);
    const [productRating, setProductRating] = useState("");
    const [qualityRating, setQualityRating] = useState("");
    const [moneyRating, setMoneyRating] = useState("");
    const [fileData, setFileData] = useState([]);
    const [note, setNote] = useState("");
    // const [key, setKey] = useState(Math.random());
    // const [modalDetail, setModalDetail] = useState({
    //     show: false,
    //     title: "",
    //     flag: ""
    // });
    // const handleOnCloseModal = () => {
    //     setModalDetail({
    //         show: false,
    //         title: "",
    //         flag: ""
    //     });
    //     setKey(Math.random());
    // }

    const handleSubmit = () => {
        const submitData = {
            "postData": {
                "order_id": results?.order_detail?.order_id?.toString(),
                "product_id": results?.id?.toString(),
                "seller_id": results?.seller_detail?.unique_uuid,
                "rating": productRating,
                "comment": note,
                "product_quality_rating": qualityRating,
                "value_of_money_rating": moneyRating,
                "images": [{
                    "document": document
                }]
            },
            "tokenData": auth?.payload?.token
        }
        setLoading(true)
        dispatch(updateProductReviewAsync(submitData))
            .then(unwrapResult)
            .then(async (obj) => {
                setLoading(false)
                if (!toast.isActive(toastId.current)) {
                    toastId.current = toast.success(obj?.data?.msg)
                    history.push('/product-review-available')
                }
            })
            .catch((obj) => {
                setLoading(false)
            })
    }

    let imageFile;
    let fileObj = [];
    let fileArray = [];
    let fileDetails = []

    const handleChange = (e) => {
        imageFile = e.target.files[0];
        let params = {
            document: imageFile
        }
        dispatch(uploadIdCardAsync(params))
            .then(unwrapResult)
            .then((obj) => {
                setDocument(obj.payload.profile_photo)
                if (!toast.isActive(toastId.current)) {
                    toastId.current = toast.success(obj?.msg)
                }
            }
            )
            .catch((obj) => {
                if (!toast.isActive(toastId.current)) {
                    toastId.current = toast.error(obj?.message)
                }
            })
        // setImageData(imageFile)
        // setDocument(URL.createObjectURL(imageFile));
        fileObj.push(e.target.files)
        for (let i = 0; i < fileObj[0].length; i++) {
            fileArray.push({ url: URL.createObjectURL(fileObj[0][i]), name: fileObj[0][i]?.name, size: fileObj[0][i]?.size })
        }
        setFileData(fileArray)
    }

    const onChangeInput = (e, fieldName) => {
        const value = e.target.value;
        if (fieldName === "note") {
            setNote(value.trimStart());
        }
    };
    const ratingDetailsHandle = () => {
        const ratingParams = {
            "postData": {
                user_id: results?.order_detail?.user_id,
                order_id: results?.order_detail?.order_id,
                flag_id: results?.id
            },
            "tokenData": auth?.payload?.token
        }
        dispatch(getRatingDetailsAsync(ratingParams))
            .then(unwrapResult)
            .then((obj) => {
                setProductRating(obj?.data?.payload?.rating)
                setQualityRating(obj?.data?.payload?.product_quality_rating)
                setMoneyRating(obj?.data?.payload?.value_of_money_rating)
                setNote(obj?.data?.payload?.comment)
                setNote(obj?.data?.payload?.comment)
                setFileData(obj?.data?.payload?.images)
            }
            )
            .catch((obj) => {
            })
    }

    useEffect(() => {
        ratingDetailsHandle()
    }, [])

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <>
            <div className="order-heading">
                <h4><Link to='/product-review-available'><img src={leftArrowImg} alt="leftArrowImg" /></Link> Product review</h4>
            </div>

            <div className="main-content">
                <div className="product-review-area">
                    <div className="rate-upper">
                        <h2>Rate your experience</h2>
                        <p>Are you satisfy with product?</p>
                    </div>

                    <div className="rating-partex">
                        <div className="rating-leftex">
                            <img src={results?.image} alt="review1Img" />
                        </div>
                        <div className="rating-rightex">
                            <h4>{results?.name}</h4>
                            <div className="rateYo">
                                <ReactStars
                                    count={5}
                                    half={false}
                                    value={Number(productRating)}
                                    onChange={(e) => setProductRating(e)}
                                    size={30}
                                    color2={'#FCBA30'}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="rating-innerex">
                        <div className="rating-inrleftex">
                            <p>Product Quality</p>
                        </div>
                        <div className="rating-inrrightex">
                            <div className="rateYo">
                                <ReactStars
                                    count={5}
                                    half={false}
                                    value={Number(qualityRating)}
                                    onChange={(e) => setQualityRating(e)}
                                    size={30}
                                    color2={'#FCBA30'}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="rating-innerex">
                        <div className="rating-inrleftex">
                            <p>Value of Money</p>
                        </div>
                        <div className="rating-inrrightex">
                            <div className="rateYo">
                                <ReactStars
                                    count={5}
                                    half={false}
                                    value={Number(moneyRating)}
                                    onChange={(e) => setMoneyRating(e)}
                                    size={30}
                                    color2={'#FCBA30'}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="additional-box">
                        <h4>Write your additional feedback</h4>
                        <form action="#" method="POST"  >
                            <textarea name="" id="" cols="30" rows="5" value={note} onChange={(e) => { onChangeInput(e, 'note') }}>Thank you for your help and quick delivery which enable our customer to meet his deadline – excellent service.</textarea>
                        </form>
                    </div>

                    <div className="attach-part">
                        <h4>Attach image</h4>
                        <div className="upload-btn-wrapper">
                            <button className="filebtn"><img src={cameraImg} alt="cameraImg" /></button>
                            <input type="file" name="myfile" onChange={(e) => handleChange(e)} multiple />
                        </div>
                        {/* <div className="attach-img"> */}
                        {/* <img src={attach1Img} alt="attach1Img" />
                            <img src={attach2Img} alt="attach2Img" />
                            <img src={attach3Img} alt="attach3Img" />
                            <img src={attach4Img} alt="attach4Img" /> */}
                        {/* </div> */}
                    </div>
                    {(fileData) ?
                        <>
                            {
                                (fileData || [])?.map((data, index) => (
                                    <div className="attach-item" key={index}>
                                        <img src={data?.document} alt="attachImg" />
                                        {/* <div className="attach-cnt">
                                            <h4>{data?.name}</h4> 
                                             <p>{data?.size} kb</p>
                                             <span></span>
                                        </div> */}
                                    </div>
                                ))
                            }
                        </>
                        : ""
                    }

                    <div className="submit-btnrating">
                        <Link  to='#' >
                            <button type="button" className="btn" disabled={loading === true} onClick={() => handleSubmit()}>
                                {loading === true && (
                                    <span className="spinner-border spinner-border-sm"></span>
                                )}
                                &nbsp;&nbsp;
                                <span>Submit</span>
                            </button>
                        </Link>
                    </div>
                </div>
            </div>
        </>
    );
}


export default ProductReviewEdit;
