import React, { useState, useEffect } from "react";
import { Link, useHistory, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import { leftArrowImg, categoryImg, addCouponImg } from "../../../utilities/images";
import { getAllCouponsByCategoryIdAsync, selectVerifiedCoupon, verifiedCouponsApiAsync } from "./couponSlice";
import moment from "moment-timezone";
import { selectLoginAuth } from "../auth/authSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { getCategoryApiAsync } from "../dashboard/dashboardSlice";
import CommonLoader from "../../components/shared/CommonLoader";
import CustomModal from "../../components/shared/CustomModal";
import ProductModal from "../dashboard/ProductModal";
import Slider from "react-slick";
import SliderWrapper from "../dashboard/SliderStyle";

const CouponsTwo = (props) => {
    const history = useHistory();
    const [couponCode, setCouponCode] = useState("");
    const { sellerId, serviceId } = useParams();
    const dispatch = useDispatch();
    const [categoryData, setCategoryData] = useState({});
    const [couponLoading, setCouponLoading] = useState(false);
    const [categoryLoading, setCategoryLoading] = useState(false);
    const [categorySelected, setCategorySelected] = useState('');
    const toastId = React.useRef(null);
    const [couponsData, setCouponsData] = useState({})
    const couponVerifiedData = useSelector(selectVerifiedCoupon);
    let cartPrice = localStorage.getItem("cartprice");
    const auth = useSelector(selectLoginAuth);
    const [key, setKey] = useState(Math.random());
    const [modalDetail, setModalDetail] = useState({
        show: false,
        title: "",
        flag: ""
    });
   
    const handleOnCloseModal = () => {
        setModalDetail({
            show: false,
            title: "",
            flag: ""
        });
        setKey(Math.random());
    }
    const handleChange = (e, fieldName) => {
        const value = e.target.value;
        if (fieldName === "couponCode") {
            setCouponCode(value);
        }
    }
    const offersSettings = {
        dots: false,
        autoplay: true,
        autoplaySpeed: 2000,
        infinite: true,
        slidesToShow: 2,
        centerMode: true,
        centerPadding: '20px',
        slidesToScroll: 1,
        initialSlide: 0,
        speed: 500,
        arrows: false,
        adaptiveHeight: true
    };
    const handleCategory = async (data) => {
        setCategorySelected(data?.name)
        setCouponLoading(true)
        let paramsData = {
            postData: {
                category_id: data?.id
            },
            tokenData: auth?.payload?.token
        }
        dispatch(getAllCouponsByCategoryIdAsync(paramsData))
            .then(unwrapResult)
            .then((obj) => {
                setCouponsData(obj)
                setCouponLoading(false)
                if (!toast.isActive(toastId.current)) {
                    toastId.current = toast.success(obj?.msg)
                }
            }
            )
            .catch((obj) => {
                setCouponLoading(false)
                if (!toast.isActive(toastId.current)) {
                    toastId.current = toast.error(obj?.message)
                    console.log(obj)
                }
            })
    }

    const handleAddCoupon = async () => {
        let data = {
            "postData": {
                code: couponCode,
                seller_id: sellerId,
                service_id: serviceId,
                order_amount: cartPrice
            },
            "tokenData": auth?.payload?.token
        }
        await dispatch(verifiedCouponsApiAsync(data))
            .then(unwrapResult)
            .then((obj) => {
                if (!toast.isActive(toastId.current)) {
                    toastId.current = toast.success(obj?.data?.msg)
                }
                localStorage.setItem("couponsdata", JSON.stringify(couponVerifiedData?.data?.payload))
                history.push('/cart')
            }
            )
            .catch((obj) => {
                // if (!toast.isActive(toastId.current)) {
                //     toastId.current = toast.error(obj?.message)
                // }
            })
    }

    const handleApply = (value) => {
        localStorage.setItem("couponsdata", JSON.stringify(value))
        history.push('/cart')
    }
    const categoryDataHandle = () => {
        let categoryParams = {
            'postData': {
                service_type: 'product'
            },
            'tokenData': auth?.payload?.token
        }
        setCouponLoading(true)
        dispatch(getCategoryApiAsync(categoryParams))
            .then(unwrapResult)
            .then((obj) => {
                setCategoryData(obj)
                let data = {
                    'postData': {
                        category_id: obj?.data?.payload?.data[0]?.id,
                    },
                    'tokenData': auth?.payload?.token
                }
                setCategorySelected(obj?.data?.payload?.data[0]?.name)
                dispatch(getAllCouponsByCategoryIdAsync(data))
                    .then(unwrapResult)
                    .then((obj) => {
                        setCouponsData(obj)
                        setCouponLoading(false)
                        if (!toast.isActive(toastId.current)) {
                            toastId.current = toast.success(obj?.msg)
                        }
                    }
                    )
                    .catch((obj) => {
                        setCouponLoading(false)
                        if (!toast.isActive(toastId.current)) {
                            toastId.current = toast.error(obj?.message)
                            console.log(obj)
                        }
                    })
            }
            )
            .catch((obj) => {
                setCouponLoading(false)
            })
    }

    useEffect(() => {
        categoryDataHandle()
        window.scrollTo(0, 0)
    }, [])

    return (
        <>
            {couponLoading === true ?
                <CommonLoader />
                :
                <>
                    <div className="header-part browse-addprdcard-header">
                        <div className="row align-items-center">
                            <div className="header-left-item col-6 col-md-6">
                                <h6 className="mb-0"><Link to='/more'><img src={leftArrowImg} alt="leftArrowImg" /></Link> Coupons</h6>
                            </div>
                            <div className="header-right-item col-6 col-md-6 text-end">
                                <Link className="hisText" to="/coupons-history">History</Link>
                            </div>
                        </div>
                    </div>
                    <div className="main-content">

                        <div className="coupon-part usdPart_">
                            <div className="usdBox_"><strong className="usdsubtext">USD $250</strong> <p className="usdpara_">saved this month</p> </div>
                            <div className="addCoupn" onClick={() => {
                                setModalDetail({
                                    show: true,
                                    flag: "addCoupon"
                                })
                                setKey(Math.random())
                            }}><Link className="addcupn" to="#"><img className="me-1" src={addCouponImg} alt="" /> Add a coupon</Link> 
                            </div>
                        </div>
                        <div className="maphm-btmprt">
                            <div className="maphm-btmlinks">
                                <ul className="card_category">
                                    <li className="card_categoryItems" >
                                        <img className="allIcon" onClick={() => {
                                            setModalDetail({
                                                show: true,
                                                flag: "products"
                                            });
                                            setKey(Math.random())
                                        }} src={categoryImg} alt=''/>
                                    </li>
                                    {
                                        categoryData?.data?.payload?.data?.map((val, index) => {
                                            return (
                                                <li className="card_categoryItems" style={{ zIndex: categoryData?.data?.payload?.data?.filter(item => item?.image !== null).length - index }} key={index} onClick={() => { handleCategory(val) }}><Link to="#" className={(categorySelected === val?.name) ? `active` : ''}><img src={val?.image} alt="catImg" /> {val?.name}</Link></li>
                                            )
                                        })
                                    }
                                </ul>
                            </div>
                        </div>

                        <div className="coupon-tab">
                            <div>
                                <div className="select_coupon">
                                    {couponsData?.data?.payload?.length > 0 ?
                                        <div className="offer-areahm p-0">
                                            {couponsData?.data?.payload?.map((val, index) => {
                                                return (
                                                    <React.Fragment key={index}>
                                                        <div className="offer-title mb-3">
                                                            <strong>{val?.couponData?.length > 0 ? val?.categoryName : <></>}</strong>
                                                        </div>
                                                        <div className='offercontain' >
                                                            <SliderWrapper>
                                                                <Slider {...offersSettings}>
                                                                    {val?.couponData?.map((offerData, index) => {
                                                                        return (
                                                                            <React.Fragment key={index}>
                                                                                <div className="carousel-cell">
                                                                                    <div className="offer-crslitem">
                                                                                        <img src={offerData?.image} alt="offerCrsImg1" />
                                                                                        <div className="offer-crslinner">
                                                                                            <div className="offertextContent">
                                                                                                <div className="mb-3">
                                                                                                    <h2 className="offerDiscount">-{offerData.discount_percentage}%</h2>
                                                                                                </div>
                                                                                                <h4 className="highLightTxt mb-1">
                                                                                                    {offerData?.name}
                                                                                                </h4>
                                                                                                <p className="subTextSmall">
                                                                                                    Get {offerData.discount_percentage}% off your entire purchase of ${offerData.max_discount} or more
                                                                                                </p>
                                                                                                <div className="mt-3">
                                                                                                    <p className="subTextSmall">
                                                                                                        Expires <br />
                                                                                                    </p>
                                                                                                    <span className="highLightSmall">{moment(offerData.end_time).format("DD MMM YYYY")}</span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </React.Fragment>
                                                                        )
                                                                    })}
                                                                </Slider>
                                                            </SliderWrapper>
                                                        </div>
                                                    </React.Fragment>
                                                )
                                            })}
                                        </div>
                                        : <></>}
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            }
            <CustomModal
                key={key}
                show={modalDetail.show}
                // backdrop="static"
                showCloseBtn={true}
                ids="productInner"
                isRightSideModal={false}
                mediumWidth={true}
                child={modalDetail.flag === 'products'
                    ? <ProductModal flag="others" categoryData={categoryData?.data?.payload?.data} setSubcategoryId={(e) => handleCategory(e)} setCategorySelected={setCategorySelected} categoryLoading={categoryLoading} closeModal={() => handleOnCloseModal()} details={(e) => handleOnCloseModal(e)} />
                    : modalDetail.flag === 'addCoupon' ?
                        <div className='productModall'>
                            <div className='productInnerDetails pt-4 pb-4'>
                                <div className='modalHeaderproduct_ d-flex justify-content-center mb-4'>
                                    <h5 className='subHeading'>Save a Coupon</h5>
                                    <i className="fas fa-times filterclose" onClick={handleOnCloseModal}></i>
                                </div>
                                <div className='row productScroll'>
                                    <div className="coupon-part">
                                        <form action="#" method="POST">
                                            <input type="text" placeholder="Coupon code" onChange={(e) => { handleChange(e, 'couponCode') }} value={couponCode} />
                                            <button type="button" onClick={handleAddCoupon}>Add Coupon</button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                        : ""

                }
                onCloseModal={() => handleOnCloseModal()}
            />
        </>
    );
}

export default CouponsTwo;