import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getFavoriteList,createFavoriteListApi,createProductFavoriteApi, getProductFavoriteList} from './favoriteListApi';

const initialState = {
    status:{
    }
};

export const getFavoriteListAsync=createAsyncThunk("favorites/getFavoriteList", async(data)=>{
    const response=await getFavoriteList(data.postData, data.tokenData);
    return response;
});
export const createFavoriteListApiAsync=createAsyncThunk("favorites/createFavoriteListApi", async(data)=>{
    const response=await createFavoriteListApi(data.postData, data.tokenData);
    return response;
});
export const createProductFavoriteApiAsync=createAsyncThunk("favorites/createProductFavoriteApi", async(data)=>{
    const response=await createProductFavoriteApi(data.postData, data.tokenData);
    return response;
});
export const getProductFavoriteListAsync=createAsyncThunk("favorites/getProductFavoriteList", async(data)=>{
    const response=await getProductFavoriteList(data.postData, data.tokenData);
    return response;
});

export const favoriteListSlice = createSlice({
    name: "favorites",
    initialState,
});

export default favoriteListSlice.reducer;