import React, { useState, useEffect } from "react";
import { Link, useHistory } from 'react-router-dom';
import { riderImg, leftArrowImg, arrowBlueImg, Info } from "../../../utilities/images";
import { createCartApiAsync, getCartApiAsync, handleCartSubmitResponseApiAsync, removeOneCartProductApiAsync, selectCartData, selectCartResponse } from "./cartSlice";
import { useDispatch, useSelector } from "react-redux";
import { selectLoginAuth } from "../auth/authSlice";
import { getAllDeliveryTypesApiAsync, getDeliveryTypesApiAsync, selectDeliveryTypeData } from "../delivery/deliverySlice";
import { unwrapResult } from "@reduxjs/toolkit";
import CommonLoader from "../../components/shared/CommonLoader";

const Cart = (props) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const auth = useSelector(selectLoginAuth);
    const [loading, setLoading] = useState(false);
    const cartData = useSelector(selectCartData);
    const deliveryFees = useSelector(selectCartResponse);
    const deliveryType = useSelector(selectDeliveryTypeData);

    console.log(cartData,"cart response")
    let couponData = (localStorage.getItem("couponsdata")) ? JSON.parse(localStorage.getItem("couponsdata")) : ""
    let discountValue = couponData ? Number((cartData?.data?.payload?.amout?.total_amount * couponData?.discount_percentage) / 100).toFixed(2) : (0).toFixed(2)
    const [count, setCount] = useState(cartData?.data?.payload?.cart_products)

    const handleIncrement = async (mineId, data) => {
        let countData = await cartData?.data?.payload?.cart_products.map((item, index) =>
            mineId === item.product_id ? { ...count[index], qty: count[index].qty + 1 } : item
        )
        setCount(data => countData);
        let countFilter = countData.find(element => element.product_id === mineId);
        let objProduct
        if (cartData?.data?.payload?.cart_products[0]?.supply_variant_id) {
            objProduct = {
                "postData": {
                    "seller_id": cartData?.data?.payload?.seller_id,
                    "product_id": countFilter?.product_id,
                    "qty": countFilter?.qty,
                    "service_id": cartData?.data?.payload?.service_id,
                    "service_type": cartData?.data?.payload?.service_type,
                    "delivery_option": "1",
                    "supply_id": cartData?.data?.payload?.cart_products[0]?.supply_id,
                    "supply_price_id": cartData?.data?.payload?.cart_products[0]?.supply_price_id,
                    "supply_variant_id": cartData?.data?.payload?.cart_products[0]?.supply_variant_id,
                    "app_name": cartData?.data?.payload?.app_name
                },
                "tokenData": auth?.payload?.token
            };
        } else {
            objProduct = {
                "postData": {
                    "seller_id": cartData?.data?.payload?.seller_id,
                    "product_id": countFilter?.product_id,
                    "qty": countFilter?.qty,
                    "service_id": cartData?.data?.payload?.service_id,
                    "service_type": cartData?.data?.payload?.service_type,
                    "delivery_option": "1",
                    "supply_id": cartData?.data?.payload?.cart_products[0]?.supply_id,
                    "supply_price_id": cartData?.data?.payload?.cart_products[0]?.supply_price_id,
                    "app_name": cartData?.data?.payload?.app_name
                },
                "tokenData": auth?.payload?.token
            };
        }
        await dispatch(createCartApiAsync(objProduct))
        dispatch(getCartApiAsync(auth?.payload?.token))
    }

    const handleDecrement = async (mineId, data) => {
        let deleteProduct = false
        let countData = await cartData?.data?.payload?.cart_products.map((item, index) => {
            if (mineId === item.product_id) {
                if (count[index].qty <= 1) {
                    deleteProduct = true
                    // return ({ ...count[index], qty: 1 })
                    let params = {
                        "postData": {
                            cart_id: data?.cart_id,
                            product_id: data?.id
                        },
                        "tokenData": auth?.payload?.token
                    }
                    dispatch(removeOneCartProductApiAsync(params))
                        .then(unwrapResult)
                        .then(async (obj) => {
                            deleteProduct = false
                            dispatch(getCartApiAsync(auth?.payload?.token))
                            return false
                        }
                        )
                        .catch((obj) => {
                        })

                }
                else {
                    deleteProduct = false
                    return ({ ...count[index], qty: count[index].qty - 1 })
                }
            } else {
                deleteProduct = false
                return ({ ...count[index], qty: count[index].qty })
            }
        }
        )

        setCount(data => countData);
        let countFilter = countData.find(element => element?.product_id === mineId);
        let objProduct
        if (cartData?.data?.payload?.cart_products[0]?.supply_variant_id) {
            objProduct = {
                "postData": {
                    "seller_id": cartData?.data?.payload?.seller_id,
                    "product_id": countFilter?.product_id,
                    "qty": countFilter?.qty,
                    "service_id": cartData?.data?.payload?.service_id,
                    "service_type": cartData?.data?.payload?.service_type,
                    "delivery_option": "1",
                    "supply_id": cartData?.data?.payload?.cart_products[0]?.supply_id,
                    "supply_price_id": cartData?.data?.payload?.cart_products[0]?.supply_price_id,
                    "supply_variant_id": cartData?.data?.payload?.cart_products[0]?.supply_variant_id,
                    "app_name": cartData?.data?.payload?.app_name
                },
                "tokenData": auth?.payload?.token
            };
        } else {
            objProduct = {
                "postData": {
                    "seller_id": cartData?.data?.payload?.seller_id,
                    "product_id": countFilter?.product_id,
                    "qty": countFilter?.qty,
                    "service_id": cartData?.data?.payload?.service_id,
                    "service_type": cartData?.data?.payload?.service_type,
                    "delivery_option": "1",
                    "supply_id": cartData?.data?.payload?.cart_products[0]?.supply_id,
                    "supply_price_id": cartData?.data?.payload?.cart_products[0]?.supply_price_id,
                    "app_name": cartData?.data?.payload?.app_name
                },
                "tokenData": auth?.payload?.token
            };
        }
        if (deleteProduct) { }
        else {
            await dispatch(createCartApiAsync(objProduct))
            dispatch(getCartApiAsync(auth?.payload?.token))
        }

    }

    const handleApplyCoupon = (val) => {
        localStorage.setItem("cartprice", val)
        history.push(`/coupons/${cartData?.data?.payload?.service_id}/${cartData?.data?.payload?.seller_id}/product`)
    }

    const handleCartRemoveProduct = async (val) => {
        let data = {
            "postData": {
                cart_id: val?.cart_id,
                product_id: val?.id
            },
            "tokenData": auth?.payload?.token

        }
        await dispatch(removeOneCartProductApiAsync(data))
        dispatch(getCartApiAsync(auth?.payload?.token))
    }

    if (!cartData || couponData?.minimum_order_amount > cartData?.data?.payload?.amout?.total_amount) {
        localStorage.removeItem("couponsdata")
    }


    let checkoutData = {
        ...cartData?.data?.payload,
        "delivery_type_id": (deliveryFees && Object.keys(deliveryFees).length > 0) ? deliveryFees?.delivery_type_id : deliveryType?.data?.payload[0]?.id,
        "preffered_delivery_start_time": (deliveryFees && Object.keys(deliveryFees).length > 0) ? deliveryFees?.preffered_delivery_start_time : "",
        "preffered_delivery_end_time": (deliveryFees && Object.keys(deliveryFees).length > 0) ? deliveryFees?.preffered_delivery_end_time : "",
        "cartOtherData": {
            "delivery_type_id": (deliveryFees && Object.keys(deliveryFees).length > 0) ? deliveryFees?.delivery_type_id : deliveryType?.data?.payload[0]?.id,
            "preffered_delivery_start_time": (deliveryFees && Object.keys(deliveryFees).length > 0) ? deliveryFees?.preffered_delivery_start_time : "",
            "preffered_delivery_end_time": (deliveryFees && Object.keys(deliveryFees).length > 0) ? deliveryFees?.preffered_delivery_end_time : "",
            "delivery_fees": (deliveryFees && Object.keys(deliveryFees).length > 0) ? deliveryType?.data?.payload[deliveryFees?.delivery_type_id - 1]?.price : deliveryType?.data?.payload[0]?.price,
            "couponData": couponData && couponData,
            "shipping": cartData?.data?.payload?.service_type
        }
    }
    const handleCheckout = () => {
        dispatch(handleCartSubmitResponseApiAsync(checkoutData))
            .then(unwrapResult)
            .then(async (obj) => {
                console.log(obj,"response of obj")
                history.push('/placing-1')
            }
            )
            .catch((obj) => {
            })
    }

    const getCartDeliveryData = async () => {
        setLoading(true)
        await dispatch(getCartApiAsync(auth?.payload?.token))
            .then(unwrapResult)
            .then(async (obj) => {
                let data = {
                    "postData": obj?.data?.payload?.seller_id,
                    "tokenData": auth?.payload?.token,
                    "app_name": 'b2c',
                    "delivery_option": '1'
                }
                dispatch(getDeliveryTypesApiAsync(data))
                    .then(async (obj) => {
                        setLoading(false)
                    }
                    )
                    .catch((obj) => {
                        setLoading(false)
                    })
            }
            )
            .catch((obj) => {
            })
        setCount(cartData?.data?.payload?.cart_products)
    }

    useEffect(() => {
        window.scrollTo(0, 0)
        getCartDeliveryData()
    }, [])

    // useEffect(() => {
    //     // dispatch(getAllDeliveryTypesApiAsync(auth?.payload?.token))
    //     if (cartData?.data?.payload?.seller_id) {
    //         let data = {
    //             "postData": cartData?.data?.payload?.seller_id,
    //             "tokenData": auth?.payload?.token,
    //             "app_name" : 'b2c',
    //             "delivery_option" : '1'                
    //         }
    //         dispatch(getDeliveryTypesApiAsync(data))
    //     }
    // }, [cartData])

    return (
        <>
            <div className="order-heading">
                <h4><Link to="#" onClick={() => history.goBack()}><img src={leftArrowImg} alt="leftArrowImg" /></Link> Checkout </h4>
            </div>
            {loading === true ?
                <CommonLoader />
                :
                <>

                    {
                        cartData?.data?.payload?.cart_products?.length > 0 ?
                            <div className="main-content cart-mainpd">
                                <div className="product-delivery-area">
                                    <div className="product-delvbox">
                                        <img src={riderImg} alt="riderImg" />
                                        <div className="rider-cnt">
                                            {/* Object.keys(myObj).length */}
                                            {
                                                (deliveryFees && Object.keys(deliveryFees).length > 0) ?
                                                    <>
                                                        {
                                                            ((deliveryFees?.delivery_type_id === 1)) ?
                                                                <>
                                                                    <h6>{deliveryType?.data?.payload[deliveryFees?.delivery_type_id - 1]?.title}</h6>
                                                                    <h2>{deliveryType?.data?.payload[deliveryFees?.delivery_type_id - 1]?.description}</h2>
                                                                    <p>
                                                                        <Link to='/delivery-types'>Change</Link>
                                                                    </p>
                                                                </> :
                                                                <>
                                                                    <h2>{deliveryType?.data?.payload[deliveryFees?.delivery_type_id - 1]?.title}</h2>
                                                                    <p>
                                                                        {`${deliveryFees?.preffered_delivery_start_time} - ${deliveryFees?.preffered_delivery_end_time}`}
                                                                        <Link to='/delivery-types'>Change</Link>
                                                                    </p>
                                                                </>
                                                        }
                                                    </>
                                                    :
                                                    <>
                                                        <h6>{deliveryType?.data?.payload[0]?.title}</h6>
                                                        <h2>{deliveryType?.data?.payload[0]?.description}</h2>
                                                        <p>
                                                            <Link to='/delivery-types'>Change</Link>
                                                        </p>
                                                    </>
                                            }
                                        </div>
                                    </div>
                                    {
                                        cartData?.data?.payload?.cart_products?.map((val, index) => {
                                            return (
                                                <div className="select-card" key={index}>
                                                    <img src={val?.product_details?.product_images[0]?.url} alt="selectCard1Img" />
                                                    <div className="select-cardcnt">
                                                        <h4>{val?.product_details?.name}</h4>
                                                        {/* <p>{val?.product_details?.description}</p> */}
                                                        <div className="quantity">
                                                            <form action="#" method="POST">
                                                                <span className="input-number-decrement" onClick={() => handleDecrement(val?.product_id, val)}><i className="fas fa-minus"></i></span>
                                                                <input className="input-number4" name="product1" type="text" value={cartData?.data?.payload?.cart_products[index]?.qty} min="1" disabled />
                                                                <span className="input-number-increment" onClick={() => handleIncrement(val?.product_id, val)}><i className="fas fa-plus"></i></span>
                                                            </form>
                                                        </div>
                                                    </div>
                                                    <div className="select-cardrit">
                                                        <span><i className="fas fa-times" onClick={() => handleCartRemoveProduct(val)}></i></span>
                                                        <h6>${val?.product_details?.price}</h6>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }

                                    <div className="apply-couponbox">
                                        <Link to="#">
                                            {
                                                couponData ?
                                                    <>
                                                        <h4 className="savings_head">"{couponData?.code} applied" <small className="savings">{discountValue} coupon savings</small></h4>
                                                        <span className="coupon_remove" onClick={() => localStorage.removeItem("couponsdata")}><i className="fa fa-trash"></i></span>
                                                    </>
                                                    :
                                                    <>
                                                        <h4>Apply Coupon <small>Add your coupon here</small></h4>
                                                        <span onClick={() => handleApplyCoupon(cartData?.data?.payload?.amout?.total_amount)}><i className="fas fa-chevron-right"></i></span>
                                                    </>
                                            }
                                        </Link>
                                    </div>

                                    <div className="cart-totalbox">
                                        <ul>
                                            <li>Sub Total {cartData?.data?.payload?.amout?.total_amount ? <span>${(cartData?.data?.payload?.amout?.total_amount).toFixed(2)}</span> : ""}</li>
                                            <li>Coupon <span>-${discountValue}</span></li>
                                            <li><small>Taxes & Other fees <img src={Info} alt="" /></small> <span>$0.00</span></li>
                                            <li>Delivery fees <span>${deliveryFees && Object.keys(deliveryFees).length > 0 ? Number(deliveryType?.data?.payload[deliveryFees?.delivery_type_id - 1]?.price).toFixed(2) : Number(deliveryType?.data?.payload[0]?.price).toFixed(2)}</span></li>
                                            <li><strong>Total</strong> <strong>${(((cartData?.data?.payload?.amout?.total_amount) + Number(deliveryFees && Object.keys(deliveryFees).length > 0 ? Number(deliveryType?.data?.payload[deliveryFees?.delivery_type_id - 1]?.price) : Number(deliveryType?.data?.payload[0]?.price))) - (discountValue)).toFixed(2)}</strong></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="selected-product checkout-box">
                                    <div className="selected-itembox">
                                        <div className="selected-leftcnt">
                                            <p><strong>Continue to checkout</strong></p>
                                        </div>
                                        <div className="selected-rightbtn" onClick={handleCheckout}>
                                            <Link to='#'>
                                                <img src={arrowBlueImg} alt="" />
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            : <div className="delivery-parthm">No Cart Found</div>
                    }</>}
        </>
    );
}

export default Cart;