import React, { useEffect, useState } from "react";
import { Link, useHistory } from 'react-router-dom';
import { ProfileImg, chatImage, coinStackImg, leftArrowImg, mapViewImg } from "../../../utilities/images";
import { selectLoginAuth } from "../auth/authSlice";
import { getReorderByUserAsync } from '../dashboard/dashboardSlice';
import moment from "moment-timezone";
import { useDispatch, useSelector } from "react-redux";
import { selectWalletBalance } from "../wallet/walletSlice";
import CommonLoader from '../../components/shared/CommonLoader';
import { unwrapResult } from "@reduxjs/toolkit";


const TrackingTwo = (props) => {
    const history = useHistory();
    const walletData = useSelector(selectWalletBalance);
    const [reorderProducts, setReorderProducts] = useState({});
    const auth = useSelector(selectLoginAuth);
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [status, setStatus] = useState("");

    let id = localStorage.getItem('orderId');
    let data = {
        'postData': {
            id: id
        },
        "tokenData": auth?.payload?.token
    }
    useEffect(() => {
        window.scrollTo(0, 0)
        setLoading(true)
        dispatch(getReorderByUserAsync(data))
            .then(unwrapResult)
            .then(async (obj) => {
                setReorderProducts(obj)
                setStatus(obj?.data?.payload?.status)
                setLoading(false)
            }
            )
            .catch((obj) => {
                setLoading(false)
            })
    }, [])

    return (
        <>
            <div className="order-heading">
                <div className="header-left-item">
                    <h4><Link to="#" onClick={() => { history.push('/tracking') }}><img src={leftArrowImg} alt="leftArrowImg" /></Link> Track your order </h4>
                </div>
                <div className="header-right-item">
                    <Link to="/wallet" className="coinStack">{walletData ? walletData?.data?.payload?.sila_balance : 0}
                        <img src={coinStackImg} alt="coinStack" />
                    </Link>
                </div>
            </div>
            {loading === true ?
                <CommonLoader />
                :
                <div className="main-content">
                    <div className="assinged-main">
                        <div className="assinged-overlay">
                            <div className="assinged-box">
                                <div className="assinged-boxupper">
                                    <div className="assinged-boxuper-left">
                                        <h4>Order status</h4>
                                        <p>Preparing your order</p>
                                    </div>
                                    <div className="assinged-boxuper-right">
                                        <Link to="#" onClick={() => history.push('/tracking')}><img src={mapViewImg} alt="mapViewImg" /> Map</Link>
                                    </div>
                                </div>

                                {/* const WAITING_TO_ACCEPT_BY_DRIVER = '0';
                                const ACCEPTED_BY_DRIVER = '1';
                                const SELLER_OTP_VERIFIED = '2';
                                const PICKED_UP = '3';
                                const CUSTOMER_OTP_VERIFIED = '4';
                                const DELIVERED = '5'; */}

                                <div className="track-timeline">
                                    <ul>
                                        <li className={status === 5 ? "active-timeline" : ""}>
                                            <h4>Delivered</h4>
                                            <p>Product has delivered</p>
                                        </li>
                                        <li className={status >= 4 ? "active-timeline" : ""}>
                                            <h4>Verified code</h4>
                                            {/* <p>---- ----</p> */}
                                        </li>
                                        {/* <li className={status === 0 ? "active-timeline" : ""}>
                                            <h4>Next to your block</h4>
                                            <p>with in 10 minutes</p>
                                        </li> */}
                                        <li className={status >= 3 ? "active-timeline" : ""}>
                                            <h4>Product Pickup</h4>
                                            {/* <p>with in 10 minutes</p> */}
                                        </li>
                                        <li className={status >= 1 ? "active-timeline" : ""}>
                                            <h4>Assign Driver</h4>
                                            {/* <p>with in 10 minutes</p> */}
                                        </li>
                                        <li className={status >= 0 ? "active-timeline" : ""}>
                                            <h4>Ready to pickup</h4>
                                            {/* <p>with in 10 minutes</p> */}
                                        </li>
                                        <li className={status >= 0 ? "active-timeline" : ""}>
                                            <h4>Order accepted</h4>
                                            <p>{moment(reorderProducts?.data?.payload?.updated_at).format('DD MMM, YYYY | HH:mm a')}</p>
                                        </li>
                                    </ul>
                                    {status >= 1 ?
                                        <div style={{ display: "flex", justifyContent: "space-around", marginTop: "10px" }}>
                                            <img style={{ width: '50px' }} src={ProfileImg} alt="" />
                                            <p>Gerardo K. Jennings</p>
                                            <img style={{ width: '30px' }} src={chatImage} alt="" />
                                        </div> : <></>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>}
        </>
    );
}

export default TrackingTwo;