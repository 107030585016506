import React, { useState } from 'react'
import { unwrapResult } from '@reduxjs/toolkit'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import { leftArrowImg } from '../../../utilities/images'
import { checkKycAsync, requestKycAsync, selectLoginAuth } from './authSlice'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'

const RequestKyc = () => {
    const dispatch = useDispatch()
    const history = useHistory()
    const {flag} = useParams();
    const [kycRequest, setKycRequest] = useState(false)
    const toastId = React.useRef(null)
    const [loading, setLoading] = useState(false)
    const auth = useSelector(selectLoginAuth)

    const handleRequestKyc = async () => {
        setLoading(true)
        await dispatch(requestKycAsync(auth?.payload?.token))
            .then(unwrapResult)
            .then((obj) => {
                setLoading(false)
                if (!toast.isActive(toastId.current)) {
                    toastId.current = toast.success(obj?.msg)
                }
            }
            )
            .catch((obj) => {
                setLoading(false)
            })
    }

    const handleCheckKyc = async () => {
        await dispatch(checkKycAsync(auth?.payload?.token))
            .then(unwrapResult)
            .then((obj) => {
                if (!toast.isActive(toastId.current)) {
                    toastId.current = toast.success(obj?.msg)
                }
                setKycRequest(obj.payload)
            }
            )
            .catch((obj) => {

            })
    }
    return (
        <main className="main-wrapper kycwrapper">
            <div className="registration-upper reg-heading2">
                <h4><Link to="#">
                    <img src={leftArrowImg} alt="" />
                </Link>{" "}
                    Request KYC
                </h4>
            </div>
            <div className='commontMainbox'>
                <p className='parasubHead'>You will need to fill out a form in the next step. Please read the instructions below to complete the form correctly You will need to fill out a form in the next step. Please read the instructions below to complete the form correctly</p>
                <div className='requestbtn'>
                    <button type='text' className='blueprimeBtn' onClick={handleRequestKyc} disabled={loading}>
                        {loading === true && (
                            <span className="spinner-border spinner-border-sm"></span>
                        )}
                        &nbsp;&nbsp;
                        Request KYC
                    </button>
                </div>

                <p className='parasubHead mt-4'>You will need to fill out a form in the next step. Please read the instructions below to complete the form correctly You will need to fill out a form in the next step.</p>
                <div className='requestStatus_ mt-5'>
                    <div className='kycReview'>
                        <h5 className='innerMainhead'>KYC Review Status</h5>
                        <p className='greyHeading_'>{" "}</p>
                    </div>
                    <div className='refreshStatus'>
                        <h5 className='innerMainhead'><i className="fas fa-sync" style={{ cursor: "pointer" }} onClick={handleCheckKyc}></i> Refresh Status</h5>
                        <p className='greyHeading_'>Status : {kycRequest && "Passed"}</p>
                    </div>
                </div>
                <div className='bottomBtnbox'>
                    <button type="submit" className="document_button blueprimeBtn" disabled={!kycRequest} onClick={() => history.push(`/id-verification/${flag}`)}>Continue</button>
                </div>
            </div>
        </main>
    )
}

export default RequestKyc