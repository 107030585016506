import React, { useEffect, useState } from "react";
import { Link, useHistory } from 'react-router-dom';
import { bagImg, cancelOrderImg, coinStackImg, leftArrowImg } from "../../../utilities/images";
import { useDispatch, useSelector } from "react-redux";
import { cancelOrderByUserAsync, getAllOrderByUserAsync, ratingFeedbackByUserAsync } from "../dashboard/dashboardSlice";
import { selectLoginAuth } from "../auth/authSlice";
import moment from "moment-timezone";
import { unwrapResult } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import CustomModal from "../../components/shared/CustomModal";
import ReactStars from 'react-stars'
import PaginationSetting from '../../constants/PaginationSetting';
import { Box } from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import status from '../../../status.json';
import CommonLoader from '../../components/shared/CommonLoader'
import { selectWalletBalance } from "../wallet/walletSlice";

const Orders = (props) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const [pagination, setPagination] = useState(PaginationSetting);
    const [page, setPage] = useState(1);
    const auth = useSelector(selectLoginAuth);
    const walletData = useSelector(selectWalletBalance);
    const [loading, setLoading] = useState(false);
    const [allOrderLoading, setAllOrderLoading] = useState(false);
    const [selectAllOrder, setSelectAllOrder] = useState({});
    const toastId = React.useRef(null);
    const [id, setId] = useState('');
    const [rating, setRating] = useState("");
    const [description, setDescription] = useState("");
    const [key, setKey] = useState(Math.random());
    const [modalDetail, setModalDetail] = useState({
        show: false,
        title: "",
        flag: ""
    });
    const data = {
        "postData": {
            page: page,
            limit: pagination.recordsPerPage
        },
        "tokenData": auth?.payload?.token
    }

    const handleOnCloseModal = () => {
        setModalDetail({
            show: false,
            title: "",
            flag: ""
        });
        setRating("")
        setDescription("")
        setKey(Math.random());
    }

    const cancelOrderHandle = async () => {
        const cancelData = {
            "postData": {
                "orderId": id,
                "status": status.CANCELED
            },
            "tokenData": auth?.payload?.token
        }
        dispatch(cancelOrderByUserAsync(cancelData))
            .then(unwrapResult)
            .then(async (obj) => {
                toast.success(obj?.data?.msg)
                dispatch(getAllOrderByUserAsync(data))
                    .then(unwrapResult)
                    .then(async (obj) => {
                        setSelectAllOrder(obj)
                        setPagination((prevState) => ({ ...prevState, totalPages: obj?.payload?.data?.payload?.total_pages, totalRecords: obj?.payload?.data?.payload?.total }));
                        setAllOrderLoading(false)
                        handleOnCloseModal()
                    }
                    )
                    .catch((obj) => {
                        setAllOrderLoading(false)
                    })
            }
            )
            .catch((obj) => {
            })
    }

    const submitRatingHandler = () => {
        if (!rating) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error("Please select the rating")
            }
        }
        else if (!description) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error("Please write the feedback")
            }
        }
        else {
            const ratingData = {
                "postData": {
                    "order_id": id,
                    "feedback": description,
                    "rating": rating
                },
                "tokenData": auth?.payload?.token
            }
            setLoading(true)
            dispatch(ratingFeedbackByUserAsync(ratingData))
                .then(unwrapResult)
                .then(async (obj) => {
                    if (!toast.isActive(toastId.current)) {
                        toastId.current = toast.success(obj?.data?.msg)
                    }
                    setLoading(false)
                    setRating("")
                    setDescription("")
                    setModalDetail({
                        show: false,
                        title: "",
                        flag: ""
                    });
                    setKey(Math.random());
                }
                )
                .catch((obj) => {
                    setLoading(false)
                })
        }
    }
    const allOrderDataHandle = () => {
        setAllOrderLoading(true)
        dispatch(getAllOrderByUserAsync(data))
            .then(unwrapResult)
            .then(async (obj) => {
                setSelectAllOrder(obj)
                setPagination((prevState) => ({ ...prevState, totalPages: obj?.payload?.data?.payload?.total_pages, totalRecords: obj?.payload?.data?.payload?.total }));
                setAllOrderLoading(false)
            }
            )
            .catch((obj) => {
                setAllOrderLoading(false)
            })
    }

    useEffect(() => {
        allOrderDataHandle()
    }, [page])

    const renderRatingModelHtml = () => {
        return (
            <div className="modal-content">
                <div className="modal-header address-mdlheader">
                    <h5><strong>Rate Your Order</strong></h5>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => { handleOnCloseModal() }}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                    <br />
                </div>
                <div className="modal-body address-mdlbody pt-0">
                    <div className="taxes-mdlbox-cnt">
                        <p className="ratingStar">Give rating and review your order.</p>
                        <div className="giveStar">
                            <ReactStars
                                count={5}
                                onChange={(e) => setRating(e)}
                                size={45}
                                value={Number(rating)}
                                color2={'#FCBA30'}
                                activeColor={"#ffd700"}
                            />
                        </div>
                    </div>
                    <div className="taxes-mdlbox-cnt">
                        <h4>Give your review</h4>
                        <textarea className="form-control" rows="4" cols="50" value={description} onChange={(e) => setDescription(e.target.value)} style={{ height: "200px", width: "320px" }} placeholder="Write" />
                    </div>
                </div>
                <div className="modal-footer">
                    <button className="btn btn-light cancleBtn" onClick={() => handleOnCloseModal()} disabled={loading}>Cancel</button>
                    <button className="btn btn-primary submitBtn" onClick={() => submitRatingHandler()} disabled={loading}>
                        {loading === true && (
                            <span className="spinner-border spinner-border-sm"></span>
                        )}
                        &nbsp;&nbsp;
                        <span>Submit</span>
                    </button>
                </div>
            </div>
        )
    }
    const renderCancelOrderHtml = () => {
        return (
            <div className="modal-content">
                <div className="modal-body">
                    <div className="modal-mainprt">
                        <div className="mdlverify-cnt cardmdl-verify">
                            <img src={cancelOrderImg} alt="veirfy2Img" />
                            <h6>Cancel Order</h6>
                            <p>Are you sure,you want to cancel your order?</p>
                        </div>
                        <div className="modal-footer" >
                            <button className="btn cancleBtn " onClick={() => handleOnCloseModal()}  >No</button>
                            <button className="btn btn-primary submitBtn" onClick={() => cancelOrderHandle()}>Yes,I'm sure</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <>

            <div className="order-heading">
                <div className="header-left-item">
                    <h4><Link to="#" onClick={() => { history.push('/more') }}><img src={leftArrowImg} alt="leftArrowImg" /></Link>Orders & Reorders</h4>
                </div>
                <div className="header-right-item">
                    <button type="button"><img src={bagImg} alt="bagImg" /></button>
                    <Link to="/wallet" className="coinStack">{walletData ? walletData?.data?.payload?.sila_balance : 0}
                        <img src={coinStackImg} alt="coinStack" />
                    </Link>
                </div>
            </div>
            {allOrderLoading === true ?
                <CommonLoader />
                :
                <>
                    <div className="main-content">
                        <div className="order-mainprt">
                            <div className="tabs">
                                <div className="tab-upper tab-upperhm noshadow">
                                    <ul className="tab-links text-left pb-4">
                                        <li className="active"><Link to="#tab1">Delivery</Link></li>
                                        <li><Link to="#tab2">Pickup</Link></li>
                                        <li><Link to="#tab2">Shipping</Link></li>
                                    </ul>
                                </div>
                                <div className="order-title mb-2">
                                    <h2>Past orders</h2>
                                </div>

                                <div className="tab-content">
                                    {/* <!--  <div id="tab1" className="tab active">
                                <div className="browse-tab1">

                                </div>
                            </div>

                            <div id="tab2" className="tab">
                                <div className="browse-tab2">

                                </div>
                            </div>
                            <div id="tab2" className="tab">
                                <div className="browse-tab2">

                                </div>
                            </div> --> */}
                                </div>
                            </div>

                            {/* <div className="order-title">
                               <h2>Past orders</h2>
                                </div> */}

                            {selectAllOrder && selectAllOrder?.data?.payload?.data?.length > 0 &&
                                <div>
                                    {selectAllOrder && selectAllOrder?.data?.payload?.data?.map((value, index) => {
                                        return (
                                            <React.Fragment key={index}>
                                                <div className="order-part">
                                                    <div className="order-item">
                                                        <img style={{ width: "70px" }} src={value?.seller_details?.profile_photo} alt="" />
                                                        <div className="order-cnt">
                                                            <h4>{value?.seller_details?.firstname} </h4>
                                                            <p className="orderPara_">{value?.order_details?.map((v, i) => {
                                                                return (
                                                                    <div key={i}>{v?.product_name}</div>
                                                                )
                                                            })}</p>
                                                            <span>
                                                                <ReactStars
                                                                    count={5}
                                                                    edit={false}
                                                                    size={15}
                                                                    value={0}
                                                                    color2={'#FCBA30'}
                                                                    activeColor={"#ffd700"}
                                                                />
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="order-btm">
                                                        <span className="smalltext_">{moment(value?.created_at).format('DD MMM,')}</span>
                                                        <span className="smalltext_">{moment(value?.created_at).format('hh:mm')}</span>
                                                        <button className="btn btn-primary" onClick={() => history.push(`/past-order-view/${value?.id}`)}>Select items to reorder</button>
                                                    </div>
                                                </div>
                                            </React.Fragment>
                                        )
                                    })
                                    }
                                </div>

                            }
                        </div>
                    </div>
                </>}
            {pagination.totalRecords > pagination.recordsPerPage &&
                <Box py={3} display="flex" justifyContent="center" className="pagination_content">
                    <Pagination
                        key={page}
                        count={pagination.totalPages || 0}
                        color="secondary"
                        variant="outlined"
                        onChange={(e, value) => setPage(value)}
                        defaultPage={page}
                    />
                </Box>
            }
            <CustomModal
                key={key}
                show={modalDetail.show}
                backdrop="static"
                showCloseBtn={false}
                isRightSideModal={false}
                mediumWidth={modalDetail.flag === 'rating' ? false : true}
                darkBackground={modalDetail.flag === 'rating' ? true : false}
                size="lg"
                child={modalDetail.flag === 'rating' ?
                    renderRatingModelHtml() : modalDetail.flag === 'cancelOrder' ? renderCancelOrderHtml() : <></>
                }
                onCloseModal={() => handleOnCloseModal()}
            />
        </>
    );
}

export default Orders;