import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  registerSendOtp,
  verifyOtp,
  loginApi,
  registerApi,
  changePinApi,
  verifyAgeDocumentApi,
  getIdCardPhoto,
  createWalletApi,
  getWalletProfile,
  requestKyc,
  checkKyc,
  getDocumentType,
  uploadKycDocument,
  generatePlaidTokenApi,
  getBankAccountApi,
  linkBankAccount
} from "./authApi";
import { toast } from "react-toastify";

const initialState = {
  user: null,
  otp: null,
  verify: null,
  changepin: null,
  verifyAge: null,
  uploadIdCardPhoto1: null,
  uploadIdCardPhoto2: null,
  walletProfile: null,
  status: "idle",
  status1: {
    otpStatus: "idle",
    verifyStatus: "idle",
    userStatus: "idle",
    loginStatus: "idle"
  }
};

export const registerOtpSendAsync = createAsyncThunk("auth/registerotp", async (data, { rejectWithValue }) => {
  const response = await registerSendOtp(data);
  return response.data;
});
export const registerOtpVerifyAsync = createAsyncThunk("auth/verifyotp", async (data) => {
  const response = await verifyOtp(data);
  return response.data;
});
export const loginAsync = createAsyncThunk("auth/login", async (data) => {
  const response = await loginApi(data);
  return response.data;
});

export const registerAsync = createAsyncThunk("auth/register", async (data) => {
  const response = await registerApi(data);
  return response.data;
});
export const changePinAsync = createAsyncThunk("auth/changepin", async (data) => {
  const response = await changePinApi(data);
  return response.data;
});
export const verifyAgeAsync = createAsyncThunk("auth/verifyage", async (data) => {
  const response = await verifyAgeDocumentApi(data.postData, data.tokenData);
  return response.data;
});
export const uploadIdCardAsync = createAsyncThunk("idCard/photo", async (data) => {
  const response = await getIdCardPhoto(data);
  return response.data;
});
export const uploadIdCardAsync1 = createAsyncThunk("idCard/photo1", async (data) => {
  const response = await getIdCardPhoto(data);
  return response.data;
});
export const createWalletAsync = createAsyncThunk("auth/createWalletApi", async (data) => {
  const response = await createWalletApi(data.postData, data.tokenData);
  return response.data;
});
export const getWalletProfileAsync = createAsyncThunk("auth/getWalletProfile", async (data) => {
  const response = await getWalletProfile(data);
  return response.data;
});
export const requestKycAsync = createAsyncThunk("auth/requestKyc", async (data) => {
  const response = await requestKyc(data);
  return response.data;
});
export const checkKycAsync = createAsyncThunk("auth/checkKyc", async (data) => {
  const response = await checkKyc(data);
  return response.data;
});
export const getDocumentTypeAsync = createAsyncThunk("auth/getDocumentType", async (data) => {
  const response = await getDocumentType(data);
  return response.data;
});
export const uploadKycDocumentAsync = createAsyncThunk("auth/uploadKycDocument", async (data) => {
  const response = await uploadKycDocument(data.postData, data.tokenData);
  return response.data;
});
export const generatePlaidTokenApiAsync = createAsyncThunk("auth/generatePlaidTokenApi", async (data) => {
  const response = await generatePlaidTokenApi(data);
  return response.data;
});
export const linkBankAccountAsync = createAsyncThunk("auth/linkBankAccount", async (data) => {
  const response = await linkBankAccount(data.postData, data.tokenData);
  return response.data;
});
export const getBankAccountApiAsync = createAsyncThunk("auth/getBankAccountApi", async (data) => {
  const response = await getBankAccountApi(data);
  return response.data;
});

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logout: (state) => {
      state.user = null;
      state.otp = null;
      state.verify = null;
      state.changepin = null;
      state.uploadIdCardPhoto1 = null;
      state.uploadIdCardPhoto2 = null;
      state.walletProfile = null;
      toast.success("Successfully Logout")
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(registerOtpSendAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(registerOtpSendAsync.fulfilled, (state, { payload }) => {
        state.otp = payload
        state.status = "idle";
      })
      .addCase(registerOtpSendAsync.rejected, (state, { error }) => {
        state.status = "idle";
        state.otp = null
      })
      .addCase(registerOtpVerifyAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(registerOtpVerifyAsync.fulfilled, (state, { payload }) => {
        state.verify = payload
        state.status = "idle";
      })
      .addCase(registerOtpVerifyAsync.rejected, (state, { error }) => {
        state.status = "idle";
        state.verify = null
      })
      .addCase(registerAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(registerAsync.fulfilled, (state, { payload }) => {
        state.user = payload
        state.status = "idle";
      })
      .addCase(registerAsync.rejected, (state, { error }) => {
        state.status = "idle";
        state.user = null
      })
      .addCase(loginAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(loginAsync.fulfilled, (state, { payload }) => {
        state.user = payload
        state.status = "idle";
      })
      .addCase(loginAsync.rejected, (state, { error }) => {
        state.status = "idle";
        state.user = null
      })
      .addCase(changePinAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(changePinAsync.fulfilled, (state, { payload }) => {
        state.changepin = payload
        state.status = "idle";
      })
      .addCase(changePinAsync.rejected, (state, { error }) => {
        state.status = "idle";
        state.changepin = null
      })
      .addCase(verifyAgeAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(verifyAgeAsync.fulfilled, (state, { payload }) => {
        state.verifyAge = payload
        state.status = "idle";
      })
      .addCase(verifyAgeAsync.rejected, (state, { error }) => {
        state.status = "idle";
        state.verifyAge = null
      })
      .addCase(uploadIdCardAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(uploadIdCardAsync.fulfilled, (state, { payload }) => {
        state.uploadIdCardPhoto1 = payload
        state.status = "idle";
      })
      .addCase(uploadIdCardAsync.rejected, (state, { error }) => {
        state.status = "idle";
        state.uploadIdCardPhoto1 = null
      })
      .addCase(uploadIdCardAsync1.pending, (state) => {
        state.status = "loading";
      })
      .addCase(uploadIdCardAsync1.fulfilled, (state, { payload }) => {
        state.uploadIdCardPhoto2 = payload
        state.status = "idle";
      })
      .addCase(uploadIdCardAsync1.rejected, (state, { error }) => {
        state.status = "idle";
        state.uploadIdCardPhoto2 = null
      })
      .addCase(createWalletAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(createWalletAsync.fulfilled, (state, { payload }) => {
        state.walletProfile = payload
        state.status = "idle";
      })
      .addCase(createWalletAsync.rejected, (state, { error }) => {
        state.status = "idle";
        state.walletProfile = null
      })
      .addCase(getWalletProfileAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getWalletProfileAsync.fulfilled, (state, { payload }) => {
        state.walletProfile = payload
        state.status = "idle";
      })
      .addCase(getWalletProfileAsync.rejected, (state, { error }) => {
        state.status = "idle";
        state.walletProfile = null
      })

  }
});

export const { logout } = authSlice.actions;
export const selectLoginAuth = (state) => state.auth.user;
export const selectLoginProfile = (state) => state.auth.user;
export const selectOtpId = (state) => state.auth.otp;
export const selectVerifyResponse = (state) => state.auth.verify;
export const selectWalletProfile = (state) => state.auth.walletProfile;
export default authSlice.reducer;