import React, { useEffect, useState } from "react";
import { Link, useHistory } from 'react-router-dom';
import Slider from "react-slick";
import SliderWrapper from "./SliderStyle";
import { getHomeDataApiAsync, getCategoryApiAsync, getProductServiceCountAsync } from "./dashboardSlice"
import { useSelector, useDispatch } from "react-redux";
import MobileHeader from "../../components/shared/MobileHeader";
import { selectLoginAuth } from "../auth/authSlice";
import { getAllDeliveryTypesApiAsync } from "../delivery/deliverySlice";
import { userProfileAsync } from "../profile/profileSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import CommonLoader from "../../components/shared/CommonLoader";
import CustomModal from "../../components/shared/CustomModal";
import ProductModal from "./ProductModal";
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import moment from "moment-timezone";
import TrendingSellerModal from "./TrendingSellerModal";
import { getWalletDataAsync } from "../wallet/walletSlice";

const Dashboard = (props) => {
    const [key, setKey] = useState(Math.random());
    const [serviceType, setServiceType] = useState("");
    const [modalDetail, setModalDetail] = useState({
        show: false,
        title: "",
        flag: ""
    });
    const handleOnCloseModal = () => {
        setModalDetail({
            show: false,
            title: "",
            flag: ""
        });
        setKey(Math.random());
    }

    const history = useHistory();
    const [countData, setCountData] = useState({});
    const [homeData, setHomeData] = useState({});
    const [categoryData, setCategoryData] = useState({});
    const [dataLoading, setDataLoading] = useState(false);
    const [categoryLoading, setCategoryLoading] = useState(false);
    const auth = useSelector(selectLoginAuth);
    const dispatch = useDispatch();

    const foodSettings = {
        dots: true,
        autoplay: true,
        autoplaySpeed: 2000,
        infinite: true,
        slidesToShow: 1,
        centerMode: true,
        centerPadding: '20px',
        slidesToScroll: 1,
        initialSlide: 0,
        speed: 500,
        arrows: false,
        adaptiveHeight: true,
        appendDots: (dots) => <ul>{dots}</ul>,
        customPaging: (i) => <div className="slideContent" >{`${i + 1}/${(homeData?.data?.payload?.service_sellers?.find(item => item?.service?.slug === "food")?.sellers?.length)}`}</div>
    };
    const fashionSettings = {
        dots: true,
        autoplay: true,
        autoplaySpeed: 2000,
        infinite: true,
        slidesToShow: 1,
        centerMode: true,
        centerPadding: '20px',
        slidesToScroll: 1,
        initialSlide: 0,
        speed: 500,
        arrows: false,
        adaptiveHeight: true,
        appendDots: (dots) => <ul>{dots}</ul>,
        customPaging: (i) => <div className="slideContent" >{`${i + 1}/${(homeData?.data?.payload?.service_sellers?.find(item => item?.service?.slug === "fashion")?.sellers?.length)}`}</div>
    };
    const retailSettings = {
        dots: true,
        autoplay: true,
        autoplaySpeed: 2000,
        infinite: true,
        slidesToShow: 1,
        centerMode: true,
        centerPadding: '20px',
        slidesToScroll: 1,
        initialSlide: 0,
        speed: 500,
        arrows: false,
        adaptiveHeight: true,
        appendDots: (dots) => <ul>{dots}</ul>,
        customPaging: (i) => <div className="slideContent" >{`${i + 1}/${(homeData?.data?.payload?.service_sellers?.find(item => item?.service?.slug === "retail")?.sellers?.length)}`}</div>
    };
    const healthSettings = {
        dots: true,
        autoplay: true,
        autoplaySpeed: 2000,
        infinite: true,
        slidesToShow: 1,
        centerMode: true,
        centerPadding: '20px',
        slidesToScroll: 1,
        initialSlide: 0,
        speed: 500,
        arrows: false,
        adaptiveHeight: true,
        appendDots: (dots) => <ul>{dots}</ul>,
        customPaging: (i) => <div className="slideContent" >{`${i + 1}/${(homeData?.data?.payload?.service_sellers?.find(item => item?.service?.slug === "health")?.sellers?.length)}`}</div>
    };
    const serviceSettings = {
        dots: true,
        autoplay: true,
        autoplaySpeed: 2000,
        infinite: true,
        slidesToShow: 1,
        centerMode: true,
        centerPadding: '20px',
        slidesToScroll: 1,
        initialSlide: 0,
        speed: 500,
        arrows: false,
        adaptiveHeight: true,
        appendDots: (dots) => <ul>{dots}</ul>,
        customPaging: (i) => <div className="slideContent" >{`${i + 1}/${(homeData?.data?.payload?.service_sellers?.find(item => item?.service?.slug === "services")?.sellers?.length)}`}</div>
    };
    const offersSettings = {
        dots: false,
        autoplay: true,
        autoplaySpeed: 2000,
        infinite: true,
        slidesToShow: 2,
        centerMode: true,
        centerPadding: '20px',
        slidesToScroll: 1,
        initialSlide: 0,
        speed: 500,
        arrows: false,
        adaptiveHeight: true
    };
    const recentlyAddedSettings = {
        dots: true,
        autoplay: false,
        autoplaySpeed: 2000,
        infinite: true,
        slidesToShow: 1,
        centerMode: true,
        centerPadding: '20px',
        slidesToScroll: 1,
        initialSlide: 0,
        speed: 500,
        arrows: false,
        adaptiveHeight: true,
        appendDots: (dots) => <ul>{dots}</ul>,
        customPaging: (i) => <div className="slideContent" >{`${i + 1}/${(homeData?.data?.payload?.recently_added_sellers
            ?.length)}`}</div>
    };
    const onlineSettings = {
        dots: true,
        autoplay: true,
        autoplaySpeed: 2000,
        infinite: true,
        slidesToShow: 1,
        centerMode: true,
        centerPadding: '20px',
        slidesToScroll: 1,
        initialSlide: 0,
        speed: 500,
        arrows: false,
        adaptiveHeight: true,
        appendDots: (dots) => <ul>{dots}</ul>,
        customPaging: (i) => <div className="slideContent" >{`${i + 1}/${(homeData?.data?.payload?.classes?.length)}`}</div>
    };

    const categoryChangeHandle = (flag) => {
        setServiceType(flag)
        setModalDetail({ show: true, flag: 'products', });
        setKey(Math.random());
        let categoryParams = {
            'postData': {
                service_type: flag,
                main_category: true
            },
            'tokenData': auth?.payload?.token
        }
        setCategoryLoading(true)
        dispatch(getCategoryApiAsync(categoryParams))
            .then(unwrapResult)
            .then(async (obj) => {
                setCategoryData(obj)
                setCategoryLoading(false)
            }
            )
            .catch((obj) => {
                setCategoryLoading(false)
            })
    }

    const handleHomeData = () => {
        setDataLoading(true)
        dispatch(getHomeDataApiAsync(auth?.payload?.token))
            .then(unwrapResult)
            .then(async (obj) => {
                setHomeData(obj)
                setDataLoading(false)
            }
            )
            .catch((obj) => {
                setDataLoading(false)
            })
    }
    const countHandle = () => {
        setDataLoading(true)
        dispatch(getProductServiceCountAsync(auth?.payload?.token))
            .then(unwrapResult)
            .then(async (obj) => {
                setCountData(obj)
                setDataLoading(false)
            }
            )
            .catch((obj) => {
                setDataLoading(false)
            })
    }

    useEffect(() => {
        window.scrollTo(0, 0)
        handleHomeData()
        countHandle()
        let params = {
            loginId: auth?.payload?.id,
            token: auth?.payload?.token
        }
        dispatch(userProfileAsync(params))
        .then(unwrapResult)
            .then(async (obj) => {
                let walletParams = {
                    token: auth?.payload?.token
                }
                obj?.payload?.is_wallet === true &&                
                dispatch(getWalletDataAsync(walletParams))
            }
            )
            .catch((obj) => {
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        // dispatch(getAllDeliveryTypesApiAsync(auth?.payload?.token))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            <MobileHeader />
            {dataLoading === true ?
                <CommonLoader />
                :
                <div className="main-content">
                    <div className="home-tab1prt">
                        <div className="search-barhm searchLabel">
                            <DropdownButton id="dropdown-basic-button" title="Everything">
                                <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                                <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                                <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
                            </DropdownButton>
                            <form action="#" method="POST">
                                <input type="search" onFocus={() => history.push('/browse')} />
                            </form>
                        </div>

                        <div className="newhome-mainprt">
                            <div className="row">
                                {
                                    countData?.data?.payload?.length > 0 ?
                                        countData?.data?.payload?.map((val, index) => {
                                            return (
                                                <div className="col-6" key={index}>
                                                    <div className="newhome-item"
                                                        onClick={() => categoryChangeHandle(val?.slug)}
                                                    >
                                                        <Link to='#' className='newhomeLink'>
                                                            <img src={val?.image} alt="" className="service_img" />
                                                            <h4>{val?.name}</h4>
                                                            <p>{val?.count}+ {val?.name} listed</p>
                                                        </Link>
                                                    </div>
                                                </div>
                                            )
                                        }) : ""
                                }
                            </div>
                        </div>

                        {/* Treding food slider */}
                        <TrendingSellerModal data={homeData?.data?.payload?.service_sellers} settings={foodSettings} slug={"food"} />

                        {/* Treding Fashion */}
                        <TrendingSellerModal data={homeData?.data?.payload?.service_sellers} settings={fashionSettings} slug={"fashion"} />

                        {/* Treding Retail */}
                        <TrendingSellerModal data={homeData?.data?.payload?.service_sellers} settings={retailSettings} slug={"retail"} />

                        {/* Treding HealthCare */}
                        <TrendingSellerModal data={homeData?.data?.payload?.service_sellers} settings={healthSettings} slug={"health"} />

                        {/* Treding Services */}
                        <TrendingSellerModal data={homeData?.data?.payload?.service_sellers} settings={serviceSettings} slug={"services"} />

                        {/* Treding Online Course */}
                        <TrendingSellerModal data={homeData?.data?.payload?.service_sellers} settings={onlineSettings} slug={"classes"} />

                        {/* offer */}
                        {homeData?.data?.payload?.coupons?.length > 0 ?
                            <div className="offer-areahm p-0">
                                <div className="offer-title">
                                    <h2>Offers</h2>
                                </div>
                                <div className='offercontain' >
                                    <SliderWrapper>
                                        <Slider {...offersSettings}>
                                            {homeData?.data?.payload?.coupons
                                                ?.map((offerData, index) => {
                                                    return (
                                                        <React.Fragment key={index}>
                                                            <div className="carousel-cell">
                                                                <div className="offer-crslpart">
                                                                    <div className="offer-crslitem">
                                                                        <img src={offerData?.image} alt="offerCrsImg1" />
                                                                        <div className="offer-crslinner">
                                                                            <div className="offertextContent">
                                                                                <div className="mb-3">
                                                                                    <h2 className="offerDiscount">-{offerData.discount_percentage}%</h2>
                                                                                </div>
                                                                                <h4 className="highLightTxt mb-1">
                                                                                    {offerData?.name}
                                                                                </h4>
                                                                                <p className="subTextSmall">
                                                                                    Get {offerData.discount_percentage}% off your entire purchase of ${offerData.max_discount} or more
                                                                                </p>
                                                                                <div className="mt-3">
                                                                                    <p className="subTextSmall">
                                                                                        Expires <br />
                                                                                    </p>
                                                                                    <span className="highLightSmall">{moment(offerData.end_time).format("DD MMM YYYY")}</span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </React.Fragment>
                                                    )
                                                })}
                                        </Slider>
                                    </SliderWrapper>
                                </div>
                            </div>
                            : ""}

                        {/* Recently Added */}
                        {/* {HomeData?.data?.payload?.recently_added_sellers?.length > 0 ?
                        <div className="banner-slider trslider-part customSpac" id="bannerSlider">
                            <div className="trslider-title">
                                <h2>Recently Added</h2>
                            </div>
                            <SliderWrapper>
                                <Slider {...recentlyAddedSettings}>
                                    {
                                        homeData?.data?.payload?.recently_added_sellers?.map((val, index) => {
                                            return (
                                                <div className="carousel-cell carousel-recent" key={index}>
                                                    <div className="trslider-main">
                                                        <img src={val?.seller_info?.user_profiles?.banner_image ? val?.seller_info?.user_profiles?.banner_image : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ_kSOmMr7hLzMZ9oTxsZrjcuHY2vZ7UTY5Bg&usqp=CAU"} alt="" />
                                                        <div className="trslider-cnt">
                                                            <h6>{val?.seller_info?.user_profiles?.firstname} <img src={starCircleImg} alt="" /> <span>{val?.seller_rating?.rating}</span></h6>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </Slider>
                            </SliderWrapper>
                        </div> : ""} */}
                    </div>
                </div>
            }
            <CustomModal
                key={key}
                show={modalDetail.show}
                // backdrop="static"
                showCloseBtn={false}
                ids="productInner"
                isRightSideModal={false}
                mediumWidth={true}
                child={modalDetail.flag === 'products'
                    ? <ProductModal flag="dashboard" serviceType={serviceType} categoryData={categoryData?.data?.payload?.data} categoryLoading={categoryLoading} closeModal={() => handleOnCloseModal()} details={(e) => handleOnCloseModal(e)} />
                    : ""

                }
                onCloseModal={() => handleOnCloseModal()}
            />
        </>
    );
};

export default Dashboard