import { unwrapResult } from '@reduxjs/toolkit';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams, Link } from 'react-router-dom';
import { arrowBlueImg, categoryImg, heart2, leftArrowImg, SearchImg, filterIcon } from '../../../utilities/images';
import { selectLoginAuth } from '../auth/authSlice';
import { getExtraProductsByCategoryIdAsync, getProductListAsync, selectProductList } from './dashboardSlice';
import CommonLoader from "../../components/shared/CommonLoader";
import CustomModal from "../../components/shared/CustomModal";
import ProductModal from "./ProductModal";
import Slider from 'react-slick';
import SliderWrapperFashion from "./fashionStyle";
import { getCartApiAsync, selectCartData } from '../cart/cartSlice';
import { toast } from 'react-toastify';
import { createFavoriteListApiAsync } from '../favoriteList/favoriteListSlice';
import SubcatefilterModal from './subcatefilterModal';
import PaginationSetting from '../../constants/PaginationSetting';
import { Box } from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";

const SubcategoryProducts = () => {
    const history = useHistory();
    const { id, subCatId, sellerUserId } = useParams();
    const auth = useSelector(selectLoginAuth);
    const [pagination, setPagination] = useState(PaginationSetting);
    const [page, setPage] = useState(1);
    const [size, setSize] = useState('');
    const [material, setMaterial] = useState('');
    const [sizeId, setSizeId] = useState('');
    const [materialId, setMaterialId] = useState('');
    const [color, setColor] = useState('');
    const [colorId, setColorId] = useState('');
    const [productDataLoading, setProductDataLoading] = useState(false);
    const dispatch = useDispatch();
    const cartData = useSelector(selectCartData);
    const productsBySubcategoryId = useSelector(selectProductList);
    const [categorySelected, setCategorySelected] = useState('');
    const [subcategoryData, setSubcategoryData] = useState([]);
    const [subcategoryId, setSubcategoryId] = useState(subCatId);
    const [serviceId, setServiceId] = useState("");
    const [key, setKey] = useState(Math.random());
    const [modalDetail, setModalDetail] = useState({
        show: false,
        title: "",
        flag: ""
    });

    let params = {
        page: page,
        limit: pagination.recordsPerPage,
        app_name: "b2c",
        delivery_options: "1",
        seller_id: id,
        sub_category_ids: subcategoryId,
        tokenData: auth?.payload?.token
    }
    const handleOnCloseModal = () => {
        setModalDetail({
            show: false,
            title: "",
            flag: ""
        });
        setKey(Math.random());
    }
    const colorHandle = (i) => {
        setColor(color)
        setColorId(i)
    }
    const offersSettings = {
        dots: true,
        autoplay: true,
        autoplaySpeed: 2000,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
        speed: 500,
        arrows: false,
        adaptiveHeight: true
    };

    const attributeHandle = (flag, name, id) => {
        if (flag === 'size') {
            setSize(name)
            setSizeId(id)
        }
        if (flag === 'color') {
            setColor(name)
            setColorId(id)
        }
        if (flag === 'material') {
            setMaterial(name)
            setMaterialId(id)
        }
    }

    const handleSubcategory = async (data) => {
        setSubcategoryId(data?.id)
        setServiceId(data?.service_id)
        setCategorySelected(data.name)
        setProductDataLoading(true)
        let subCategoryParams = {
            "postData": {
                page: page,
                limit: pagination.recordsPerPage,
                app_name: "b2c",
                delivery_options: "1",
                seller_id: id,
                sub_category_ids: data?.id
            },
            "tokenData": auth?.payload?.token
        }
        await dispatch(getExtraProductsByCategoryIdAsync(subCategoryParams))
            .then(unwrapResult)
            .then(async (obj) => {
                // setProductsBySubcategoryId(obj?.data?.payload?.data)
                setPagination((prevState) => ({ ...prevState, totalPages: obj?.data?.payload?.total_pages, totalRecords: obj?.data?.payload?.total }));
                setProductDataLoading(false)
            }
            )
            .catch((obj) => {
                setProductDataLoading(false)

            })
    }

    const handlePagination = (obj) =>{
        setPagination((prevState) => ({ ...prevState, totalPages: obj?.data?.payload?.total_pages, totalRecords: obj?.data?.payload?.total }));

    }

    const favoriteHandle = () => {
        let favData = {
            "postData": {
                "seller_id": sellerUserId
            },
            "tokenData": auth?.payload?.token
        }
        setProductDataLoading(true)
        dispatch(createFavoriteListApiAsync(favData))
            .then(unwrapResult)
            .then(async (obj) => {
                toast.success(obj?.data?.msg)
                let sellerProductParams = {
                    "postData": {
                        sellerId: id,
                        lat: 32.5534,
                        long: 76.1258,
                        app_name: "b2c",
                        delivery_options: "1",
                        page: 1,
                        limit: 9
                    },
                    "tokenData": auth?.payload?.token
                }
                dispatch(getProductListAsync(sellerProductParams))
                    .then(unwrapResult)
                    .then(async (obj) => {
                        setCategorySelected(obj?.data?.payload?.subCategories?.find(item => item?.id == subcategoryId)?.name)
                        setSubcategoryData(obj?.data?.payload?.subCategories)
                        let subCategoryParams = {
                            "postData": {
                                page: page,
                                limit: pagination.recordsPerPage,
                                seller_id: id,
                                app_name: "b2c",
                                delivery_options: "1",
                                sub_category_ids: subcategoryId
                            },
                            "tokenData": auth?.payload?.token
                        }
                        await dispatch(getExtraProductsByCategoryIdAsync(subCategoryParams))
                            .then(unwrapResult)
                            .then(async (obj) => {
                                // setProductsBySubcategoryId(obj?.data?.payload?.data)
                                setPagination((prevState) => ({ ...prevState, totalPages: obj?.data?.payload?.total_pages, totalRecords: obj?.data?.payload?.total }));
                                setProductDataLoading(false)
                            }
                            )
                            .catch((obj) => {
                                setProductDataLoading(false)
                            })
                    }
                    )
                    .catch((obj) => {
                        setProductDataLoading(false)
                    })
            })
    }

    const productDataHandle = () => {
        setProductDataLoading(true)
        let sellerProductParams = {
            "postData": {
                sellerId: id,
                lat: 32.5534,
                long: 76.1258,
                app_name: "b2c",
                delivery_options: "1"
            },
            "tokenData": auth?.payload?.token
        }
        dispatch(getProductListAsync(sellerProductParams))
            .then(unwrapResult)
            .then(async (obj) => {
                setServiceId(obj?.data?.payload?.subCategories?.find(item => item?.id == subcategoryId)?.service_id)
                setCategorySelected(obj?.data?.payload?.subCategories?.find(item => item?.id == subcategoryId)?.name)
                setSubcategoryData(obj?.data?.payload?.subCategories)
                let subCategoryParams = {
                    "postData": {
                        page: page,
                        limit: pagination.recordsPerPage,
                        seller_id: id,
                        app_name: "b2c",
                        delivery_options: "1",
                        sub_category_ids: subcategoryId
                    },
                    "tokenData": auth?.payload?.token
                }
                await dispatch(getExtraProductsByCategoryIdAsync(subCategoryParams))
                    .then(unwrapResult)
                    .then(async (obj) => {
                        setPagination((prevState) => ({ ...prevState, totalPages: obj?.data?.payload?.total_pages, totalRecords: obj?.data?.payload?.total }));
                        // setProductsBySubcategoryId(obj?.data?.payload?.data)
                        setProductDataLoading(false)
                    }
                    )
                    .catch((obj) => {
                        setProductDataLoading(false)

                    })
            }
            )
            .catch((obj) => {
                setProductDataLoading(false)
            })
    }

    useEffect(() => {
        productDataHandle()
        dispatch(getCartApiAsync(auth?.payload?.token))
        window.scrollTo(0, 0)
    }, [])

    return (
        <>
            {
                productDataLoading === true ?
                    <CommonLoader />
                    :
                    <>
                        <div className="order-heading">
                            <div className='header-left'>
                                <img style={{ cursor: "pointer" }} onClick={() => { history.push(`/home-store/${id}`) }} src={leftArrowImg} alt="" className='me-1' />
                                <h6 className='subhead_'>Back</h6>
                            </div>
                            <div className="header-right-item">
                                <button type="button" onClick={() => favoriteHandle()}><img src={heart2} alt="" /></button>
                                <button type="button"><img src={SearchImg} alt="" /></button>
                            </div>
                        </div>
                        <div className="maphm-btmprt">
                            <div className="maphm-btmlinks">
                                <ul className="card_category">
                                    <li className='card_categoryItems'><img onClick={() => {
                                        setModalDetail({
                                            show: true,
                                            flag: "products"
                                        });
                                        setKey(Math.random())
                                    }} src={categoryImg} alt='' className='allIcon' /></li>
                                    {
                                        subcategoryData?.filter(item => item?.image !== null)?.map((val, index) => {
                                            return (
                                                <li key={index} onClick={() => { handleSubcategory(val) }} className='card_categoryItems' style={{ zIndex: subcategoryData?.filter(item => item?.image !== null)?.length - index }}>
                                                    <Link to="#" className={(categorySelected === val?.name) ? `active` : ''}><img src={val?.image} alt="catImg" /> {val?.name}
                                                    </Link>
                                                </li>
                                            )
                                        })
                                    }
                                </ul>
                            </div>
                        </div>
                        {
                            (productsBySubcategoryId?.data?.payload?.data?.length > 0) ?
                                <>
                                    <div className="fashion-itemBox mt-4">
                                        <div className="row">
                                            {productsBySubcategoryId?.data?.payload?.data?.filter(item => item?.product_images.length > 0)?.map((val, index) => {
                                                return (
                                                    <React.Fragment key={index}>
                                                        <div className="col-6" >
                                                            <div className='fashion-item fash-margtop'>
                                                                <div className="fashion-slider" >
                                                                    <SliderWrapperFashion>
                                                                        <Slider {...offersSettings}>
                                                                            {
                                                                                val?.product_images && val?.product_images?.map((value, i) => {
                                                                                    return (
                                                                                        <div key={i}>
                                                                                            <img src={value?.url} alt="" className="fashion-m" />
                                                                                        </div>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </Slider>
                                                                    </SliderWrapperFashion>
                                                                </div>
                                                                {val?.supplies[0]?.attributes?.length > 0 ?
                                                                    val?.supplies[0]?.attributes?.map((item, ind) => {
                                                                        return (
                                                                            <div key={ind}>
                                                                                {(item?.name === "Color") ?
                                                                                    <div className="fasprd-dtcolor">
                                                                                        <div className="fashion-color">
                                                                                            {item?.values?.map((v, i) => {
                                                                                                return (
                                                                                                    <span style={{ backgroundColor: `${v?.name}` }} className={(colorId === v?.id) ? "active" : ""}
                                                                                                        onClick={() => attributeHandle('color', v?.name, v?.id)}
                                                                                                        key={i}>
                                                                                                    </span>
                                                                                                )
                                                                                            })}
                                                                                        </div>
                                                                                    </div> : ""}
                                                                                {(item?.name === "Size") ?
                                                                                    <div className="fasprd-size">
                                                                                        <ul>
                                                                                            {item?.values?.map((v, i) => {
                                                                                                return (
                                                                                                    <li className={(sizeId === v?.id) ? "active" : ""}
                                                                                                        onClick={() => attributeHandle('size', v?.name, v?.id)}
                                                                                                        key={i} >
                                                                                                        <strong>{v?.name}</strong>
                                                                                                    </li>
                                                                                                )
                                                                                            })}
                                                                                        </ul>
                                                                                    </div> : ""}
                                                                                {(item?.name === "Material") ?
                                                                                    <div className="fasprd-size">
                                                                                        <ul>
                                                                                            {item?.values?.map((v, i) => {
                                                                                                return (
                                                                                                    <li className={(materialId === v?.id) ? "active" : ""}
                                                                                                        onClick={() => attributeHandle('material', v?.name, v?.id)}
                                                                                                        key={i} >
                                                                                                        <strong>{v?.name}</strong>
                                                                                                    </li>
                                                                                                )
                                                                                            })}
                                                                                        </ul>
                                                                                    </div> : ""}
                                                                            </div>
                                                                        )
                                                                    })
                                                                    : ""}
                                                                <p className='mt-4 subheadLight_text mb-2' style={{ cursor: 'pointer' }} ><strong>{val?.name}</strong> <br />
                                                                    <h6 className='textdiscrption mt-1'>{val?.description}</h6></p>
                                                                <h6>${val?.price}</h6>
                                                                <div className='addBox_' onClick={() => history.push(`/home-store-product/${id}/${val?.id}/${"subcategory"}/${subcategoryId}/${sellerUserId}`)}>
                                                                    <i className="fas fa-plus"></i>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </React.Fragment>
                                                )
                                            })}
                                        </div>
                                    </div>
                                </> : <>No Products Found</>
                        }
                        {cartData?.data?.payload?.cart_products.length > 0 ?
                            <div className="cartItemsbox">
                                <div className="selected-itembox">
                                    <div className="selected-leftcnt">
                                        <p>Items: <strong>{cartData?.data?.payload?.cart_products.length}</strong></p>
                                        <p>$ <strong>{(cartData?.data?.payload?.amout?.total_amount).toFixed(2)}</strong></p>
                                    </div>
                                    <div className="selected-rightbtn">
                                        <Link to='/cart'>
                                            <img src={arrowBlueImg} alt="arrowBlueImg" />
                                        </Link>
                                    </div>
                                </div>
                            </div> : ""}
                        <div className='filetriconBox'>
                            <img className='filterimg_' onClick={() => {
                                setModalDetail({
                                    show: true,
                                    flag: "subcatefilter"
                                });
                                setKey(Math.random())
                            }} src={filterIcon} />
                        </div>
                    </>}
            <CustomModal
                key={key}
                show={modalDetail.show}
                backdrop="static"
                showCloseBtn={false}
                ids="productInner"
                isRightSideModal={false}
                mediumWidth={true}
                child={modalDetail.flag === 'products'
                    ? <ProductModal flag="others" categoryData={subcategoryData} setSubcategoryId={(e) => handleSubcategory(e)} setCategorySelected={setCategorySelected} categoryLoading={productDataLoading} closeModal={() => handleOnCloseModal()} details={(e) => handleOnCloseModal(e)} />
                    : modalDetail.flag === 'subcatefilter'
                        ?
                        <SubcatefilterModal handlePagination={(e)=>handlePagination(e)} serviceId={serviceId} params={params} length={productsBySubcategoryId?.data?.payload?.data?.length} flag="subcatefilter" categoryData={subcategoryData} setSubcategoryId={(e) => handleSubcategory(e)} setCategorySelected={setCategorySelected} categoryLoading={productDataLoading} closeModal={() => handleOnCloseModal()} details={(e) => handleOnCloseModal(e)} />
                        :
                        ""
                }
                onCloseModal={() => handleOnCloseModal()}
            />
            {pagination.totalRecords > pagination.recordsPerPage &&
                <Box py={3} display="flex" justifyContent="center">
                    <Pagination
                        key={page}
                        count={pagination.totalPages || 0}
                        color="secondary"
                        variant="outlined"
                        onChange={(e, value) => setPage(value)}
                        defaultPage={page}
                    />
                </Box>
            }
        </>
    )
}

export default SubcategoryProducts

