import React, { useEffect, useState } from 'react';
import { bagImg, heart2, leftArrowImg, categoryImg, starCircleImg, clockImg, deliveryParcelImg } from '../../../utilities/images';
import Badge from "@material-ui/core/Badge";
import { Link, useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { selectLoginAuth } from '../auth/authSlice';
import CommonLoader from "../../components/shared/CommonLoader";
import CustomModal from "../../components/shared/CustomModal";
import ProductModal from "./ProductModal";
import { getBrandListAsync, getTrendingNearBySellerAsync } from './dashboardSlice';
import PaginationSetting from '../../constants/PaginationSetting';
import { Box } from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";


const SellerListing = () => {
    const history = useHistory();
    const PathName = window.location.pathname;
    const dispatch = useDispatch();
    const { categoryId, subcategoryId, selectedbrandId, flag, categoryName, subCategoryName ,type } = useParams();
    const [brandLoading, setBrandLoading] = useState(false);
    const [pagination, setPagination] = useState(PaginationSetting);
    const [page, setPage] = useState(1);
    const [count, setCount] = useState("");
    const auth = useSelector(selectLoginAuth);
    const [brandData, setBrandData] = useState({});
    const [sellerData, setSellerData] = useState({});
    const [categorySelected, setCategorySelected] = useState('');
    const [key, setKey] = useState(Math.random());
    const [modalDetail, setModalDetail] = useState({
        show: false,
        title: "",
        flag: ""
    });
    const handleOnCloseModal = () => {
        setModalDetail({
            show: false,
            title: "",
            flag: ""
        });
        setKey(Math.random());
    }
    const BrandsDataHandle = async () => {
        setBrandLoading(true)
        let subCategoryParams = {
            "postData": {
                main_category: true,
                b2b_service_type: flag,
                category_id: subcategoryId
            },
            "tokenData": auth?.payload?.token
        }
        await dispatch(getBrandListAsync(subCategoryParams))
            .then(unwrapResult)
            .then(async (obj) => {
                setBrandData(obj)
                setCategorySelected(obj?.data?.payload?.data?.find(item => item?.id == selectedbrandId)?.name)
                let params = {
                    postData: {
                        page: page,
                        limit: pagination.recordsPerPage,
                        brand_id: selectedbrandId,
                        category_id: subcategoryId,
                        lat: 30.7355,
                        b2b_service_type: flag,
                        long: 76.7121,
                        need_trending: true,
                        need_nearby: true
                    },
                    tokenData: auth?.payload?.token
                }
                dispatch(getTrendingNearBySellerAsync(params))
                    .then(unwrapResult)
                    .then(async (obj) => {
                        setSellerData(obj)
                        setPagination((prevState) => ({ ...prevState, totalPages: obj?.data?.payload?.sellers?.total_pages, totalRecords: obj?.data?.payload?.sellers?.total }));
                        setBrandLoading(false)
                    }
                    )
                    .catch((obj) => {
                        setBrandLoading(false)
                    })
            }
            )
            .catch((obj) => {
                setBrandLoading(false)

            })
    }
    const sellersHandle = async (data) => {
        setCategorySelected(data?.name)
        setBrandLoading(true)
        let params = {
            postData: {
                page: page,
                limit: pagination.recordsPerPage,
                brand_id: data?.id,
                category_id: subcategoryId,
                b2b_service_type: flag,
                lat: 30.7355,
                long: 76.7121,
                need_trending: true,
                need_nearby: true
            },
            tokenData: auth?.payload?.token
        }
        dispatch(getTrendingNearBySellerAsync(params))
            .then(unwrapResult)
            .then(async (obj) => {
                setSellerData(obj)
                setPagination((prevState) => ({ ...prevState, totalPages: obj?.data?.payload?.sellers?.total_pages, totalRecords: obj?.data?.payload?.sellers?.total }));
                setBrandLoading(false)
            }
            )
            .catch((obj) => {
                setBrandLoading(false)
            })
    }

    useEffect(() => {
        BrandsDataHandle()
    }, [page])

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <>
            {
                brandLoading === true ?
                    <CommonLoader />
                    :
                    <>
                        <div className="header-part browse-addprdcard-header">
                            <div className="header-left-item">
                                <div className="header-left">
                                    <img onClick={() => history.push(`/subcategory-list/${categoryId}/${flag}/${categoryName}/${subcategoryId}`)} style={{ cursor: "pointer" }} src={leftArrowImg} alt="leftArrow" className='me-1' />
                                    <h6 className='subhead_'>{categoryName}/{subCategoryName}</h6>
                                </div>
                            </div>
                            <div className="header-right-item">
                                <button type="button" >
                                    <img src={heart2} alt="bellImg" />
                                </button>
                                {
                                    count !== "" ?
                                        <Badge overlap="rectangular" color="secondary" badgeContent={count}>
                                            <button type="button" onClick={() => history.push('/cart')}><img src={bagImg} alt="bagImg" /></button>
                                        </Badge>
                                        :
                                        <button type="button" onClick={() => history.push('/cart')}><img src={bagImg} alt="bagImg" /></button>
                                }
                            </div>
                        </div>
                        {<div className="maphm-btmprt">
                            <div className="maphm-btmlinks">
                                <ul className="card_category">
                                    <li className='card_categoryItems'><img onClick={() => {
                                        setModalDetail({
                                            show: true,
                                            flag: "products"
                                        });
                                        setKey(Math.random())
                                    }} src={categoryImg} alt='' className='allIcon' /></li>
                                    {
                                        brandData?.data?.payload?.data?.map((val, index) => {
                                            return (
                                                <li onClick={() => sellersHandle(val)} key={index} className='card_categoryItems'><Link to="#" className={(categorySelected === val?.name) ? `active` : ''}><img src={val?.image} alt="catImg" /> {val?.name}</Link></li>
                                            )
                                        })
                                    }
                                </ul>
                            </div>
                        </div>}
                        <div className="main-content">
                            <div className="tobacco-mainitem">
                                <ul>
                                    {
                                        sellerData?.data?.payload?.sellers?.data ?
                                            sellerData?.data?.payload?.sellers?.data?.map((val, index) => {
                                                return (
                                                    <div style={{cursor:"pointer"}} key={index} onClick={type === 'Retail' ? () => history.push({ pathname: `/home-store/${val?.unique_uuid}`, state: PathName }) : () => history.push({ pathname: `/home-store-services/${val?.unique_uuid}`, state: PathName })} >
                                                        <div className='innerProductContainer'>
                                                            <div className='innerProductArea'>
                                                                <img style={{ width: "100px" }} src={val?.user_profiles?.profile_photo} alt='tob1Img' />
                                                            </div>
                                                            <ul>
                                                                <li><strong>{val?.user_profiles?.firstname}</strong>  </li>
                                                                <li><img src={starCircleImg} alt="starCircleImg" /> {val?.sellerRating?.rating} </li>
                                                                <li><img src={clockImg} alt="clockImg" />{val?.distance?.timeString} </li>
                                                                <li><img src={deliveryParcelImg} alt="deliveryParcelImg" /> ${val?.deliveryFee} Delivery fee</li>
                                                            </ul>
                                                        </div>
                                                    </div>

                                                )
                                            })
                                            : <span>No Seller Found</span>
                                    }
                                </ul>
                            </div>
                        </div>
                    </>}
            {pagination.totalRecords > pagination.recordsPerPage &&
                <Box py={3} display="flex" justifyContent="center">
                    <Pagination
                        key={page}
                        count={pagination.totalPages || 0}
                        color="secondary"
                        variant="outlined"
                        onChange={(e, value) => setPage(value)}
                        defaultPage={page}
                    />
                </Box>
            }
            <CustomModal
                key={key}
                show={modalDetail.show}
                // backdrop="static"
                showCloseBtn={false}
                ids="productInner"
                isRightSideModal={false}
                mediumWidth={true}
                child={modalDetail.flag === 'products'
                    ? <ProductModal flag="others" categoryData={brandData?.data?.payload?.data} setSubcategoryId={(e) => sellersHandle(e)} setCategorySelected={setCategorySelected} categoryLoading={brandLoading} closeModal={() => handleOnCloseModal()} details={(e) => handleOnCloseModal(e)} />
                    : ""

                }
                onCloseModal={() => handleOnCloseModal()}
            />
        </>
    )
}

export default SellerListing
