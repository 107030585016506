import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getIdCardPhoto } from "./ageVerificationApi";

const initialState = {
  uploadIdCardPhoto1: null,
  uploadIdCardPhoto2: null,
  status: "idle",
};

export const uploadIdCardAsync = createAsyncThunk("idCard/photo", async (data) => {
  const response = await getIdCardPhoto(data);
  return response.data;
});
export const uploadIdCardAsync1 = createAsyncThunk("idCard/photo1", async (data) => {
  const response = await getIdCardPhoto(data);
  return response.data;
});

export const profilePhotoSlice = createSlice({
  name: "uploadIdCardPhoto",
  initialState,
  extraReducers: (builder) => {
    builder
    .addCase(uploadIdCardAsync.pending, (state) => {
      state.status = "loading";
    })
    .addCase(uploadIdCardAsync.fulfilled, (state, { payload }) => {
      state.uploadIdCardPhoto1 = payload
      state.status = "idle";
    })
    .addCase(uploadIdCardAsync.rejected, (state, { error }) => {
      state.status = "idle";
      state.uploadIdCardPhoto1 = null
    })
    .addCase(uploadIdCardAsync1.pending, (state) => {
      state.status = "loading";
    })
    .addCase(uploadIdCardAsync1.fulfilled, (state, { payload }) => {
      state.uploadIdCardPhoto2 = payload
      state.status = "idle";
    })
    .addCase(uploadIdCardAsync1.rejected, (state, { error }) => {
      state.status = "idle";
      state.uploadIdCardPhoto2 = null
    })
  }
});

export const selectIdCardPhoto1 = (state) => state.idCardPhoto.uploadIdCardPhoto1;
export const selectIdCardPhoto2 = (state) => state.idCardPhoto.uploadIdCardPhoto2;
export default profilePhotoSlice.reducer;