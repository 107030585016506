import { unwrapResult } from "@reduxjs/toolkit";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from 'react-router-dom';
import { leftArrowImg } from "../../../utilities/images";
import { selectLoginAuth } from "../auth/authSlice";
import { getTermsPrivacyAsync, selectTermsPrivacy } from "./settingSlice";
import CommonLoader from '../../components/shared/CommonLoader';


const TermsAndConditions = (props) => {
    const termsConditionsData = useSelector(selectTermsPrivacy);
    const dispatch = useDispatch();
    const [termsLoading, setTermsLoading] = useState(false);
    const auth = useSelector(selectLoginAuth);

    const termsConditionsDataHandle = () => {
        setTermsLoading(true)
        dispatch(getTermsPrivacyAsync(auth?.payload?.token))
            .then(unwrapResult)
            .then((obj) => {
                setTermsLoading(false)
            })
            .catch((obj) => {
                setTermsLoading(false)
            })
    }

    useEffect(() => {
        termsConditionsDataHandle()
    }, [])

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <>
            {termsLoading === true ?
                <CommonLoader />
                :
                <>
                    <div className="order-heading">
                        <h4><Link to='/more'><img src={leftArrowImg} alt="leftArrowImg" /></Link> Terms & Conditions</h4>
                    </div>

                    <div className="main-content">
                        <div className="update-accinfo term-andcond">
                            <h4>Terms and Conditions</h4>
                            <h2>Welcome to JOBR!</h2>
                            <p><strong>You will need to fill out a form in the next step. Please read the instructions below to complete the form correctly</strong></p>
                            <p>{termsConditionsData?.data?.payload?.terms_and_conditons}</p>
                            <ul>
                                <li><Link to='/dashboard'>Accepted</Link></li>
                            </ul>
                        </div>
                    </div>
                </>}
        </>
    );
}

export default TermsAndConditions;