import React, { useEffect } from "react";
import { Link } from 'react-router-dom';
import {leftArrowImg,workingImg} from "../../../utilities/images";

const ReportAndIssueTwo = (props) => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

  return (
        <>
         <div className="age-upper">
                <Link to='/help-center'><img src={leftArrowImg} alt="leftArrowImg" /> Report other issue</Link>
            </div>

            <div className="ageverify4-main report-submit">
                <h2>Thank you</h2>
                <img src={workingImg} alt="workingImg" />
                <div className="ageverify4-cnt">
                    <h4>Submitted</h4>
                    <p>We ‘ll get back to you soon</p>
                </div>
            </div>
        </>
       );
    }  
    
export default ReportAndIssueTwo;