import React, { useEffect } from "react";
import { Link, useHistory } from 'react-router-dom';
import localStorage from "redux-persist/es/storage";
import { ordersRerdersImg, bellImg, SearchImg, coinStackImg, editProfileImg, locationImg, Iconp1, Iconp3, Iconp4, products, Iconp6, Iconp7, Iconp8, Iconp9, Iconp10, UserVerified, bookingImg, Iconp5, heart2 } from "../../../utilities/images";
import { logout, selectLoginProfile } from "../auth/authSlice";
import { useSelector, useDispatch } from "react-redux";
import { selectUserProfile, userProfileAsync } from "../profile/profileSlice";
import { selectWalletBalance , walletDataEmpty } from "../wallet/walletSlice";
import { cartEmpty } from "../cart/cartSlice";
import { emptyProfile } from "../profile/profileSlice";
import { emptyDashboard } from "../dashboard/dashboardSlice";

const More = (props) => {
    const history = useHistory();
    const dispatch = useDispatch()
    const selectLoginUser = useSelector(selectLoginProfile);
    const selectProfile = useSelector(selectUserProfile);
    const walletData = useSelector(selectWalletBalance);
    let userProfile = selectProfile?.user_profiles;
    let loginId = selectLoginUser?.payload.id;
    let userName = '';
    if (userProfile) {
        if (userProfile.firstname) {
            userName = userProfile.firstname;
        }
        // if(userProfile.lastname){
        //     userName = userName + " " + userProfile.lastname;
        // }
    }

    const handleLogout = async () => {
        await dispatch(logout())
        await dispatch(walletDataEmpty())
        await dispatch(cartEmpty())
        await dispatch(emptyProfile())
        await dispatch(emptyDashboard())
        window.localStorage.clear()
        await history.push('/registartion/step-1')
    }
    useEffect(() => {
        let params = {
            loginId: loginId,
            token: selectLoginUser.payload.token
        }
        dispatch(userProfileAsync(params))
    }, [dispatch])

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <>
            <div className="order-heading">
                <div className="header-left-item">
                    <h4>More</h4>
                </div>
                <div className="header-right-item">
                    <button type="button" onClick={() => history.push('/notifications')}><img src={bellImg} alt="bell" /></button>
                    <button type="button">
                        <img src={SearchImg} alt="search" />
                    </button>
                    <Link to="/wallet" className="coinStack">{walletData ? walletData?.data?.payload?.sila_balance : 0}
                        <img src={coinStackImg} alt="coinStack" />
                    </Link>
                </div>
            </div>
            <div className="main-content">
                <div className="more-profile-area">
                    <div className="more-profilebox">
                        <Link to='/profile'>
                            <div className="profile-itemmr">
                                <img src={selectProfile?.payload?.user_profiles?.profile_photo ? selectProfile?.payload?.user_profiles?.profile_photo : editProfileImg} alt="editProfileImg" />
                            </div>
                            <div className="profile-cntmr">
                                <h4> {selectProfile?.payload?.user_profiles ? selectProfile?.payload?.user_profiles?.firstname + " " + selectProfile?.payload?.user_profiles?.lastname : ""}</h4>
                                <p><img src={locationImg} alt="location" /> {selectProfile?.payload?.current_location?.formatted_address ? `${(selectProfile?.payload?.current_location?.formatted_address).substring(0, 40)}...` : ""}</p>
                                <h6><img src={UserVerified} alt="" /> ID: 548962134</h6>
                            </div>
                            <div className="profile-moreicon">
                                <i className="fas fa-chevron-right"></i>
                            </div>
                        </Link>
                    </div>
                    <div className="profile-links">
                        <ul>
                            <li><Link to='/age-verification'><img src={Iconp1} alt="iconp1" /> Age verifications <i className="fas fa-chevron-right"></i></Link></li>
                            {/* <li><Link to='/face-id-and-Pin'><img src={Iconp2} alt="iconp2" />Face ID & PIN <i className="fas fa-chevron-right"></i></Link></li> */}
                            <li><Link to='/wallet'><img src={Iconp3} alt="iconp3" />JBR Wallet <i className="fas fa-chevron-right"></i></Link></li>
                            <li><Link to='/payment'><img src={Iconp3} alt="iconp3" />Payment methods <i className="fas fa-chevron-right"></i></Link></li>
                            <li><Link to='/orders'><img src={ordersRerdersImg} alt="iconp3" />Orders & Reorders <i className="fas fa-chevron-right"></i></Link></li>
                            <li><Link to='/tracking'><img src={ordersRerdersImg} alt="iconp3" />Track Your Order <i className="fas fa-chevron-right"></i></Link></li>
                            <li><Link to='/booking-list'><img src={bookingImg} alt="iconp3" />Service Booking List<i className="fas fa-chevron-right"></i></Link></li>
                            <li><Link to='/addresses'><img src={Iconp4} alt="iconp4" />Addresses <i className="fas fa-chevron-right"></i></Link></li>
                            <li><Link to='/coupons-two'><img src={Iconp5} alt="iconp5" />Coupons <i className="fas fa-chevron-right"></i></Link></li>
                            <li><Link to='/favoriteList'><img src={heart2} alt="heart" />Favorite list <i className="fas fa-chevron-right"></i></Link></li>
                            <li><Link to='/product-review-available'><img src={products} alt="products" /> Product review<i className="fas fa-chevron-right"></i></Link></li>
                            <li><Link to='/refer-and-earn'><img src={Iconp6} alt="iconp6" />Refer & Earn <i className="fas fa-chevron-right"></i></Link></li>
                            <li><Link to='/help-center'><img src={Iconp7} alt="iconp7" />Help center <i className="fas fa-chevron-right"></i></Link></li>
                            <li><Link to='/settings'><img src={Iconp8} alt="iconp8" />Settings <i className="fas fa-chevron-right"></i></Link></li>
                            <li><Link to='/terms-and-conditions'><img src={Iconp9} alt="iconp9" />Terms & Conditions <i className="fas fa-chevron-right"></i></Link></li>
                            <li><Link to='/privacy-policy'><img src={Iconp10} alt="iconp10" />Privacy Policy <i className="fas fa-chevron-right"></i></Link></li>
                        </ul>
                    </div>
                    <div className="profile-btmprt">
                        <Link to='#' onClick={() => handleLogout()}>Logout</Link>
                        <p>V 0000.0001</p>
                    </div>
                </div>
            </div>
        </>
    );
}

export default More;