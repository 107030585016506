import React from "react";
import { useState } from "react";
import { Link, useHistory, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import CustomModal from "../../components/shared/CustomModal";
import { locationImg, homeImg, invoiceImg } from "../../../utilities/images";
import { addressDetailAsync, changeCurrentAddressApiAsync, selectAllAddress } from "./addressSlice";
import { selectLoginAuth } from "../auth/authSlice";
import Geocode from "react-geocode";
import { useEffect } from "react";
import { withGoogleMap, GoogleMap, withScriptjs, Marker } from "react-google-maps";
import { getAddressFromCoordinates } from './AddressMethods'
import UseGeoLocation from "./useGeoLocation";
import { unwrapResult } from "@reduxjs/toolkit";
import ChangeAddress from "./ChangeAddress";

Geocode.setApiKey(process.env.REACT_APP_MAP_KEY);
Geocode.enableDebug();
Geocode.setLanguage("en");
Geocode.setLocationType("ROOFTOP");
const NewAddress = (props) => {
    const dispatch = useDispatch();
    const history = useHistory();
    // const toastId = React.useRef(null)
    const auth = useSelector(selectLoginAuth);
    const { flag, id } = useParams();
    const allAddresssData = useSelector(selectAllAddress);
    const [loading, setLoading] = useState(false);
    const [apartment, setApartment] = useState("");
    const [floorNo, setFloorNo] = useState("");
    const [address, setAddress] = useState('');
    const [latitude, setLatitude] = useState(null)
    const [longitude, setLongitude] = useState(null)
    const [district, setDistrict] = useState('');
    const [country, setCountry] = useState('');
    const [countryCode, setCountryCode] = useState('')
    const [state, setState] = useState('')
    const [stateCode, setStateCode] = useState('')
    const [placeId, setPlaceId] = useState('');
    const [zipCode, setZipCode] = useState('');
    const [add1, setAdd1] = useState('');
    const [add2, setAdd2] = useState('');
    const [zoom, setZoom] = useState(15)
    const [city, setCity] = useState("")
    const [label, setLabel] = useState("Home");
    const [key, setKey] = useState(Math.random());
    const [modalDetail, setModalDetail] = useState({
        show: false,
        title: "",
        flag: ""
    });
    // const location = UseGeoLocation()

    let pathName = window.location.pathname

    let lat, long
    const geocodeHandler = () => {
        navigator.geolocation.getCurrentPosition(function (position) {
            console.log(position, 'position');
            getAddressFromCoordinates(allAddresssData?.payload?.data?.find(item => item.id == id)?.latitude ? allAddresssData?.payload?.data?.find(item => item.id == id)?.latitude : position.coords.latitude, allAddresssData?.payload?.data?.find(item => item.id == id)?.longitude ? allAddresssData?.payload?.data?.find(item => item.id == id)?.longitude : position.coords.longitude)
                .then(data => {
                    setPlaceId(data.place_id);
                    setAddress(data?.formatted_address);
                    setLongitude(position.coords.longitude)
                    setLatitude(position.coords.latitude)
                    lat = position.coords.latitude
                    long = position.coords.longitude

                    for (var i = 0; i < data.address_components.length; i++) {
                        if (data.address_components[i].types[0] === 'locality') {
                            setCity(data?.address_components?.[i]?.long_name);
                            setDistrict(data?.address_components?.[i]?.long_name);
                        }

                        if (data.address_components[i].types[0] === 'country') {
                            setCountry(data?.address_components?.[i]?.long_name);
                            setCountryCode(data?.address_components?.[i]?.short_name);
                        }

                        if (data.address_components[i].types[0] === 'administrative_area_level_1') {
                            setState(data?.address_components?.[i]?.long_name);
                            setStateCode(data?.address_components?.[i]?.short_name);
                        }

                        if (data.address_components[i].types[0] === 'postal_code') {
                            setZipCode(data?.address_components?.[i]?.long_name);
                        }
                        if (
                            data.address_components[i].types[1] === 'sublocality' &&
                            data.address_components[i].types[2] === 'sublocality_level_3'
                        ) {
                            setAdd1(data?.address_components?.[i]?.long_name);
                        }

                        if (
                            data.address_components[i].types[1] === 'sublocality' &&
                            data.address_components[i].types[2] === 'sublocality_level_1'
                        ) {
                            setAdd2(data?.address_components?.[i]?.long_name);
                        }
                    }
                })
                .catch(err => console.log('error getting address', err));
        });
    }

    // if (location.loaded) {
    //     console.log(JSON.stringify(location, "location infoooooooooooooooo"))
    // }

    const handleOnCloseModal = () => {
        setModalDetail({
            show: false,
            title: "",
            flag: ""
        });
        setKey(Math.random());
    }

    const handleChange = (e, fieldName) => {
        const value = e.target.value;
        if (fieldName === "Apartment") {
            setApartment(value);
        }
        else if (fieldName === "FloorOrAprNo") {
            setFloorNo(value);
        }
    }

    const handleOnSubmit = async (e) => {
        e.preventDefault();
        // if (!apartment) {
        //     if (!toast.isActive(toastId.current)) {
        //         toastId.current = toast.error("Please enter apartment details");
        //     }
        //     return;
        // }
        let params = {
            "place_id": placeId,
            "custom_address": address,
            "token": auth?.payload?.token,
            "address_type": label,
            "city": city,
            "district": district,
            "country": country,
            "postal_code": zipCode,
            "country_code": countryCode,
            "formatted_address": address,
            "latitude": latitude,
            "longitude": longitude
        }

        let data = {
            "postData": {
                "place_id": placeId,
                "custom_address": address,
                "address_type": label,
                "city": city,
                "district": district,
                "country": country,
                "postal_code": zipCode,
                "formatted_address": address,
                "state": state,
                "state_code": stateCode,
                "latitude": latitude,
                "longitude": longitude
            },
            "tokenData": auth?.payload?.token,
            "id": id
        }
        if (id) {
            setLoading(true)
            await dispatch(changeCurrentAddressApiAsync(data))
                .then(unwrapResult)
                .then((obj) => {
                    setLoading(false)
                    history.push('/addresses')
                }
                )
                .catch((obj) => {
                    setLoading(false)
                })
        } else {
            setLoading(true)
            await dispatch(addressDetailAsync(params))
                .then(unwrapResult)
                .then((obj) => {
                    setLoading(false)
                    flag === "placing" ? history.push(`/addresses/${flag}`) : history.push('/addresses')
                }
                )
                .catch((obj) => {
                    setLoading(false)
                })
        }
    }

    const handleClick = (event) => {
        setLabel(event?.currentTarget?.textContent)
    }

    const AsyncMap = withScriptjs(
        withGoogleMap(
            props => (
                <GoogleMap
                    defaultZoom={zoom}
                    defaultCenter={{ lat: latitude, lng: longitude }}
                >
                    <Marker
                        name={'Dolores park'}
                        draggable={true}
                        onDragEnd={onMarkerDragEnd}
                        position={{ lat: latitude, lng: longitude }}
                    />
                    <Marker />
                </GoogleMap>
            )
        )
    );

    const onMarkerDragEnd = (event) => {
        let newLat = event.latLng.lat(),
            newLng = event.latLng.lng();

        getAddressFromCoordinates(newLat, newLng)
            .then(data => {
                setPlaceId(data.place_id);
                setAddress(data?.formatted_address);
                setLongitude(newLng)
                setLatitude(newLat)
                lat = newLat
                long = newLng

                for (var i = 0; i < data.address_components.length; i++) {
                    if (data.address_components[i].types[0] === 'locality') {
                        setCity(data?.address_components?.[i]?.long_name);
                        setDistrict(data?.address_components?.[i]?.long_name);
                    }

                    if (data.address_components[i].types[0] === 'country') {
                        setCountry(data?.address_components?.[i]?.long_name);
                        setCountryCode(data?.address_components?.[i]?.short_name);
                    }

                    if (data.address_components[i].types[0] === 'postal_code') {
                        setZipCode(data?.address_components?.[i]?.long_name);
                    }
                    if (
                        data.address_components[i].types[1] === 'sublocality' &&
                        data.address_components[i].types[2] === 'sublocality_level_3'
                    ) {
                        setAdd1(data?.address_components?.[i]?.long_name);
                    }

                    if (data.address_components[i].types[0] === 'administrative_area_level_1') {
                        setState(data?.address_components?.[i]?.long_name);
                        setStateCode(data?.address_components?.[i]?.short_name);
                    }

                    if (
                        data.address_components[i].types[1] === 'sublocality' &&
                        data.address_components[i].types[2] === 'sublocality_level_1'
                    ) {
                        setAdd2(data?.address_components?.[i]?.long_name);
                    }
                }
            })
            .catch(err => console.log('error getting address', err));
        setLatitude(newLat)
        setLongitude(newLng)
    };

    // let modalData = {
    //     "add1": add1,
    //     "add2": add2,
    //     "zipcode": zipCode,
    //     "city": city
    // }

    const handleModalDetails = (data) => {
        let newLat = data.lat,
            newLng = data.lng;

        getAddressFromCoordinates(newLat, newLng)
            .then(data => {
                setPlaceId(data.place_id);
                setAddress(data?.formatted_address);
                setLongitude(newLng)
                setLatitude(newLat)
                lat = newLat
                long = newLng

                for (var i = 0; i < data.address_components.length; i++) {
                    if (data.address_components[i].types[0] === 'locality') {
                        setCity(data?.address_components?.[i]?.long_name);
                        setDistrict(data?.address_components?.[i]?.long_name);
                    }

                    if (data.address_components[i].types[0] === 'country') {
                        setCountry(data?.address_components?.[i]?.long_name);
                        setCountryCode(data?.address_components?.[i]?.short_name);
                    }

                    if (data.address_components[i].types[0] === 'administrative_area_level_1') {
                        setState(data?.address_components?.[i]?.long_name);
                        setStateCode(data?.address_components?.[i]?.short_name);
                    }

                    if (data.address_components[i].types[0] === 'postal_code') {
                        setZipCode(data?.address_components?.[i]?.long_name);
                    }
                    if (
                        data.address_components[i].types[1] === 'sublocality' &&
                        data.address_components[i].types[2] === 'sublocality_level_3'
                    ) {
                        setAdd1(data?.address_components?.[i]?.long_name);
                    }

                    if (
                        data.address_components[i].types[1] === 'sublocality' &&
                        data.address_components[i].types[2] === 'sublocality_level_1'
                    ) {
                        setAdd2(data?.address_components?.[i]?.long_name);
                    }
                }
            })
            .catch(err => console.log('error getting address', err));
        setLatitude(newLat)
        setLongitude(newLng)
    }

    useEffect(() => {
        // if (!id) {
            geocodeHandler()
        // }
    }, [])

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <>
            <div className="map_box">
                {/* <img src={addressMapImg} alt="addressMapImg" /> */}
                <AsyncMap
                    googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_MAP_KEY}&libraries=places`}
                    loadingElement={
                        <div style={{ height: `100%` }} />
                    }
                    containerElement={
                        <div style={{ height: '300px' }} />
                    }
                    mapElement={
                        <div style={{ height: `100%` }} />
                    }
                />
            </div>
            <div className="main-content">
                <div className="delivery-details">
                    <div className="delivery-dtupper">
                        <h2>Delivery details</h2>
                        <p>
                            <img src={locationImg} alt="locationImg" />
                            <span>
                                {address}
                            </span>
                            {/* <Link to="#" onClick={() => { setModalDetail({ show: true, flag: 'add', }); setKey(Math.random()); }}>
                                <img src={edit2Img} alt="edit2Img" />
                            </Link> */}
                            <input type="button" className="change_button" value="Change" onClick={() => { setModalDetail({ show: true, flag: 'add', }); setKey(Math.random()); }} />
                        </p>
                        <form action="#" method="POST" >
                            <input type="text" placeholder="Apartment" className="Customform-control" onChange={(e) => { handleChange(e, 'Apartment') }} value={apartment} />
                            <input type="text" placeholder="Floor or Apr No (Optional)" className="Customform-control" onChange={(e) => { handleChange(e, 'FloorOrAprNo') }} value={floorNo} />
                        </form>
                    </div>
                    <div className="delivery-btmprt">
                        <h4>Add label</h4>
                        <ul>
                            <li>
                                <Link to="#" onClick={(e) => handleClick(e)} className={label === "Home" ? "active" : ""}><span><img src={homeImg} alt="homeWhiteImg" /></span>Home</Link>
                            </li>
                            <li>
                                <Link to="#" onClick={(e) => handleClick(e)} className={label === "Work" ? "active" : ""}><span><i className="fa fa-briefcase"></i></span>Work</Link>
                            </li>
                            <li>
                                <Link to="#" onClick={(e) => handleClick(e)} className={label === "Billing" ? "active" : ""} ><span><img src={invoiceImg} alt="invoiceImg" /></span>Billing</Link>
                            </li>
                            <li>
                                <Link to="#" onClick={(e) => handleClick(e)} className={label === "Other" ? "active" : ""}  ><span><i className="fas fa-plus"></i></span>Other</Link>
                            </li>
                        </ul>
                        <div className="delivery-adrbtn">
                            <button type="button" className="btn btn-primary continue" disabled={loading} onClick={(e) => { handleOnSubmit(e) }}>
                                {loading === true && (
                                    <span className="spinner-border spinner-border-sm"></span>
                                )}
                                &nbsp;&nbsp;
                                <span>Save</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <CustomModal
                key={key}
                show={modalDetail.show}
                // backdrop="static"
                showCloseBtn={false}
                isRightSideModal={false}
                mediumWidth={true}
                child={modalDetail.flag === 'add'
                    // ? <Add closeModal={() => handleOnCloseModal()} data={props?.location?.state} modelData={modalData} />
                    ? <ChangeAddress closeModal={() => handleOnCloseModal()} details={(e) => handleModalDetails(e)} />
                    : ""
                }
                onCloseModal={() => handleOnCloseModal()}
            />
        </>
    );
}

export default NewAddress;