import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { selectLoginAuth } from '../containers/auth/authSlice';

const Authenticator = (props) => {
  const history = useHistory();
  const auth = useSelector(selectLoginAuth)
  const pathName = window.location.pathname;
  const signupPaths = ["/login", "/home", "/document-success", "/forgot-pin", "/verify-phone", "/set-new-pin",
    "/re-enter-new-pin", "/", "/verify-phone-on-register", "/register-email", "/verify-email", "/area-code-with-state",
    "/create-profile", "/register", "/registration-guideline", "/register2", "/registration-doc-pi", "/registration-doc-dd",
    "/registration-doc-vd", "/registration-doc-vrd", "/registration-doc-vid", "/register3", "/registration-agreement",
    "/register4", "/business-id-verification", "/business-registration", "/kyb-verification"];
  const authenticateUser = (isAuthenticationRequired) => {
    if (auth?.payload?.token) {
      if (signupPaths.includes(pathName)) {
        history.push('/dashboard');
        // return false;
      }
    }
    else if (isAuthenticationRequired) {
      history.push('/registartion/step-1');
    }
  }

  useEffect(() => {
    authenticateUser(props.isAuthenticationRequired);
  }, []);

  return (
    <>
      {props.children}
    </>
  )
}
export default Authenticator;