import React, { useEffect } from "react";
//import { bindActionCreators } from "redux";
import { Link } from 'react-router-dom';
import { leftArrowImg, referImg, copyImg, coinStackImg, userDoubleImg } from "../../../utilities/images";
import { selectWalletBalance } from "../wallet/walletSlice";
import { useSelector } from "react-redux";

const ReferAndEarn = (props) => {
    const walletData = useSelector(selectWalletBalance);
    useEffect(() => {
        //
    }, [])

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <>
            <div className="order-heading">
                <div className="header-left-item">
                    <h4><Link to='/more'><img src={leftArrowImg} alt="leftArrowImg" /></Link> Refer & Earn</h4>
                </div>
                <div className="header-right-item">
                    <Link to="/wallet" className="coinStack">{walletData ? walletData?.data?.payload?.sila_balance : 0}
                        <img src={coinStackImg} alt="coinStack" />
                    </Link>
                </div>
            </div>

            <div className="main-content">
                <div className="refer-part">
                    <div className="refer-item text-center">
                        <img src={referImg} alt="referImg" />
                    </div>

                    <div className="refer-cnt">
                        <h4>Earn JOBR coins</h4>
                        <p>Our gateway is routed directly to Telstra, with Optus and Vodafone networks service.</p>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur risus elit, condimentum ut molestie non, imperdiet sit amet magna. Sed interdum interdum ultrices. Nam.</p>
                        <ul>
                            <li><span>shortlink/com <img src={copyImg} alt="copyImg" /></span></li>
                            <li><Link to="#">Share</Link></li>
                        </ul>
                    </div>

                    <div className="refer-box">
                        <Link to='/refer-and-earn-two'>Referral <i className="fas fa-chevron-right"></i></Link>
                        <div className="row">
                            <div className="col-6">
                                <div className="refer-inner">
                                    <span>10 <img src={userDoubleImg} alt="userDoubleImg" /></span>
                                    <p>Referred</p>
                                </div>
                            </div>
                            <div className="col-6 bdr-left">
                                <div className="refer-inner">
                                    <span className="bonust-btn">150 <img src={coinStackImg} alt="coinStackImg" /></span>
                                    <p>Bonus Received</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>

    );
}

export default ReferAndEarn;