import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import Lock2 from "../../public/images/lock-2.png";
// import PinField from "react-pin-field"
import OTPInput from 'react-otp-input';
import { useDispatch, useSelector } from 'react-redux';
import { registerOtpSendAsync, registerOtpVerifyAsync, selectOtpId } from './authSlice';
import { unwrapResult } from '@reduxjs/toolkit';

const OtpVerification = (props) => {
    const history = useHistory();
    const pathName = window.location.pathname;
    const [otp, setOtp] = useState("");
    const toastId = React.useRef(null)
    const dispatch = useDispatch()
    const [otpLoading,setOtpLoading]=useState(false)
    let otpId = useSelector(selectOtpId)

    const onComplete = (code) => {
        setOtp(code);
    }
    let data = JSON.parse(localStorage.getItem("phonedetails"))
    let value = {
        "phone_code": data?.phone_code,
        "phone_no": data?.phone_no
    }
    const resendOtp = () => {
        dispatch(registerOtpSendAsync(value))
        .then(unwrapResult)
        .then((obj) => {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.success(obj?.msg)
            }
        }
        )
        .catch((obj) => {
            // if (!toast.isActive(toastId.current)) {
            //     toastId.current = toast.error(obj?.message)
            //     console.log(obj)
            // }
        })
    }
    const handleOnSubmit = async () => {
        let data = {
            "id": Number(otpId?.payload?.id),
            "otp": Number(otp),
            "role_id": 2
        }
        let data1 = {
            "id": Number(otpId?.payload?.id),
            "otp": Number(otp),
            "role_id": 2,
            "isAlreadyCheck": true
        }

        if (otp.length < 5) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error("Please enter a valid OTP");
            }

            return;
        }
        if (!otp) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error("Please enter OTP");
            }
            return;
        }
        if (pathName === '/registartion/otp-verification') {
            setOtpLoading(true)
            await dispatch(registerOtpVerifyAsync(data))
            .then(unwrapResult)
            .then((obj) => {
            setOtpLoading(false)
                if (!toast.isActive(toastId.current)) {
                    toastId.current = toast.success(obj?.msg)
                }
                history.push('/registartion/step-2')
            }
            )
            .catch((obj) => {
            setOtpLoading(false)
                // if (!toast.isActive(toastId.current)) {
                //     toastId.current = toast.error(obj?.message)
                //     console.log(obj)
                // }
            })
        }
        else {
            await dispatch(registerOtpVerifyAsync(data1))
            .then(unwrapResult)
            .then((obj) => {
                if (!toast.isActive(toastId.current)) {
                    toastId.current = toast.success(obj?.msg)
                }
                history.push('/reset-new-pin');
            }
            )
            .catch((obj) => {
                // if (!toast.isActive(toastId.current)) {
                //     toastId.current = toast.error(obj?.message)
                //     console.log(obj)
                // }
            })
        }
    }

    useEffect(() => {
        window.scrollTo(0, 0)   
          }, [])

    return (
        <>
            <div className="lock-part2">
                <img src={Lock2} alt="" />
            </div>

            <div className="verify-part">
                <h2>Verify your <strong>phone number</strong></h2>
                <p>Enter OTP code here</p>
                <div className="verify-box text-center">
                    {/* <PinField className='input_digits_'
                        length={4}
                        data-cy="pin-field"
                        // onChange ={() => {}} 
                        onComplete={(code) => onComplete(code)}
                    /> */}
                    <div className="pin-box d-flex justify-content-center" >
                        <OTPInput numInputs={5}
                            className='input_digits_'
                            value={otp}
                            data-cy="pin-field"
                            isInputNum={true}
                            onChange={onComplete}
                            autoComplete={false}
                        // onComplete={(code) => onComplete(code)}
                        />
                    </div>
                </div>
            </div>
           

            <div className="bottom-part">
            <p>Didn’t receive Code? <Link to="#" onClick={() => { resendOtp() }}>Resend</Link></p>
                {/* <Link to="#" className="bottom-btn" onClick={() => { handleOnSubmit() }}>Continue</Link> */}
                <button className="btn btn-primary continue" type="button" disabled={otpLoading===true} onClick={() => { handleOnSubmit() }}>
                    {otpLoading===true && (
                        <span className="spinner-border spinner-border-sm"></span>
                    )}
                    &nbsp;&nbsp;
                    <span>Continue</span>
                </button>
            </div>
        </>
    )
}

export default OtpVerification;