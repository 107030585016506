import React, { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom';
import { leftArrowImg, biArrowDownLeftImg, coinStackImg, biArrowDownImg, biArrowUpImg } from "../../../utilities/images";
import { getTransectionHistoryAsync, selectTransectionHistory, selectWalletBalance } from './walletSlice';
import { useDispatch, useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { selectLoginAuth } from '../auth/authSlice';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import moment from 'moment-timezone';
import CommonLoader from '../../components/shared/CommonLoader';
import PaginationSetting from '../../constants/PaginationSetting';
import Pagination from "@material-ui/lab/Pagination";
import { Box } from "@material-ui/core";

const WalletTwo = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const { flag } = useParams();
    const [tabData, setTabData] = useState("");
    const auth = useSelector(selectLoginAuth);
    const [walletLoading, setWalletLoading] = useState(false);
    const walletData = useSelector(selectWalletBalance);
    const transectionHistoryData = useSelector(selectTransectionHistory);
    const [pagination, setPagination] = useState(PaginationSetting);
    const [page, setPage] = useState(1);
    const handleTransectionType = (data) => {
        data === "top_ups" ? setTabData(1) : data === "payments" ? setTabData(2) : setTabData(3)
        let params = {
            postData: {
                page: 1,
                limit: 9,
                transaction_type: data
            },
            tokenData: auth?.payload?.token
        }
        setWalletLoading(true)
        dispatch(getTransectionHistoryAsync(params))
            .then(unwrapResult)
            .then(async (obj) => {
                setWalletLoading(false)
            }
            )
            .catch((obj) => {
                setWalletLoading(false)
            })
    }

    const transactionsHistoryHandle = () => {
        flag === "top_ups" ? setTabData(1) : flag === "payments" ? setTabData(2) : setTabData(3)
        let params = {
            postData: {
                page: page,
                limit: pagination.recordsPerPage,
                transaction_type: flag
            },
            tokenData: auth?.payload?.token
        }
        setWalletLoading(true)
        dispatch(getTransectionHistoryAsync(params))
            .then(unwrapResult)
            .then(async (obj) => {
                setPagination((prevState) => ({ ...prevState, totalPages: obj?.data?.payload?.total_pages, totalRecords: obj?.data?.payload?.total }));
                setWalletLoading(false)
            }
            )
            .catch((obj) => {
                setWalletLoading(false)
            })
    }

    useEffect(() => {
        transactionsHistoryHandle()
    }, [page])

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <>
            {walletLoading === true ?
                <CommonLoader />
                :
                <>
                    <div className="main-wrapper">
                        <div className="order-heading">
                            <div className="header-left-item">
                                <h4><Link to="#" onClick={() => history.push('/wallet')}><img src={leftArrowImg} alt="" /></Link> Transection history</h4>
                            </div>
                            <div className="header-right-item">
                                <Link to="/wallet" className="coinStack">{walletData ? walletData?.data?.payload?.sila_balance : 0}
                                    <img src={coinStackImg} alt="coinStack" />
                                </Link>
                            </div>
                        </div>

                        <div className="main-content">
                            <div className="wallet-mainprt">
                                <div className="tabs">
                                    <div className="tab-upper tab-upperhm noshadow">
                                        <ul className="tab-links text-left pb-4">
                                            <li onClick={() => handleTransectionType("top_ups")} className={tabData === 1 ? "active" : ""}><Link to="#">Added</Link></li>
                                            <li onClick={() => handleTransectionType("payments")} className={tabData === 2 ? "active" : ""}><Link to="#">Purchases</Link></li>
                                            <li onClick={() => handleTransectionType("transfers")} className={tabData === 3 ? "active" : ""}><Link to="#">Earn</Link></li>
                                        </ul>
                                    </div>

                                    {/* <div className="tab-content">
                     <div id="tab1" className="tab active">
                        <div className="browse-tab1">

                        </div>
                    </div>

                    <div id="tab2" className="tab">
                        <div className="browse-tab2">

                        </div>
                    </div>
                    <div id="tab2" className="tab">
                        <div className="browse-tab2">

                        </div>
                    </div> 
                </div> */}
                                </div>

                                <div className="transection-history">
                                    <ul>
                                        {
                                            transectionHistoryData?.data?.payload?.data?.length > 0 ?
                                                transectionHistoryData?.data?.payload?.data?.map((val, ind) => {
                                                    return (
                                                        <li key={ind}>
                                                            <div className="transhist-left">
                                                                <img src={val?.payment_type === "issue_sila" ? biArrowDownLeftImg : val?.payment_type === "transfer" ? biArrowUpImg : biArrowDownImg} alt="biArrowDownLeftImg" />
                                                                <div className="transhist-cnt">
                                                                    <h4>{val?.payment_type}</h4>
                                                                    <p>{moment(val?.createdAt).format('DD MMM,YYYY')}</p>
                                                                </div>
                                                            </div>
                                                            <div className="transhist-right">
                                                                <p>JBR {val?.amount}.00</p>
                                                            </div>
                                                        </li>
                                                    )
                                                })
                                                : "No Transection History"
                                        }
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </>}
                {pagination.totalRecords > pagination.recordsPerPage &&
                <Box py={3} display="flex" justifyContent="center">
                    <Pagination
                        key={page}
                        count={pagination.totalPages || 0}
                        color="secondary"
                        variant="outlined"
                        onChange={(e, value) => setPage(value)}
                        defaultPage={page}
                    />
                </Box>
            }
        </>
    )
}

export default WalletTwo