import axios from "axios";
import { toast } from "react-toastify";
import {WALLET_API_URL } from  "./../../config"

export function loginApi(data) {
  return new Promise((resolve, reject) =>
    axios
      .post(`${process.env.REACT_APP_STAGING_USER_SERVICES}api/v1/users/login/`, data, {
        headers: {
          'Content-Type': 'application/json',
          'app-name': 'b2c'
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        toast.error(error?.response?.data?.msg)
        // console.error(error);
      })
  )
}

export function registerSendOtp(data) {
  return new Promise((resolve, reject) =>
    axios
      .post(`${process.env.REACT_APP_STAGING_USER_SERVICES}api/v1/user_phones/`, data, {
        headers: {
          'Content-Type': 'application/json',
          'app-name': 'b2c'
        }
      })
      .then(function (response) {
        // toast.success(response?.data?.msg)
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        toast.error(error?.response?.data?.msg)
      })
  )
}
export function verifyOtp(data) {
  return new Promise((resolve, reject) =>
    axios
      .post(`${process.env.REACT_APP_STAGING_USER_SERVICES}api/v1/user_phones/verify-phone/`, data, {
        headers: {
          'Content-Type': 'application/json',
          'app-name': 'b2c'
        }
      })
      .then(function (response) {
        // toast.success(response?.data?.msg)
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        toast.error(error?.response?.data?.msg)
        console.error(error);
      })
  )
}

export function registerApi(data) {
  return new Promise((resolve, reject) =>
    axios
      .post(`${process.env.REACT_APP_STAGING_USER_SERVICES}api/v1/users/`, data, {
        headers: {
          'Content-Type': 'application/json',
          'app-name': 'b2c'
        }
      })
      .then(function (response) {
        // toast.success(response?.data?.msg)
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        toast.error(error?.response?.data?.msg)
        console.error(error);
      })
  )
}

export function changePinApi(data) {
  return new Promise((resolve, reject) =>
    axios
      .post(`${process.env.REACT_APP_STAGING_USER_SERVICES}api/v1/users/change-pin/`, data, {
        headers: {
          'Content-Type': 'application/json',
          'app-name': 'b2c'
        }
      })
      .then(function (response) {
        // toast.success(response?.data?.msg)
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        toast.error(error?.response?.data?.msg)
        console.error(error);
      })
  )
}
export function verifyAgeDocumentApi(data, token) {
  return new Promise((resolve, reject) =>
    axios
      .post(`${process.env.REACT_APP_STAGING_USER_SERVICES}api/v1/user_profiles/verify-document`, data, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
          'app-name': 'b2c'
        }
      })
      .then(function (response) {
        localStorage.removeItem("invaliddata")
        // toast.success(response?.data?.msg)
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        toast.error(error?.response?.data?.msg)
        console.error(error);
      })
  )
}
export function getIdCardPhoto(data) {
  var formData = new FormData();
  formData.append('document', data.document);
  return new Promise((resolve, reject) =>
    axios.post(`${process.env.REACT_APP_STAGING_USER_SERVICES}api/v1/user_profiles/verification-document`, formData, {
      headers: {
        'Content-Type': 'application/json',
        'app-name': 'b2c'
      }
    })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        console.error(error);
      })
  )
}
export function createWalletApi(data, token) {
  return new Promise((resolve, reject) =>
    axios
      .post(`${WALLET_API_URL}api/v1/wallets`, data, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
          'app-name': 'b2c'
        }
      })
      .then(function (response) {
        // toast.success(response?.data?.msg)
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        toast.error(error?.response?.data?.payload[0]?error?.response?.data?.payload[0]:error?.response?.data?.msg)
        console.error(error?.response,"response error");
      })
  )
}
export function getWalletProfile(token) {
  return new Promise((resolve, reject) =>
    axios
      .get(`${WALLET_API_URL}api/v1/wallets/user`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
          'app-name': 'b2c'
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        console.error(error);
      })
  )
}
export function requestKyc(token) {
  return new Promise((resolve, reject) =>
    axios
      .get(`${WALLET_API_URL}api/v1/wallets/request-kyc`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
          'app-name': 'b2c'
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        console.error(error);
      })
  )
}
export function checkKyc(token) {
  return new Promise((resolve, reject) =>
    axios
      .get(`${WALLET_API_URL}api/v1/wallets/request-kyc`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
          'app-name': 'b2c'
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        console.error(error);
      })
  )
}
export function getDocumentType(token) {
  return new Promise((resolve, reject) =>
    axios
      .get(`${WALLET_API_URL}api/v1/wallets/document-types`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
          'app-name': 'b2c'
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        console.error(error);
      })
  )
}
export function uploadKycDocument(data, token) {
  return new Promise((resolve, reject) =>
    axios
      .post(`${WALLET_API_URL}api/v1/wallets/upload-documents`, data, {
        headers: {
          'Authorization': token,
          'app-name': 'b2c'
        }
      })
      .then(function (response) {
        // toast.success(response?.data?.msg)
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        toast.error(error?.response?.data?.msg)
        console.error(error?.response,"response error");
      })
  )
}
export function generatePlaidTokenApi(token) {
  return new Promise((resolve, reject) =>
    axios
      .get(`${WALLET_API_URL}api/v1/wallets/plaid/token`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
          'app-name': 'b2c'
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        console.error(error);
      })
  )
}
export function getBankAccountApi(token) {
  return new Promise((resolve, reject) =>
    axios
      .get(`${WALLET_API_URL}api/v1/wallets/bank-accounts`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
          'app-name': 'b2c'
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        console.error(error);
      })
  )
}
export function linkBankAccount(data, token) {
  return new Promise((resolve, reject) =>
    axios
      .post(`${WALLET_API_URL}api/v1/wallets/link-bank-account`, data, {
        headers: {
          'Authorization': token,
          'app-name': 'b2c'
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        toast.error(error?.response?.data?.msg)
        console.error(error?.response,"response error");
      })
  )
}
