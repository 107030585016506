import React, { useEffect, useState } from "react";
import { Link, useHistory } from 'react-router-dom';
import CustomModal from "../../components/shared/CustomModal";
import { leftArrowImg, bxsCoinStackImg, applePayImg, creditCardImg, debitCardImg, veirfy2Img } from "../../../utilities/images";

const PaymentAdded = (props) => {
    const history = useHistory();
    const [key, setKey] = useState(Math.random());
    const [selectedData, setSelectedData] = useState("creditcard")
    const [modalDetail, setModalDetail] = useState({
        show: false,
        title: "",
        flag: ""
    });

    const handleOnCloseModal = () => {
        setModalDetail({
            show: false,
            title: "",
            flag: ""
        });
        setKey(Math.random());
    }

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <>
            <div className="order-heading">
                <div className="header-left-item">
                    <h4><Link to='/credit-card'><img src={leftArrowImg} alt="leftArrowImg" /></Link> Payment methods</h4>
                </div>
                <div className="header-right-item">
                    <Link to="#">0 <img src={bxsCoinStackImg} alt="bxsCoinStackImg" /></Link>
                </div>
            </div>

            <div className="main-content">
                <div className="payment-part">
                    <form action="#" method="POST">
                        <ul>
                            <li>
                                <div className="pay-left">
                                    <Link to="#"><img src={applePayImg} alt="applePayImg" /> Apple pay</Link>
                                </div>
                                <div className="pay-right">
                                    <span>Default</span>
                                    <div className="radio-btn">
                                        <input type="radio" id="radio-btn1" name="radio" value="default" checked={selectedData === 'default'} onChange={e => {setSelectedData(e.target.value)}} />
                                        <label htmlFor="radio-btn1"></label>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div className="pay-left">
                                    <img src={creditCardImg} alt="creditCardImg" />
                                    Credit card **********5698
                                </div>
                                <span className="radio-btn">
                                    <input type="radio" id="radio-btn2" name="radio" value="creditcard" checked={selectedData === 'creditcard'} onChange={e => {setSelectedData(e.target.value)}}/>
                                    <label htmlFor="radio-btn2"></label>
                                </span>
                            </li>
                            <li>
                                <Link to="#">
                                    <img src={debitCardImg} alt="debitCardImg" />
                                    Add debit card
                                    <span><i className="fas fa-chevron-right"></i></span>
                                </Link>
                            </li>
                        </ul>
                    </form>
                    <div className="pay-btn">
                        <Link
                            to="#" data-toggle="modal" data-target="#exampleModalCenter"
                            onClick={() => { setModalDetail({ show: true }); setKey(Math.random()); }}
                        >Pay Now</Link>
                    </div>
                </div>
            </div>

            <CustomModal
                key={key}
                show={modalDetail.show}
                backdrop="static"
                showCloseBtn={true}
                isRightSideModal={true}
                child={
                    <div className="modal-body">
                        {/* <div className="modal-header">
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() =>{props.closeModal()}}>
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div> */}
                        <div className="modal-body">
                            <div className="modal-mainprt">
                                <div className="mdlverify-cnt cardmdl-verify">
                                    <h4>Done!</h4>
                                    <img src={veirfy2Img} alt="veirfy2Img" />
                                    <p>USD 25.00 paid</p>
                                    <h6>Successfully 25.00 JBR added</h6>
                                </div>
                                <div className="mdlverify-btn mdllogin-btn">
                                    <Link to="#" onClick={() => { (props.location.state === "insufficient") ? history.push('/placing-2') :history.push('/placing') }} className="active" data-dismiss="modal" aria-label="Close">Back to cart</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                // size="lg"
                //title={modalDetail.title}
                // footerContent = {}
                // footerClasses = 'justify-content-start' // justify-content-start, justify-content-end, justify-content-center
                onCloseModal={() => handleOnCloseModal()}
            />
        </>
    );
}

export default PaymentAdded;
