import React, { useEffect } from "react";
import { Link, useHistory } from 'react-router-dom';
import {leftArrowImg, brandW1Img, brandW2Img, brandW3Img, brandW4Img, brandW5Img } from "../../../utilities/images";

const WalletBrands = (props) => {
    const history = useHistory();

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
  return (
        <>
          <div className="order-heading">
                <div className="header-left-item wallet-header-left">
                    <h4><Link to="#" onClick={() => {history.push('/wallet')}}><img src={leftArrowImg} alt="leftArrowImg" /></Link> Brands <span>5 brands</span></h4>
                </div>
            </div>

            <div className="main-content">  
                <div className="wallet-brandsprt">
                    <ul>
                        <li>
                            <div className="wallet-brand-left">
                                <img src={brandW1Img} alt="brandW1Img" />
                                <div className="wallet-brand-cnt">
                                    <h4>Marlboro</h4>
                                    <p>5 Products purcheses</p>
                                </div>
                            </div>
                            <div className="wallet-brand-right">
                                <Link to="#"><i className="fas fa-chevron-right"></i></Link>
                            </div>
                        </li>
                        <li>
                            <div className="wallet-brand-left">
                                <img src={brandW2Img} alt="brandW2Img" />
                                <div className="wallet-brand-cnt">
                                    <h4>Vomedo</h4>
                                    <p>1 Products purcheses</p>
                                </div>
                            </div>
                            <div className="wallet-brand-right">
                                <Link to="#"><i className="fas fa-chevron-right"></i></Link>
                            </div>
                        </li>
                        <li>
                            <div className="wallet-brand-left">
                                <img src={brandW3Img} alt="brandW3Img" />
                                <div className="wallet-brand-cnt">
                                    <h4>Adequate Fragnance</h4>
                                    <p>3 Products purcheses</p>
                                </div>
                            </div>
                            <div className="wallet-brand-right">
                                <Link to="#"><i className="fas fa-chevron-right"></i></Link>
                            </div>
                        </li>
                        <li>
                            <div className="wallet-brand-left">
                                <img src={brandW4Img} alt="brandW4Img" />
                                <div className="wallet-brand-cnt">
                                    <h4>H&M</h4>
                                    <p>7 Products purcheses</p>
                                </div>
                            </div>
                            <div className="wallet-brand-right">
                                <Link to="#"><i className="fas fa-chevron-right"></i></Link>
                            </div>
                        </li>
                        <li>
                            <div className="wallet-brand-left">
                                <img src={brandW5Img} alt="brandW5Img" />
                                <div className="wallet-brand-cnt">
                                    <h4>iJOY</h4>
                                    <p>3 Products purcheses</p>
                                </div>
                            </div>
                            <div className="wallet-brand-right">
                                <Link to="#"><i className="fas fa-chevron-right"></i></Link>
                            </div>
                        </li>
                    </ul>              
                </div>            
            </div>
        </>
  );
}  

 
  export default WalletBrands;
  