import React, { useEffect, useState } from 'react';
import { useHistory, Link, useParams, useLocation } from 'react-router-dom';
import { arrowBlueImg, deliveryParcelImg, discountOutlineImg, dotCircleImg, heart2, leftArrowImg, starCircleImg } from '../../../utilities/images';
import { useDispatch, useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { selectLoginAuth } from '../auth/authSlice';
import CommonLoader from "../../components/shared/CommonLoader";
import { getCompanyReviewsDetailsAsync, getProductListAsync } from './dashboardSlice';
import SliderWrapper from './offerStyle';
import SliderWrapperNew from "./SliderStyle";
import SliderWrapperFashion from "./fashionStyle";
import { toast } from 'react-toastify';
import Slider from 'react-slick';
import Geocode from "react-geocode";
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import { createFavoriteListApiAsync } from '../favoriteList/favoriteListSlice';
import CustomModal from "../../components/shared/CustomModal";
import BusinessDetails from './BusinessDetails';
import CompanyReviews from './CompanyReviews';
import { getCartApiAsync, selectCartData } from '../cart/cartSlice';


const HomeStore = () => {
    const history = useHistory();
    const location = useLocation();
    const { id } = useParams();
    const auth = useSelector(selectLoginAuth);
    const dispatch = useDispatch();
    const [size, setSize] = useState('');
    const [material, setMaterial] = useState('');
    const [sizeId, setSizeId] = useState('');
    const [materialId, setMaterialId] = useState('');
    const [color, setColor] = useState('');
    const [colorId, setColorId] = useState('');
    const [address, setAddress] = useState("");
    const [productDataLoading, setProductDataLoading] = useState(false);
    const [productList, setProductList] = useState({});
    const [companyReviewData, setCompanyReviewData] = useState({});
    const cartData = useSelector(selectCartData);
    const [city, setCity] = useState("");
    const [key, setKey] = useState(Math.random());
    const [modalDetail, setModalDetail] = useState({
        show: false,
        title: "",
        flag: ""
    });
    const handleOnCloseModal = () => {
        setModalDetail({
            show: false,
            title: "",
            flag: ""
        });
        setKey(Math.random());
    }
    const geocodeHandler = () => {
        navigator.geolocation.getCurrentPosition(function (position) {
            Geocode.setApiKey("AIzaSyBbytCk92gm3MK3Mrs_374RDKf4bz0X1ck");
            Geocode.setLanguage("en");
            Geocode.setLocationType("ROOFTOP");
            Geocode.enableDebug();
            Geocode.fromLatLng(position.coords.latitude, position.coords.longitude).then(
                (response) => {
                    const address = response.results[0].formatted_address;
                    let city, state, country;
                    for (let i = 0; i < response.results[0].address_components.length; i++) {
                        for (let j = 0; j < response.results[0].address_components[i].types.length; j++) {
                            switch (response.results[0].address_components[i].types[j]) {
                                case "locality":
                                    city = response.results[0].address_components[i].long_name;
                                    break;
                                case "administrative_area_level_1":
                                    state = response.results[0].address_components[i].long_name;
                                    break;
                                case "country":
                                    country = response.results[0].address_components[i].long_name;
                                    break;
                            }
                        }
                    }
                    setAddress(address)
                    setCity(city)
                },
                (error) => {
                    console.error(error);
                }
            )
        })
    }

    const productSettings = {
        dots: false,
        autoplay: true,
        autoplaySpeed: 2000,
        infinite: true,
        slidesToShow: 2,
        slidesToScroll: 1,
        centerMode: true,
        centerPadding: '20px',
        initialSlide: 0,
        speed: 500,
        arrows: false,
        adaptiveHeight: true,
        appendDots: (dots) => <ul>{dots}</ul>,
        customPaging: (i) => <div className="slideContent" >{`${i + 1}/${(productList?.data?.payload?.sellerDetails?.length)}`}</div>
    };
    const bannerSettings = {
        dots: true,
        autoplay: true,
        autoplaySpeed: 2000,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
        speed: 500,
        arrows: false,
        adaptiveHeight: true,
        appendDots: (dots) => <ul>{dots}</ul>,
        customPaging: (i) => <div className="slideContent" >{`${i + 1}/${(productList?.data?.payload?.bannerDataList.length)}`}</div>
    };


    const offersSettings = {
        dots: true,
        autoplay: true,
        autoplaySpeed: 2000,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
        speed: 500,
        arrows: false,
        adaptiveHeight: true
    };

    const favoriteHandle = (favId) => {
        let favData = {
            "postData": {
                "seller_id": favId
            },
            "tokenData": auth?.payload?.token
        }
        setProductDataLoading(true)
        dispatch(createFavoriteListApiAsync(favData))
            .then(unwrapResult)
            .then(async (obj) => {
                toast.success(obj?.data?.msg)
                let sellerProductParams = {
                    "postData": {
                        sellerId: id,
                        lat: 32.5534,
                        long: 76.1258,
                        app_name: 'b2c',
                        delivery_options: 1
                    },
                    "tokenData": auth?.payload?.token
                }
                dispatch(getProductListAsync(sellerProductParams))
                    .then(unwrapResult)
                    .then(async (obj) => {
                        setProductList(obj)
                        setProductDataLoading(false)
                    }
                    )
                    .catch((obj) => {
                        setProductDataLoading(false)
                    })
            }
            )
            .catch((obj) => {
                setProductDataLoading(false)
            })

    }

    const attributeHandle = (flag, name, id) => {
        if (flag === 'size') {
            setSize(name)
            setSizeId(id)
        }
        if (flag === 'color') {
            setColor(name)
            setColorId(id)
        }
        if (flag === 'material') {
            setMaterial(name)
            setMaterialId(id)
        }
    }

    const productDataHandle = () => {
        setProductDataLoading(true)
        let sellerProductParams = {
            "postData": {
                sellerId: id,
                lat: 32.5534,
                long: 76.1258,
                app_name: 'b2c',
                delivery_options: 1
            },
            "tokenData": auth?.payload?.token
        }
        dispatch(getProductListAsync(sellerProductParams))
            .then(unwrapResult)
            .then(async (obj) => {
                setProductList(obj)
                setProductDataLoading(false)
            }
            )
            .catch((obj) => {
                setProductDataLoading(false)
            })
    }
    const companyReviewsDataHandle = () => {
        setProductDataLoading(true)
        let sellerProductParams = {
            "postData": {
                sellerId: id
            },
            "tokenData": auth?.payload?.token
        }
        dispatch(getCompanyReviewsDetailsAsync(sellerProductParams))
            .then(unwrapResult)
            .then(async (obj) => {
                setCompanyReviewData(obj)
                setProductDataLoading(false)
            }
            )
            .catch((obj) => {
                setProductDataLoading(false)
            })
    }

    useEffect(() => {
        window.scrollTo(0, 0)
        productDataHandle()
        geocodeHandler()
        companyReviewsDataHandle()
        dispatch(getCartApiAsync(auth?.payload?.token))
    }, [])

    return (
        <>
            {productDataLoading === true ?
                <CommonLoader />
                :
                <>
                    <div className='header-part browse-addprdcard-header'>
                        <div className='row align-items-center'>
                            <div className='header-left-item col-6 col-md-6'>
                                <img style={{ cursor: "pointer" }} onClick={location?.state ? () => history.push(location?.state) : () => history.push('/dashboard')} src={leftArrowImg} alt="" className='me-1' />
                                <h6 className='subhead_'>Back</h6>
                            </div>
                            <div className="header-right-item col-6 col-md-6 text-end">
                                <button type="button" onClick={() => favoriteHandle(productList?.data?.payload?.sellerDetails?.id)}><img src={heart2} alt="" /></button>
                                <DropdownButton className='d-inline-block p-0' id="dropdown-basic-button" title={<img src={dotCircleImg} alt="" />}>
                                    <Dropdown.Item as={Link} to="#" onClick={() => {
                                        setModalDetail({
                                            show: true,
                                            flag: "company_reviews"
                                        });
                                        setKey(Math.random());
                                    }}>Company Review</Dropdown.Item>
                                    <Dropdown.Item as={Link} to="#" onClick={() => {
                                        setModalDetail({
                                            show: true,
                                            flag: "business_details"
                                        });
                                        setKey(Math.random());
                                    }}>Business Details</Dropdown.Item>
                                    <Dropdown.Item as={Link} to="#" onClick={() => history.push(`/store-directions/${id}`)} >Get Store directions</Dropdown.Item>
                                </DropdownButton>
                            </div>
                        </div>
                    </div>
                    <div className='main-content'>
                        <div className="store-banner-part p-0">
                            <div>
                                <img src={productList?.data?.payload?.sellerDetails?.user_profiles?.banner_image} alt='' className='storyBannerBg' />
                                <div className="store-box">
                                    <div className="store-item">
                                        <img src={productList?.data?.payload?.sellerDetails?.user_profiles?.profile_photo} alt="" />
                                    </div>
                                    <div className="store-cnt">
                                        {/* <h4>{productList?.data?.payload?.sellerDetails?.user_profiles?.username} <Link to="#" > <i onClick={() => favoriteHandle(productList?.data?.payload?.sellerDetails?.id)} className={productList?.data?.payload?.isFavourite === true ? `far fa-heart` : `far fa-heart`}></i></Link></h4> */}
                                        <h4>{productList?.data?.payload?.sellerDetails?.user_profiles?.username}</h4>
                                        <p><img src={starCircleImg} alt="" /> {productList?.data?.payload?.sellerRating?.rating} ({productList?.data?.payload?.sellerRating?.review_count}+ ratings)</p>
                                        <h6>{productList?.data?.payload?.sellerDetails?.user_profiles?.firstname}</h6>
                                        <ul>
                                            <li>Outlate <span>{productList?.data?.payload?.sellerCurrentLocation?.address_line_1}</span></li>
                                            <li>30 mins <Link to="#">{city} <i className="fas fa-chevron-down"></i></Link></li>
                                        </ul>
                                        <h3><Link to="#"><img src={deliveryParcelImg} alt="" /> ${productList?.data?.payload?.deliveryFee} Delivery fee</Link></h3>
                                    </div>
                                </div>
                            </div>
                            {/* ******offer slider***** */}
                            <div className="banner-slider trslider-part" id="offerSlider">
                                <SliderWrapper>
                                    <Slider {...offersSettings}>
                                        {
                                            productList?.data?.payload?.sellerOffersList?.length > 0 && productList?.data?.payload?.sellerOffersList?.map((val, index) => {
                                                return (
                                                    <React.Fragment key={index}>
                                                        <div className="store-btn">
                                                            <Link to="#"><img src={discountOutlineImg} alt="" />
                                                                {val?.discount_percentage}% off upto ${val?.max_discount}
                                                            </Link>
                                                            <div className="owl-dots dotstab d-block">
                                                                <span>{(index + 1)}/{productList?.data?.payload?.sellerOffersList?.length}</span>
                                                            </div>
                                                        </div>
                                                    </React.Fragment>
                                                )
                                            })
                                        }
                                    </Slider>
                                </SliderWrapper>
                            </div>
                        </div>
                        {/* ******banner slider***** */}
                        {
                            productList?.data?.payload?.bannerDataList.length > 0 ?
                                <div id="bannerSlider" className="banner-slider trslider-part">
                                    <SliderWrapperNew>
                                        <Slider {...bannerSettings}>
                                            {
                                                productList?.data?.payload?.bannerDataList?.map((val, index) => {
                                                    return (
                                                        <div className="carousel-cell" key={index}>
                                                            <img className="d-block w-100" src={val?.path} alt="" />
                                                        </div>
                                                    )
                                                })
                                            }
                                        </Slider>
                                    </SliderWrapperNew>
                                </div> : <></>
                        }

                        {/* ******Sub-categories***** */}
                        <div className="store-slider subcateBox_" style={{ paddingTop: '16px', display: 'flex' }}>
                            {productList?.data?.payload?.subCategories?.length > 0 ?
                                <>
                                    {productList?.data?.payload?.subCategories?.filter(item => item?.image !== null)?.map((val, i) => {
                                        return (
                                            <React.Fragment key={i}>
                                                <div style={{ cursor: "pointer" }} className="carousel-cell subcategoryItems_" onClick={() => history.push(`/subcategory-products/${productList?.data?.payload?.sellerDetails?.unique_uuid}/${val?.id}/${productList?.data?.payload?.sellerDetails?.id}`)}>
                                                    <div className="paddcart-item ">
                                                        <div className="paddcart-inner">
                                                            <img src={val?.image} alt="" />
                                                            <h4 className='textHeading_'>{val?.name}</h4>
                                                            <p>{val?.qty}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </React.Fragment>
                                        )
                                    })}
                                </> : ""
                            }
                        </div>
                        {/* ******best selling items***** */}
                        <div className="store-slider">
                            {productList?.data?.payload?.topSellingProduct?.length > 0 ?
                                <>
                                    <div className="store-sldhead">
                                        <h2>Best-Selling Items</h2>
                                    </div>
                                    <SliderWrapper>
                                        <Slider {...productSettings} >
                                            {productList?.data?.payload?.topSellingProduct?.map((val, index) => {
                                                return (
                                                    <React.Fragment key={index}>
                                                        <div className="carousel-cell bestSellBox_" >
                                                            <div className="paddcart-item">
                                                                <div className="paddcart-inner">

                                                                    <SliderWrapperFashion>
                                                                        <Slider {...offersSettings}>
                                                                            {
                                                                                val?.product_images?.map((value, ind) => {
                                                                                    return (
                                                                                        <div key={ind}>
                                                                                            <img src={value?.url} alt="" className="fashion-m" />
                                                                                        </div>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </Slider>
                                                                    </SliderWrapperFashion>
                                                                    <div className='sellBody_'>
                                                                        {val?.supplies[0]?.attributes ?
                                                                            val?.supplies[0]?.attributes?.map((item, ind) => {
                                                                                return (
                                                                                    <React.Fragment key={ind}>
                                                                                        {(item?.name === "Color") ?
                                                                                            <ul className='colorBox mb-2'>
                                                                                                {item?.values?.map((v, i) => {
                                                                                                    return (
                                                                                                        <li style={{ backgroundColor: `${v?.name}` }} className={(colorId === v?.id) ? "active" : ""}
                                                                                                            onClick={() => attributeHandle('color', v?.name, v?.id)}
                                                                                                            key={i}>
                                                                                                        </li>
                                                                                                    )
                                                                                                })}
                                                                                            </ul> : ""}
                                                                                        {(item?.name === "Size") ?
                                                                                            <ul className='fasprd-size'>
                                                                                                {item?.values?.map((v, i) => {
                                                                                                    return (
                                                                                                        <li className={(sizeId === v?.id) ? "active" : ""}
                                                                                                            onClick={() => attributeHandle('size', v?.name, v?.id)}
                                                                                                            key={i} >
                                                                                                            <strong>{v?.name}</strong>
                                                                                                        </li>
                                                                                                    )
                                                                                                })}
                                                                                            </ul> : ""}
                                                                                        {(item?.name === "Material") ?
                                                                                            <div className='materialBox'>
                                                                                                {item?.values?.map((v, i) => {
                                                                                                    return (
                                                                                                        <p className={(materialId === v?.id) ? "active" : ""}
                                                                                                            onClick={() => attributeHandle('material', v?.name, v?.id)}
                                                                                                            key={i} >
                                                                                                            <strong>{v?.name}</strong>
                                                                                                        </p>
                                                                                                    )
                                                                                                })}
                                                                                            </div> : ""}
                                                                                    </React.Fragment>
                                                                                )
                                                                            })
                                                                            : ""}
                                                                        {/* <ul className='colorBox mb-2'>
                                                                            <li className="redBox active"><span>red</span></li>
                                                                            <li className="skyBluebox"><span>blue</span></li>
                                                                            <li className="blackBox"><span>black</span></li>
                                                                        </ul>
                                                                        <ul className='sizeBox_'>
                                                                            <li className="sizeitem_ active"><span>S</span></li>
                                                                            <li className="sizeitem_"><span>M</span></li>
                                                                            <li className="sizeitem_"><span>L</span></li>
                                                                            <li className="sizeitem_"><span>XL</span></li>
                                                                        </ul>
                                                                        <div className='materialBox'>
                                                                            <p className='textHeading_'>cotton</p>
                                                                        </div> */}
                                                                        <h4 className='smallHeading'>{val?.name}</h4>
                                                                        <p className='smallHeading mb-2'>{val?.brand?.name}</p>
                                                                        <p className='boldText_'>${val?.price}</p>
                                                                        <div className='addBox_' onClick={() => history.push(`/home-store-product/${productList?.data?.payload?.sellerDetails?.unique_uuid}/${val?.id}/store/${productList?.data?.payload?.sellerDetails?.user_profiles?.user_id}`)}>
                                                                            <i className="fas fa-plus"></i>
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>


                                                    </React.Fragment>
                                                )
                                            })}
                                        </Slider>
                                    </SliderWrapper>
                                </>
                                : <></>}
                        </div>
                        {cartData?.data?.payload?.cart_products.length > 0 ?
                            <div className="cartItemsbox">
                                <div className="selected-itembox">
                                    <div className="selected-leftcnt">
                                        <p>Items: <strong>{cartData?.data?.payload?.cart_products.length}</strong></p>
                                        <p>$ <strong>{(cartData?.data?.payload?.amout?.total_amount).toFixed(2)}</strong></p>
                                    </div>
                                    <div className="selected-rightbtn">
                                        <Link to='/cart'>
                                            <img src={arrowBlueImg} alt="arrowBlueImg" />
                                        </Link>
                                    </div>
                                </div>
                            </div> : ""}
                    </div>
                </>}
            <CustomModal
                key={key}
                show={modalDetail.show}
                // backdrop="static"
                showCloseBtn={false}
                ids="productInner"
                isRightSideModal={false}
                mediumWidth={true}
                child={modalDetail.flag === 'business_details'
                    ? <BusinessDetails productList={productList} categoryLoading={productDataLoading} closeModal={() => handleOnCloseModal()} details={(e) => handleOnCloseModal(e)} /> :
                    modalDetail.flag === 'company_reviews'
                        ? <CompanyReviews productList={companyReviewData} categoryLoading={productDataLoading} closeModal={() => handleOnCloseModal()} details={(e) => handleOnCloseModal(e)} />
                        : ""
                }
                onCloseModal={() => handleOnCloseModal()}
            />
        </>
    )
}

export default HomeStore
