import React, { useState } from 'react'
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { leftArrowImg, deliveryTruckImg, coinStackImg } from "../../../utilities/images";
import { selectLoginAuth } from '../auth/authSlice';
import { handleCartResponseApiAsync, selectCartData } from '../cart/cartSlice';
import { getDeliveryTypesApiAsync, selectDeliveryTypeData } from './deliverySlice';
import { selectWalletBalance } from '../wallet/walletSlice';

const DeliveryTypes = () => {
    const history = useHistory()
    const [selectedValue, setSelectedValue] = useState("Express delivery")
    const [slotTime, setSlotTime] = useState("")
    const walletData = useSelector(selectWalletBalance);
    const [deliveryId, setDeliveryId] = useState(1)
    const dispatch = useDispatch()
    const auth = useSelector(selectLoginAuth)
    const cartData = useSelector(selectCartData)
    const deliveryType = useSelector(selectDeliveryTypeData)

    let data = {
        "postData": cartData?.data?.payload?.seller_id,
        "tokenData": auth?.payload?.token
    }
    const handleSlot = (val) => {
        setSlotTime(val)
    }
    const handleChange = (data) => {
        setSelectedValue(data?.title)
        setDeliveryId(data?.id)
    }
    const handleSubmit = async () => {
        let data
        if (deliveryId !== 1) {
            data = {
                "delivery_type_id": deliveryId,
                "preffered_delivery_start_time": slotTime?.start_time,
                "preffered_delivery_end_time": slotTime?.end_time,
            }
        }
        else {
            data = {
                "delivery_type_id": deliveryId
            }
        }
        await dispatch(handleCartResponseApiAsync(data))
        history.push('/cart')
    }
    useEffect(() => {
        dispatch(getDeliveryTypesApiAsync(data))
    }, [])

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div className="main-wrapper">
            <div className="order-heading">
                <div className="header-left-item" onClick={() => { history.goBack() }}>
                    <h4><img src={leftArrowImg} alt="" /> Delivery</h4>
                </div>
                <div className="header-right-item">
                    <Link to="/wallet" className="coinStack">{walletData ? walletData?.data?.payload?.sila_balance : 0}
                        <img src={coinStackImg} alt="coinStack" />
                    </Link>
                </div>
            </div>

            <div className="main-content">
                <div className="delivery-typearea">
                    <form action="#" method="POST">
                        {
                            deliveryType?.data?.payload?.map((val, index) => {
                                return (
                                    <React.Fragment key={index}>
                                        <div className="delivery-type-part">
                                            <img src={deliveryTruckImg} alt="" />
                                            <div className="delivery-type-cnt">
                                                <h2>{val?.title} <small>{val?.description && (val?.description)}</small></h2>
                                                <h4>${Number(val?.price).toFixed(2)}</h4>
                                            </div>
                                            <div className="delivery-type-radbtn">
                                                <span className="radio-btn">
                                                    <input type="radio" id="radio-btn1" name="radio" checked={selectedValue === val?.title} onChange={() => handleChange(val)} />
                                                    <label htmlFor="radio-btn1"></label>
                                                </span>
                                            </div>
                                        </div>
                                        {
                                            val?.slots &&
                                            <>
                                                {(selectedValue === val?.title) ?
                                                    <div className="delivery-typeablw">
                                                        <h4>Available {val?.deliver_in_hours} hour windows</h4>
                                                        <div className="delivery-time">
                                                            {
                                                                val?.slots?.map((val, ind) => {
                                                                    return (
                                                                        <button type='button' key={ind} className={(slotTime?.start_time === val?.start_time && slotTime?.end_time === val?.end_time) ? 'time_button active' : 'time_button'} onClick={() => handleSlot(val)}>{val?.start_time}-{val?.end_time}</button>
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                        <p>** You can change time slot before 1:00pm EST | Charge applicable</p>
                                                    </div>
                                                    : ""}

                                            </>
                                        }
                                    </React.Fragment>
                                )
                            })
                        }
                    </form>
                    <div className="delivery-typebtn" onClick={handleSubmit}>
                        <Link to='#'>Done</Link>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default DeliveryTypes