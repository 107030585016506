import { Switch, Route } from 'react-router-dom';
import * as Layouts from './../app/layouts';
import * as Containers from './../app/containers';

function Router() {

  return (
    <Switch>
      {/* Auth Routes*/}

      {/* login forget password routes */}
      <Layouts.NonPrivateRoute exact path="/login" component={Containers.Login} />
      <Layouts.NonPrivateRoute exact path="/" component={Containers.Terms} />
      <Layouts.NonPrivateRoute exact path="/privacy" component={Containers.Privacy} />
      <Layouts.NonPrivateRoute exact path="/forgot-password" component={Containers.ForgotPassword} />
      <Layouts.NonPrivateRoute exact path="/otp-verification" component={Containers.OtpVerification} />
      <Layouts.NonPrivateRoute exact path="/reset-new-pin" component={Containers.SetNewPin} />
      <Layouts.PrivateRoute exact path="/create-wallet/:flag" component={Containers.CreateWallet} />
      <Layouts.PrivateRoute exact path="/id-verification/:flag" component={Containers.IdVerification} />
      <Layouts.PrivateRoute exact path="/bank-account/:flag" component={Containers.BankAccountList} />
      {/* registration routes */}
      <Layouts.NonPrivateRoute exact path="/registartion/step-1" component={Containers.RegistrationStep1} />
      <Layouts.NonPrivateRoute exact path="/registartion/otp-verification" component={Containers.OtpVerification} />
      <Layouts.NonPrivateRoute exact path="/registartion/step-2" component={Containers.RegistrationStep2} />
      <Layouts.PrivateRoute exact path="/request-kyc/:flag" component={Containers.RequestKyc} />
      <Layouts.PrivateRoute exact path="/age-verification" component={Containers.AgeVerification} />
      <Layouts.PrivateRoute exact path="/age-verification-check" component={Containers.CheckingAgeVerification} />
      <Layouts.PrivateRoute exact path="/ageVerificationTwo" component={Containers.AgeVerificationTwo} />
      {/* <Layouts.NonPrivateRoute exact path="/setNewPin" component={Containers.SetNewPin} /> */}
      {/* <Layouts.NonPrivateRoute exact path="/ageVerificationTwo" component={Containers.AgeVerificationTwo} />
      <Layouts.NonPrivateRoute exact path="/ageVerificationThree" component={Containers.AgeVerificationThree} /> */}
      <Layouts.PrivateRoute exact path="/dashboard" component={Containers.Dashboard} />
      <Layouts.PrivateRoute exact path="/location-select" component={Containers.DashboardAddress} />
      <Layouts.PrivateRoute exact path="/subcategory-list/:id/:flag/:categoryName" component={Containers.SubCategory} />
      <Layouts.PrivateRoute exact path="/subcategory-list/:id/:flag/:categoryName/:subCatId" component={Containers.SubCategory} />
      <Layouts.PrivateRoute exact path="/home-category/:id/:flag/:categoryName" component={Containers.HomeCategory} />
      <Layouts.PrivateRoute exact path="/home-services/:id/:flag/:categoryName" component={Containers.HomeServices} />
      <Layouts.PrivateRoute exact path="/book-appointment" component={Containers.BookAppointment} />
      <Layouts.PrivateRoute exact path="/appointment-time/:id/:sellerId" component={Containers.AppointmentTime} />
      <Layouts.PrivateRoute exact path="/select-service/:id/:sellerId" component={Containers.SelectService} />
      <Layouts.PrivateRoute exact path="/select-service-1/:id/:sellerId" component={Containers.SelectService1} />
      <Layouts.PrivateRoute exact path="/feedback" component={Containers.FeedBack} />
      <Layouts.PrivateRoute exact path="/feedback-thankyou" component={Containers.FeedbackThankYou} />
      <Layouts.PrivateRoute exact path="/delivery-types" component={Containers.DeliveryTypes} />
      <Layouts.PrivateRoute exact path="/delivery-types-2" component={Containers.DeliveryTypes2} />
      <Layouts.PrivateRoute exact path="/changing-time-slot" component={Containers.TimeSlot} />
      <Layouts.PrivateRoute exact path="/assigned-currier" component={Containers.AssignedCurrier} />
      <Layouts.PrivateRoute exact path="/assigned-currier2" component={Containers.AssignedCurrier2} />
      <Layouts.PrivateRoute exact path="/assigned-driver" component={Containers.AssignedDriver} />
      <Layouts.PrivateRoute exact path="/notifications" component={Containers.Notifications} />
      <Layouts.PrivateRoute exact path="/seller-list/:categoryId/:subcategoryId/:selectedbrandId/:flag/:categoryName/:subCategoryName/:type" component={Containers.SellerList} />
      <Layouts.PrivateRoute exact path="/home-store/:id" component={Containers.HomeStore} />
      <Layouts.PrivateRoute exact path="/home-store-services/:id" component={Containers.HomeStoreServices} />
      <Layouts.PrivateRoute exact path="/subcategory-products/:id/:subCatId/:sellerUserId" component={Containers.SubcategoryProducts} />
      {/* <Layouts.PrivateRoute exact path="/subcategory-products/:id" component={Containers.SubcategoryProducts} /> */}
      <Layouts.PrivateRoute exact path="/home-store-product/:sellerId/:id/:flag/:sellerUserId" component={Containers.HomeStoreProduct} />
      <Layouts.PrivateRoute exact path="/home-store-product/:sellerId/:id/:flag/:subCatId/:sellerUserId" component={Containers.HomeStoreProduct} />
      <Layouts.PrivateRoute exact path="/store-directions/:id" component={Containers.StoreDirections} />
      <Layouts.PrivateRoute exact path="/placing-1" component={Containers.Promotion} />
      <Layouts.PrivateRoute exact path="/placing-1/:id" component={Containers.Promotion} />
      <Layouts.PrivateRoute exact path="/placing-2" component={Containers.Promotion1} />
      <Layouts.PrivateRoute exact path="/profile" component={Containers.Profile} />
      <Layouts.PrivateRoute exact path="/edit-profile" component={Containers.EditProfile} />
      <Layouts.PrivateRoute exact path="/scan" component={Containers.Scan} />
      <Layouts.PrivateRoute exact path="/scan-result" component={Containers.ScanResult} />
      <Layouts.PrivateRoute exact path="/browse" component={Containers.Browse} />
      <Layouts.PrivateRoute exact path="/browse/:id" component={Containers.Browse} />
      <Layouts.PrivateRoute exact path="/booking-list" component={Containers.BookingList} />
      <Layouts.PrivateRoute exact path="/cart" component={Containers.Cart} />
      <Layouts.PrivateRoute exact path="/cart-services/:id/:sellerId" component={Containers.CartServices} />
      <Layouts.PrivateRoute exact path="/orders" component={Containers.Orders} />
      <Layouts.PrivateRoute exact path="/reorder-view" component={Containers.ReorderView} />
      <Layouts.PrivateRoute exact path="/order-processing" component={Containers.OrderProcessing} />
      <Layouts.PrivateRoute exact path="/orders-placed" component={Containers.OrdersPlaced} />
      <Layouts.PrivateRoute exact path="/placing" component={Containers.Placing} />
      <Layouts.PrivateRoute exact path="/past-order-view/:id" component={Containers.PastOrderView} />
      <Layouts.PrivateRoute exact path="/more" component={Containers.More} />
      <Layouts.PrivateRoute exact path="/face-id-and-Pin" component={Containers.FaceIdAndPin} />
      <Layouts.PrivateRoute exact path="/wallet" component={Containers.Wallet} />
      <Layouts.PrivateRoute exact path="/wallet-brands" component={Containers.walletBrands} />
      <Layouts.PrivateRoute exact path="/wallet-products" component={Containers.walletProducts} />
      <Layouts.PrivateRoute exact path="/wallet-shops" component={Containers.walletShops} />
      <Layouts.PrivateRoute exact path="/wallet-2/:flag" component={Containers.Wallet2} />
      <Layouts.PrivateRoute exact path="/payment-methods" component={Containers.PaymentMethods} />
      <Layouts.PrivateRoute exact path="/search-ints" component={Containers.SearchInts} />
      <Layouts.PrivateRoute exact path="/search-result" component={Containers.SearchResult} />
      <Layouts.PrivateRoute exact path="/search-result-dots" component={Containers.SearchResultDots} />
      <Layouts.PrivateRoute exact path="/payment" component={Containers.Payment} />
      <Layouts.PrivateRoute exact path="/credit-card" component={Containers.AddCreditCard} />
      <Layouts.PrivateRoute exact path="/payment-added" component={Containers.PaymentAdded} />
      <Layouts.PrivateRoute exact path="/addresses" component={Containers.Addresses} />
      <Layouts.PrivateRoute exact path="/addresses/:flag" component={Containers.Addresses} />
      <Layouts.PrivateRoute exact path="/new-address" component={Containers.NewAddress} />
      <Layouts.PrivateRoute exact path="/new-address/:flag" component={Containers.NewAddress} />
      <Layouts.PrivateRoute exact path="/new-address-add" component={Containers.NewAddress} />
      <Layouts.PrivateRoute exact path="/new-address-add/:flag" component={Containers.NewAddress} />
      <Layouts.PrivateRoute exact path="/change-address/:id" component={Containers.NewAddress} />
      <Layouts.PrivateRoute exact path="/add-new-address" component={Containers.AddNewAddress} />
      <Layouts.PrivateRoute exact path="/tracking" component={Containers.Tracking} />
      <Layouts.PrivateRoute exact path="/tracking-two" component={Containers.TrackingTwo} />
      <Layouts.PrivateRoute exact path="/coupons/:serviceId/:sellerId/:flag" component={Containers.Coupons} />
      <Layouts.PrivateRoute exact path="/coupons-two" component={Containers.CouponsTwo} />
      <Layouts.PrivateRoute exact path="/coupons-history" component={Containers.CouponHistory} />
      <Layouts.PrivateRoute exact path="/favoriteList" component={Containers.FavoriteList} />
      <Layouts.PrivateRoute exact path="/refer-and-earn" component={Containers.ReferAndEarn} />
      <Layouts.PrivateRoute exact path="/refer-and-earn-two" component={Containers.ReferAndEarnTwo} />
      <Layouts.PrivateRoute exact path="/product-review-available" component={Containers.ProductReview} />
      <Layouts.PrivateRoute exact path="/product-review-available-two" component={Containers.ProductReviewTwo} />
      <Layouts.PrivateRoute exact path="/product-review-edit" component={Containers.ProductReviewEdit} />
      <Layouts.PrivateRoute exact path="/product-review-edit-two" component={Containers.ProductReviewEditTwo} />
      <Layouts.PrivateRoute exact path="/help-center" component={Containers.HelpCenter} />
      <Layouts.PrivateRoute exact path="/help-center-two" component={Containers.HelpCenterTwo} />
      <Layouts.PrivateRoute exact path="/help-center-three" component={Containers.HelpCenterThree} />
      <Layouts.PrivateRoute exact path="/home-support" component={Containers.HomeSupport} />
      <Layouts.PrivateRoute exact path="/my-support-request" component={Containers.SupportRequest} />
      <Layouts.PrivateRoute exact path="/my-support-request-details/:id" component={Containers.SupportRequestDetails} />
      <Layouts.PrivateRoute exact path="/my-account" component={Containers.MyAccount} />
      <Layouts.PrivateRoute exact path="/my-account-two" component={Containers.MyAccountTwo} />
      <Layouts.PrivateRoute exact path="/report-an-issue" component={Containers.ReportAndIssue} />
      <Layouts.PrivateRoute exact path="/report-an-issue-two" component={Containers.ReportAndIssueTwo} />
      <Layouts.PrivateRoute exact path="/faq" component={Containers.Faq} />
      <Layouts.PrivateRoute exact path="/faq-verified" component={Containers.FaqVerified} />
      <Layouts.PrivateRoute exact path="/settings" component={Containers.Settings} />
      <Layouts.PrivateRoute exact path="/terms-and-conditions" component={Containers.TermsAndConditions} />
      <Layouts.PrivateRoute exact path="/privacy-policy" component={Containers.PrivacyPolicy} />
      <Route component={Containers.NotFound} />
    </Switch>
  );
}

export default Router;