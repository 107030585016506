import React from 'react'
import { crossImg, vectorImg, verficationImg } from '../../../utilities/images'

const BusinessDetails = (props) => {

    return (
        <>
            {
                props?.categoryLoading === true ?
                    <div className="pageLoader d-flex justify-content-center align-items-center">
                        <div className="spinner-border" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
                    :
                    <>
                        <div className="order-heading">
                            <div className='header-left_'>
                                <h5>Business Details</h5>
                                <div className='veriImg'>
                                    {props?.productList?.data?.payload?.sellerDetails?.user_profiles?.is_business_registered === true ? <img src={verficationImg} alt='' /> : ""}
                                </div>
                            </div>

                            <div className="header-right-item">
                                <button type="button"><img src={vectorImg} alt="" /></button>
                                <button type="button" onClick={() => props.closeModal()}><img src={crossImg} alt="" /></button>
                            </div>
                        </div>
                        <div className="modal-dialog modal-dialog" role="document">
                            <div className="modal-content prdmdl-content">
                                <div className="modal-body">

                                    <div className="fasprd-dtcontent commonOut_">
                                        <h6>Basic Information</h6>
                                        <p>{props?.productList?.data?.payload?.sellerDetails?.user_profiles?.business_details}</p>
                                    </div>
                                    <div className="product-mdlcnt commonOut_ mt-3 mb-4">
                                        <h6>Overview</h6>
                                        {props?.productList?.data?.payload?.sellerDetails?.user_profiles?.overview?.length > 0 ?
                                            props?.productList?.data?.payload?.sellerDetails?.user_profiles?.overview?.map((v, i) => {
                                                return (
                                                    <ul key={i}>
                                                        {v?.year_established ? <li className='yeartext_'><span className='lefttextbox'>Year established</span> <span className='boldTextt_'>{v?.year_established}</span></li> : <></>}
                                                        {v?.business_type ? <li className='yeartext_'><span className='lefttextbox'>Business type</span> <span className='boldTextt_'>{v?.business_type}</span></li> : <></>}
                                                        {v?.country ? <li className='yeartext_'><span className='lefttextbox'>Country/Region </span> <span className='boldTextt_'>{v?.country}</span></li> : <></>}
                                                        {v?.main_products ? <li className='yeartext_'><span className='lefttextbox'>Main Products</span> <span className='boldTextt_'>{v?.main_products} </span></li> : <></>}
                                                        {v?.total_annual_revenue ? <li className='yeartext_'><span className='lefttextbox'>Total Annual Revenue  US</span> <span className='boldTextt_'>{v?.total_annual_revenue}</span></li> : <></>}
                                                        {v?.main_markets ? <li className='yeartext_'> <span className='lefttextbox'>Main Markets</span> <span className='boldTextt_'>{v?.main_markets} </span> </li> : <></>}
                                                        {v?.patents ? <li className='yeartext_'><span className='lefttextbox'>Patents </span> <span className='boldTextt_'>{v?.patents} </span></li> : <></>}
                                                        {v?.product_certifications ? <li className='yeartext_'> <span className='lefttextbox'>Product Certifications</span> <span className='boldTextt_'>({props?.productList?.data?.payload?.sellerDetails?.user_profiles?.certification?.length}) CE </span></li> : <></>}
                                                    </ul>
                                                )
                                            })
                                            : <></>}
                                    </div>
                                    <div className="productewip commonOut_ mb-4">
                                        <h6>Production Equipments</h6>
                                        <div className="fashion-item">
                                            <div className="row" style={{ marginTop: "20px" }}>
                                                {props?.productList?.data?.payload?.sellerDetails?.user_profiles?.manufacturer_images?.length > 0 ?
                                                    props?.productList?.data?.payload?.sellerDetails?.user_profiles?.manufacturer_images?.map((v, i) => {
                                                        return (
                                                            <div key={i} className="col-6" >
                                                                <div className='fashion-item fash-margtop '>
                                                                    <div className="fashion-slider" >
                                                                        <img src={v} alt="" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    }) : 'No Data'}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="main-content certifybox_ commonOut_">
                                        <h6>Certification</h6>
                                        <div className="tobacco-mainitem" style={{ display: "flex" }}>
                                            {
                                                props?.productList?.data?.payload?.sellerDetails?.user_profiles?.certification?.length > 0 ?
                                                    props?.productList?.data?.payload?.sellerDetails?.user_profiles?.certification?.map((val, index) => {
                                                        return (
                                                            <div key={index} >
                                                                <div>
                                                                    <img className='me-3' style={{ width: "100px" }} src={val} alt='tob1Img' />
                                                                </div>
                                                            </div>

                                                        )
                                                    })
                                                    : <span>No Certification</span>
                                            }

                                        </div>
                                    </div>
                                    <div className="main-content certifybox_ commonOut_ mt-4 mb-4">
                                        <h6>Factory Inspection Reports</h6>
                                        <div className="tobacco-mainitem" style={{ display: "flex" }}>
                                            {
                                                props?.productList?.data?.payload?.sellerDetails?.user_profiles?.business_inspection_report?.length > 0 ?
                                                    props?.productList?.data?.payload?.sellerDetails?.user_profiles?.business_inspection_report?.map((val, index) => {
                                                        return (
                                                            <div key={index} >
                                                                <div>
                                                                    <img className='me-3' style={{ width: "100px" }} src={val} alt='tob1Img' />
                                                                </div>
                                                            </div>

                                                        )
                                                    })
                                                    : <span>No Inspection Reports</span>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>}
        </>
    )
}

export default BusinessDetails
