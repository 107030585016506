import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import { leftArrowImg, review3Img, attach1Img, attach2Img, attach3Img, attach4Img, ratingImg } from "../../../utilities/images";

const ProductReviewEditTwo = (props) => {
    const [note, setNote] = useState("");

    const onChangeInput = (e, fieldName) => {
        const value = e.target.value;
        if (fieldName === "note") {
            setNote(value.trimStart());
        }
    };

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <>
            <div className="order-heading">
                <h4><Link to='/product-review-edit'><img src={leftArrowImg} alt="leftArrowImg" /></Link> Product review</h4>
            </div>

            <div className="main-content">
                <div className="product-review-area">
                    <div className="rate-upper">
                        <h2>Rate your experience</h2>
                        <p>Are you satisfy with product?</p>
                    </div>

                    <div className="rating-partex">
                        <div className="rating-leftex">
                            <img src={review3Img} alt="review3Img" />
                        </div>
                        <div className="rating-rightex">
                            <h4>Marlboro Touch</h4>
                            <div className="rateYo"> <img src={ratingImg} alt="ratingImg" style={{ hight: "150px", width: "150px" }} /></div>
                        </div>
                    </div>

                    <div className="rating-innerex">
                        <div className="rating-inrleftex">
                            <p>Product Quality</p>
                        </div>
                        <div className="rating-inrrightex">
                            <div className="rateYo"> <img src={ratingImg} alt="ratingImg" style={{ hight: "150px", width: "150px" }} /></div>
                        </div>
                    </div>

                    <div className="rating-innerex">
                        <div className="rating-inrleftex">
                            <p>Velue of Money</p>
                        </div>
                        <div className="rating-inrrightex">
                            <div className="rateYo"> <img src={ratingImg} alt="ratingImg" style={{ hight: "150px", width: "150px" }} /></div>
                        </div>
                    </div>

                    <div className="additional-box">
                        <h4>Write your additional feedback</h4>
                        <form action="#" method="POST" value={note} onChange={(e) => { onChangeInput(e, 'note') }} >
                            <textarea name="" id="" cols="30" rows="5">Thank you for your help and quick delivery which enable our customer to meet his deadline – excellent service.</textarea>
                        </form>
                    </div>

                    <div className="attach-part">
                        <h4>Attach image</h4>
                        <div className="attach-img">
                            <img src={attach1Img} alt="attach1Img" />
                            <img src={attach2Img} alt="attach2Img" />
                            <img src={attach3Img} alt="attach3Img" />
                            <img src={attach4Img} alt="attach4Img" />
                        </div>
                    </div>

                    <div className="submit-btnrating mt-5 pt-2">
                        <Link to="#">Submitted</Link>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ProductReviewEditTwo;