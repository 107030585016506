import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import CustomModal from "../../components/shared/CustomModal";
import ProducttypeModal from './producttypeModal';
import { getAttributesForFilterAsync, getBrandListAsync, getExtraProductsByCategoryIdAsync } from './dashboardSlice';
import { unwrapResult } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import { selectLoginAuth } from '../auth/authSlice';

const SubcatefilterModal = (props) => {
    const [flag, setFlag] = useState("");
    const dispatch = useDispatch();
    const auth = useSelector(selectLoginAuth);
    const [key, setKey] = useState(Math.random());
    const [attributeData, setAttributeData] = useState({});
    const [attributeValueData, setAttributeValueData] = useState([]);
    const [productDataLoading, setProductDataLoading] = useState(false);
    const [modalDetail, setModalDetail] = useState({
        show: false,
        title: "",
        flag: ""
    });

    const handleClearAll = () => {
        let subCategoryParams = {
            "postData": {
                page: props?.params?.page,
                limit: props?.params?.limit,
                app_name: props?.params?.app_name,
                delivery_options: props?.params?.delivery_options,
                seller_id: props?.params?.seller_id,
                sub_category_ids: props?.params?.sub_category_ids
            },
            "tokenData": props?.params?.tokenData
        }
        setProductDataLoading(true)
        dispatch(getExtraProductsByCategoryIdAsync(subCategoryParams))
            .then(unwrapResult)
            .then(async (obj) => {
                // setPagination((prevState) => ({ ...prevState, totalPages: obj?.data?.payload?.total_pages, totalRecords: obj?.data?.payload?.total }));
                setProductDataLoading(false)
            }
            )
            .catch((obj) => {
                setProductDataLoading(false)

            })
    }
    const handleBrandData = () =>{
        let brandParams = {
            "postData": {
                main_category: true,
                sub_category_ids: props?.params?.sub_category_ids
            },
            "tokenData": auth?.payload?.token
        }
        dispatch(getBrandListAsync(brandParams))
                    .then(unwrapResult)
                    .then(async (obj) => {
                        setAttributeValueData(obj?.data?.payload?.data)
                        setModalDetail({
                            show: true,
                            flag: "producttypeModal"
                        });
                        setKey(Math.random());
                        setFlag("brand")
                    }
                    )
                    .catch((obj) => {
                    })
    }

    const handleOnCloseModal = () => {
        setModalDetail({
            show: false,
            title: "",
            flag: ""
        });
        setKey(Math.random());
    }

    useEffect(() => {
        let params = {
            "postData": {
                service_id: props?.serviceId
            },
            "tokenData": props?.params?.tokenData
        }
        setProductDataLoading(true)
        dispatch(getAttributesForFilterAsync(params))
            .then(unwrapResult)
            .then(async (obj) => {
                setAttributeData(obj)
                setProductDataLoading(false)
            }
            )
            .catch((obj) => {
                setProductDataLoading(false)

            })
    }, [])
    return (
        <>
            {productDataLoading === true ?
                <div className="pageLoader d-flex justify-content-center align-items-center">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
                :
                <div className="productModal filterMOdal">
                    <div className="productInnerDetails pt-4 pb-4">
                        <div className="productScroll">
                            <div className='modalHeaderproduct_ d-flex justify-content-center mb-4'>
                                <h5 className='subHeading'>Filter</h5>
                                <i className="fas fa-times filterclose" onClick={() => props?.closeModal()}></i>
                            </div>
                            <div className='filterBody_'>
                                <ul className='filterList'>
                                    <li className='listItemms_' onClick={() => {
                                        setModalDetail({
                                            show: true,
                                            flag: "producttypeModal"
                                        });
                                        setKey(Math.random());
                                        setFlag("price")
                                    }}>
                                        <h6 className='descrTxt'>Price</h6>
                                        <i className="fas fa-chevron-right" aria-hidden="true"></i></li>
                                    <li className='listItemms_' onClick={() => {handleBrandData()}} >
                                        <h6 className='descrTxt'>Brand</h6>
                                        <i className="fas fa-chevron-right" aria-hidden="true"></i></li>
                                    {attributeData?.data?.payload?.data?.length > 0 ?
                                        attributeData?.data?.payload?.data?.map((val, ind) => {
                                            return (
                                                <li key={ind} className='listItemms_' onClick={() => {
                                                    setModalDetail({
                                                        show: true,
                                                        flag: "producttypeModal"
                                                    });
                                                    setKey(Math.random());
                                                    setFlag("attribute")
                                                    setAttributeValueData(val?.attribute_values)
                                                }}>
                                                    <h6 className='descrTxt'>{val?.name}</h6>
                                                    <i className="fas fa-chevron-right" aria-hidden="true"></i>
                                                </li>
                                            )
                                        })
                                        : <></>}
                                </ul>
                            </div>
                            <div className='bottomfilterBtn d-flex mt-5'>
                                <Link onClick={() => handleClearAll()} to="#" className="clearAll_">Clear All</Link>
                                <Link onClick={() => { props.closeModal() }} to="#" className="primaryBtn">View Results ({props?.length ? props?.length : 0})</Link>
                            </div>

                        </div>
                    </div>
                </div>}
            <CustomModal
                key={key}
                show={modalDetail.show}
                backdrop="static"
                showCloseBtn={false}
                ids="productInner"
                isRightSideModal={false}
                mediumWidth={true}
                child={modalDetail.flag === 'producttypeModal'

                    ?
                    <ProducttypeModal paginationHandle={(e)=>props.handlePagination(e)} attributeValueData={attributeValueData} closeFilterModal={() => props.closeModal()} flag={flag} newParams={props.params} closeModal={() => handleOnCloseModal()} details={(e) => handleOnCloseModal(e)} />
                    :
                    ""
                }
                onCloseModal={() => handleOnCloseModal()}
            />
        </>
    )
}

export default SubcatefilterModal