import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getSearchProduct } from './SearchApi';
const initialState = {
  searchProductList: null,
  status: {
    searchProductList: "idle"
  },
};
export const searchProductListAsync = createAsyncThunk("productSearch/searchProductList", async (data) => {
  const response = await getSearchProduct(data.postData, data.token);
  return response;
});

export const SearchSlice = createSlice({
  name: "productSearch",
  initialState,
  extraReducers: (builder) => {
    builder
    .addCase(searchProductListAsync.pending, (state) => {
        state.status = "loading";
    })
    .addCase(searchProductListAsync.fulfilled, (state, { payload }) => {
        state.searchProductList = payload
        state.status = "idle";
    })
    .addCase(searchProductListAsync.rejected, (state, { error }) => {
        state.status = "idle";
        state.searchProductList = null
})
 
  },
});
export const selectedsearchProductListData = (state) => state.productSearch.searchProductList;
export default SearchSlice.reducer;