import React, { useEffect } from "react";
import { Link } from 'react-router-dom';
import { leftArrowImg, coinStackImg } from "../../../utilities/images";
import { selectWalletBalance } from "../wallet/walletSlice";
import { useSelector } from "react-redux";

const ReferAndEarnTwo = (props) => {
    const walletData = useSelector(selectWalletBalance);

    useEffect(() => {
        //
    }, [])

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <>
            <div className="order-heading browse-addprdcard-header">
                <div className="header-left-item">
                    <h4><Link to='/refer-and-earn'><img src={leftArrowImg} alt="leftArrowImg" /></Link> Referral history</h4>
                </div>
                <div className="header-right-item">
                    <Link to="/wallet" className="coinStack">{walletData ? walletData?.data?.payload?.sila_balance : 0}
                        <img src={coinStackImg} alt="coinStack" />
                    </Link>
                </div>
            </div>

            <div className="main-content">
                <div className="refer-history">
                    <div className="refer-history-heading">
                        <span>Date</span>
                        <span>Reffered</span>
                        <span>Rewards</span>
                    </div>
                    <ul>
                        <li>
                            <span>21 Jun 2022</span>
                            <span>Sariffe</span>
                            <span>15</span>
                        </li>
                        <li>
                            <span>21 Jun 2022</span>
                            <span>Mick260</span>
                            <span>15</span>
                        </li>
                        <li>
                            <span>21 Jun 2022</span>
                            <span>Allen260</span>
                            <span>15</span>
                        </li>
                        <li>
                            <span>21 Jun 2022</span>
                            <span>Allen260</span>
                            <span>15</span>
                        </li>
                        <li>
                            <span>21 Jun 2022</span>
                            <span>Allen260</span>
                            <span>15</span>
                        </li>
                        <li>
                            <span>21 Jun 2022</span>
                            <span>Allen260</span>
                            <span>15</span>
                        </li>
                        <li>
                            <span>21 Jun 2022</span>
                            <span>Allen260</span>
                            <span>15</span>
                        </li>
                        <li>
                            <span>21 Jun 2022</span>
                            <span>Allen260</span>
                            <span>15</span>
                        </li>
                        <li>
                            <span>21 Jun 2022</span>
                            <span>Allen260</span>
                            <span>15</span>
                        </li>
                        <li>
                            <span>21 Jun 2022</span>
                            <span>Allen260</span>
                            <span>15</span>
                        </li>
                    </ul>
                </div>
            </div>
        </>
    );
}

export default ReferAndEarnTwo;