import React, { useEffect } from "react";
import { Link } from 'react-router-dom';
import { leftArrowImg, coinStackImg } from "../../../utilities/images";
import { selectWalletBalance } from "../wallet/walletSlice";
import { useSelector } from "react-redux";

const HelpCenterThree = (props) => {
    const walletData = useSelector(selectWalletBalance);

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <>
            <div className="order-heading">
                <div className="header-left-item">
                    <h4><Link to='/help-center-two'><img src={leftArrowImg} alt="leftArrowImg" /></Link> Get help with my orders</h4>
                </div>
                <div className="header-right-item">
                    <Link to="/wallet" className="coinStack">{walletData ? walletData?.data?.payload?.sila_balance : 0}
                        <img src={coinStackImg} alt="coinStack" />
                    </Link>
                </div>
            </div>

            <div className="main-content">
                <div className="help-part">
                    <ul>
                        {/* <li>
                            <Link to="#">I have requested a refund <i className="fas fa-chevron-right"></i></Link>
                        </li>
                        <li>
                            <Link to="#">Order was overcharged <i className="fas fa-chevron-right"></i></Link>
                        </li>
                        <li>
                            <Link to="#">I did not received products <i className="fas fa-chevron-right"></i></Link>
                        </li> */}
                        <li>
                            <Link to='/home-support'>Need more help <i className="fas fa-chevron-right"></i></Link>
                        </li>
                    </ul>
                </div>
            </div>
        </>
    );
}

export default HelpCenterThree;