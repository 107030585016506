import React, { useEffect } from 'react'
import { leftArrowImg, coinStackImg, mapView, Fedex } from "../../../utilities/images"
import { Link, useHistory } from 'react-router-dom'

const AssignedCurrier = () => {
    const history = useHistory()
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
    return (
        <div className="main-wrapper">
            <div className="order-heading">
                <div className="header-left-item">
                    <h4><Link to="#" onClick={() => { history.push("/tracking") }}><img src={leftArrowImg} alt="" /></Link> Track your order </h4>
                </div>
                <div className="header-right-item">
                    <Link to="#">0 <img src={coinStackImg} alt="" /></Link>
                </div>
            </div>
            <div className="main-content">
                <div className="assinged-main">
                    <div className="assinged-overlay">
                        <div className="assinged-box">
                            <div className="assinged-boxupper">
                                <div className="assinged-boxuper-left">
                                    <h4>Order status</h4>
                                    <p>Assinged driver</p>
                                </div>
                                <div className="assinged-boxuper-right">
                                    <Link to="#"><img src={mapView} alt="" /> Map</Link>
                                </div>
                            </div>

                            <div className="track-timeline">
                                <ul>
                                    <li className="verified-timeline">
                                        <h4>Delivered</h4>
                                        <p>Verifiered successfull</p>
                                    </li>
                                    <li className="active-timeline">
                                        <h4>DEPART SHIP PTNR SITE USPS AWAITS ITEM</h4>
                                        <p>IMPERIAL, PA, 15126, US • Aug 5, 2:39am</p>
                                    </li>
                                    <li className="active-timeline">
                                        <h4>DEPART SHIP PTNR SITE USPS AWAITS ITEM</h4>
                                        <p>IMPERIAL, PA, 15126, US • Aug 5, 2:39am</p>
                                    </li>
                                    <li className="active-timeline">
                                        <h4>Pickup by SHIP PTNR SITE USPS AWAITS ITEM</h4>
                                        <p>IMPERIAL, PA, 15126, US • Aug 5, 2:39am</p>
                                    </li>
                                    <li className="active-timeline">
                                        <h4>Ready to pickup</h4>
                                        <p>with in 10 minutes</p>
                                    </li>
                                    <li className="active-timeline">
                                        <h4>Order accepted</h4>
                                        <p>21 Jun, 2022  |   10:30 am</p>
                                    </li>
                                </ul>
                            </div>

                            <div className="assigned-bottom-part">
                                <div className="assigned-bottom-box">
                                    <div className="assigned-btmleft">
                                        <img src={Fedex} alt="" />
                                        <div className="assigned-btmcnt">
                                            <h2>FedEx</h2>
                                            <p>9361289677013480485935</p>
                                        </div>
                                    </div>
                                    <div className="assigned-btmright">
                                        <Link to="#"><img src="images/phone-light.svg" alt="" /> Contact</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AssignedCurrier