import React from 'react';
import { Link } from 'react-router-dom';
import Slider from "react-slick";
import SliderWrapper from "./SliderStyle";
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const TrendingSellerModal = (props) => {
    const history = useHistory();
    return (
        <>
            {props?.data?.find(item => item?.service?.slug === props.slug) ?
                <div className="banner-slider trslider-part customSpac" id="bannerSlider">
                    <div className="trslider-title">
                        <h2>Trending {props?.data?.find(item => item?.service?.slug === props.slug)?.service?.name}</h2>
                    </div>
                    <SliderWrapper>
                        <Slider {...props.settings}>
                            {
                                props?.data?.find(item => item?.service?.slug === props.slug)?.sellers?.map((val, index) => {
                                    return (
                                        <React.Fragment key={index}>
                                            <div className="carousel-cell dotsClass">
                                                <div className="trslider-main">
                                                    <img src={val?.user_profiles?.profile_photo} alt="" />
                                                    <div className="trslider-cnt">
                                                        <h4>Top {props?.data?.find(item => item?.service?.slug === props.slug)?.sellers?.length} {props?.data?.find(item => item?.service?.slug === props.slug)?.service?.name} in your city</h4>
                                                        <Link to="#" onClick={()=>history.push(`/home-store/${val?.unique_uuid}`)}>SHOP NOW</Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </React.Fragment>
                                    )
                                })
                            }
                        </Slider>
                    </SliderWrapper>
                </div>
                : ""}
        </>
    )
}

export default TrendingSellerModal
