import React, { useState, useEffect } from "react";
import { useHistory } from 'react-router-dom';
import { Info, orderTwoSvg, leftArrowImg, starCircleImg, coinStackImg } from "../../../utilities/images";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { getReorderByUserAsync } from "../dashboard/dashboardSlice";
import { useDispatch, useSelector } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { selectLoginAuth } from "../auth/authSlice";
import { Link } from "@material-ui/core";
import { selectWalletBalance } from "../wallet/walletSlice";

const PastOrderView = (props) => {
    const dispatch = useDispatch()
    const history = useHistory();
    const { id } = useParams();
    const walletData = useSelector(selectWalletBalance);
    const auth = useSelector(selectLoginAuth);
    const [reorderData, setReorderData] = useState({});
    const [loading, setLoading] = useState(false);

    const singleOrderDataByIdHandle = () => {
        const data = {
            "postData": {
                id: id
            },
            "tokenData": auth?.payload?.token
        }
        setLoading(true)
        dispatch(getReorderByUserAsync(data))
            .then(unwrapResult)
            .then(async (obj) => {
                setReorderData(obj)
                setLoading(false)
            }
            )
            .catch((obj) => {
                setLoading(false)
            })
    }

    useEffect(() => {
        singleOrderDataByIdHandle()
    }, [])

    return (
        <>
            <div className="order-heading">
                <div className="header-left-item">
                    <h4><Link onClick={() => history.push('/orders')}><img src={leftArrowImg} alt="" /></Link> Order details</h4>
                </div>
                <div className="header-right-item">
                    <Link to="/wallet" className="coinStack">{walletData ? walletData?.data?.payload?.sila_balance : 0}
                        <img src={coinStackImg} alt="coinStack" />
                    </Link>
                </div>
            </div>

            <div className="main-content">
                <div className="order-banner"></div>
                <div className="container pastord-mainprt">
                    <div className="ord-profbox">
                        <img style={{ width: "70px" }} src={reorderData?.data?.payload?.seller_details?.profile_photo} alt="" />
                        <div className="ord-profcnt">
                            <h4>{reorderData?.data?.payload?.seller_details?.firstname}</h4>
                            <p><img src={starCircleImg} alt="" /> {reorderData?.data?.payload?.seller_details?.sellerRating?.rating} ({reorderData?.data?.payload?.seller_details?.sellerRating?.review_count}+ ratings)</p>
                            {/* <span>e-vape store</span> */}
                        </div>
                    </div>

                    <div className="order-detailsbox">
                        <h4><img src={orderTwoSvg} alt="" /> Order details</h4>
                        <div className="details-ordupr">
                            <ul>
                                <li>Order Number <span>{reorderData?.data?.payload?.order_delivery?.order_id}</span></li>
                                <li>Order From <span>{reorderData?.data?.payload?.order_delivery?.order_pick_address?.substring(0,25)+'...'}</span></li>
                                <li>Delivery address <span>{reorderData?.data?.payload?.order_delivery?.order_delivery_address?.substring(0,25)+"..."}</span></li>
                            </ul>
                        </div>
                        <div className="details-ordmdl">
                            <ul>
                                {reorderData?.data?.payload?.order_details?.map((val, ind) => {
                                    return (
                                        <li key={ind}>
                                            <div className="details-mdlleft">
                                                <strong>{val?.qty}</strong> <small>x</small>{val?.product_details?.name}<span>(Packet)</span>
                                            </div>
                                            <p>${val?.product_details?.price}</p>
                                        </li>
                                    )
                                })}
                            </ul>
                        </div>

                        <div className="total-part taxes-fees">
                            <ul>
                                <li>Subtotal <span>${reorderData?.data?.payload?.actual_amount}</span></li>
                                <li>Discount <span>-${reorderData?.data?.payload?.discount}</span></li>
                                <li> <small>Taxes & Other fees <img src={Info} alt="" /></small> <span>${reorderData?.data?.payload?.tax}</span></li>
                                <li>Delivery fees <span>${reorderData?.data?.payload?.delivery_charge}</span></li>
                                <li><strong>Total</strong> <strong>${reorderData?.data?.payload?.payable_amount}</strong></li>
                            </ul>
                        </div>
                    </div>

                    <div className="helpord-cnt text-center">
                        <Link to="#">Get help with this order</Link>
                    </div>

                    <div className="reorder-btn mt-auto">
                        <Link to='/reorder-view'>Select items to reorder</Link>
                    </div>
                </div>
            </div>

        </>
    );
}

export default PastOrderView;