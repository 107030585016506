import React, { useState, useEffect } from 'react';
import { unwrapResult } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom';
import { ArrowWhite, bagImg, bellImg, leftArrowImg, Msg1 } from '../../../utilities/images';
import { selectLoginAuth } from '../auth/authSlice';
import { getProductByIdApiAsync } from './dashboardSlice'
import CommonLoader from '../../components/shared/CommonLoader';


const SelectService = () => {
    const history = useHistory();
    const [productLoading, setProductLoading] = useState(false);
    const { id, sellerId } = useParams();
    const auth = useSelector(selectLoginAuth);
    const dispatch = useDispatch();
    const [productData, setProductData] = useState({});
    const [checkedTrendingState, setCheckedTrendingState] = useState(
        new Array(productData?.data?.payload?.product_detail?.product_attribute[0]?.attributes?.attribute_values?.length).fill(false)
    );
    const [checkedValue, setCheckedValue] = useState([]);
    const [checkedPrice, setCheckedPrice] = useState([]);

    const productDataHandle = () => {
        setProductLoading(true)
        let getProductByIdParams = {
            "postData": {
                id: id,
                seller_id: sellerId
            },
            "tokenData": auth?.payload?.token
        }
        dispatch(getProductByIdApiAsync(getProductByIdParams))
            .then(unwrapResult)
            .then(async (obj) => {
                setProductData(obj)
                setProductLoading(false)
            }
            )
            .catch((obj) => {
                setProductLoading(false)
            })
    }

    const handleData = (position) => {
        const updatedCheckedState = checkedTrendingState.map((item, index) =>
            index === position ? !item : item
        );
        setCheckedTrendingState(updatedCheckedState)
        let data = updatedCheckedState.map((val, index) => {
            return (
                val === true ? productData?.data?.payload?.product_detail?.product_attribute[0]?.attributes?.attribute_values[index] : false
            )
        })
        let result = data?.filter(val => val !== false);
        setCheckedValue(result);

        let priceList = result?.map((v, i) => {
            return v?.price
        })
        let totalPrice = priceList?.reduce((a, b) => a + b, 0)

        setCheckedPrice(totalPrice)
    }
    let newData = {
        "total_price": checkedValue?.length > 0 ? checkedValue?.reduce(( sum, { price } ) => sum + price , 0):"",
        "checked_values": checkedValue
    }


    useEffect(() => {
        productDataHandle()
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <>
            {
                productLoading === true ?
                    <CommonLoader />
                    :
                    <>
                        <div className="order-heading" >
                            <h4 style={{ cursor: "pointer" }} onClick={() => history.goBack()}><img src={leftArrowImg} alt="" /> {productData?.data?.payload?.product_detail?.name} </h4>
                            <div className="header-right-item">
                                <button type="button" onClick={() => history.push('/notifications')}><img src={bellImg} alt="" /></button>
                                <button type="button"><img src={bagImg} alt="" /></button>
                            </div>
                        </div>

                        <div className="main-content cart-mainpd">
                            <div className="body-massage-mainprt">
                                <div className="body-msgtitle">
                                    <h2>Select Service <span>Required</span></h2>
                                </div>
                                <div className="bodymsg-part">
                                    {
                                        productData?.data?.payload?.product_detail?.product_attribute[0]?.attributes?.attribute_values?.map((val, index) => {
                                            return (
                                                <div className="bodymsg-item" key={index}>
                                                    <div className="bodymsg-left">
                                                        <div className="form-group" >
                                                            <input
                                                                type="checkbox"
                                                                id={`custom-checkbox1-${index}`}
                                                                name={val?.id}
                                                                value={val?.id}
                                                                checked={checkedTrendingState[index]}
                                                                onChange={() => handleData(index)}
                                                            />
                                                            <label htmlFor={`custom-checkbox1-${index}`}></label>
                                                        </div>
                                                        <div className="bdymsg-cnt">
                                                            <h4>{val?.name}</h4>
                                                            <p>USD ${val?.price}</p>
                                                        </div>
                                                    </div>
                                                    <div className="bodymsg-right">
                                                        <img src={Msg1} alt="" />
                                                    </div>
                                                </div>

                                            )
                                        })
                                    }
                                </div>
                            </div>
                            {newData?.checked_values?.length > 0 ?
                                <div className="selected-product checkout-box">
                                    <div className="selected-itembox appoint-box">
                                        <div className="selected-leftcnt">
                                            <p>Services: <strong>{checkedValue?.length}</strong></p>
                                            <p>$ <strong>{checkedPrice.toFixed(2)}</strong></p>
                                        </div>
                                        <div style={{ cursor: "pointer" }} className="selected-rightbtn">
                                            <img src={ArrowWhite} alt="" />
                                        </div>
                                    </div>
                                </div>
                                : ""}
                        </div>
                    </>
            }
        </>
    )
}

export default SelectService