import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getUserFaq } from "./faqApi";


const initialState = {
    faq: null,
    status: "idle",
};

export const faqAsync = createAsyncThunk("faq/profile", async (data) => {
    const response = await getUserFaq(data);
    return response.data;
});

export const faqSlice = createSlice({
    name: "faq",
    initialState,
    extraReducers: (builder) => {
        builder
        .addCase(faqAsync.pending, (state) => {
            state.status = "loading";
        })
        .addCase(faqAsync.fulfilled, (state, { payload }) => {
            state.faq = payload
            state.status = "idle";
        })
        .addCase(faqAsync.rejected, (state, { error }) => {
            state.status = "idle";
            state.faq = null
    })
  
    },
});

export const selectUserFaq = (state) => state.faq;
export default faqSlice.reducer;