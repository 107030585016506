import React from 'react'
import { Link, useHistory } from 'react-router-dom';
import { leftArrowImg, bxsCoinStackImg } from "../../../utilities/images";
import ReactStars from 'react-stars'
import { useEffect } from 'react';

const FeedBack = () => {
    const history = useHistory()
    const ratingChanged = (newRating) => {

      }

      useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
    return (
        <div className="main-wrapper">
            <div className="order-heading">
                <div className="header-left-item">
                    <h4><Link to='/tracking'><img src={leftArrowImg} alt="" /></Link> Track your order </h4>
                </div>
                <div className="header-right-item">
                    <Link to="#">0 <img src={bxsCoinStackImg} alt="" /></Link>
                </div>
            </div>

            <div className="main-content">
                <div className="feedback-mainprt">
                    <h2>Rate your experience</h2>
                    <p>Are you satisfy with delivery service?</p>
                    <div className="rating-innerex">
                        <div className="rating-inrleftex">
                            <p>Order</p>
                        </div>
                        <div className="rating-inrrightex">
                            <ReactStars
                                count={5}
                                onChange={ratingChanged}
                                size={30}
                                color2={'#FCBA30'} />
                        </div>
                    </div>

                    <div className="rating-innerex">
                        <div className="rating-inrleftex">
                            <p>Driver</p>
                        </div>
                        <div className="rating-inrrightex">
                        <ReactStars
                                count={5}
                                onChange={ratingChanged}
                                size={30}
                                color2={'#FCBA30'} />
                        </div>
                    </div>
                    <div className="rating-innerex border-0">
                        <div className="rating-inrleftex">
                            <p>Shop</p>
                        </div>
                        <div className="rating-inrrightex">
                        <ReactStars
                                count={5}
                                onChange={ratingChanged}
                                size={30}
                                className="rate"
                                color2={'#FCBA30'} />
                        </div>
                    </div>
                    <div className="feedback-inrcnt pt-5">
                        <ul>
                            <li><Link to="#">Overall service</Link></li>
                            <li><Link to="#" className="active">Delivery man was rude</Link></li>
                            <li><Link to="#">5 start</Link></li>
                            <li><Link to="#">Very Professional</Link></li>
                            <li><Link to="#">Exerienced</Link></li>
                            <li><Link to="#">Good behavior</Link></li>
                            <li><Link to="#">Pick up and delivery service</Link></li>
                            <li><Link to="#">Speed and Efficiency</Link></li>
                            <li><Link to="#">Pick up and delivery service</Link></li>
                            <li><Link to="#">Speed and Efficiency</Link></li>
                            <li><Link to="#">Pick up and delivery service</Link></li>
                            <li><Link to="#">Speed and Efficiency</Link></li>
                        </ul>
                    </div>

                    <div className="feedback-form">
                        <form action="#" method="POST">
                            <p>Write your additional feedback</p>
                            <textarea name="" id="" cols="30" rows="5">Thank you for your help and quick delivery which enable our customer to meet his deadline – excellent service.</textarea>
                            <button className="active" onClick={() => history.push('/feedback-thankyou')}>Submit</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FeedBack