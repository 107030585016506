import React, { useEffect } from "react";
import { Link, useHistory } from 'react-router-dom';
import { leftArrowImg, bxsCoinStackImg, homeImg, invoiceImg, edit2Img, workImg } from "../../../utilities/images";

const NewAddress = (props) => {
    const history = useHistory();

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <><div className="order-heading">
            <div className="header-left-item">
                <h4><Link to="#" onClick={() => { history.push('/more') }}><img src={leftArrowImg} alt="leftArrowImg" /></Link> Addresses</h4>
            </div>
            <div className="header-right-item">
                <Link to="#">0 <img src={bxsCoinStackImg} alt="bxsCoinStackImg" /></Link>
            </div>
        </div>

            <div className="main-content">
                <div className="address-part">
                    <form action="#" method="POST">
                        <div className="address-item">
                            <div className="radio-btn">
                                <img src={homeImg} alt="homeImg" />
                            </div>
                            <div className="address-cnt">
                                <h4>Home</h4>
                                <p>2598 West Street <br />Holland, MI 49424</p>
                            </div>
                            <div className="edit-btn">
                                <Link to="#">
                                    <img src={edit2Img} alt="edit2Img" />
                                </Link>
                            </div>
                        </div>
                        <div className="address-item">
                            <div className="radio-btn">
                                <img src={workImg} alt="workImg" />
                            </div>
                            <div className="address-cnt">
                                <h4>Work</h4>
                                <p>2598 West Street <br />Holland, MI 49424</p>
                            </div>
                            <div className="edit-btn">
                                <Link to="#">
                                    <img src={edit2Img} alt="edit2Img" />
                                </Link>
                            </div>
                        </div>
                        <div className="address-item">
                            <div className="radio-btn">
                                <img src={invoiceImg} alt="invoiceImg" />
                            </div>
                            <div className="address-cnt">
                                <h4>Billing</h4>
                                <p>2598 West Street <br />Holland, MI 49424</p>
                            </div>
                            <div className="edit-btn">
                                <Link to="#">
                                    <img src={edit2Img} alt="edit2Img" />
                                </Link>
                            </div>
                        </div>
                    </form>
                    <div className="address-btn">
                        <Link to="#" onClick={()=>history.push('/new-address')}>Add new address</Link>
                    </div>
                </div>
            </div>

        </>
    );
}


export default NewAddress;