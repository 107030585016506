import React from 'react'
import { useState } from 'react'
import { Link, useHistory } from 'react-router-dom';
import { usePlaidLink } from 'react-plaid-link';
import { useEffect } from 'react';
import { unwrapResult } from '@reduxjs/toolkit';
import { selectLoginAuth, generatePlaidTokenApiAsync, getBankAccountApiAsync, linkBankAccountAsync } from '../auth/authSlice';
import { useDispatch, useSelector } from 'react-redux';
import Card from 'react-bootstrap/Card';
import { leftArrowImg } from '../../../utilities/images';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';

const DocumentSuccess = () => {
    const history = useHistory()
    const {flag} = useParams();
    const [linkToken, setLinkToken] = useState("")
    const [bankDetails, setBankDetails] = useState([])
    const auth = useSelector(selectLoginAuth)
    const { open, ready } = usePlaidLink({
        token: linkToken,
        onSuccess: (public_token, metadata) => {
            // send public_token to server
            console.log(public_token);
            let params = {
                postData: {
                    "plaid_public_token": public_token
                },
                tokenData: auth?.payload?.token
            }
            dispatch(linkBankAccountAsync(params))
                .then(unwrapResult)
                .then((obj) => {
                    dispatch(getBankAccountApiAsync(auth?.payload?.token))
                        .then(unwrapResult)
                        .then((obj) => {
                            setBankDetails(obj?.payload)
                        }
                        )
                        .catch((obj) => {
                        })
                }
                )
                .catch((obj) => {
                })
        },
    });
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(generatePlaidTokenApiAsync(auth?.payload?.token))
            .then(unwrapResult)
            .then((obj) => {
                setLinkToken(obj?.payload?.link_token)
            }
            )
            .catch((obj) => {
            })
        dispatch(getBankAccountApiAsync(auth?.payload?.token))
            .then(unwrapResult)
            .then((obj) => {
                setBankDetails(obj?.payload)
            }
            )
            .catch((obj) => {
            })
    }, [])

    return (
        <>
            <main className="main-wrapper kycwrapper">
                <div className="registration-upper reg-heading2">
                    <h4><Link to="#">
                        <img src={leftArrowImg} alt="" />
                    </Link>{" "}
                        Add Bank account
                    </h4>
                </div>
                <div className='spc_'>
                    {
                    bankDetails?.length > 0 ? <>
                        {
                            bankDetails?.map((val, index) => {
                                return (
                                    <Card body key={index}>
                                        <div className='d-flex'>
                                            <h6>A/C No:- </h6>{"  "}
                                            <span>{val?.account_number}</span>
                                        </div>
                                        <div className='d-flex'>
                                            <h6>A/C Holder Name:- </h6>{"  "}
                                            <span>{val?.account_owner_name}</span>
                                        </div>
                                    </Card>
                                )
                            })
                        }
                        </> : 
                        <div className='text-center blankData'>
                            <h6>Document are uploaded successfully.</h6>
                        </div>
                    }
                    <div className='bottom-part '>
                        <button type="submit" className="primaryBtn mb-2" onClick={() => open()} disabled={!ready}>
                            {!ready && (
                                <span className="spinner-border spinner-border-sm"></span>
                            )}
                            &nbsp;&nbsp;
                            Add Bank account</button> 
                            {bankDetails?.length > 0 && <button type="submit" className="primaryBtn" onClick={flag === 'order' ? ()=> history.push('/placing-1') : ()=> history.push('/payment')}>
                                Continue</button>}                       
                    </div>                     
                </div>
            </main>
        </>
    )
}

export default DocumentSuccess