import React from 'react';
import { useHistory } from 'react-router-dom';


const ProductModal = (props) => {
    const history = useHistory();

    const handleChange = (val) => {
        props.setSubcategoryId(val)
        props.closeModal()
    }
    const handleCategory = (id, type, name) => {
            history.push(`/home-category/${id}/${type}/${name}`)
    }

    return (
        <>
            {
                props?.categoryLoading === true ?
                    <div className="pageLoader d-flex justify-content-center align-items-center">
                        <div className="spinner-border" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
                    :
                    <div className='productModal'>
                        <div className='productInnerDetails'>
                            <div className='productScroll'>
                                <div className='row'>
                                    {props?.categoryData?.length > 0 ? props?.categoryData?.filter(item => ((item?.categoryData?.image !== null) && (item?.image !== null)))?.map((value, index) => {
                                        return (
                                            <div className='col-sm-3 col-md-3 col-lg-3 text-center mb-3' key={index}>
                                                <div className='innerCateItems'
                                                    onClick={props.flag === "dashboard" ? () => handleCategory(value?.categoryData?.id ? value?.categoryData?.id : value?.id, props.serviceType, value?.categoryData?.name? value?.categoryData?.name: value?.name) : () => handleChange(value)}
                                                >
                                                    <span className='productInnerImg'>
                                                        <img src={value?.categoryData?.image ?value?.categoryData?.image : value?.image} alt='' className='' />
                                                    </span>
                                                    <h5 className='productName mt-2'>{value?.categoryData?.name ? value?.categoryData?.name: value?.name}</h5>
                                                </div>
                                            </div>
                                        )
                                    }) : "No Categories Found"}
                                </div>
                            </div>
                        </div>
                    </div>
            }
        </>
    )
}

export default ProductModal