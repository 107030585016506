import React, { useEffect } from "react";
import { Link, useHistory } from 'react-router-dom';
import { leftArrowImg, shopImg, starCircleImg, clockImg, deliveryParcelImg } from "../../../utilities/images";

const WalletShops = (props) => {
    const history = useHistory();

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <>
            <div className="order-heading">
                <div className="header-left-item wallet-header-left">
                    <h4><Link to="#" onClick={() => { history.push('/wallet') }}><img src={leftArrowImg} alt="leftArrowImg" /></Link>Shops <span>3 shops</span></h4>
                </div>
            </div>

            <div className="main-content">
                <div className="wallet-shop-area">
                    <div className="result-sec">
                        <div className="result-secleft">
                            <img src={shopImg} alt="shopImg" />
                        </div>
                        <div className="result-secright">
                            <h4>Miami smoke <Link to="#"><i className="far fa-heart"></i></Link></h4>
                            <p><img src={starCircleImg} alt="starCircleImg" /> 4.5</p>
                            <p><img src={clockImg} alt="clockImg" /> 26-50 min</p>
                            <p><img src={deliveryParcelImg} alt="deliveryParcelImg" /> $6.49 Delivery fee</p>
                        </div>
                    </div>
                    <div className="result-sec">
                        <div className="result-secleft">
                            <img src={shopImg} alt="shopImg" />
                        </div>
                        <div className="result-secright">
                            <h4>Miami smoke <Link to="#"><i className="far fa-heart"></i></Link></h4>
                            <p><img src={starCircleImg} alt="starCircleImg" /> 4.5</p>
                            <p><img src={clockImg} alt="clockImg" /> 26-50 min</p>
                            <p><img src={deliveryParcelImg} alt="deliveryParcelImg" /> $6.49 Delivery fee</p>
                        </div>
                    </div>
                    <div className="result-sec">
                        <div className="result-secleft">
                            <img src={shopImg} alt="shopImg" />
                        </div>
                        <div className="result-secright">
                            <h4>Miami smoke <Link to="#"><i className="far fa-heart"></i></Link></h4>
                            <p><img src={starCircleImg} alt="starCircleImg" /> 4.5</p>
                            <p><img src={clockImg} alt="clockImg" /> 26-50 min</p>
                            <p><img src={deliveryParcelImg} alt="deliveryParcelImg" />$6.49 Delivery fee</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default WalletShops;