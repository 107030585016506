import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from 'react-router-dom';
import { leftArrowImg, coinStackImg } from "../../../utilities/images";
import { selectLoginAuth } from "../auth/authSlice";
import { Box } from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import PaginationSetting from '../../constants/PaginationSetting';
import CommonLoader from '../../components/shared/CommonLoader';
import { unwrapResult } from "@reduxjs/toolkit";
import moment from "moment-timezone";
import { getAllOrderByUserAsync } from "../dashboard/dashboardSlice";
import { selectWalletBalance } from "../wallet/walletSlice";


const HelpCenterTwo = (props) => {
    const history = useHistory();
    const [pastOrders, setPastOrders] = useState({});
    const [pagination, setPagination] = useState(PaginationSetting);
    const [page, setPage] = useState(1);
    const walletData = useSelector(selectWalletBalance);
    const [productLoading, setProductLoading] = useState(false);
    const auth = useSelector(selectLoginAuth);
    const dispatch = useDispatch();

    const pastOrdersHandle = async () => {
        const data = {
            "postData": {
                page: page,
                limit: pagination.recordsPerPage
            },
            "tokenData": auth?.payload?.token
        }
        setProductLoading(true)
        dispatch(getAllOrderByUserAsync(data))
            .then(unwrapResult)
            .then(async (obj) => {
                setPastOrders(obj)
                setPagination((prevState) => ({ ...prevState, totalPages: obj?.data?.payload?.total_pages, totalRecords: obj?.data?.payload?.total }));
                setProductLoading(false)
            }
            )
            .catch((obj) => {
                setProductLoading(false)
            })
    }

    useEffect(() => {
        pastOrdersHandle()
    }, [])


    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <>
            <div className="order-heading">
                <div className="header-left-item">
                    <h4><Link to='/help-center'><img src={leftArrowImg} alt="leftArrowImg" /></Link> Get help with my orders</h4>
                </div>
                <div className="header-right-item">
                    <Link to="/wallet" className="coinStack">{walletData ? walletData?.data?.payload?.sila_balance : 0}
                        <img src={coinStackImg} alt="coinStack" />
                    </Link>
                </div>
            </div>
            {productLoading === true ?
                <CommonLoader />
                :
                <div className="main-content">
                    <div className="help-tab">
                        <div id="tab1" className="tab">
                            <div className="tab-othercnt">
                                <p>Lorem ipsum dolor sit amet consectetur adipisicing, elit. Qui molestiae iusto magnam sint et vel, nesciunt error illum debitis dignissimos blanditiis veniam aperiam, odit consequuntur. Doloremque, culpa.</p>
                            </div>
                        </div>

                        <div id="tab2" className="tab active">
                            <div className="help-tab2prt">
                                {pastOrders?.data?.payload?.data?.length > 0 ? pastOrders?.data?.payload?.data?.map((val, ind) => {
                                    return (
                                        <div className="help-tabbox" key={ind}>
                                            <h4 onClick={() => history.push('/help-center-three')} style={{ cursor: 'pointer' }}>{val?.seller_details?.firstname}</h4>
                                            {val?.order_details?.map((v, i) => {
                                                return (
                                                    <p key={i} style={{ display: "inline-block" }}>{v?.product_name} {i < val?.order_details?.length - 1 ? "," : ""}</p>
                                                )
                                            })}
                                            <p>{moment(val?.created_at).format('DD MMM, h:mm')}</p>
                                            <span>{val?.status === 0 ? "REVIEWING"
                                                : val?.status === 1 ? "ACCEPTED_BY_SELLER"
                                                    : val?.status === 2 ? "PREPAIRING"
                                                        : val?.status === 3 ? "READY_FOR_PICKUP"
                                                            : val?.status === 4 ? "ASSIGN_DRIVER"
                                                                : val?.status === 5 ? "PICKUP"
                                                                    : val?.status === 6 ? "Delivered"
                                                                        : val?.status === 7 ? "PICKUP_BY_CUSTOMER"
                                                                            : val?.status === 8 ? "CANCELED"
                                                                                : val?.status === 9 ? "REJECTED_BY_SELLER"
                                                                                    : ""}</span>
                                        </div>

                                    )
                                }) : "No Orders"}
                            </div>
                        </div>
                    </div>
                </div>}
            {pagination.totalRecords > pagination.recordsPerPage &&
                <Box py={3} display="flex" justifyContent="center">
                    <Pagination
                        key={page}
                        count={pagination.totalPages || 0}
                        color="secondary"
                        variant="outlined"
                        onChange={(e, value) => setPage(value)}
                        defaultPage={page}
                    />
                </Box>
            }
        </>
    );
}

export default HelpCenterTwo;