import React, { useState, useEffect } from "react";
import { useHistory, Link } from "react-router-dom";
import { toast } from "react-toastify";
import { createWalletAsync, selectLoginAuth, selectLoginProfile } from "../../containers/auth/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
} from 'react-places-autocomplete';
import DatePicker from "react-datepicker";
import moment from "moment-timezone";
import { leftArrowImg } from "../../../utilities/images";
import { selectUserProfile, userProfileAsync } from "../profile/profileSlice";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

const RegistrationDocPi = (props) => {
    const history = useHistory();
    const {flag} = useParams();
    const [dob, setDob] = useState("")
    const [ssn, setSsn] = useState("")
    const [gender, setGender] = useState("male")
    const [apt, setApt] = useState("")
    const [zipcode, setZipCode] = useState("")
    const [address, setAddress] = useState("")
    const dispatch = useDispatch()
    const toastId = React.useRef(null)
    const [piLoading, setPiLoading] = useState(false)
    const [countryCode, setCountryCode] = useState("")
    const [countryValue, setCountryValue] = useState("");
    const [stateCode, setStateCode] = useState("")
    const [stateValue, setStateValue] = useState("")
    const [cityValue, setCityValue] = useState("")
    const [email, setEmail] = useState("")
    const selectLoginUser = useSelector(selectLoginProfile);
    const selectProfile = useSelector(selectUserProfile);
    let loginId = selectLoginUser.payload.id;

    const autoCompleteHandleChange = (address) => {
        if (address === "") {
            setCityValue("")
            setStateValue("")
            setCountryValue("")
            setZipCode("")
        }
        setAddress(address);
    };

    const autoCompleteHandleSelect = (address) => {
        geocodeByAddress(address)
            .then((results) => {
                fillAddressDetails(results);
            })
            .catch((error) => { });
    };

    const fillAddressDetails = (results) => {
        setAddress(results[0].formatted_address);

        for (let j = 0; j < results[0].address_components.length; j++) {
            if (results[0].address_components[j].types[0] == "postal_code") {
                setZipCode(results[0].address_components[j].short_name);
            } else if (results[0].address_components[j].types[0] == "locality") {
                setCityValue(results[0].address_components[j].long_name);
            } else if (
                results[0].address_components[j].types[0] ==
                "administrative_area_level_1" ||
                results[0].address_components[j].types[0] === 'administrative_area_level_3' ||
                results[0].address_components[j].types[0] === 'locality'
            ) {
                setStateValue(results[0].address_components[j].long_name);
                setStateCode(results[0].address_components[j]?.short_name)
            } else if (results[0].address_components[j].types[0] == "country") {
                setCountryValue(results[0].address_components[j].long_name);
                setCountryCode(results[0].address_components[j]?.short_name);
            }
        }
    };

    const auth = useSelector(selectLoginAuth)
    const gotoRegister2Page = (e) => {
        e.preventDefault();
        history.push("/register2");
    };

    const gotoRegistrationDocDdPage = async (e) => {
        e.preventDefault();
        if (!dob) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error("Please enter your DOB");
            }
            return;
        }
        if (!ssn) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error("Please enter your ssn");
            }
            return;
        }
        if (ssn.length < 9) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error("ssn number should be of 9 digits");
            }
            return;
        }
        if (!email) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error("Please enter your email");
            }
            return;
        }
        var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
        var resultemail = pattern.test(email);

        if (resultemail === false) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error("Please enter valid email");
            }
            return;
        }
        if (!address.length > 0) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error("Please enter your street address");
            }
            return;
        }
        if (!zipcode) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error("Please enter your zipcode");
            }
            return;
        }
        if (!countryValue) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error("Please enter country")
            }
            return
        }
        if (!stateValue) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error("Please enter state")
            }
            return
        }
        if (!cityValue) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error("Please enter City")
            }
            return
        }
        setPiLoading(true)

        let walletData = {
            postData: {
                "type": "individual",
                "first_name": selectProfile?.payload?.user_profiles?.firstname,
                "last_name": selectProfile?.payload?.user_profiles?.lastname,
                "address_type": "current",
                "address": address,
                "city": cityValue,
                "state": stateCode,
                "zip": zipcode,
                "country": countryCode,
                "phone": selectProfile?.payload?.user_profiles?.phone_no,
                "email": selectProfile?.payload?.email,
                "dob": moment(dob).format('YYYY-MM-DD'),
                "ssn": ssn
            },
            tokenData: auth?.payload?.token
        }

        await dispatch(createWalletAsync(walletData))
            .then(unwrapResult)
            .then((obj) => {
                setPiLoading(false)
                if (!toast.isActive(toastId.current)) {
                    toastId.current = toast.success(obj?.msg)
                }
                history.push(`/request-kyc/${flag}`);
            }
            )
            .catch((obj) => {
                setPiLoading(false)
            })
    }

    useEffect(() => {
        let params = {
            loginId: loginId,
            token: selectLoginUser.payload.token
        }
        dispatch(userProfileAsync(params))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            <main className="main-wrapper">
                <div className="registration-upper reg-heading2">
                    <Link to="#" onClick={(e) => gotoRegister2Page(e)}>
                        <img src={leftArrowImg} alt="" className="me-1" />
                    </Link>{" "}
                    <h6 className="subhead_">Personal Information</h6>
                </div>
                <div className="personal-infomain">
                    <form className="horizontalForm row">
                        <div className="col-md-12 mb-4">
                            <label htmlFor="p1">Date of birthday (month / day / year)</label>
                            <DatePicker
                                selected={dob}
                                onChange={(date) => setDob(date)}
                                dateFormat="MM/dd/yyyy"
                                maxDate={moment().subtract(21, 'years')._d}
                                placeholderText="MM/DD/YYYY"
                                className="Customform-control date"
                                autoComplete="off"
                            />
                        </div>
                        <div className="col-md-12 mb-4">
                            <label htmlFor="p1">Ssn</label>
                            <input type="password" placeholder="SSN" className="Customform-control" value={ssn} onChange={e => setSsn(e.target.value)} maxLength={9} />
                        </div>
                        <div className="col-md-12 mb-4">
                            <input type="email" placeholder="Please enter your email" className="Customform-control" value={email} onChange={e => setEmail(e.target.value)} />
                        </div>
                        <div className="col-md-12 mb-4">
                            <div className="gender-prt">
                                <label htmlFor="">Gender</label>
                                <div className="checkboxGroup">
                                    <div className="checkInputs">
                                        <input type="radio" id="male" name="age" value="30" checked={gender === 'male'}
                                            onChange={() => setGender('male')} className="checkBox" />
                                        <label for="male" className='checkBoxTxt'>Male</label>
                                    </div>
                                    <div className="checkInputs">
                                        <input type="radio" id="female" name="age" value="30" checked={gender === 'female'}
                                            onChange={() => setGender('female')} className="checkBox" />
                                        <label for="female" className='checkBoxTxt'>Female</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12 mb-4">
                            <label htmlFor="p2">Current Addreess</label>
                            <PlacesAutocomplete
                                value={address}
                                onChange={autoCompleteHandleChange}
                                onSelect={autoCompleteHandleSelect}
                                searchOptions={{
                                    componentRestrictions: {
                                        country: ['us'],
                                    }
                                }}
                            >
                                {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                    <div>
                                        <input
                                            {...getInputProps({
                                                placeholder: 'Street Address',
                                                className: 'location-search-input',
                                            })}
                                        />
                                        <div className="autocomplete-dropdown-container">
                                            {loading && <div>Loading...</div>}
                                            {suggestions.map(suggestion => {
                                                const className = suggestion.active
                                                    ? 'suggestion-item--active'
                                                    : 'suggestion-item';
                                                // inline style for demonstration purpose
                                                const style = suggestion.active
                                                    ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                                    : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                                return (
                                                    <div
                                                        {...getSuggestionItemProps(suggestion, {
                                                            className,
                                                            style,
                                                        })}
                                                    >
                                                        <span>{suggestion.description}</span>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                )}
                            </PlacesAutocomplete>
                        </div>
                        <div className="col-md-12 mb-4">
                            <label>Apt.</label>
                            <input type="text" placeholder="Apt. " value={apt} onChange={e => setApt(e.target.value)} className="Customform-control" />
                        </div>
                        <div className="col-md-12 mb-4">
                            <label>City</label>
                            <input type="text" placeholder="Please enter city " value={cityValue} onChange={e => setCityValue(e.target.value)} className="Customform-control" />
                        </div>
                        <div className="col-md-12 mb-4">
                            <label>State</label>
                            <input type="text" placeholder="Please enter state " value={stateValue} onChange={e => setStateValue(e.target.value)} className="Customform-control" />
                        </div>
                        <div className="col-md-12 mb-4">
                            <label>Zip Code</label>
                            <input type="text" placeholder="Zip Code" value={zipcode} onChange={e => setZipCode(e.target.value)} className="Customform-control" />
                        </div>
                        <div className="col-md-12 mb-4">
                            <label>Country</label>
                            <input type="text" placeholder="Please enter country " value={countryValue} onChange={e => setCountryValue(e.target.value)} className="Customform-control" />
                        </div>
                        <button type="submit" className="primaryBtn mb-3" disabled={piLoading === true} onClick={(e) => gotoRegistrationDocDdPage(e)}>
                            {piLoading === true && (
                                <span className="spinner-border spinner-border-sm"></span>
                            )}
                            &nbsp;&nbsp;
                            Next
                        </button>
                    </form>
                </div>
            </main>
        </>
    );
};

export default RegistrationDocPi;