import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { bagImg, bellImg, coinStackImg, leftArrowImg } from "../../../utilities/images";
import { selectLoginAuth } from "../../containers/auth/authSlice";
import { selectUserProfile } from "../../containers/profile/profileSlice";
import Badge from "@material-ui/core/Badge"
import { getCartApiAsync } from "../../containers/cart/cartSlice";
import { useState } from "react";
import { unwrapResult } from "@reduxjs/toolkit";
import { useEffect } from "react";
import { selectWalletBalance } from "../../containers/wallet/walletSlice";

const MobileHeader = (props) => {
    const history = useHistory()
    const pathName = window.location.pathname;
    const auth = useSelector(selectLoginAuth);
    const walletData = useSelector(selectWalletBalance);
    const [count, setCount] = useState("")
    const dispatch = useDispatch()
    const selectProfile = useSelector(selectUserProfile);

    const getCartDeliveryData = async () => {
        await dispatch(getCartApiAsync(auth?.payload?.token))
            .then(unwrapResult)
            .then(async (obj) => {
                setCount(obj?.data?.payload?.cart_products.length)
            }
            )
            .catch((obj) => {
            })

    }

    useEffect(() => {
        getCartDeliveryData()
    }, [])

    return (
        <>
            <div className="header-part browse-addprdcard-header">
                <div className="row align-items-center">
                    <div className="header-left-item col-4 col-md-4">
                        <div className="header-left">
                            {(pathName === '/dashboard') ? "" :
                                <Link to="/dashboard">
                                    <img src={leftArrowImg} alt="leftArrow" />
                                </Link>
                            }
                            <Link to="#">{selectProfile?.payload?.user_profiles?.firstname}</Link>
                            <p className="location_select" onClick={() => history.push('location-select')}>{selectProfile?.payload?.current_location?.formatted_address ? `${(selectProfile?.payload?.current_location?.formatted_address).substring(0, 25)}...` : "Add Address"}</p>
                        </div>
                    </div>
                    <div className="header-right-item col-8 col-md-8 text-end">
                        <button type="button" onClick={() => { history.push('/notifications') }}>
                            <img src={bellImg} alt="bellImg" />
                        </button>
                        {
                            count !== "" ?
                                <Badge overlap="rectangular" color="secondary" badgeContent={count}>
                                    <button type="button" onClick={() => history.push('/cart')}><img src={bagImg} alt="bagImg" /></button>
                                </Badge>
                                :
                                <button type="button" onClick={() => history.push('/cart')}><img src={bagImg} alt="bagImg" /></button>
                        }


                        <Link to="/wallet" className="coinStack">{walletData ? walletData?.data?.payload?.sila_balance : 0}
                            <img src={coinStackImg} alt="coinStack" />
                        </Link>
                    </div>
                </div>
            </div>
        </>
    );
};

export default MobileHeader;
