import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { coinStackImg, leftArrowImg } from '../../../utilities/images';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { selectWalletBalance } from '../wallet/walletSlice';
import { couponsAsync } from '../coupons/couponSlice';
import { useDispatch, useSelector } from 'react-redux';
import { selectLoginAuth } from '../auth/authSlice';
import { Box } from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import PaginationSetting from '../../constants/PaginationSetting';
import { unwrapResult } from '@reduxjs/toolkit';
import CommonLoader from "../../components/shared/CommonLoader";
import moment from "moment-timezone";


const CouponHistory = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const [pagination, setPagination] = useState(PaginationSetting);
    const [page, setPage] = useState(1);
    const [couponLoading, setCouponLoading] = useState(false);
    const auth = useSelector(selectLoginAuth);
    const walletData = useSelector(selectWalletBalance);
    const [couponData, setCouponData] = useState({});
    let currentDate = new Date();

    useEffect(() => {
        let data = {
            postData: {
                page: page,
                limit: pagination.recordsPerPage
            },
            tokenData: auth?.payload?.token
        }
        setCouponLoading(true)
        dispatch(couponsAsync(data))
            .then(unwrapResult)
            .then((obj) => {
                setCouponData(obj)
                setCouponLoading(false)
                // setPagination((prevState) => ({ ...prevState, totalPages: obj?.data?.payload?.total_pages, totalRecords: obj?.data?.payload?.total }));
            }
            )
            .catch((obj) => {
                setCouponLoading(false)
            })
    }, [])

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <>

            <div className="order-heading">
                <div className="header-left-item">
                    <h4><Link to="" onClick={() => history.goBack()}><img src={leftArrowImg} alt="leftArrowImg" /></Link> History</h4>
                </div>
                <div className="header-right-item">
                    <Link to="/wallet" className="coinStack">{walletData ? walletData?.data?.payload?.sila_balance : 0}
                        <img src={coinStackImg} alt="coinStack" />
                    </Link>
                </div>
            </div>
            {couponLoading === true ?
                <CommonLoader />
                :
                <>
                    {
                        (couponData?.data?.payload?.data?.length > 0) ?
                            <>
                                <div className="fashion-itembox historyitems mt-5 mb-5">
                                    <div className="row">
                                        {couponData?.data?.payload?.data?.filter(item => moment(item?.end_time).format("DD MMM YYYY") < moment(currentDate).format("DD MMM YYYY"))?.map((offerData, index) => {
                                            return (
                                                <React.Fragment key={index}>
                                                    <div className="col-6" >
                                                        <div className='fashion-item fash-margtop'>
                                                            <div className="offer-crslitem">
                                                                <img src={offerData?.image} alt="offerCrsImg1" />
                                                                <div className="offer-crslinner">
                                                                    <div className="offertextContent">
                                                                        <div className="mb-3">
                                                                            <h2 className="offerDiscount">-{offerData.discount_percentage}%</h2>
                                                                        </div>
                                                                        <h4 className="highLightTxt mb-1">
                                                                            {offerData?.name}
                                                                        </h4>
                                                                        <p className="subTextSmall">
                                                                            Get {offerData.discount_percentage}% off your entire purchase of ${offerData.max_discount} or more
                                                                        </p>
                                                                        <div className="mt-3">
                                                                            <p className="subTextSmall">
                                                                                Expires <br />
                                                                            </p>
                                                                            <span className="highLightSmall">{moment(offerData.end_time).format("DD MMM YYYY")}</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </React.Fragment>
                                            )
                                        })}
                                    </div>
                                </div>
                            </> : <>No Coupon History</>}

                </>}
            {pagination.totalRecords > pagination.recordsPerPage &&
                <Box py={3} display="flex" justifyContent="center">
                    <Pagination
                        key={page}
                        count={pagination.totalPages || 0}
                        color="secondary"
                        variant="outlined"
                        onChange={(e, value) => setPage(value)}
                        defaultPage={page}
                    />
                </Box>
            }
        </>

    )
}

export default CouponHistory
