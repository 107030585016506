import React from 'react';
import { crossImg, vectorImg } from '../../../utilities/images';
import ReactStars from 'react-stars';
import CountryList from 'country-list-with-dial-code-and-flag';

const CompanyReviews = (props) => {
    return (
        <>
            {
                props?.categoryLoading === true ?
                    <div className="pageLoader d-flex justify-content-center align-items-center">
                        <div className="spinner-border" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
                    :
                    <>
                        <div className="order-heading ">
                            <h5 >Company Reviews</h5>
                            <div className="header-right-item">
                                <button type="button"><img src={vectorImg} alt="" /></button>
                                <button type="button" onClick={() => props.closeModal()}><img src={crossImg} alt="" /></button>
                            </div>
                        </div>
                        <div className="modal-dialog modal-dialog compnyReview" role="document">
                            <div className="modal-content prdmdl-content">
                                <div className="modal-body">
                                    <div className="product-mdlcnt">
                                        <h6>Overview</h6>
                                        <div className='compnyreviewrate'>
                                            <div className='rating_box me-3'><strong>{props?.productList?.data?.payload?.sellerRating?.rating}</strong><span>/5</span></div>
                                            <div className='rateinfo'><span className='rateHrading'>Very Satisfied</span>
                                            <p className='ratepara_'>{props?.productList?.data?.payload?.sellerRating?.review_count} reviews</p></div></div>
                                        <ul className='startrating_ mt-3'>
                                            <li>
                                                <div className='justify-content-between align-item-center' style={{ display: "flex" }}>
                                                    <span>Suplier Service</span>
                                                    <div className='rightRating_'>
                                                    <span className='me-3'>
                                                        <ReactStars
                                                            count={5}
                                                            edit={false}
                                                            size={15}
                                                            value={Number(props?.productList?.data?.payload?.sellerRating?.rating)}
                                                            color2={'#FCBA30'}
                                                            activeColor={"#ffd700"}
                                                        /></span>
                                                     <span className='acceptLeft'> {props?.productList?.data?.payload?.sellerRating?.rating} Acceptable</span>
                                                </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div className='justify-content-between align-item-center' style={{ display: "flex" }}>
                                                    <span>On-time Shipment</span>
                                                    <span className='me-3'>
                                                        <ReactStars
                                                            count={5}
                                                            edit={false}
                                                            size={15}
                                                            value={Number(props?.productList?.data?.payload?.OnTimeShipment?.rating)}
                                                            color2={'#FCBA30'}
                                                            activeColor={"#ffd700"}
                                                        />
                                                    </span>
                                                    <span className='acceptLeft'>{props?.productList?.data?.payload?.OnTimeShipment?.rating} Acceptable</span>
                                                </div>
                                            </li>
                                            <li>
                                                <div className='justify-content-between align-item-center' style={{ display: "flex" }}>
                                                    <span>Product Quality</span>
                                                    <span className='me-3'>
                                                        <ReactStars
                                                            count={5}
                                                            edit={false}
                                                            size={15}
                                                            value={Number(props?.productList?.data?.payload?.sellerRating?.rating)}
                                                            color2={'#FCBA30'}
                                                            activeColor={"#ffd700"}
                                                        /></span>
                                                    <span className='acceptLeft'>{props?.productList?.data?.payload?.sellerRating?.rating} Acceptable</span>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                    {props?.productList?.data?.payload?.reviewDetail?.length > 0 ?
                                        props?.productList?.data?.payload?.reviewDetail?.map((item, index) => {
                                            return (
                                                <div className="order-part" key={index}>
                                                    <div className="order-item">
                                                        <div className="order-cnt">
                                                        <span>{CountryList.findOneByDialCode(item?.country_code).flag}</span>
                                                            <h4>{(item?.username).replace((item?.username).substring(1,(item?.username?.length)-1), "********")}</h4>
                                                            <div style={{ display: "flex" }}>
                                                                <ReactStars
                                                                    count={5}
                                                                    edit={false}
                                                                    size={15}
                                                                    value={Number(item?.product_rating)}
                                                                    color2={'#FCBA30'}
                                                                    activeColor={"#ffd700"}
                                                                /> | <span>{item?.product_rating}.0</span>
                                                            </div>
                                                            <p>{item?.productReview}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        }) : ""}
                                </div>
                            </div>
                        </div>
                    </>}
        </>
    )
}

export default CompanyReviews
