import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import logo from "../../public/images/logo.svg";
import LockOneImg from "../../public/images/lock-1.png";
import PhoneInput from 'react-phone-input-2'
import { registerOtpSendAsync } from './authSlice';
import { useDispatch } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';

const ForgotPassword = (props) => {
    const history = useHistory();
    const [phoneNo, setPhoneNo] = useState("");
    const [phoneCode, SetPhoneCode] = useState("");
    const dispatch = useDispatch()
    const toastId = React.useRef(null)

    const onChangePhoneNumber = (value, data, event, formattedValue) => {
        let phoneCode = data.dialCode;
        let phoneNumber = value.slice(data.dialCode.length);
        setPhoneNo(phoneNumber);
        SetPhoneCode(phoneCode);
    }
    let value = {
        "phone_code": `+${phoneCode}`,
        "phone_no": phoneNo,
        "isAlreadyCheck": true
    }

    const sendOtp = () => {
        if (!phoneNo) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error("Please enter phone number");
            }

            return;
        }
        else if (phoneNo && phoneNo.length <= 4) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error("Phone number length should be of 5 to 15 digits");
            }

            return;
        }
        else if (phoneNo && !phoneCode) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error("Please select your phone code");
            }

            return;
        }
        dispatch(registerOtpSendAsync(value))
            .then(unwrapResult)
            .then((obj) => {
                localStorage.setItem("phonedetails", JSON.stringify(value))
                history.push('/otp-verification');
            }
            )
            .catch((obj) => {
            })

    }

    useEffect(() => {
        // props.logout();
    }, []);
    
    useEffect(() => {
        window.scrollTo(0, 0)   
          }, [])

    return (
        <>
            <div className="logo-part">
                <Link to="#">
                    <img src={logo} alt="" />
                </Link>
            </div>

            <div className="lock-part">
                <img src={LockOneImg} alt="" />
            </div>
            <div className="phone-numbpart">
                <h2>Enter your Phone number</h2>
                <p>We’ll send you OTP to set your new PIN</p>
                <div className="country-plugin">
                    <form action="#" name="POST">
                        <div id="result">
                            <PhoneInput
                                country="us"
                                // value={phoneNo}
                                enableSearch={true}
                                placeholder="Phone no."
                                onChange={(value, data, event, formattedValue) => { onChangePhoneNumber(value, data, event, formattedValue) }}
                            />
                            <span id="valid-msg" className="hide">✓ Valid</span>
                            <span id="error-msg" className="hide"></span>
                        </div>
                    </form>
                </div>
            </div>
            <div className="bottom-part">
                <Link to="#" className="bottom-btn" onClick={() => { sendOtp() }}>Send</Link>
            </div>
        </>
    )

}

export default ForgotPassword;