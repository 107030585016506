import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import { checkBadgeImg, fireDarkImg, leftArrowImg, starDarkImg, timerImg } from "../../../utilities/images";

const SearchResultDots = (props) => {
    const [sortsData, setSortsData] = useState([])
    const [priceData, setPriceData] = useState([])
    const [deliveryData, setDeliveryData] = useState([])
    const [ratingData, setRatingdata] = useState([])
    const [distanceData, setDistancedata] = useState([])

    // const filterResponse = {
    //     "sorts": sortsData,
    //     "price_range": priceData,
    //     "delivery": deliveryData,
    //     "rating": ratingData,
    //     "distance": distanceData
    // }

    const handleSortsChange = (event) => {
        var updatedList = [...sortsData];
        if (event.target.checked) {
          updatedList = [...sortsData, event.target.value];
        } else {
          updatedList.splice(sortsData.indexOf(event.target.value), 1);
        }
        setSortsData(updatedList);
    }
    const handlePriceChange = (event) => {
        var updatedList = [...priceData];
        if (event.target.checked) {
          updatedList = [...priceData, event.target.value];
        } else {
          updatedList.splice(priceData.indexOf(event.target.value), 1);
        }
        setPriceData(updatedList);
    }
    const handleDeliveryChange = (event) => {
        var updatedList = [...deliveryData];
        if (event.target.checked) {
          updatedList = [...deliveryData, event.target.value];
        } else {
          updatedList.splice(deliveryData.indexOf(event.target.value), 1);
        }
        setDeliveryData(updatedList);
    }
    const handleRatingChange = (event) => {
        var updatedList = [...ratingData];
        if (event.target.checked) {
          updatedList = [...ratingData, event.target.value];
        } else {
          updatedList.splice(ratingData.indexOf(event.target.value), 1);
        }
        setRatingdata(updatedList);
    }
    const handleDistanceChange = (event) => {
        var updatedList = [...distanceData];
        if (event.target.checked) {
          updatedList = [...distanceData, event.target.value];
        } else {
          updatedList.splice(distanceData.indexOf(event.target.value), 1);
        }
        setDistancedata(updatedList);
    }
      useEffect(() => {
        // 
    }, [])

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <>
            <div className="order-heading">
                <h4><Link to='/search-result'><img src={leftArrowImg} alt="leftArrowImg" /></Link> All Filters</h4>
            </div>
            <div className="main-content">
                <div className="result-3dotprt">
                    <div className="dot3-item">
                        <h4>Sorts</h4>
                        <ul>
                            <li name="picked_up_for_you"><img src={checkBadgeImg} alt="checkBadgeImg" /> Picked up for you <span><input type="checkbox" value="pick_up_for_you" onChange={(e)=>handleSortsChange(e)}/></span></li>
                            <li name="most_popular"><img src={fireDarkImg} alt="fireDarkImg" /> Most popular <span><input type="checkbox" value="most_popular" onChange={(e)=>handleSortsChange(e)}/></span></li>
                            <li name="rating"><img src={starDarkImg} alt="starDarkImg" /> Rating <span><input type="checkbox" value="rating" onChange={(e)=>handleSortsChange(e)}/></span></li>
                            <li name="deliver_time"><img src={timerImg} alt="timerImg" /> Delivery time <span><input type="checkbox" value="deliver_time" onChange={(e)=>handleSortsChange(e)}/></span></li>
                        </ul>
                    </div>
                    <div className="dot3-item">
                        <h4>Price range</h4>
                        <ul>
                            <li><img src={checkBadgeImg} alt="checkBadgeImg" /> Picked up for you <span><input type="checkbox" value="pick_up_for_you" onChange={(e)=>handlePriceChange(e)}/></span></li>
                            <li><img src={fireDarkImg} alt="fireDarkImg" /> Most popular <span><input type="checkbox" value="most_popular" onChange={(e)=>handlePriceChange(e)}/></span></li>
                            <li><img src={starDarkImg} alt="starDarkImg" /> Rating <span><input type="checkbox" value="rating" onChange={(e)=>handlePriceChange(e)}/></span></li>
                            <li><img src={timerImg} alt="timerImg" /> Delivery time <span><input type="checkbox"  value="deliver_time" onChange={(e)=>handlePriceChange(e)}/></span></li>
                        </ul>
                    </div>
                    <div className="dot3-item">
                        <h4>Max Delivery fee</h4>
                        <ul>
                            <li><img src={checkBadgeImg} alt="checkBadgeImg" /> Picked up for you <span><input type="checkbox" value="pick_up_for_you" onChange={(e)=>handleDeliveryChange(e)}/></span></li>
                            <li><img src={fireDarkImg} alt="fireDarkImg" /> Most popular <span><input type="checkbox" value="most_popular" onChange={(e)=>handleDeliveryChange(e)}/></span></li>
                            <li><img src={starDarkImg} alt="starDarkImg" /> Rating <span><input type="checkbox" value="rating" onChange={(e)=>handleDeliveryChange(e)} /></span></li>
                            <li><img src={timerImg} alt="timerImg" /> Delivery time <span><input type="checkbox"  value="deliver_time" onChange={(e)=>handleDeliveryChange(e)} /></span></li>
                        </ul>
                    </div>
                    <div className="dot3-item">
                        <h4>Ratings</h4>
                        <ul>
                            <li><img src={checkBadgeImg} alt="checkBadgeImg" />1 <span><input type="checkbox" value="1" onChange={(e)=>handleRatingChange(e)}/></span></li>
                            <li><img src={fireDarkImg} alt="fireDarkImg" />3-4 <span><input type="checkbox" value="3-4" onChange={(e)=>handleRatingChange(e)}/></span></li>
                            <li><img src={starDarkImg} alt="starDarkImg" /> 5-6 <span><input type="checkbox" value="5-6" onChange={(e)=>handleRatingChange(e)} /></span></li>
                            <li><img src={timerImg} alt="timerImg" /> 7-8 <span><input type="checkbox" value="7-8" onChange={(e)=>handleRatingChange(e)}/></span></li>
                            <li><img src={timerImg} alt="timerImg" /> 9 <span><input type="checkbox" value="9" onChange={(e)=>handleRatingChange(e)}/></span></li>
                            <li><img src={timerImg} alt="timerImg" /> 10 <span><input type="checkbox" value="10" onChange={(e)=>handleRatingChange(e)}/></span></li>
                        </ul>
                    </div>
                    <div className="dot3-item">
                        <h4>Distance</h4>
                        <ul>
                            <li><img src={checkBadgeImg} alt="checkBadgeImg" />within 1 m <span><input type="checkbox" value="within1m" onChange={(e)=>handleDistanceChange(e)}/></span></li>
                            <li><img src={fireDarkImg} alt="fireDarkImg" />within 2 m <span><input type="checkbox" value="within2m" onChange={(e)=>handleDistanceChange(e)}/></span></li>
                            <li><img src={starDarkImg} alt="starDarkImg" /> within 5 m <span><input type="checkbox" value="within5m" onChange={(e)=>handleDistanceChange(e)}/></span></li>
                            <li><img src={timerImg} alt="timerImg" /> within 10 m <span><input type="checkbox" value="within10m" onChange={(e)=>handleDistanceChange(e)}/></span></li>
                        </ul>
                    </div>
                    <div className="dot3-btn">
                        <Link to='/search-result'>Apply</Link>
                    </div>
                </div>
            </div>
        </>
    );
}

export default SearchResultDots;