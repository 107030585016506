import React from 'react'
import { useHistory, useParams, Link } from 'react-router-dom';
import { withGoogleMap, GoogleMap, withScriptjs, Marker, InfoWindow } from "react-google-maps";
import { getAddressFromCoordinates } from '../addresses/AddressMethods';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectLoginAuth } from '../auth/authSlice';
import { useEffect } from 'react';
import Geocode from "react-geocode";
import { categoryImg } from '../../../utilities/images';
import { getCategoryApiAsync, getTrendingNearBySellerAsync } from './dashboardSlice';
import { unwrapResult } from '@reduxjs/toolkit';
import CustomModal from "../../components/shared/CustomModal";
import ProductModal from "./ProductModal";
import CommonLoader from '../../components/shared/CommonLoader';

Geocode.setApiKey(process.env.REACT_APP_MAP_KEY);
Geocode.enableDebug();
Geocode.setLanguage("en");
Geocode.setLocationType("ROOFTOP");

const MapView = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [sellerLatLong, setSellerLatLong] = useState([]);
  const auth = useSelector(selectLoginAuth);
  const { id, flag } = useParams();
  const [dataLoading, setDataLoading] = useState(false);
  const [categoryData, setCategoryData] = useState({});
  const [address, setAddress] = useState("")
  const [latitude, setLatitude] = useState(null)
  const [longitude, setLongitude] = useState(null)
  const [district, setDistrict] = useState('');
  const [country, setCountry] = useState('');
  const [countryCode, setCountryCode] = useState('')
  const [state, setState] = useState('')
  const [stateCode, setStateCode] = useState('')
  const [placeId, setPlaceId] = useState('');
  const [zipCode, setZipCode] = useState('');
  const [add1, setAdd1] = useState('');
  const [add2, setAdd2] = useState('');
  const [zoom, setZoom] = useState(15)
  const [city, setCity] = useState("")
  const [categorySelected, setCategorySelected] = useState('');
  const [key, setKey] = useState(Math.random());
  const [modalDetail, setModalDetail] = useState({
    show: false,
    title: "",
    flag: ""
  });
  const handleOnCloseModal = () => {
    setModalDetail({
      show: false,
      title: "",
      flag: ""
    });
    setKey(Math.random());
  }

  let lat, long

  const geocodeHandler = () => {
    navigator.geolocation.getCurrentPosition(function (position) {
      getAddressFromCoordinates(position.coords.latitude, position.coords.longitude)
        .then(data => {
          setPlaceId(data.place_id);
          setAddress(data?.formatted_address);
          setLongitude(position.coords.longitude)
          setLatitude(position.coords.latitude)
          lat = position.coords.latitude
          long = position.coords.longitude

          for (var i = 0; i < data.address_components.length; i++) {
            if (data.address_components[i].types[0] === 'locality') {
              setCity(data?.address_components?.[i]?.long_name);
              setDistrict(data?.address_components?.[i]?.long_name);
            }

            if (data.address_components[i].types[0] === 'country') {
              setCountry(data?.address_components?.[i]?.long_name);
              setCountryCode(data?.address_components?.[i]?.short_name);
            }

            if (data.address_components[i].types[0] === 'administrative_area_level_1') {
              setState(data?.address_components?.[i]?.long_name);
              setStateCode(data?.address_components?.[i]?.short_name);
            }

            if (data.address_components[i].types[0] === 'postal_code') {
              setZipCode(data?.address_components?.[i]?.long_name);
            }
            if (
              data.address_components[i].types[1] === 'sublocality' &&
              data.address_components[i].types[2] === 'sublocality_level_3'
            ) {
              setAdd1(data?.address_components?.[i]?.long_name);
            }

            if (
              data.address_components[i].types[1] === 'sublocality' &&
              data.address_components[i].types[2] === 'sublocality_level_1'
            ) {
              setAdd2(data?.address_components?.[i]?.long_name);
            }
          }
        })
        .catch(err => console.log('error getting address', err));
    });
  }

  const AsyncMap = withScriptjs(
    withGoogleMap(
      props => (
        <GoogleMap
          defaultZoom={zoom}
          defaultCenter={{ lat: latitude, lng: longitude }}
        >
          {sellerLatLong ? sellerLatLong?.map((val, ind) => {
            return (
              val?.map((v, i) => {
                return (
                  <Marker
                    key={v?.id}
                    id={v?.id}
                    position={{ lat: parseFloat(v?.position?.lat), lng: parseFloat(v?.position?.lng) }}
                    onClick={() => history.push(`/home-store/${v?.sellerId}`)}
                  >
                    <InfoWindow >
                      <div>{v?.distance}km</div>
                    </InfoWindow>
                  </Marker>
                )
              })

            )
          }) : ""}
        </GoogleMap>
      )
    )
  );

  const handleNearSellers = (data) => {
    setCategorySelected(data?.name ? data?.name : data?.name)
    setDataLoading(true)
    let params = {
      postData: {
        page: 1,
        limit: 10,
        b2b_service_type: flag,
        lat: 30.7355,
        long: 76.7121,
        need_trending: true,
        need_nearby: true,
        category_id: data?.id ? data?.id : data?.id
      },
      tokenData: auth?.payload?.token
    }
    dispatch(getTrendingNearBySellerAsync(params))
      .then(unwrapResult)
      .then(async (obj) => {
        setDataLoading(false)
        let sellerLatitudes = obj?.data?.payload?.sellers?.data?.map((v, i) => {
          return (
            {
              'id': i + 1,
              "distance": v?.distance?.inKM,
              'sellerId': v?.unique_uuid,
              'position': {
                'lat': v?.user_locations[0]?.latitude,
                'lng': v?.user_locations[0]?.longitude
              }
            }
          )
        })
        setSellerLatLong([sellerLatitudes])
      }
      )
      .catch((obj) => {
        setDataLoading(false)
      })

  }
  const subCategoryDataHandle = () => {
    setDataLoading(true)
    let categoryParam = {
      "postData": {
        category_id: id,
        main_category: true,
        service_type: flag
      },
      "tokenData": auth?.payload?.token
    }
    dispatch(getCategoryApiAsync(categoryParam))
      .then(unwrapResult)
      .then((obj) => {
        let params = {
          postData: {
            page: 1,
            limit: 10,
            b2b_service_type: flag,
            lat: 30.7355,
            long: 76.7121,
            need_trending: true,
            need_nearby: true,
            category_id: obj?.data?.payload?.data[0]?.id
          },
          tokenData: auth?.payload?.token
        }
        setCategoryData(obj)
        setCategorySelected(obj?.data?.payload?.data[0]?.name)
        dispatch(getTrendingNearBySellerAsync(params))
          .then(unwrapResult)
          .then((obj) => {
            setDataLoading(false)
            let sellerLatitudes = obj?.data?.payload?.sellers?.data?.map((v, i) => {
              return (
                {
                  "id": i + 1,
                  "distance": v?.distance?.inKM,
                  'sellerId': v?.unique_uuid,
                  "position": {
                    "lat": v?.user_locations[0]?.latitude,
                    "lng": v?.user_locations[0]?.longitude
                  }
                }
              )
            })
            setSellerLatLong([sellerLatitudes])
          }
          )
          .catch((obj) => {
            setDataLoading(false)
          })
      }
      )
      .catch((obj) => {
        setDataLoading(false)
      })
  }

  useEffect(() => {
    geocodeHandler()
    subCategoryDataHandle()
    window.scrollTo(0, 0)
  }, [])
  return (
    <>
      {
        dataLoading === true || sellerLatLong === [] ?
          <CommonLoader />
          :
          <>
            <div className="map_box">
              <div style={{ position: 'relative', top: '30px' }} >
                <div className="search-barhm">
                  <form action="#" method="POST">
                    <input type="search" placeholder="Search here" onFocus={() => history.push('/browse')} />
                  </form>
                </div>
              </div>
              {/* <img src={addressMapImg} alt="addressMapImg" /> */}
              <AsyncMap
                googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_MAP_KEY}&libraries=places`}
                loadingElement={
                  <div style={{ height: `100%` }} />
                }
                containerElement={
                  <div className='mapInnerArea' />
                }
                mapElement={
                  <div style={{ height: `100%` }} />
                }
              />
              {categoryData?.data?.payload?.data?.length > 0 ?
                <div className="maphm-btmprt">
                  <div className="maphm-btmlinks">
                    <ul className="card_category">
                      <li className='card_categoryItems'>
                        <img onClick={() => {
                          setModalDetail({
                            show: true,
                            flag: "products"
                          });
                          setKey(Math.random())
                        }} src={categoryImg} alt='' className='allIcon' /></li>
                      {
                        categoryData?.data?.payload?.data?.map((val, index) => {
                          return (
                            <li key={index} onClick={() => { handleNearSellers(val) }} className='card_categoryItems' style={{ zIndex: categoryData?.data?.payload?.data?.filter(item => ((item?.image !== null) && (item?.image !== null))).length - index}}><Link to="#" className={(categorySelected === val?.name) ? `active` : ''}><img src={val?.image} alt="catImg" /> {val?.name}</Link></li>
                          )
                        })
                      }
                    </ul>
                  </div>
                </div> : ""}
            </div>
          </>}
      <CustomModal
        key={key}
        show={modalDetail.show}
        // backdrop="static"
        showCloseBtn={false}
        ids="productInner"
        isRightSideModal={false}
        mediumWidth={true}
        child={modalDetail.flag === 'products'
          ? <ProductModal flag="others" categoryData={categoryData?.data?.payload?.data} setSubcategoryId={(e) => handleNearSellers(e)} setCategorySelected={setCategorySelected} categoryLoading={dataLoading} closeModal={() => handleOnCloseModal()} details={(e) => handleOnCloseModal(e)} />
          : ""

        }
        onCloseModal={() => handleOnCloseModal()}
      />
    </>
  )
}

export default MapView
