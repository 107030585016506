import React, { useState, useEffect } from "react";
import { Link, useHistory } from 'react-router-dom';
import {
    clockImg, deliveryParcelImg, deliveryTruckImg, dotDarkImg, searchResult1Img,
    sendImg, shopImg, starCircleImg
} from "../../../utilities/images";

const SearchResult = (props) => {
    const history = useHistory();
    const [tabData, setTabData] = useState('tab1')

    useEffect(() => {
        // 
    }, [])

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <>
            <div className="main-content">
                <div className="search-result">
                    <form action="#" method="POST">
                        <input type="search" onFocus={() => history.push('/search-ints')} />
                    </form>
                </div>

                <div className="search-tab">
                    <div className="tabs">
                        <div className="tab-upper tab-upperhm">
                            <ul className="tab-links">
                                <li className={(tabData === 'tab1') ? 'active' : ""} onClick={() => setTabData('tab1')}><Link to="#" >Products</Link></li>
                                <li className={(tabData === 'tab2') ? 'active' : ""} onClick={() => setTabData('tab2')}><Link to="#">Retails</Link></li>
                            </ul>
                        </div>

                        <div className="tab-content">
                            {(tabData === 'tab1') ?
                                <div id="tab1">
                                    <div className="home-tab1prt">
                                        <div className="delivery-parthm">
                                            <div className="delivery-upperhm srcresult-links">
                                                <ul>
                                                    <li><Link to="#"><img src={sendImg} alt="sendImg" /> Near Me</Link></li>
                                                    <li><Link to="#"><img src={deliveryTruckImg} alt="deliveryTruckImg" /> Free Delivery</Link></li>
                                                    <li><Link to='/search-result-dots'><img src={dotDarkImg} alt="dotDarkImg" /></Link></li>
                                                </ul>
                                            </div>

                                            <div className="result-title">
                                                <h2>23 Results</h2>
                                            </div>

                                            <div className="delivery-sechm">
                                                <img className="delv-prd" src={searchResult1Img} alt="searchResult1Img" />
                                                <h4>Miami smoke </h4>
                                                <ul>
                                                    <li><img src={starCircleImg} alt="starCircleImg" /> 4.5</li>
                                                    <li><img src={clockImg} alt="clockImg" /> 26-50 min</li>
                                                    <li><img src={deliveryParcelImg} alt="deliveryParcelImg" /> $6.49 Delivery fee</li>
                                                </ul>
                                            </div>
                                            <div className="delivery-sechm">
                                                <img className="delv-prd" src={searchResult1Img} alt="searchResult1Img" />
                                                <h4>Miami smoke </h4>
                                                <ul>
                                                    <li><img src={starCircleImg} alt="starCircleImg" /> 4.5</li>
                                                    <li><img src={clockImg} alt="clockImg" /> 26-50 min</li>
                                                    <li><img src={deliveryParcelImg} alt="deliveryParcelImg" /> $6.49 Delivery fee</li>
                                                </ul>
                                            </div>
                                            <div className="delivery-sechm">
                                                <img className="delv-prd" src={searchResult1Img} alt="searchResult1Img" />
                                                <h4>Miami smoke </h4>
                                                <ul>
                                                    <li><img src={starCircleImg} alt="starCircleImg" /> 4.5</li>
                                                    <li><img src={clockImg} alt="clockImg" /> 26-50 min</li>
                                                    <li><img src={deliveryParcelImg} alt="deliveryParcelImg" /> $6.49 Delivery fee</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                :
                                <div id="tab2">
                                    <div className="home-tab2prt">
                                        <div className="delivery-parthm">
                                            <div className="delivery-upperhm srcresult-links">
                                                <ul>
                                                    <li><Link to="#"><img src={sendImg} alt="sendImg" /> Near Me</Link></li>
                                                    <li><Link to="#"><img src={deliveryTruckImg} alt="deliveryTruckImg" /> Free Delivery</Link></li>
                                                    <li><Link to='/search-result-dots'><img src={dotDarkImg} alt="dotDarkImg" /></Link></li>
                                                </ul>
                                            </div>
                                            <div className="result-title">
                                                <h2>10 Results</h2>
                                            </div>
                                            <div className="result-sec">
                                                <div className="result-secleft">
                                                    <img src={shopImg} alt="shopImg" />
                                                </div>
                                                <div className="result-secright">
                                                    <h4>Miami smoke <Link to="#" className="fav-active"><i className="far fa-heart"></i></Link></h4>
                                                    <p><img src={starCircleImg} alt="starCircleImg" /> 4.5</p>
                                                    <p><img src={clockImg} alt="clockImg" /> 26-50 min</p>
                                                    <p><img src={deliveryParcelImg} alt="deliveryParcelImg" /> $6.49 Delivery fee</p>
                                                </div>
                                            </div>
                                            <div className="result-sec">
                                                <div className="result-secleft">
                                                    <img src={shopImg} alt="shopImg" />
                                                </div>
                                                <div className="result-secright">
                                                    <h4>Miami smoke <Link to="#"><i className="far fa-heart"></i></Link></h4>
                                                    <p><img src={starCircleImg} alt="starCircleImg" /> 4.5</p>
                                                    <p><img src={clockImg} alt="clockImg" /> 26-50 min</p>
                                                    <p><img src={deliveryParcelImg} alt="deliveryParcelImg" /> $6.49 Delivery fee</p>
                                                </div>
                                            </div>
                                            <div className="result-sec">
                                                <div className="result-secleft">
                                                    <img src={shopImg} alt="shopImg" />
                                                </div>
                                                <div className="result-secright">
                                                    <h4>Miami smoke <Link to="#"><i className="far fa-heart"></i></Link></h4>
                                                    <p><img src={starCircleImg} alt="starCircleImg" /> 4.5</p>
                                                    <p><img src={clockImg} alt="clockImg" /> 26-50 min</p>
                                                    <p><img src={deliveryParcelImg} alt="deliveryParcelImg" /> $6.49 Delivery fee</p>
                                                </div>
                                            </div>
                                            <div className="result-sec">
                                                <div className="result-secleft">
                                                    <img src={shopImg} alt="shopImg" />
                                                </div>
                                                <div className="result-secright">
                                                    <h4>Miami smoke <Link to="#"><i className="far fa-heart"></i></Link></h4>
                                                    <p><img src={starCircleImg} alt="starCircleImg" /> 4.5</p>
                                                    <p><img src={clockImg} alt="clockImg" /> 26-50 min</p>
                                                    <p><img src={deliveryParcelImg} alt="deliveryParcelImg" /> $6.49 Delivery fee</p>
                                                </div>
                                            </div>
                                            <div className="result-sec">
                                                <div className="result-secleft">
                                                    <img src={shopImg} alt="shopImg" />
                                                </div>
                                                <div className="result-secright">
                                                    <h4>Miami smoke <Link to="#"><i className="far fa-heart"></i></Link></h4>
                                                    <p><img src={starCircleImg} alt="starCircleImg" /> 4.5</p>
                                                    <p><img src={clockImg} alt="clockImg" /> 26-50 min</p>
                                                    <p><img src={deliveryParcelImg} alt="deliveryParcelImg" /> $6.49 Delivery fee</p>
                                                </div>
                                            </div>
                                            <div className="result-sec">
                                                <div className="result-secleft">
                                                    <img src={shopImg} alt="shopImg" />
                                                </div>
                                                <div className="result-secright">
                                                    <h4>Miami smoke <Link to="#"><i className="far fa-heart"></i></Link></h4>
                                                    <p><img src={starCircleImg} alt="starCircleImg" /> 4.5</p>
                                                    <p><img src={clockImg} alt="clockImg" /> 26-50 min</p>
                                                    <p><img src={deliveryParcelImg} alt="deliveryParcelImg" /> $6.49 Delivery fee</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default SearchResult;