import React, { useEffect, useState } from "react";
import { Link, useHistory } from 'react-router-dom';
import { leftArrowImg, editProfileImg, user2Img, emailImg, tellImg, verifyImg, calender2Img } from "../../../utilities/images";
import { toast } from "react-toastify";
import { profilePhotoAsync, selectProfilePhoto, selectUserProfile, userProfileAsync, updateProfileAsync } from "./profileSlice";
import { useSelector, useDispatch } from "react-redux";
import { selectLoginProfile } from "../auth/authSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import DatePicker from "react-datepicker";
import moment from 'moment-timezone';
import CommonLoader from "../../components/shared/CommonLoader";

const EditProfile = (props) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const toastId = React.useRef(null)
    const selectProfile = useSelector(selectUserProfile);
    let userProfile = selectProfile?.payload?.user_profiles;
    let userName = '';
    if (userProfile) {
        if (userProfile?.firstname) {
            userName = userProfile.firstname;
        }
        // if (userProfile?.lastname) {
        //     userName = userName + " " + userProfile.lastname;
        // }
    }
    let curDate = new Date()
    const [img1, setImg] = useState("")
    const [loading, setLoading] = useState(false);
    const [userEmail, setUserEmail] = useState(selectProfile?.payload?.email ? selectProfile?.payload?.email : "");
    const [fullname, setFullname] = useState(userName ? userName : "");
    const [phoneNo, setPhoneNo] = useState(userProfile ? userProfile.full_phone_number : "");
    const [ssnNo, setSsnNo] = useState(userProfile ? userProfile.ssn_number : "");
    const [dob, setDob] = useState(userProfile.dob ? userProfile.dob : curDate);
    const updatedProfileImage = useSelector(selectProfilePhoto);
    const selectLoginUser = useSelector(selectLoginProfile);
    let loginId = selectLoginUser?.payload.id;
    const status1w = useSelector(state => state?.editProfilePhoto)
    const onChangeInput = (e, fieldName) => {
        const value = e.target.value;
        if (fieldName === "userName") {
            setFullname(value);
        }
        else if (fieldName === "email") {
            setUserEmail(value.trim());
        }
        else if (fieldName === "mobile") {
            setPhoneNo(value)

        }
        else if (fieldName === "ssn") {
            setSsnNo(value)
        }
        else if (fieldName === "dob") {
            setDob(value)
        }

    };

    const handleCheck = async () => {
        if (!fullname) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error("Please enter your user name");
            }
            return;
        }
        else if (fullname.length < 3) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error("Username should be of 3 digits");
            }
            return;
        }
        // else if (!userEmail) {
        //     toast.error("Please enter your email");
        //     return;
        // }
        else if (!phoneNo) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error("Please enter phone number");
            }
            return;
        }
        else if (phoneNo && phoneNo.length <= 4) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error("Phone number length should be of 5 to 15 digits");
            }
            return;
        }

        let params = {
            profile_photo: updatedProfileImage?.payload?.profile_photo,
            loginId: loginId,
            firstname: fullname,
            // lastname: "aaa",
            email: userEmail,
            dob: dob,
            ssn_number: ssnNo,
            token: selectLoginUser.payload.token
        }
        await dispatch(updateProfileAsync(params))
        history.push('/profile')
    }

    const handleProfileChange = async (e) => {
        setLoading(true);
        let param = {
            loginId: loginId,
            token: selectLoginUser.payload.token
        }
        await dispatch(userProfileAsync(param))
        const imageFile = e.target.files[0];
        setLoading(false);
        let params = {
            profile: imageFile
        }
        await dispatch(profilePhotoAsync(params))
            .then(unwrapResult)
            .then((obj) => {
                // let newFormValues = [...img1];
                setImg(obj?.payload?.profile_photo);
                if (!toast.isActive(toastId.current)) {
                    toastId.current = toast.success(obj?.msg)
                }
            }
            )
            .catch((obj) => {
                // if (!toast.isActive(toastId.current)) {
                //     toastId.current = toast.error(obj?.message)
                // }
            })
    }

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <>
            {status1w?.status === "loading" && (
                <CommonLoader />
            )}

            <div className="order-heading">
                <div className="header-left-item">
                    <h4><Link to='/profile'><img src={leftArrowImg} alt="leftArrowImg" /></Link> Profile</h4>
                </div>
                <div className="header-right-item">
                    <Link className="saveBtn_" to="#" onClick={() => handleCheck()}>Save</Link>
                </div>
            </div>

            <div className="main-content">
                <div className="upload-btn-wrapper upload-btn-wrapper2">
                    <div className="profile-imageprt">
                        {/* {status1w?.status === "loading" && (
                            <span className="spinner-border spinner-border-sm"></span>
                        )} */}
                        &nbsp;&nbsp;
                        <img src={img1 ? img1 : selectProfile?.payload?.user_profiles?.profile_photo ? selectProfile?.payload?.user_profiles?.profile_photo : editProfileImg} alt="editProfileImg" />
                        <input type="file" name="myfile" style={{ opacity: '0' }} onChange={(e) => handleProfileChange(e)} />
                    </div>
                </div>
                <div className="profile-describe">
                    <form action="#" method="POST">
                        <div className="profile-info">
                            <label htmlFor="">Name</label>
                            <div className="inputFiled"><img src={user2Img} alt="user2Img" /><input className="user-name p-edit" type="text" id="r1" value={fullname} name="userName" onChange={(e) => { onChangeInput(e, 'userName') }} /></div>
                        </div>
                        <div className="profile-info">
                            <label htmlFor="">Email address</label>
                            <div className="inputFiled"><img src={emailImg} alt="emailImg" /> <input disabled className="user-name p-edit" type="text" id="r2" value={userEmail ? userEmail : 'abc@gmail.com'} name="email" onChange={(e) => { onChangeInput(e, 'email') }} /></div>
                        </div>
                        <div className="profile-info">
                            <label htmlFor="">Phone number</label>
                            <div className="inputFiled"><img src={tellImg} alt="tellImg" /><input disabled className="user-name p-edit" type="text" id="r2" value={phoneNo} name="mobile" onChange={(e) => { onChangeInput(e, 'mobile') }} /><img className="verified" src={verifyImg} alt="verifyImg" /></div>
                        </div>
                        <div className="profile-info">
                            <label htmlFor="">SSN</label>
                            <div className="inputFiled"><img src={calender2Img} alt="calender2Img" /> <input className="user-name p-edit" type="text" id="r2" value={ssnNo} name="ssn" onChange={(e) => { onChangeInput(e, 'ssn') }} /><img className="verified" src={verifyImg} alt="verifyImg" /></div>
                        </div>
                        <div className="profile-info">
                            <label htmlFor="">Date of Birth</label>
                            <div className="inputFiled"><img src={calender2Img} alt="calender2Img" />
                                <DatePicker
                                    selected={moment(dob).toDate()}
                                    name="birth"
                                    className="birth p-edit"
                                    onChange={(date) => setDob(date)}
                                    dateFormat="MM/dd/yyyy"
                                    maxDate={moment().subtract(10, 'years')._d}
                                    placeholderText="MM/DD/YYYY"
                                    autoComplete="off"
                                />
                            </div>
                            {/* <input className="birth p-edit" type="date" id="r3" value={dob} placeholder="mm / dd / yyyy" name="dob" onChange={(e) => { onChangeInput(e, 'dob') }} /> */}
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
}

export default EditProfile;