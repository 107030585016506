import axios from "axios";
const queryString = require('query-string');

export function getFavoriteList(data, token) {
  let query = queryString.stringify(data)
  return new Promise((resolve, reject) =>
    axios
      .get(`${process.env.REACT_APP_STAGING_USER_SERVICES}api/v1/favourites` + "?" + query, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
          'app-name': 'b2c'
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        console.error(error);
        reject(error)
      })
  )
}

export function createFavoriteListApi(data, token) {
  return new Promise((resolve, reject) =>
    axios
      .post(`${process.env.REACT_APP_STAGING_USER_SERVICES}api/v1/favourites`, data, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
          'app-name': 'b2c'
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        console.error(error);
      })
  )
}
export function createProductFavoriteApi(data, token) {
  return new Promise((resolve, reject) =>
    axios
      .post(`${process.env.REACT_APP_STAGING_USER_SERVICES}api/v1/product_favourites`, data, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
          'app-name': 'b2c'
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        console.error(error);
      })
  )
}

export function getProductFavoriteList(data, token) {
  let query = queryString.stringify(data)
  return new Promise((resolve, reject) =>
    axios
      .get(`${process.env.REACT_APP_STAGING_USER_SERVICES}api/v1/product_favourites` + "?" + query, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
          'app-name': 'b2c'
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        console.error(error);
      })
  )
}