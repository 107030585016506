import React, { useEffect, useState } from "react";
import { Link, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import { leftArrowImg, coinStackImg } from "../../../utilities/images";
import { faqAsync, selectUserFaq } from "./faqSlice";
import { selectWalletBalance } from "../wallet/walletSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import CommonLoader from "../../components/shared/CommonLoader";

const Faq = (props) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const selectFaq = useSelector(selectUserFaq);
    const walletData = useSelector(selectWalletBalance);
    let Faq = selectFaq?.faq?.payload;

    useEffect(() => {
        window.scrollTo(0, 0)
        let params = {
            page: 1,
            limit: 10
        }
        setLoading(true)
        dispatch(faqAsync(params))
            .then(unwrapResult)
            .then(async (obj) => {
                setLoading(false)
            }
            )
            .catch((obj) => {
                setLoading(false)

            })
    }, [])

    return (
        <>
            <div className="order-heading">
                <div className="header-left-item">
                    <h4><Link to='/help-center'><img src={leftArrowImg} alt="leftArrowImg" /></Link> FAQ</h4>
                </div>
                <div className="header-right-item">
                    <Link to="/wallet" className="coinStack">{walletData ? walletData?.data?.payload?.sila_balance : 0}
                        <img src={coinStackImg} alt="coinStack" />
                    </Link>
                </div>
            </div>
            {loading === true ?
                <CommonLoader />
                :
                <>
                    <div className="main-content">
                        <div className="help-part">
                            {Faq && Faq?.data?.length > 0 ? (
                                <>
                                    {Faq?.data?.map((items, index) => {

                                        return (
                                            <ul key={index}>
                                                <li onClick={() => { history.push({ pathname: '/my-account-two', state: { faqid: items.id } }) }}>
                                                    <Link to='#' >{items.question} <i className="fas fa-chevron-right"></i></Link>
                                                </li>
                                                {/* <li>
                                            <Link to='/faq-verified'>How to get verified? <i className="fas fa-chevron-right"></i></Link>
                                        </li>
                                        <li>
                                            <Link to="#">SMS verification not received <i className="fas fa-chevron-right"></i></Link>
                                        </li>
                                        <li>
                                            <Link to='/home-support'>Delete my account <i className="fas fa-chevron-right"></i></Link>
                                        </li> */}
                                            </ul>
                                        )
                                    })}
                                </>
                            ) : (
                                <p className="text-center mt-5 w-70">No Faq(s) Found</p>
                            )}

                        </div>
                    </div>
                </>}
        </>

    );
}

export default Faq;