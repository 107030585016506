import axios from "axios";

export function getIdCardPhoto(data) {
    var formData = new FormData();
    formData.append('document', data.document);
    return new Promise((resolve, reject) =>
        axios.post(`${process.env.REACT_APP_STAGING_USER_SERVICES}api/v1/user_profiles/verification-document`, formData, {
            headers: {
                'Content-Type': 'application/json',
                'app-name': 'b2c'
            }
        })
            .then(function (response) {
                resolve(response);
            })
            .catch(function (error) {
                console.error(error);
                reject(error)
            })
    )
}